import cx from 'classnames';
import { useState } from 'react';

import Image from '../../Image/Image';
import StyledForm from '../../StyledForm/StyledForm';

import styles from './inBlogEmailSignup.module.scss';

/**
 * @param {object} [props] component props
 * @param {object} [props.image] the image part of the component
 * @param {string} [props.title] headline of the sign up section
 * @param {string} [props.copy] description of the sign up
 * @param {object} [props.newsletterForm] data for the newsletter form
 * @returns {JSX.Element} react component
 */
export default function InBlogEmailSignup({ image, title, copy, newsletterForm }) {
  const [isNewsletterSent, setIsNewsletterSent] = useState(false);

  const inBlogEmailSignupImageSizes = {
    defaultSize: [351, 346],
    fallbackSize: [351, 346],
  };

  const newsletterFormImageSize =
    newsletterForm?.fields?.successImage?.fields?.file?.details?.image;

  return (
    <div className={styles.container}>
      <div className={styles['image-and-content-container']}>
        <div className={styles['image-and-content']}>
          <div className={styles['image-container']}>
            <Image
              classNameImage={styles.image}
              url={image.fields.file.url}
              alt={image.fields.description}
              imageSizes={inBlogEmailSignupImageSizes}
              quality="30"
              fit="pad"
              roundedCorners="max"
              wrapImage={false}
            />
          </div>

          <div className={styles.content}>
            <h2 className={cx('h4', styles.headline)}>{title}</h2>

            <p className={cx('p2', styles.copy)}>{copy}</p>
          </div>
        </div>
      </div>

      {isNewsletterSent ? (
        <div id="inblog-newsletter-success" className={styles.newsletterSuccess}>
          {newsletterForm?.fields?.successImage?.fields?.file?.url && (
            <Image
              classNameImage={styles.successImage}
              url={newsletterForm.fields.successImage.fields.file.url}
              alt={newsletterForm.fields.successImage.fields.description}
              widht={newsletterFormImageSize?.width}
              height={newsletterFormImageSize?.height}
              quality="40"
              wrapImage={false}
            />
          )}
          <h3 className={styles.successTitle}>{newsletterForm?.fields?.successMessageTitle}</h3>
          <p className="p2">{newsletterForm?.fields?.successMessage}</p>
        </div>
      ) : (
        newsletterForm?.fields && (
          <StyledForm
            fields={newsletterForm.fields}
            id="inBlog"
            isInline="true"
            className={styles.form}
            setIsFormSent={setIsNewsletterSent}
            hidden={false}
            netlify={true}
          />
        )
      )}
    </div>
  );
}
