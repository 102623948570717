import { useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import cx from 'classnames';

import AccessibleButton from '../AccessibleButton/AccessibleButton';
import SiteContext from '../AppContext';
import IngredientsGrid from '../IngredientsGrid/IngredientsGrid';
import { breakpoints, getImageSize } from '../../lib/imageUtils';

import styles from './benefitsAndCallout.module.scss';

/**
 * @param {object} [props] component props
 * @param {boolean} [props.hasAnimation] animate component into page
 * @param {object} [props.fields] component field props
 * @param {object} [props.fields.mainImage] benefits accent image (now fresh only)
 * @param {object} [props.fields.accentImage] benefits accent image (now fresh only)
 * @param {Array<any>} [props.fields.iconsWithText] array of benefits
 * @param {object} [props.fields.cta] benefits cta
 * @param {string} [props.fields.title] callout text
 * @returns {JSX.Element} react component for our philosophy
 */
export default function BenefitsAndCallout({
  hasAnimation = false,
  fields: { mainImage, accentImage, cta, iconsWithText, title },
}) {
  const siteContext = useContext(SiteContext);
  const { brand } = siteContext;

  const [animIn, setAnimIn] = useState(!hasAnimation);

  const [benefitsRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  });

  const mainImageSize = mainImage?.fields?.file?.details?.image || [];

  const mainImageSrcSize = [
    { media: breakpoints.xl, size: getImageSize(716, mainImageSize) },
    { media: breakpoints.md, size: getImageSize(500, mainImageSize) },
    { media: breakpoints.sm, size: getImageSize(300, mainImageSize) },
  ];

  const [mainImgWidth, mainImgHeight] = mainImage ? getImageSize(500, mainImageSize) : [500, null];

  const ctaType = brand === 'now-fresh' || brand === 'summit' ? 'primary' : 'secondary';

  useEffect(() => {
    if (inView) {
      setAnimIn(true);
    }
  }, [inView]);

  return (
    <div className={styles.container}>
      {accentImage?.fields?.file && (
        <div
          aria-label={accentImage?.fields.description}
          className={styles.accentImage}
          style={{ backgroundImage: `url('${accentImage.fields.file.url}')` }}
        />
      )}
      <div
        className={cx(styles.benefitsAnimWrapper, { [styles.benefitsAnimIn]: animIn })}
        ref={benefitsRef}>
        {iconsWithText && (
          <div className={styles.benefits}>
            <IngredientsGrid ingredients={iconsWithText} />
          </div>
        )}

        {title && (
          <div className={styles.callout}>
            <div className={styles.contentWrapper}>
              <h2 className={styles.calloutHeadline}>{title}</h2>
              {cta?.fields?.linkText && (
                <div className={styles.calloutCta}>
                  <AccessibleButton
                    className={styles.calloutBtn}
                    link={cta}
                    theme="dark"
                    type={ctaType}>
                    {cta.fields.linkText}
                  </AccessibleButton>
                </div>
              )}
            </div>
            {mainImage && (
              <div className={styles.bgImage}>
                <picture>
                  {mainImageSrcSize.map(({ media, size }, index) =>
                    ['avif', 'webp'].map((imgFormat, formatIndex) => (
                      <source
                        key={`${index}${formatIndex}`}
                        media={media}
                        type={`image/${imgFormat}`}
                        srcSet={`${mainImage.fields.file.url}?w=${size[0]}&fm=${imgFormat}, ${
                          mainImage.fields.file.url
                        }?w=${size[0] * 2}&fm=${imgFormat} 2x`}
                      />
                    )),
                  )}
                  <img
                    src={`${mainImage.fields.file.url}?w=${mainImgWidth}`}
                    alt={mainImage.fields.description ?? ''}
                    width={mainImgWidth}
                    height={mainImgHeight}
                  />
                </picture>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
