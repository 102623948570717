import RemoteIcon from '../RemoteIcon/remoteIcon';
import cx from 'classnames';
import styles from './ingredientsGrid.module.scss';

/**
 * @param {object} [props] component Props
 * @param {Array<any>} [props.ingredients] List of quantities for the table
 * @param {boolean} [props.xLargeNoWrap] Show full width for desktop
 * @returns {JSX.Element} react component for the ingredients grid
 */
export default function IngredientsGrid({ ingredients, xLargeNoWrap = false }) {
  let hasEyebrow = false;

  if (ingredients) {
    // If any of the ingredients has an eyebrow, switch to eyebrow layout
    for (let i = 0; i < ingredients.length; i++) {
      if (ingredients[i].fields?.eyebrow) {
        hasEyebrow = true;
        break;
      }
    }
  }

  return (
    <ul
      className={cx(styles.container, {
        [styles.xLargeNoWrap]: xLargeNoWrap,
        [styles.hasEyebrow]: hasEyebrow,
      })}>
      {ingredients &&
        ingredients.length &&
        ingredients.map((item, index) => (
          <li className={styles.ingredient} key={index}>
            {item.fields?.image?.fields?.file?.url && (
              <RemoteIcon
                url={item.fields.image.fields.file.url}
                className={styles.ingredientImg}
              />
            )}
            <div className={styles.ingredientText}>
              {hasEyebrow && <div className={styles.ingredientEyebrow}>{item.fields.eyebrow}</div>}
              <div className={styles.ingredientDesc}>{item.fields.primaryText}</div>
            </div>
          </li>
        ))}
    </ul>
  );
}
