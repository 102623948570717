import { getExternalLinkFromString } from '../../lib/utils';
import Cat2Info from './Cat2Info';
import Cat3Info from './Cat3info';

/**
 * Function that accepts a list of either ingredient or
 * ingredient quantity and returns a text rendering of the list.
 * @param {Array<any>} itemList list of ingredients or ingredient quantities
 * @param {string} separator the punctuation to use between ingredients
 * @returns {string} String representation of the list
 */
export function getTextFromIngredients(itemList, separator = ',') {
  return itemList.reduce((ingredientText, ingredient, i) => {
    if (i !== 0) {
      ingredientText =
        ingredientText.slice(-1) === ':' ? `${ingredientText} ` : `${ingredientText}${separator} `;
    }
    if (ingredient.sys.contentType.sys.id === 'ingredient') {
      ingredientText = `${ingredientText}${ingredient.fields.label}`;
      if (ingredient?.fields?.compositeList?.length > 0) {
        ingredientText = `${ingredientText} (${getTextFromIngredients(
          ingredient.fields.compositeList,
        )})`;
      }
    }
    if (ingredient.sys.contentType.sys.id === 'ingredientQuantity') {
      const ingredientObj = ingredient.fields.ingredient;
      ingredientText = `${ingredientText} ${ingredientObj.fields.label}`;
      ingredientText = `${ingredientText} ${ingredient.fields.quantity}`;
      if (ingredient?.fields?.compositeList?.length > 0) {
        ingredientText = `${ingredientText} (${getTextFromIngredients(
          ingredient.fields.compositeList,
        )})`;
      }
    }
    if (i === itemList.length - 1) {
      return `${ingredientText}`;
    } else {
      return ingredientText;
    }
  }, '');
}

/**
 * @param {object} [props] component props
 * @param {string} [props.availability] type of category
 * @param {Array<object>} [props.cat2Recipe] category 2 recipe content
 * @param {Array<object>} [props.cat3Recipe] category 3 recipe content
 * @param {string} [props.additivesPerKg] additives text
 * @param {string} [props.calorieContent] calorie content text
 * @param {string} [props.recommendedFor] recommended for text
 * @param {Array<object>} [props.guaranteedAnalysis] guaranteed analysis content
 * @param {string} [props.guaranteedAnalysisFinePrint] guaranteed analysis fine print text
 * @param {Array<object>} [props.analyticalConstituents]  analytical constituents content
 * @param {string} [props.analyticalConstituentsFinePrint] analytical constituents fine print text
 * @param {object} [props.nutrientProfilePDF] nutrition profile pdf content
 * @param {boolean} [props.active] the ingredients table is active or not
 * @returns {JSX.Element} react component for the ingredients component
 */
export default function IngredientDetails({
  availability,
  cat2Recipe,
  cat3Recipe,
  additivesPerKg,
  calorieContent,
  recommendedFor,
  guaranteedAnalysis,
  guaranteedAnalysisFinePrint,
  analyticalConstituents,
  analyticalConstituentsFinePrint,
  nutrientProfilePDF,
  active,
}) {
  const pdfLink = getExternalLinkFromString(nutrientProfilePDF?.fields?.file?.url);

  return (
    <section>
      {availability === 'cat2' && (
        <Cat2Info
          calorieContent={calorieContent}
          cat2Recipe={cat2Recipe}
          guaranteedAnalysis={guaranteedAnalysis}
          guaranteedAnalysisFinePrint={guaranteedAnalysisFinePrint}
          pdfLink={pdfLink}
          recommendedFor={recommendedFor}
          active={active}
        />
      )}
      {availability === 'cat3' && (
        <Cat3Info
          recommendedFor={recommendedFor}
          pdfLink={pdfLink}
          additivesPerKg={additivesPerKg}
          analyticalConstituents={analyticalConstituents}
          analyticalConstituentsFinePrint={analyticalConstituentsFinePrint}
          cat3Recipe={cat3Recipe}
          active={active}
        />
      )}
    </section>
  );
}
