import { useRef } from 'react';
import Slider from 'react-slick';

import RichText from '../Richtext/Richtext';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import HeroCarouselMediaRenderer from './HeroCarouselMediaRenderer';
import styles from './heroCarouselSplash.module.scss';

/**
 * @param {object} [props] component props
 * @param {string} [props.headline] hero headline
 * @param {object} [props.headerImage] hero header image
 * @param {object} [props.headerImageMobile] mobile hero header image
 * @param {string} [props.subhead] hero subhead
 * @param {Array<any>} [props.headerCTAs] array of hero ctas
 * @returns {JSX.Element} react component for hero splash item
 */
function HeroCarouselSplashItem({ headline, headerImage, headerImageMobile, subhead, headerCTAs }) {
  const isRichTextField =
    typeof subhead === 'object' &&
    subhead !== null &&
    subhead.content &&
    Array.isArray(subhead.content);

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        {headline && <h1 className={styles.headline}>{headline}</h1>}
        {subhead &&
          (isRichTextField ? (
            <RichText content={subhead} className={styles.subhead} />
          ) : (
            <p className={styles.subhead}>{subhead}</p>
          ))}
        {headerCTAs && (
          <ul className={styles.ctas}>
            {headerCTAs.map((item, index) => (
              <li className={styles.cta} key={index}>
                <AccessibleButton type="primary" theme="dark" link={item} key={index}>
                  {item.fields && item.fields.linkText}
                </AccessibleButton>
              </li>
            ))}
          </ul>
        )}
      </div>
      {headerImage && (
        <HeroCarouselMediaRenderer headerImage={headerImage} hideOnMobile={!!headerImageMobile} />
      )}
      {headerImageMobile && <HeroCarouselMediaRenderer headerImage={headerImageMobile} isMobile />}
    </div>
  );
}

/**
 * @param {object} [props] component props
 * @param {Array<any>} [props.fields] component props
 * @returns {JSX.Element} react component for hero carousel
 */
export default function HeroCarouselSplash({ fields }) {
  const sliderEl = useRef(null);

  const settings = {
    slidesToShow: 1,
    arrows: true,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    className: 'hero-carousel-slick',
    pauseOnHover: false,
  };

  return (
    <div className={styles.carousel}>
      <Slider ref={sliderEl} {...settings}>
        {fields?.map((fields, index) => (
          <HeroCarouselSplashItem key={index} {...fields} />
        ))}
      </Slider>
    </div>
  );
}
