import AccessibleButton from '../AccessibleButton/AccessibleButton';
import styles from './primaryNavMenu.module.scss';
import cx from 'classnames';
import SubNav from './SubNav';
import Promoted from './Promoted';

/**
 * Component to render navigation item when it is a dropdown menu
 * @param {object} [props] component props
 * @param {boolean} [props.active] property to set if the menu item is the current active one
 * @param {string} [props.label] label of the menu item
 * @param {Function} [props.onClick] on click handler
 * @param {Function} [props.onKeyDown] on key down handler
 * @param {object} [props.secondaryNavigation] sub nav content
 * @param {object} [props.promoImage] image content
 * @param {string} [props.promoHeader] promoted header
 * @param {string} [props.promoLink] link content
 * @param {string} [props.promoCopy] copy used in the promoted section
 * @param {Function} [props.dispatch] action dispatcher for nav reducer
 * @param {object} [props.state] nav state
 * @returns {JSX.element} primary nav menu item component
 */
export default function PrimaryNavMenu({
  active,
  label,
  onClick,
  onKeyDown,
  secondaryNavigation,
  promoImage,
  promoHeader,
  promoLink,
  promoCopy,
  state,
  dispatch,
}) {
  return (
    <div className={cx(styles.menuContainer)}>
      <AccessibleButton
        onClick={onClick}
        className={cx(styles.menuItem)}
        onKeyDown={onKeyDown}
        icon={active ? 'Minimize' : 'Expand'}>
        {label}
      </AccessibleButton>
      {secondaryNavigation && (
        <div className={styles.background} style={{ height: `${active ? state.navHeight : 0}px` }}>
          <div className={styles.subnavContainer}>
            <div className={styles.subnavWrapper}>
              <ul className={cx(styles.subnav)}>
                {secondaryNavigation.map((item, i) => (
                  <li className={cx(styles.subnavItem)} key={i}>
                    <SubNav
                      {...item.fields}
                      promoImage={promoImage}
                      promoHeader={promoHeader}
                      promoLink={promoLink}
                      promoCopy={promoCopy}
                      activeCategory={state.activeSubNav === i && active}
                      animated={state.animated}
                      dispatch={dispatch}
                      index={i}
                    />
                  </li>
                ))}
              </ul>
              {promoImage && (
                <Promoted
                  imageUrl={promoImage.fields.file.url}
                  imageAlt={promoImage.fields.description}
                  promoCopy={promoCopy}
                  promoLink={promoLink}
                  header={promoHeader}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
