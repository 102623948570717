import GenericLink from '../GenericLink/GenericLink';
import RemoteIcon from '../RemoteIcon/remoteIcon';
import RichText from '../Richtext/Richtext';
import styles from './footerBottom.module.scss';
import cx from 'classnames';
import SiteContext from '../AppContext';
import { useContext } from 'react';

/**
 * @param {object} [props] component props
 * @param {Array} [props.linkColumns] Data for the column links
 * @param {boolean} [props.showSocialLinks] Flag to show or hide social column title & links
 * @param {string} [props.socialColumnHeader] fourth column title
 * @param {Array} [props.socialLinks] social network links
 * @param {object} [props.corporateLogo] petcurean logo
 * @param {object} [props.corporateText] rich text for logo slogan
 * @param {Array} [props.corporateLinks] bottom footer links
 * @param {Array} [props.locale] locale of the site
 * @returns {JSX.Element} footer bottom react component
 */
export default function FooterBottom({
  linkColumns,
  showSocialLinks,
  socialColumnHeader,
  socialLinks,
  corporateLogo,
  corporateText,
  corporateLinks,
  locale,
}) {
  const { brand } = useContext(SiteContext);
  const petcureanHref =
    locale.toLowerCase() === 'zh-cn' && brand != 'petcurean'
      ? 'https://www.petcurean.cn'
      : 'https://www.petcurean.com';
  return (
    <div className={styles.footer}>
      <section
        className={cx(styles.container, {
          [styles.columnFullWidth]: !showSocialLinks,
        })}>
        {linkColumns.map((column, i) => (
          <div className={styles.column} key={i}>
            <h2 className={cx('h5', styles.columnHeader)}>{column?.fields?.title}</h2>
            <ul>
              {column?.fields?.links?.map((link, i) => (
                <li className={styles.columnLinkContainer} key={i}>
                  <GenericLink
                    internalLink={link.fields.internalPage}
                    externalLink={link.fields.linkAddress}
                    className={styles['column-link']}>
                    {link.fields.linkText}
                  </GenericLink>
                </li>
              ))}
            </ul>
          </div>
        ))}
        {showSocialLinks && (
          <div className={styles.column}>
            <h2 className={cx('h5', styles.columnHeader)}>{socialColumnHeader}</h2>
            <ul>
              {socialLinks?.map((socialLink, i) => (
                <li className={styles.socialColumnLinkContainer} key={i}>
                  <GenericLink
                    externalLink={socialLink.fields.externalLinkAddress}
                    className={styles.columnLink}
                    externalIcon={false}>
                    <RemoteIcon
                      url={socialLink.fields.icon.fields.file.url}
                      className={styles.socialIcon}
                    />
                    {socialLink.fields.name}
                  </GenericLink>
                </li>
              ))}
            </ul>
          </div>
        )}
      </section>
      <section className={cx(styles.container, styles.bottom)}>
        <div className={styles.branding}>
          <a
            href={petcureanHref}
            target="_new"
            className={styles.logo}
            aria-label={corporateLogo?.fields.title}>
            <RemoteIcon url={corporateLogo?.fields.file.url} className={styles.brandLogo} />
          </a>

          <RichText content={corporateText} className={styles['brand-slogan']} />
        </div>
        <ul className={styles.bottomLinks}>
          {corporateLinks?.fields?.links?.map((link, i) => (
            <li key={i}>
              <GenericLink
                internalLink={link.fields.internalPage}
                externalLink={link.fields.linkAddress}
                className={styles.bottomLink}>
                {link.fields.linkText}
              </GenericLink>
            </li>
          ))}
        </ul>
      </section>
    </div>
  );
}
