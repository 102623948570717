import GenericLink from '../GenericLink/GenericLink';
import { Arrow } from '../Icons';
import Picture from '../Image/Picture';
import styles from './promoted.module.scss';

/**
 * @param {object} [props] component props
 * @param {string} [props.imageUrl] promoted image URL
 * @param {string} [props.imageAlt] promoted image alt text
 * @param {string} [props.header] promoted section header
 * @param {object} [props.promoLink] CMS link
 * @param {string} [props.promoCopy] copy used in promoted section
 * @returns {JSX.element} react component
 */
export default function Promoted({ imageUrl, imageAlt, header, promoLink, promoCopy }) {
  return (
    <div className={styles.promoted}>
      <GenericLink
        internalLink={promoLink.fields?.internalPage}
        externalLink={promoLink.fields?.linkAddress}
        externalIcon={false}>
        <Picture
          className={styles.promotedImage}
          imageSizes={{ sm: [103, 103], md: [140, 140], lg: [140, 140] }}
          url={imageUrl}
          alt={imageAlt}
          quality="50"
        />
      </GenericLink>
      <div className={styles.promotedTextContainer}>
        <p className={styles.promotedHeader}>{header}</p>
        <GenericLink
          internalLink={promoLink.fields?.internalPage}
          externalLink={promoLink.fields?.linkAddress}
          externalIcon={false}
          AppendedIcon={<Arrow />}
          className={styles.promotedCopy}>
          {promoCopy}
        </GenericLink>
      </div>
    </div>
  );
}
