import styles from './heroSimple.module.scss';
import { getImageSize, breakpoints } from '../../lib/imageUtils';
import { useContext } from 'react';
import { colors } from '../../lib/constants';

import SiteContext from '../AppContext';

/**
 * React component for Hero Simple
 * @param {object} [props] component props
 * @param {string} [props.title] title text to display
 * @param {object} [props.headerImage] image content
 * @param {object} [props.accentImage] freefrom accent image content
 * @returns {JSX.Element} Returns Category Landing react component
 */
export default function HeroSimple({ title, headerImage, accentImage }) {
  const { brand } = useContext(SiteContext);

  const imageQuality = 60;
  const imgParams = {
    gather: `&bg=${colors.gather.backgroundCream}&q=${imageQuality}`,
    'go-solutions': `&bg=${colors.goSolutions.backgroundBlack}&q=${imageQuality}`,
    'now-fresh': `&bg=${colors.nowFresh.backgroundTan}&q=${imageQuality}`,
    petcurean: `&bg=${colors.petcurean.backgroundWhite}&q=${imageQuality}`,
    summit: `&bg=${colors.summit.backgroundWhite}&q=${imageQuality}`,
  };

  const accentImageSize = accentImage?.fields?.file?.details?.image || [];
  const [accentImgWidth, accentImgHeight] = accentImage
    ? getImageSize(accentImageSize?.width, accentImageSize)
    : [500, null];

  const imgElement = () => {
    const simpleHeroImageSize = headerImage?.fields?.file?.details?.image || [];
    const simpleHeroImageURL = headerImage?.fields?.file?.url;
    const simpleHeroImageSizes = [
      { media: breakpoints.lg, size: getImageSize(450, simpleHeroImageSize) },
      { media: breakpoints.md, size: getImageSize(480, simpleHeroImageSize) },
      { media: breakpoints.sm, size: getImageSize(400, simpleHeroImageSize) },
    ];

    const [simpleHeroImageWidth, simpleHeroImageHeight] = headerImage
      ? getImageSize(400, simpleHeroImageSize)
      : [400, null];

    return (
      headerImage?.fields?.file && (
        <picture>
          {simpleHeroImageSizes.map(({ media, size }, index) =>
            ['avif', 'webp'].map((imgFormat, formatIndex) => (
              <source
                key={`${index}${formatIndex}`}
                media={media}
                type={`image/${imgFormat}`}
                width={size[0]}
                height={size[1]}
                srcSet={
                  /* eslint-disable max-len */
                  `${simpleHeroImageURL}?w=${size[0]}&h=${size[1]}&fm=${imgFormat}${imgParams[brand]}, ` +
                  `${simpleHeroImageURL}?w=${size[0] * 2}&h=${size[1] * 2}&fm=${imgFormat}${[
                    imgParams[brand],
                  ]} 2x`
                }
              />
            )),
          )}
          <img
            /* eslint-disable max-len */
            src={`${simpleHeroImageURL}?w=${simpleHeroImageWidth}&h=${simpleHeroImageHeight}${[
              imgParams[brand],
            ]}`}
            alt={headerImage.fields.description || ''}
            className={styles['hero-bg']}
            width={simpleHeroImageWidth}
            height={simpleHeroImageHeight}
          />
        </picture>
      )
    );
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.heroTitle}>{title}</h1>
      {headerImage?.fields?.file && <div className={styles.heroBgContainer}>{imgElement()}</div>}
      {accentImage?.fields?.file && (
        <div className={styles.accentImage}>
          <img
            src={`${accentImage.fields.file.url}`}
            alt={accentImage.fields.description || ''}
            width={accentImgWidth}
            height={accentImgHeight}
          />
        </div>
      )}
    </div>
  );
}
