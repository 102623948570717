import cx from 'classnames';

import { checkboxGroupDivider } from '../../lib/constants';
import { Check } from '../Icons';

import styles from './styledCheckboxGroup.module.scss';

const dividerRegex = new RegExp(checkboxGroupDivider, 'g');

/**
 * @param {object} [props] component props
 * @param {string} [props.id] checkbox group id
 * @param {string} [props.name] checkbox group name
 * @param {number} [props.label] checkbox group question
 * @param {string} [props.value] current active values
 * @param {Function} [props.onChange] checkbox on change handler
 * @param {boolean} [props.showError] flag to show or hide the error message
 * @param {string} [props.errorMsg] the error message text
 * @param {Array<object>} [props.advancedOptions] Options with submit value and label data
 * @param {Array} [props.options] Options to show
 * @param {string} [props.className] css class override for the component container
 * @param {boolean} [props.ariaRequired] flag to add the aria-required attribute
 * @returns {JSX.Element} react component
 */
export default function StyledCheckboxGroup({
  id,
  name,
  label,
  value,
  advancedOptions,
  options,
  onChange,
  showError,
  errorMsg,
  className,
  ariaRequired,
}) {
  const values = value.split(checkboxGroupDivider);
  const hasAdvancedOptions = advancedOptions?.length > 0;

  return (
    <fieldset
      className={cx(styles.container, className, {
        [styles.error]: showError,
      })}>
      <legend className={cx('p2 bold', styles.description)}>{label}</legend>
      {hasAdvancedOptions
        ? advancedOptions.map((option, i) => {
            const safeKey = option.key.replace(dividerRegex, '');
            const safeValue = option.value.replace(dividerRegex, '');
            return (
              <label key={i} className={styles.option} id={`label-${id}-${i}`}>
                <div className={styles.inputWrapper}>
                  <input
                    className={styles.input}
                    id={`${id}-${i}`}
                    aria-labelledby={`label-${id}-${i}`}
                    name={name}
                    value={safeKey}
                    type="checkbox"
                    checked={values.includes(safeKey)}
                    onChange={onChange}
                    required={ariaRequired}
                  />
                  <Check />
                </div>
                <span className={cx('p2', styles.label)}>{safeValue}</span>
              </label>
            );
          })
        : options?.map((option, i) => {
            // Ensure the divider for the values sent to the server is not present in the
            // original values to avoid crashes.
            const safeOption = option.replace(dividerRegex, '');
            return (
              <label key={i} className={styles.option} id={`label-${id}-${i}`}>
                <div className={styles.inputWrapper}>
                  <input
                    className={styles.input}
                    id={`${id}-${i}`}
                    aria-labelledby={`label-${id}-${i}`}
                    name={name}
                    value={safeOption}
                    type="checkbox"
                    checked={values.includes(safeOption)}
                    onChange={onChange}
                    required={ariaRequired}
                  />
                  <Check />
                </div>
                <span className={cx('p2', styles.label)}>{safeOption}</span>
              </label>
            );
          })}
      <p className={styles.errorMsg}>{errorMsg}</p>
    </fieldset>
  );
}
