import Link from 'next/link';
import styles from './styledLink.module.scss';
import * as icons from '../Icons';
import cx from 'classnames';
import SiteContext from '../AppContext';
import { useContext } from 'react';
/**
 * component to be used when a button needs to have a different state when tabbed and on hover
 * @param {object} [props] component Props
 * @param {Function} [props.href] url for the href attribute
 * @param {Function} [props.noHref] flag to render a link with no href
 * @param {string} [props.locale] link locale, if not present, the default locale will be used
 * @param {Function} [props.onClick] optional on click event handler if href is not present
 * @param {Function} [props.onKeyDown] optional on key down handler if href is not present
 * @param {string} [props.className] classes to be used in component
 * @param {JSX.element} [props.children] children elements
 * @param {string} [props.ariaPressed] aria-pressed for the button
 * @param {string} [props.ariaLabel] aria-label for the button
 * @param {string} [props.type] type of link either p1, p2 or p3
 * @param {string} [props.tabIndex] flag to set the tab-index attribute
 * @param {string} [props.target] target to be used in the link
 * @param {string} [props.icon] optional icon to use
 * @param {string} [props.theme] dark or light theme
 * @param {string} [props.isBold] flag to create non bold links
 * @returns {JSX.element} accessible link
 */
export default function StyledLink({
  href,
  noHref,
  locale,
  onClick,
  onKeyDown,
  ariaLabel,
  ariaPressed,
  className,
  children,
  type,
  icon,
  tabIndex = 0,
  target = '_blank',
  theme = 'light',
  isBold = true,
}) {
  const siteContext = useContext(SiteContext);
  let linkToShow = null;
  const IconToShow = icon ? icons[icon] : false;
  // In some cases we want spans with the appearance of links inside other links
  const AnchorEl = noHref ? 'span' : 'a';

  // Given the extenalLink variant doesn't use <Link>, is easier to render hrefless links there.
  const isExternalLink = /^(http:\/\/|https:\/\/|tel:|mailto:|#)/.test(href) || noHref;
  const isInternalLink = !isExternalLink && href !== '';
  const attrs = isExternalLink
    ? {
        href,
        target,
        ['aria-label']: ariaLabel,
      }
    : {
        onClick,
        onKeyDown,
        ['aria-label']: ariaLabel,
        ['aria-pressed']: ariaPressed,
      };

  if (isInternalLink) {
    linkToShow = (
      // eslint-disable-next-line max-len
      <Link
        href={'/[locale]/[...slug]'}
        as={`/${locale ? locale : siteContext.locale}/${href}`}
        className={cx(styles.link, className, {
          [styles[type]]: !!type,
          [styles[theme]]: !!theme,
        })}
        tabIndex={tabIndex}
        aria-label={ariaLabel}>
        <>
          <p className={cx({ [styles.styledContent]: isBold }, type)} tabIndex={-1}>
            {children}
          </p>
          {IconToShow && <IconToShow />}
        </>
      </Link>
    );
  } else {
    linkToShow = (
      <AnchorEl
        className={cx(styles.link, className, 'styled-link', {
          [styles[type]]: !!type,
          [styles[theme]]: !!theme,
        })}
        tabIndex={tabIndex}
        {...attrs}>
        <>
          <p className={cx({ [styles.styledContent]: isBold }, type)} tabIndex={-1}>
            {children}
          </p>
          {IconToShow && <IconToShow />}
        </>
      </AnchorEl>
    );
  }

  return linkToShow;
}
