import cx from 'classnames';

import styles from './loader.module.scss';

/**
 * @param {object} [props] component props
 * @param {string} [props.theme] dark or light theme
 * @returns {JSX.Element} react component for the Loader
 */
export default function Loader({ theme = 'light' }) {
  return (
    <span className={cx(styles.container, styles[theme])}>
      <span className={cx(styles.dot, styles.dot1)} />
      <span className={cx(styles.dot, styles.dot2)} />
      <span className={cx(styles.dot, styles.dot3)} />
    </span>
  );
}
