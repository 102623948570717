import RichText from '../Richtext/Richtext';

import styles from './richTextComponent.module.scss';

/**
 * @param {object} [props] component props
 * @param {object} [props.fields] data for the component
 * @returns {JSX.Element} react component
 */
export default function RichTextComponent({ fields }) {
  return (
    <section className={styles.container}>
      {fields.richTextEditor && (
        <RichText content={fields.richTextEditor} className={styles.richTextContent} />
      )}
    </section>
  );
}
