import Picture from '../Image/Picture';
import styles from './benefits.module.scss';
import RemoteIcon from '../RemoteIcon/remoteIcon';

/**
 * @param {object} [props] component props
 * @param {object} [props.mainImage] headline image
 * @param {string} [props.title] component title
 * @param {string} [props.description] component description
 * @param {Array<any>} [props.iconsWithText] Content icons
 * @returns {JSX.Element} benefits component
 */
export default function Benefits({ mainImage, title, description, iconsWithText }) {
  const benefitsImageSizes = {
    lg: [475, 536],
    md: [323, 392],
    sm: [203, 230],
    fallbackSize: [203, 230],
  };

  return (
    <section className={styles.container}>
      {mainImage?.fields?.file && (
        <Picture
          className={styles.img}
          url={mainImage.fields.file.url}
          imageSizes={benefitsImageSizes}
          quality="70"
          alt={mainImage.fields.description}
        />
      )}

      <div className={styles.copyContainer}>
        <h2 className={styles.headline}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>
      {iconsWithText && (
        <ul className={styles.benefits}>
          {iconsWithText
            .filter((iconText) => iconText.fields)
            .map((iconText, i) => (
              <li className={styles.benefit} key={i}>
                {iconText.fields.image && (
                  <RemoteIcon
                    url={`${iconText.fields.image.fields.file.url}?w=75&h=75&fit=pad`}
                    className={styles.benefitImage}
                  />
                )}
                <p className={styles.benefitDescription}>{iconText.fields.primaryText}</p>
              </li>
            ))}
        </ul>
      )}
    </section>
  );
}
