/**
 * Global breakpoints
 * these values mirror variables set in _viewports.scss
 */
export const breakpoints = {
  sm: '(max-width: 719px)',
  md: '(min-width: 720px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
};

/**
 * Returns the placeholder element
 * @param {object} [props] component props
 * @param {string} [props.className] placeholder classname
 * @param {string} [props.height] height amount
 * @param {string} [props.aspectRatio] placeholder aspect ratio
 * @param {string} [props.isPlaceholderAbsolute] is placeholder absolutely positioned
 * @returns {JSX.Element} react component for the placeholder
 */
export function getImgPlaceholder({
  className,
  height,
  aspectRatio = 9 / 16,
  isPlaceholderAbsolute = false,
}) {
  let style;

  if (!isPlaceholderAbsolute) {
    style = !height
      ? {
          paddingBottom: `${aspectRatio * 100}%`,
        }
      : {
          height: `${height}px`,
        };
  }

  return <div className={className} style={style} />;
}
/**
 * Returns URL with all available params passed
 * @param {string} url image url
 * @param {Array} viewport Array with width and height properties
 * @param {string} fit image object-fit property
 * @param {string} bgColor image background color property
 * @param {string} quality image quality property
 * @param {string} format image format
 * @param {string} roundedCorners image crop rounded corners & circle/elipsis property
 * @param {number} pixelDensity image pixel Density
 * @returns {string} url + query parameters to request an image
 */
export function urlParams(
  url,
  viewport = [],
  fit,
  bgColor,
  quality,
  format,
  roundedCorners,
  pixelDensity = 1,
) {
  const height = viewport[1] ?? null;
  const width = viewport[0] ?? null;
  const queryStrings = [];

  if (width) queryStrings.push(`w=${width * pixelDensity}`);
  if (height) queryStrings.push(`h=${height * pixelDensity}`);
  if (fit) queryStrings.push(`fit=${fit}`);
  if (bgColor) queryStrings.push(`bg=${bgColor}`);
  if (format) queryStrings.push(`fm=${format}`);
  if (quality) queryStrings.push(`q=${quality}`);
  if (roundedCorners) queryStrings.push(`r=${roundedCorners}`);

  return url + '?' + queryStrings.join('&');
}

/**
 * Returns an object with srcSet property
 * @param {string} srcUrl image url
 * @param {string} srcUrl2x image url for 2x px density asset
 * @returns {object} Returns an object with srcSet property
 */
export const formatSources = (srcUrl, srcUrl2x) => {
  const srcSetFieldProp = {};
  srcSetFieldProp.srcSet = `
      ${srcUrl}, 
      ${srcUrl2x} 2x
    `;
  return srcSetFieldProp;
};

/**
 * Finds image size based on contentful size response
 * @param {number} finalWidth - Rendered image width
 * @param {object} baseSize - Base image size
 * @returns {Array} Computed image size [width, height]
 */
export const getImageSize = (finalWidth = 0, baseSize) => {
  if (!baseSize) return [finalWidth, null];

  const aspectRatio = baseSize?.height / baseSize?.width;
  return [finalWidth, Math.round(finalWidth * aspectRatio)];
};

/**
 * Returns a multiplied image width for Now Fresh images
 * @param {number} width - Rendered image width
 * @param {string} brand - brand context
 * @param {string} multiplier - Image width multiplier
 * @returns {number} returns true if the brand is now-fresh and the image width is adjusted
 */
export const adjustImageSize = (width, brand, multiplier = 2) =>
  brand === 'now-fresh' ? width * multiplier : width;
