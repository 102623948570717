import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';

import styles from './videoComponent.module.scss';

/**
 * @param {object} [props] component props
 * @param {string} [props.source] component source - vimeo or youtube
 * @param {string} [props.code] component video code
 * @returns {JSX.Element} react video component
 */
export default function VideoComponent({ source, code }) {
  const ytOptions = {
    width: '100%',
    playerVars: {
      modestbranding: 1,
      rel: 0,
    },
  };

  const vimeoOptions = {
    showByline: false,
    showPortrait: false,
  };

  const renderVideo = () => {
    if (source && code) {
      switch (source.toLowerCase()) {
        case 'youtube':
          return <YouTube videoId={code} opts={ytOptions} />;
        case 'vimeo':
          return <Vimeo video={code} {...vimeoOptions} />;
        default:
          return <div>Invalid video type</div>;
      }
    } else {
      return null;
    }
  };

  return <div className={styles.container}>{renderVideo()}</div>;
}
