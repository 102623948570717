import { urlParams } from '../../lib/imageUtils';
import cx from 'classnames';

import styles from './image.module.scss';

/**
 * @param {object} [props] component props
 * @param {string} [props.alt] image alt text
 * @param {string} [props.bgColor] image background color property
 * @param {string} [props.className] image container class name
 * @param {string} [props.classNameImage] image class name
 * @param {string} [props.fit] image object-fit property
 * @param {object} [props.imageSizes] image sizes for default image size and fallback
 * (defaultSize, fallbackSize)
 * @param {string} [props.quality] image quality property
 * @param {string} [props.sizes] image sizes
 * @param {string} [props.url] image url
 * @param {string} [props.roundedCorners] image crop rounded corners & circle/elipsis property
 * @param {string} [props.loading] enable loading lazy attribute
 * @param {string} [props.fallbackFormat] force a fallback format when avif and webp checkup fails
 * @param {string} [props.wrapImage] validate if img tag should be wrap by one div container
 * @returns {JSX.Element} react component for the Image
 */
export default function Image({
  alt = '',
  className,
  sizes,
  url,
  imageSizes = { defaultSize: [], fallbackSize: [] },
  fit,
  bgColor,
  quality,
  roundedCorners,
  loading = 'lazy',
  fallbackFormat,
  wrapImage = true,
  classNameImage,
}) {
  const { defaultSize, fallbackSize } = imageSizes;
  const imageIsSVG = url.split('.').find((element) => element === 'svg');
  const imgFormat = !imageIsSVG ? (fallbackFormat ? fallbackFormat : null) : null;

  const ImageContainer = ({ children, ...restProps }) => {
    return wrapImage ? <div {...restProps}>{children}</div> : <>{children}</>;
  };

  const src1x = urlParams(url, defaultSize, fit, bgColor, quality, imgFormat, roundedCorners);
  const src2x = urlParams(url, defaultSize, fit, bgColor, quality, imgFormat, roundedCorners, 2);

  return (
    <ImageContainer className={cx(styles.container, className)}>
      <img
        className={classNameImage}
        alt={alt}
        srcSet={defaultSize.length > 0 ? `${src1x}, ${src2x} 2x` : null}
        sizes={sizes}
        src={urlParams(url, fallbackSize, fit, bgColor, quality, imgFormat, roundedCorners)}
        loading={loading}
        decoding="async"
        width={defaultSize ? defaultSize[0] || null : null}
        height={defaultSize ? defaultSize[1] || null : null}
      />
    </ImageContainer>
  );
}
