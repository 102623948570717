import FooterTop from '../FooterTop/FooterTop';
import FooterBottom from '../FooterBottom/FooterBottom';

import styles from './footer.module.css';

/**
 * Footer component
 * @param {object} [props] component props
 * @param {string} [props.footerData] content for the Footer component
 * @param {string} [props.siteLocale] locale of the site
 * @returns {JSX.Element} Footer react component
 */
export default function Footer({ footerData, siteLocale }) {
  const {
    nutritionHotlineTitle,
    nutritionHotlinePhoneNumber,
    showNutritionHotlinePhoneNumber,
    nutritionHotlineBody,
    footerHeroImage,
    newsletterHeader,
    newsletterBody,
    contactUsForm,
    newsletterForm,
    linkColumns,
    socialColumnHeader,
    socialLinks,
    corporateLogo,
    corporateText,
    corporateLinks,
    reducedVersion,
    nutritionEmailTitle,
    nutritionHotlineEmailLink,
    showNutritionEmail,
    showSocialLinks,
  } = footerData;

  const locale = siteLocale;

  const footerTopData = {
    nutritionHotlineTitle,
    nutritionHotlinePhoneNumber,
    showNutritionHotlinePhoneNumber,
    nutritionHotlineBody,
    footerHeroImage,
    newsletterHeader,
    newsletterBody,
    contactUsForm,
    newsletterForm,
    reducedVersion,
    nutritionEmailTitle,
    nutritionHotlineEmailLink,
    showNutritionEmail,
  };
  const footerBottomData = {
    linkColumns,
    socialColumnHeader,
    socialLinks,
    corporateLogo,
    corporateText,
    corporateLinks,
    showSocialLinks,
    locale,
  };

  return (
    <footer className={styles.container}>
      <FooterTop {...footerTopData} />
      <FooterBottom {...footerBottomData} />
    </footer>
  );
}
