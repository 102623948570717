import styles from './blogLandingHero.module.scss';
import cx from 'classnames';
import SiteContext from '../AppContext';
import Picture from '../Image/Picture';
import RichText from '../Richtext/Richtext';
import { useContext } from 'react';

/**
 * @param {object} [props] component props
 * @param {string} [props.title] hero title
 * @param {string} [props.description] description
 * @param {object} [props.image] hero image content
 * @param {boolean} [props.showCategories] flag to show blog categories
 * @returns {JSX.Element} react component
 */
export default function BlogLandingHero({ title, description, image, showCategories }) {
  const { brand } = useContext(SiteContext);
  const imageSizesLarge = brand === 'go-solutions' ? [229, 431] : [284, 368];
  const imageSizesMed = brand === 'go-solutions' ? [177, 331] : [204, 264];
  const imageSizesSmall = brand === 'go-solutions' ? [177, 331] : [235, 304];

  const imgSrc = image?.fields?.file?.url;
  const imgAlt = image?.fields?.description;

  const blogLandingHeroImageSizes = {
    lg: [imageSizesLarge[0], imageSizesLarge[1]],
    md: [imageSizesMed[0], imageSizesMed[1]],
    sm: [imageSizesSmall[0], imageSizesSmall[1]],
    fallbackSize: [imageSizesSmall[0], imageSizesSmall[1]],
  };

  const petcureanLandingHeroImageSizes = {
    lg: [700, 600],
    md: [450, 400],
    sm: [300, 280],
    fallbackSize: [300, 380],
  };

  const blogLandingHeroImageFitMap = {
    gather: null,
    'go-solutions': 'fill',
    'now-fresh': 'fill',
    petcurean: null,
    summit: null,
  };

  return (
    <section className={styles.container}>
      <div className={styles.copyContainer}>
        <h1 className={styles.title}>{title}</h1>
        <RichText content={description} className={styles.copyContainer} />
      </div>
      {imgSrc && (
        <Picture
          className={cx(styles.image, { [styles.hasCategories]: showCategories })}
          url={imgSrc}
          imageSizes={
            brand === 'petcurean' ? petcureanLandingHeroImageSizes : blogLandingHeroImageSizes
          }
          fit={blogLandingHeroImageFitMap[brand]}
          quality="70"
          alt={imgAlt}
          loading="eagar"
        />
      )}
    </section>
  );
}
