import GenericLink from '../GenericLink/GenericLink';

/**
 * Component to render when a menu item is a link
 * @param {object} [props] component props
 * @param {object} [props.navItem] content from navigation item
 * @param {string} [props.className] classname to be used in the component
 * @returns {JSX.element} nav item component
 */
export default function PrimaryNavLink({ navItem, className }) {
  return (
    <GenericLink internalLink={navItem.fields.internalPage} className={className}>
      {navItem.fields.linkText}
    </GenericLink>
  );
}
