import cx from 'classnames';

import { toCamelCase } from '../../lib/utils';
import { checkboxGroupDivider } from '../../lib/constants';

import { DomeCover, Heart, Digestion, Skin, Happy, Energy } from '../Icons';

import styles from './callouts.module.scss';

const ICONS = {
  pickyEaterApproved: DomeCover,
  improvedHealth: Heart,
  easyDigestion: Digestion,
  skinCoatResults: Skin,
  happyPetParent: Happy,
  increasedEnergy: Energy,
};

/**
 * @param {object} [props] component props
 * @param {string} [props.id] checkbox group id
 * @param {string} [props.name] checkbox group name
 * @param {number} [props.label] checkbox group question
 * @param {string} [props.value] current active values
 * @param {Function} [props.onChange] checkbox on change handler
 * @param {boolean} [props.showError] flag to show or hide the error message
 * @param {string} [props.errorMsg] the error message text
 * @param {Array} [props.iconButtons] Options to show
 * @param {string} [props.className] css class override for the component container
 * @param {boolean} [props.ariaRequired] flag to add the aria-required attribute
 * @returns {JSX.Element} react component
 */
export default function Callouts({
  id,
  name,
  label,
  value,
  iconButtons,
  onChange,
  showError,
  errorMsg,
  className,
  ariaRequired,
}) {
  const values = value.split(checkboxGroupDivider);

  return (
    <fieldset
      className={cx(styles.container, className, {
        [styles.error]: showError,
      })}>
      <legend className={cx('p2 bold', styles.description)}>{label}</legend>

      {iconButtons &&
        iconButtons.map((iconButton, i) => {
          const Icon = ICONS[toCamelCase(iconButton.fields.iconCode)];

          return (
            <span key={i} className={styles.option}>
              <input
                className={styles.input}
                id={`${id}-${i}`}
                name={name}
                value={`${iconButton.fields.iconCode}^${iconButton.fields.primaryText}`}
                type="checkbox"
                // eslint-disable-next-line max-len
                checked={values.includes(
                  `${iconButton.fields.iconCode}^${iconButton.fields.primaryText}`,
                )}
                onChange={onChange}
                required={ariaRequired}
              />
              <label className={cx('p3 bold', styles.label)} htmlFor={`${id}-${i}`}>
                {Icon && (
                  <Icon height={18} width={18} className={styles[iconButton.fields.iconCode]} />
                )}
                <span className={styles.text}>{iconButton.fields.primaryText}</span>
              </label>
            </span>
          );
        })}
      <p className={styles.errorMsg}>{errorMsg}</p>
    </fieldset>
  );
}
