import { getFilteredEntries } from '../../../lib/productUtils';

/**
 * reducer to handle category landing state
 * @param {object} [state] category landing state
 * @param {object} [action] action object to be processed
 * @returns {object} new category landing state
 */
export default function CategoryLandingReducer(state, action) {
  switch (action.type) {
    case 'init-state':
      return {
        products: action.products,
        filteredProducts: action.filteredProducts,
        activeFilters: action.activeFilters,
        filters: action.filters,
        filtersElement: action.filtersElement,
        filtersStuck: action.filtersStuck,
        showCategories: action.showCategories,
        gridElementY: action.gridElementY,
        openCategories: action.openCategories,
      };
    case 'open-category':
      return {
        ...state,
        openCategories: new Set([...state.openCategories, action.category]),
      };
    case 'close-category':
      state.openCategories.delete(action.category);
      return {
        ...state,
        openCategories: new Set([...state.openCategories]),
      };
    case 'add-active-filter':
      return {
        ...state,
        products: [...state.products],
        filteredProducts: getFilteredEntries(state.products, [
          ...state.activeFilters,
          action.filter,
        ]),
        activeFilters: [...state.activeFilters, action.filter],
        showCategories: false,
      };
    case 'remove-active-filter':
      return {
        ...state,
        products: [...state.products],
        filteredProducts: getFilteredEntries(
          state.products,
          state.activeFilters.filter((filter) => filter !== action.filter),
        ),
        activeFilters: state.activeFilters.filter((filter) => filter !== action.filter),
        showCategories:
          state.activeFilters.filter((filter) => filter !== action.filter).length === 0,
      };
    case 'clear-filters':
      return {
        ...state,
        filteredProducts: state.products,
        activeFilters: [],
        showCategories: true,
      };
    case 'set-products':
      return {
        ...state,
        products: [...action.products],
      };
    case 'open-filters':
      return {
        ...state,
        filtersOpen: true,
      };
    case 'close-filters':
      return {
        ...state,
        openCategories: new Set(),
        filtersOpen: false,
      };
    case 'toggle-filters-stuck':
      return {
        ...state,
        filtersStuck: !state.filtersStuck,
      };
    case 'set-filters-stuck':
      return {
        ...state,
        filtersStuck: action.filtersStuck,
      };
    default:
      throw new Error();
  }
}
