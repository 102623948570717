import FileHeader from './FileHeader';
import { toCamelCase } from '../../lib/utils';
import { useTranslation } from '../../lib/hooks';
import styles from './bvMediaUpload.module.scss';

/**
 *
 * @param {object} [props] component props
 * @param {object} [props.file] file object containing errors
 * @param {Function} [props.onDelete] function to delete the file
 * @param {Array} [props.errors] array of errors
 * @returns {JSX.Element} UploadError react component
 */
export default function UploadError({ file, onDelete, errors }) {
  const { t } = useTranslation();
  return (
    <li>
      <FileHeader file={file} onDelete={onDelete} error={true} />
      {errors?.map((error, i) => (
        <p key={i} className={styles.errorMsg}>
          {error?.code ? t(`common.forms.general.${toCamelCase(error.code)}`) : error}
        </p>
      ))}
    </li>
  );
}
