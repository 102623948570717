import AccessibleButton from '../AccessibleButton/AccessibleButton';
import styles from './bvMediaUpload.module.scss';
import cx from 'classnames';
import { Close } from '../Icons';
import { useTranslation } from '../../lib/hooks';
import Loader from '../Loader/Loader';

/**
 * Get string of file name and file size
 * @param {object} [file] file object
 * @returns {string} file name and size
 */
function getFileDetails(file) {
  const { name, size } = file;
  const fileBytes = size >= 1048576 ? 'MB' : 'KB';
  const fileSize = size >= 1048576 ? (size / 1048576).toFixed(2) : (size / 1024).toFixed(0);

  return `${name} - ${fileSize}${fileBytes}`;
}

/**
 *
 * @param {object} [props] component props
 * @param {object} [props.file] the file object
 * @param {boolean} [props.error] flag to style as file with error
 * @param {boolean} [props.isLoading] flag to style as file that is loading
 * @param {Function} [props.onDelete] function to remove file from state
 * @returns {JSX.Element} FileHeader react comopnent
 */
export default function FileHeader({ file, error, isLoading, onDelete }) {
  const { t } = useTranslation();
  return (
    <div
      className={cx(styles.fileHeader, {
        [styles.error]: error,
        [styles.loading]: isLoading,
      })}>
      <p>{getFileDetails(file)}</p>
      {isLoading && <Loader />}
      <AccessibleButton
        className={styles.removeFile}
        ariaLabel={t('common.form.deleteFile')}
        onClick={() => onDelete(file)}
        submit={false}>
        <Close />
      </AccessibleButton>
    </div>
  );
}
