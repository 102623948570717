import styles from './tabsComponent.module.scss';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import { useState, useContext } from 'react';
import cx from 'classnames';
import IngredientsComponent from '../IngredientsComponent/IngredientsComponent';
import IngredientDetails from '../IngredientDetails/IngredientDetails';
import useTranslation from '../../lib/hooks/useTranslation';
import SiteContext from '../AppContext';
import { toCamelCase } from '../../lib/utils';
/**
 * @param {object} [props] component props
 * @param {object} [props.productFields] content from product
 * @returns {JSX.Element} react component for the tabs component
 */
export default function TabsComponent({ productFields }) {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const content = [
    {
      headline: t('tabs.whatsInside'),
      tabContent: <IngredientsComponent featuredIngredients={productFields.featuredIngredient} />,
    },
    {
      headline: t('tabs.nutritionalInformation'),
      tabContent: <IngredientDetails {...productFields} active={activeTab === 1} />,
    },
  ];
  const siteContext = useContext(SiteContext);
  const brand = toCamelCase(siteContext.brand);

  return (
    <div className={styles.background}>
      <div className={styles.headerText}>
        <h2>{t(`tabs.header.${brand}`)}</h2>
      </div>
      <ul className={styles.tabItems}>
        {content.map((tab, i) => (
          <li className={cx(styles.tabItem, { active: i === activeTab })} key={i}>
            <AccessibleButton hasDataText={true} onClick={() => setActiveTab(i)}>
              {tab.headline}
            </AccessibleButton>
          </li>
        ))}
      </ul>
      <ul className={styles.tabContents}>
        {content.map((tab, i) => (
          <li className={cx(styles.tabContent, { active: i === activeTab })} key={i}>
            {tab.tabContent}
          </li>
        ))}
      </ul>
    </div>
  );
}
