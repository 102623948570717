import React from 'react';
import { formatSources } from '../../lib/imageUtils';

/**
 * Dynamically creates <source> elements based on viewport data
 * @param {object} [props] component props
 * @param {string} [props.srcUrl] - Image base url for 1x px density asset
 * @param {string} [props.srcUrl2x] - Image base url for 2x px density asset
 * @param {string} [props.media] - Media query for the source to use on a given viewport
 * @param {Array} [props.size] - Size of the image based on a given viewport - [width, height]
 * @param {string} [props.format] - Image format (jpg, png, webp, avif, etc)
 * @param {object} [props.imageInView] - true when image is visible in viewport
 * @returns {JSX.Element} <source> react component for Picture element
 */
const Source = ({ srcUrl, srcUrl2x, media = null, size, format, imageInView }) => {
  return (
    <React.Fragment>
      <source
        media={size ? media : null}
        {...formatSources(srcUrl, srcUrl2x)}
        type={format ? `image/${format}` : null}
        width={size ? size[0] || null : null}
        height={size ? size[1] || null : null}
      />
    </React.Fragment>
  );
};

export default Source;
