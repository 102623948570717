import AccessibleButton from '../AccessibleButton/AccessibleButton';
import styles from './redirectModal.module.scss';
import { getLocalizationProps } from '../../lib/LanguageProvider';
import { useTranslation } from '../../lib/hooks';
import { addReplacements } from '../../lib/hooks/useTranslation';
import { getDomainBasedOnCountryIsoCode } from '../../lib/utils';
import SiteContext from '../AppContext';
import { useContext } from 'react';
import { getCountryTranslationFromCountryCode, getExternalLinkFromString } from '../../lib/utils';
import cookieCutter from 'cookie-cutter';
import cx from 'classnames';

const btnThemeMap = {
  gather: 'dark',
  'go-solutions': 'light',
  'now-fresh': 'light',
  petcurean: 'dark',
  summit: 'dark',
};

/**
 * @param {string} [redirectLocale] locale the user is going to be redirect to
 * @returns {object} modal translations object
 */
function getRedirectText(redirectLocale) {
  return getLocalizationProps(redirectLocale);
}

/**
 * @param {object} [props] component props
 * @param {object} [props.dialogEl] Dialog element ref
 * @returns {JSX.Element} react component
 */
export default function RedirectModal({ dialogEl }) {
  const siteContext = useContext(SiteContext);
  const { locale, userLocale, countryCode, localeMap, brand } = siteContext;
  const { t } = useTranslation();
  const modalTranslations = getRedirectText(userLocale);
  const optionHandler = () => {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);
    cookieCutter.set('hideRedirectModal', true, { expires });
    dialogEl.current.close();
  };

  const domain = getDomainBasedOnCountryIsoCode(countryCode);

  const redirectLink =
    localeMap && localeMap[userLocale]
      ? `${domain}/${userLocale}/${localeMap[userLocale]}`
      : `${domain}/${userLocale}`;

  const fontClass = userLocale ? `font-${userLocale}` : '';

  return (
    <div className={styles.container}>
      <div className={styles.copyContainer}>
        <h4>
          <bdi>
            {t('common.redirectModal.headline', [
              getCountryTranslationFromCountryCode(countryCode, locale),
            ])}
          </bdi>
        </h4>
        <p className={styles.subhead}>
          <bdi>{t('common.redirectModal.subheadline')}</bdi>
        </p>
        {locale.substr(0, 2) !== userLocale.substr(0, 2) && (
          <>
            <hr className={styles.separator} />
            <h4 className={cx(styles.headline, fontClass)}>
              <bdi>
                {addReplacements(modalTranslations.translations.common.redirectModal.headline, [
                  getCountryTranslationFromCountryCode(countryCode, userLocale),
                ])}
              </bdi>
            </h4>
            <p className={cx(styles.subhead, fontClass)}>
              <bdi>{modalTranslations.translations.common.redirectModal.subheadline}</bdi>
            </p>
          </>
        )}
      </div>
      <div className={styles.ctasContainer}>
        <AccessibleButton
          type="primary"
          theme={btnThemeMap[brand]}
          className={styles.cta}
          target="_self"
          link={getExternalLinkFromString(redirectLink)}
          onClick={optionHandler}>
          {modalTranslations.translations.common.redirectModal.redirectCta}
        </AccessibleButton>
        <AccessibleButton
          type="secondary"
          theme={btnThemeMap[brand]}
          className={styles.cta}
          submit={true}
          onClick={optionHandler}>
          {t('common.redirectModal.stayCta')}
        </AccessibleButton>
      </div>
    </div>
  );
}
