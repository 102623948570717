import { useContext } from 'react';
import StoreLocatorComponent from '../../StoreLocatorComponent/StoreLocatorComponent';
import StoreLocatorWidget from '../../PriceSpiderWidgets/StoreLocatorWidget';

import SiteContext from '../../AppContext';

/**
 * React component for Store Locator template
 * @param {object} [props] component props
 * @param {object} [props.fields] props coming form contentful
 * @returns {JSX.Element} Returns StoreLocator template react component
 */
export default function StoreLocator({ fields }) {
  const { brand } = useContext(SiteContext);

  const isGather = brand === 'gather';

  return (
    <div>
      {isGather || fields.mapReference?.fields ? (
        <StoreLocatorComponent fields={fields.mapReference.fields} isStoreLocatorPage={true} />
      ) : (
        <StoreLocatorWidget fields={fields} />
      )}
    </div>
  );
}
