import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

/**
 * Function to handle state change in the navigation
 * @param {object} [state] current nav state
 * @param {object} [action] action to be executed
 * @returns {object} new navigation state
 */
export default function navReducer(state, action) {
  switch (action.type) {
    case 'reset-state':
      enableBodyScroll(state.scrollableElement);
      return {
        ...state,
        activeNav: null,
        activeSubNav: null,
        activeSmallSubNav: null,
        mobileNavOpen: false,
        subNavOpen: false,
        animated: true,
        navHeight: 0,
      };
    case 'open-nav':
      disableBodyScroll(state.scrollableElement, {
        reserveScrollBarGap: true,
      });
      return {
        ...state,
        activeNav: action.navIndex,
        subNavOpen: true,
        activeSubNav: 0,
        activeSmallSubNav: null,
        navHeight: action.navHeight,
      };
    case 'close-nav':
      if (window.matchMedia('(min-width: 1024px)').matches) {
        enableBodyScroll(state.scrollableElement);
      }
      return {
        ...state,
        activeNav: null,
        activeSubNav: null,
        activeSmallSubNav: null,
        subNavOpen: false,
        navHeight: 0,
      };
    case 'open-sub-nav':
      return { ...state, activeSubNav: action.activeSubNav };
    case 'close-sub-nav':
      return { ...state, activeSubNav: null, animated: false };
    case 'open-sub-nav-small':
      return {
        ...state,
        activeSubNav: action.activeSubNav,
        activeSmallSubNav: action.activeSubNav,
      };
    case 'close-sub-nav-small':
      return { ...state, activeSubNav: null, activeSmallSubNav: null };
    case 'close-sub-nav-animated':
      return { ...state, activeSubNav: null, animated: true };
    case 'open-mobile-nav':
      // Fix for android devices where it cuts the nav off when address bar is not present
      if (document.body) {
        document.body.style.height = '100vh';
      }
      disableBodyScroll(state.scrollableElement);
      return { ...state, mobileNavOpen: true };
    case 'close-mobile-nav':
      if (document.body) {
        document.body.style.height = '';
      }
      enableBodyScroll(state.scrollableElement);
      return { ...state, mobileNavOpen: false, activeNav: null };
    case 'set-scrollable-element':
      return { ...state, scrollableElement: action.scrollableElement };
    default:
      throw new Error();
  }
}
