import HeroCarousel from './HeroCarousel';
import HeroCopy from './HeroCopy';
import styles from './pdpHero.module.scss';

/**
 * @param {object} [props] component props
 * @param {object} [props.brand] product brand
 * @param {object} [props.productImage] main image content
 * @param {object} [props.pdpHeaderImage] Product image for the hero carousel
 * @param {object} [props.pdpHeaderAccentImage] Bg of pdpHeaderImage used for Gather
 * @param {string} [props.productColor] accent color
 * @param {string} [props.productBadge] flag used in the primary image
 * @param {object} [props.productType] Type of product (eg. bag, small can, large tetrapack)
 * @param {string} [props.ingredientAccent] color toi be used in the background image
 * @param {Array<any>} [props.carouselImages] content for carousel images
 * @param {string} [props.titleEyebrow] here eyebrow
 * @param {string} [props.shortDescription] hero description
 * @param {string} [props.packaging] product presentations
 * @param {Array<any>} [props.tagLabels] product tags
 * @param {string} [props.productName] product name
 * @param {string} [props.whereToBuyLink] Link for where to boy CTA
 * @param {Array} [props.buyOnlineLinks] Links for buyonline section
 * @param {object} [props.contactUsForm] Contact us form data
 * @param {number} [props.aggregate] aggregate review rating
 * @param {Array<object>}[props.reviews] reviews from component
 * @param {object}[props.externalIDs] external IDs from product
 * @param {object} [props.bazaarvoiceStats] statistics from Bazaarvoice
 * @param {number} [props.totalReviews] number of reviews from Bazaarvoice
 * @returns {JSX.Element} react component for PDP hero
 */
export default function PDPHero({
  brand,
  productImage,
  pdpHeaderImage,
  pdpHeaderAccentImage,
  productColor,
  productBadge,
  productType,
  ingredientAccent,
  carouselImages,
  titleEyebrow,
  productName,
  shortDescription,
  packaging,
  tagLabels,
  whereToBuyLink,
  buyOnlineLinks,
  contactUsForm,
  aggregate,
  reviews,
  externalIDs,
  bazaarvoiceStats,
  totalReviews,
}) {
  const carouselContent = {
    productImage,
    pdpHeaderImage,
    pdpHeaderAccentImage,
    productColor,
    productBadge,
    productType,
    ingredientAccent,
    carouselImages,
  };
  const copyContent = {
    brand,
    titleEyebrow,
    productName,
    shortDescription,
    packaging,
    tagLabels,
    whereToBuyLink,
    contactUsForm,
    reviews,
    aggregate,
    externalIDs,
    bazaarvoiceStats,
    totalReviews,
    buyOnlineLinks,
  };
  return (
    <section className={styles.container}>
      <HeroCarousel {...carouselContent} />
      <HeroCopy {...copyContent} />
    </section>
  );
}
