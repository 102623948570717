import { useContext, useEffect, useRef, useState } from 'react';
import { fromEvent, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { useInView } from 'react-intersection-observer';
import { breakpoints, getImageSize, adjustImageSize } from '../../lib/imageUtils';
import SiteContext from '../AppContext';
import cx from 'classnames';

import RichText from '../Richtext/Richtext';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import styles from './imageWithText.module.scss';

/**
 * @param {object} [props] component props
 * @param {string} [props.align] component alignment - left or right
 * @param {boolean} [props.hasAnimation] animate component into page
 * @param {boolean} [props.fullWidth] image goes to edge of browser on desktop
 * @param {boolean} [props.isBenefitsLayout] use the benefits alternative layout
 * @param {string} [props.className] classname used in the component
 * @param {object} [props.fields] component data
 * @param {string} [props.fields.eyebrow] component eyebrow text
 * @param {string} [props.fields.secondaryText] component Secondary Text
 * @param {string} [props.fields.primaryText] component Primary Text
 * @param {object} [props.fields.image] image lockup
 * @param {Array<any>} [props.fields.cta] component CTA
 * @param {string} [props.fields.componentLayout] component alignment - from Contentful
 * @returns {JSX.Element} react component for image with text
 */
export default function ImageWithText({
  align,
  fullWidth,
  hasAnimation = false,
  isBenefitsLayout = false,
  className,
  fields: { secondaryText, eyebrow, primaryText, image, componentLayout, cta },
}) {
  const siteContext = useContext(SiteContext);
  const { brand } = siteContext;

  let isFullWidth = fullWidth;
  if (typeof isFullWidth === 'undefined') {
    isFullWidth = brand === 'now-fresh' ? false : true;
  }

  // respect any hardcoded align props
  const componentAlignment = align ? align : componentLayout ? componentLayout : 'left';

  const imageRef = useRef();
  const imageContainerRef = useRef();

  const setImageContainerHeight = () => {
    const isXLarge = window.matchMedia('(min-width: 1280px)');

    if (imageRef?.current && imageContainerRef?.current) {
      imageContainerRef.current.style.height = isXLarge.matches
        ? imageRef.current.offsetHeight + 'px'
        : '';
    }
  };

  useEffect(() => {
    let resizeSubscription;

    if (isFullWidth) {
      setImageContainerHeight();

      resizeSubscription = fromEvent(window, 'resize')
        .pipe(debounce(() => interval(50)))
        .subscribe(() => {
          setImageContainerHeight();
        });

      if (imageRef?.current && !imageRef.current.complete) {
        fromEvent(imageRef.current, 'load').subscribe(() => {
          setImageContainerHeight();
        });
      }
    }

    return () => {
      resizeSubscription?.unsubscribe();
    };
  }, [isFullWidth]);

  const [animIn, setAnimIn] = useState(!hasAnimation);

  const [contentRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setAnimIn(true);
    }
  }, [inView]);

  const imageSrc = image?.fields?.file?.details?.image || [];
  const imageSrcSize = [
    { media: breakpoints.xl, size: getImageSize(adjustImageSize(716, brand), imageSrc) },
    { media: breakpoints.md, size: getImageSize(adjustImageSize(500, brand), imageSrc) },
    { media: breakpoints.sm, size: getImageSize(adjustImageSize(300, brand), imageSrc) },
  ];
  const [imgWidth, imgHeight] = getImageSize(adjustImageSize(300, brand), imageSrc);
  const imageFile = image?.fields?.file;

  return (
    <div
      className={cx(
        styles.container,
        {
          [styles.alignRight]: componentAlignment === 'right',
          [styles.benefitsLayout]: isBenefitsLayout,
          [styles.fullWidth]: isFullWidth,
          [styles.noImage]: !image,
        },
        className,
      )}>
      <div className={cx(styles.contentWrapper, { [styles.animIn]: animIn })} ref={contentRef}>
        {imageFile && (
          <div className={styles.imageWrapper} ref={imageContainerRef}>
            <picture>
              {imageSrcSize.map(({ media, size }, index) =>
                ['avif', 'webp'].map((imgFormat, formatIndex) => (
                  <source
                    key={`${index}${formatIndex}`}
                    media={media}
                    type={`image/${imgFormat}`}
                    srcSet={`
                        ${imageFile.url}?w=${size[0]}&h=${size[1]}&fm=${imgFormat}, 
                        ${imageFile.url}?w=${size[0] * 2}&h=${size[1] * 2}&fm=${imgFormat} 2x
                      `}
                    width={size[0]}
                    height={size[1]}
                  />
                )),
              )}
              <img
                src={`${image.fields.file.url}?w=${imgWidth}&h=${imgHeight}`}
                alt={image.fields.description ?? ''}
                ref={imageRef}
                width={imgWidth}
                height={imgHeight}
              />
            </picture>
          </div>
        )}
        <div className={styles.content}>
          {eyebrow && <p className={styles.eyebrow}>{eyebrow}</p>}
          {primaryText && <h2 className={styles.headline}>{primaryText}</h2>}
          {secondaryText && <RichText content={secondaryText} className={styles.copy} />}
          {Array.isArray(cta) && cta.length > 0 && (
            <div className={styles.ctaContainer}>
              {cta.map((component, index) => (
                <AccessibleButton
                  key={index}
                  type="primary"
                  theme="dark"
                  link={component}
                  className={styles.ctaBtn}>
                  {component.fields.linkText}
                </AccessibleButton>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
