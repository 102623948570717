import cx from 'classnames';

import { LinkOut } from '../Icons';

import styles from './textList.module.scss';
import GenericLink from '../GenericLink/GenericLink';

/**
 * Component displaying a retailer
 * @param {object} [props] component Props
 * @param {object} [props.retailer] retailer object with name and url
 * @returns {JSX.Element} Retailer react component
 */
function Retailer({ retailer }) {
  return (
    <li className={styles.retailer}>
      <GenericLink
        className={cx('p2 bold', styles.link)}
        externalLink={retailer.linkAddress}
        externalIcon={false}>
        {retailer.linkText}
        <LinkOut className={styles.icon}></LinkOut>
      </GenericLink>
    </li>
  );
}

/**
 * Component with a territory name and a list of retailers
 * @param {object} [props] component Props
 * @param {object} [props.territory] territory object with name and retailers
 * @returns {JSX.Element} Territory react component
 */
function Territory({ territory }) {
  return (
    <div className={styles.list}>
      <h2 className={cx('h5', styles.territory)}>{territory.title}</h2>

      <ul className={styles.retailers}>
        {territory.links?.map((retailer, i) => (
          <Retailer key={i} retailer={retailer.fields}></Retailer>
        ))}
      </ul>
    </div>
  );
}

/**
 * Component with a list of retailers in a grid
 * @param {object} [props] component Props
 * @param {string} [props.disclaimer] disclaimer text
 * @param {Array} [props.retailerList] list of retailers to display
 * @returns {JSX.Element} Text List react component
 */
export default function TextList({ disclaimer, retailerList = [] }) {
  return (
    <div className={styles.container}>
      <p className={cx('p3', styles.disclaimer)}>{disclaimer}</p>

      {retailerList.map((territory) => (
        <Territory key={territory.fields.title} territory={territory.fields}></Territory>
      ))}
    </div>
  );
}
