import cookieCutter from 'cookie-cutter';
import cx from 'classnames';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import RichText from '../Richtext/Richtext';
import { Close } from '../Icons';
import { useTranslation } from '../../lib/hooks';

import styles from './promoBanner.module.scss';

/**
 * Promotional Banner Component
 * @param {object} [props] component props
 * @param {boolean} [props.showPromoBanner] flag to show or hide banner
 * @param {Function} [props.setShowPromoBanner] state setter for showPromoBanner
 * @param {object} [props.promoText] rich text object for promo banner
 * @returns {JSX.Element} Layout react component
 */
export default function PromoBanner({ showPromoBanner, setShowPromoBanner, promoText }) {
  const { t } = useTranslation();
  const handleClose = () => {
    setShowPromoBanner(false);
    cookieCutter.set('promoBanner', 'hide');
  };

  return (
    <div
      className={cx(styles.container, {
        [styles.visible]: showPromoBanner,
      })}>
      <div className={styles.wrapper}>
        {promoText && <RichText content={promoText} className={styles.text} />}
        <AccessibleButton
          className={styles.close}
          ariaLabel={t('common.ctas.close')}
          onClick={handleClose}>
          <Close />
        </AccessibleButton>
      </div>
    </div>
  );
}
