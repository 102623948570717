import { useContext, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { pick, transformObjectKeysFromSnakeCaseToCamelCase } from '../../lib/utils';
import getStores from '../../lib/algoliaService';

import InfoCardList from '../InfoCardList/InfoCardList';
import PendingResults from '../InfoCardList/PendingResults';
import LocationInput from '../LocationInput/LocationInput';
import MapListControls from '../MapListControls/MapListControls';
import Map from '../Map/Map';
import SiteContext from '../AppContext';

import styles from './locations.module.scss';

const API_KEY = process.env.NEXT_PUBLIC_MAPS_API_KEY;
// eslint-disable-next-line max-len
const mapsScriptURL = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=geometry,places&callback=initMap`;
let loadingScript = false;
const VISIBLE_LOCATIONS_INTERVAL = 12;

/**
 * calculates store distance
 * @param {Array} stores list of stores
 * @param {object} location user loc
 */
function addDistanceToStores(stores, location) {
  stores.forEach((store) => {
    const google = window.google;
    const storeLoc = new google.maps.LatLng(store.lat, store.lng);
    const userLoc = new google.maps.LatLng(location);
    // eslint-disable-next-line max-len
    store.distance = Math.floor(
      google.maps.geometry.spherical.computeDistanceBetween(storeLoc, userLoc),
    );
  });
}

/**
 * Return locations from algolia
 * @param {string} brand the product brand
 * @param {boolean} showCurbside flag to search location with or without curbside pickup
 * @param {boolean} showOnline flag to search location with or without online shopping
 * @param {object} [userGeolocation] lat and long of the user's location
 * @param {Function} [setLocationsObj] updates the locations to both the map and list
 * @param {Function} [setLoading] sets the loading flag when the results arrive
 * @param {string} [campaignId] a campaign id to filter against (optional)
 * @returns {Array} store locations
 */
function getLocations(
  brand,
  showCurbside,
  showOnline,
  userGeolocation,
  setLocationsObj,
  setLoading,
  campaignId,
) {
  return getStores(
    userGeolocation.lat,
    userGeolocation.lng,
    brand.slug,
    showCurbside,
    showOnline,
    campaignId,
    (locationList) => {
      const camelCaseLocations = transformObjectKeysFromSnakeCaseToCamelCase(locationList);

      addDistanceToStores(camelCaseLocations, userGeolocation);

      const locations = pick(camelCaseLocations, [
        'lat',
        'lng',
        'distance',
        'storeLocatorName',
        'address1',
        'address2',
        'city',
        'province',
        'country',
        'postalCode',
        'curbsidepickup',
        'storeLocatorPhone',
        'shoponline',
        'url',
        'fb',
      ]);

      setLocationsObj({
        locations,
        visibleLocations: locations.slice(0, VISIBLE_LOCATIONS_INTERVAL),
        numVisibleLocations: VISIBLE_LOCATIONS_INTERVAL,
      });

      setLoading(false);
    },
  );
}

/**
 * @param {object} [props] component props
 * @param {object} [props.brand] brand with slug and name
 * @param {string} [props.campaignId] a campaign id to filter against (optional)
 * @param {object} [props.mapCentrePoint] lat and long where we are going to center the map
 * @param {boolean} [props.hasMap] flag to enable/disable the map
 * @param {string} [props.noResultsTitle] title for no results state
 * @param {string} [props.noResultsMessage] message for no results state
 * @param {object} [props.cta] link for no results state
 * @param {object} [props.distanceFormat] whether to use km or mi
 * @param {string} [props.locationPendingMessage] text for the pending results state
 * @param {object} [props.locationPendingImage] image for the pending results state
 * @param {string} [props.purchaseFilterLabel] label for filter group
 * @param {boolean} [props.hasCurbsidePickup] flag to enable/disable curbside filter
 * @param {boolean} [props.hasOnlinePurchase] flag to enable/disable online filter
 * @param {boolean} [props.isStoreLocatorPage] if is on store locator page
 * @returns {JSX.Element} Locations react component
 */
export default function Locations({
  brand,
  campaignId,
  hasMap,
  mapCentrePoint,
  noResultsTitle,
  noResultsMessage,
  cta,
  distanceFormat,
  locationPendingMessage,
  locationPendingImage,
  purchaseFilterLabel,
  hasCurbsidePickup,
  hasOnlinePurchase,
  isStoreLocatorPage,
}) {
  const [activePin, setActivePin] = useState(null);
  const [activeView, setActiveView] = useState(hasMap ? 'map' : 'list');
  const [locationsObj, setLocationsObj] = useState({
    locations: [],
    visibleLocations: [],
    numVisibleLocations: VISIBLE_LOCATIONS_INTERVAL,
  });
  const [mapReady, setMapReady] = useState();
  const [disableGeolocation, setDisableGeolocation] = useState(true);
  const [userGeolocation, setUserGeolocation] = useState();
  const [showCurbside, setShowCurbside] = useState(false);
  const [showOnline, setShowOnline] = useState(false);
  const [loading, setLoading] = useState(false);
  const locationsEl = useRef(null);
  const siteContext = useContext(SiteContext);

  useEffect(() => {
    locationsEl.current.addEventListener('pin-clicked', (e) => {
      setActivePin(e.detail.pinIndex);
    });
    const subscription = siteContext.escPressed$.subscribe(() => {
      const pinClicked = new CustomEvent('card-clicked', {
        detail: {
          pinIndex: activePin,
        },
      });
      locationsEl.current.dispatchEvent(pinClicked);
      setActivePin(null);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [activePin, setActivePin, locationsEl, siteContext]);

  const detectGeolocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setDisableGeolocation(false);
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;

        setUserGeolocation({
          lat,
          lng,
        });
      },
      () => {
        setDisableGeolocation(true);
      },
    );
  };

  useEffect(() => {
    if (mapReady) {
      if (userGeolocation) {
        setActivePin(null);
        setLoading(true);

        getLocations(
          brand,
          showCurbside,
          showOnline,
          userGeolocation,
          setLocationsObj,
          setLoading,
          campaignId,
        );
      } else {
        setLocationsObj({
          locations: [],
          visibleLocations: [],
          numVisibleLocations: VISIBLE_LOCATIONS_INTERVAL,
        });
      }
    }
  }, [brand, showCurbside, showOnline, mapReady, userGeolocation, campaignId]);

  useEffect(() => {
    if (!navigator.geolocation) {
      // Geolocation is not supported by the browser
      setDisableGeolocation(true);
    } else {
      detectGeolocation();
    }
  }, []);

  useEffect(() => {
    if (!loadingScript && !mapReady) {
      loadingScript = true;
      const script = document.createElement('script');

      script.src = mapsScriptURL;
      script.async = true;
      script.defer = true;

      window.initMap = function () {
        setMapReady(true);
      };

      document.body.appendChild(script);
    } else if (window?.google?.maps) {
      setMapReady(true);
    }
  }, [mapReady]);

  const showMoreLocations = () => {
    const numLocations = locationsObj.numVisibleLocations + VISIBLE_LOCATIONS_INTERVAL;

    setLocationsObj({
      ...locationsObj,
      visibleLocations: locationsObj.locations.slice(0, numLocations),
      numVisibleLocations: numLocations,
    });
  };

  return (
    <div className={styles.container} ref={locationsEl}>
      <div
        className={cx(styles['location-input-and-list'], {
          [styles['map-disable']]: !hasMap,
          [styles.hasBackground]: !isStoreLocatorPage,
        })}>
        <LocationInput
          hasMap={hasMap}
          setUserGeolocation={setUserGeolocation}
          mapReady={mapReady}
        />

        <MapListControls
          hasMap={hasMap}
          activeView={activeView}
          setActiveView={setActiveView}
          showCurbside={showCurbside}
          setShowCurbside={setShowCurbside}
          showOnline={showOnline}
          setShowShopOnline={setShowOnline}
          purchaseFilterLabel={purchaseFilterLabel}
          hasCurbsidePickup={hasCurbsidePickup}
          hasOnlinePurchase={hasOnlinePurchase}
        />

        <div
          className={cx(styles.viewContent, {
            [styles['active-view']]: activeView === 'list' || activePin !== null || userGeolocation,
          })}>
          <InfoCardList
            hasMap={hasMap}
            locations={locationsObj.visibleLocations}
            activePin={activePin}
            displayAll={activeView === 'list'}
            locationsEl={locationsEl}
            setActivePin={setActivePin}
            userGeolocation={userGeolocation}
            noResultsMessage={noResultsMessage}
            noResultsTitle={noResultsTitle}
            cta={cta}
            distanceFormat={distanceFormat}
            loading={loading}
            locationPendingMessage={locationPendingMessage}
            locationPendingImage={locationPendingImage}
            setActiveView={setActiveView}
            activeView={activeView}
            hasTopBorder={brand.slug === 'go-solutions' || !isStoreLocatorPage}
            onLoadMore={showMoreLocations}
            showLoadMore={locationsObj.locations.length > locationsObj.numVisibleLocations}
          />
        </div>
      </div>

      {hasMap && mapReady && (
        <div
          className={cx(styles['view-content'], styles['view-content-map'], {
            [styles['active-view']]: activeView === 'map',
          })}>
          <Map
            brand={brand}
            mapCentrePoint={mapCentrePoint}
            mapReady={mapReady}
            locations={locationsObj.visibleLocations}
            userGeolocation={userGeolocation}
            locationsEl={locationsEl}
            setActivePin={setActivePin}
            setMapReady={setMapReady}
            detectGeolocation={detectGeolocation}
            disableGeolocation={disableGeolocation}
          />
        </div>
      )}

      {(!userGeolocation || loading) && (
        <PendingResults
          message={locationPendingMessage}
          image={locationPendingImage}
          isDesktopHidden={true}
          activeView={activeView}
        />
      )}
    </div>
  );
}
