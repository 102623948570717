import { useContext } from 'react';
import { LanguageContext } from '../LanguageProvider';
import { deepFind } from '../utils';

/**
 * this function will look for every placeholder in the format `${<decimal>}`
 * and replaced each value correlating the decimal value to the index from the given array
 * @param {string} [string] string with values to be replaced
 * @param {Array<string>} [replacements] string to be used in the replacements
 * @returns {string} replaced string
 */
export function addReplacements(string, replacements) {
  const holders = string.match(/\${\d}/g);
  if (!holders) {
    return string;
  }
  const uniqueHolders = holders.reduce((uniques, holder) => {
    if (uniques.includes(holder)) {
      return uniques;
    }
    uniques.push(holder);
    return uniques;
  }, []);
  let replacedString = string;
  uniqueHolders.forEach((holder, i) => {
    const number = holder.match(/\d/g).join('');
    const integer = parseInt(number);
    replacedString = replacedString.replace(holder, replacements[integer - 1]);
  });

  return replacedString;
}

/**
 * @returns {object} function for finding translations from given key
 */
export default function useTranslation() {
  const { localization } = useContext(LanguageContext);

  /**
   * @param {string} [key] key from translation to be retrieved
   * @param {Array<string>} [replacements] optional array with replacement string for i18n value
   * @returns {string} translation from given key
   */
  function t(key, replacements = []) {
    const translation = deepFind(key, localization.translations);
    if (!translation) {
      console.warn(`Translation '${key}' for locale '${localization.locale}' not found.`);
    }
    if (translation && replacements.length > 0) {
      return addReplacements(translation, replacements);
    }
    return translation || '';
  }

  return {
    t,
    locale: localization.locale,
  };
}
