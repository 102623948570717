import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import AccessibleButton from '../AccessibleButton/AccessibleButton';

import cx from 'classnames';
import Rating from '../Rating/Rating';
import GenericLink from '../GenericLink/GenericLink';
import { colors } from '../../lib/constants';
import { getImageSize, adjustImageSize } from '../../lib/imageUtils';

import styles from './productGrid.module.scss';

/**
 * A component that returns one Product
 * @param {object} [props] component props
 * @param {Array} [props.product] Data of the products to render
 * @param {string} [props.brand] brand
 * @param {Function} [props.t] translation function from translation hook
 * @returns {JSX.Element} Returns Product react component
 */
export default function Product({ product, brand, t }) {
  const [animIn, setAnimIn] = useState(false);
  const colorMap = {
    'go-solutions': colors.goSolutions.backgroundWhite,
    'now-fresh': colors.nowFresh.backgroundTan,
    summit: colors.summit.backgroundWhite,
  };
  const color = colorMap[brand];
  const showPackaging = brand === 'now-fresh';

  const [productItemRef, inView] = useInView({
    rootMargin: '-50px 0px',
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setAnimIn(true);
    }
  }, [inView]);

  const productImage = product?.fields?.productImage?.fields?.file;
  const [width, height] = getImageSize(adjustImageSize(350, brand), productImage?.details?.image);
  const imgHeight = height ? `&h=${height}` : '';
  const imgHeight2x = height ? `&h=${height * 2}` : '';

  return (
    <div className={styles.product} ref={productItemRef}>
      <div className={styles['img-container']}>
        <div className={cx(styles['img-wrapper'], { [styles.animIn]: animIn })}>
          {productImage && (
            <div className={styles['prod-img']}>
              <picture>
                {['avif', 'webp'].map((format, index) => (
                  <source
                    key={index}
                    type={`image/${format}`}
                    srcSet={`${productImage.url}?w=${width}${imgHeight}&fm=${format}&bg=${color}, ${
                      productImage.url
                    }?w=${width * 2}${imgHeight2x}&fm=${format}&bg=${color} 2x`}
                  />
                ))}
                <img
                  alt={product.fields.productImage.fields.description}
                  src={`${productImage.url}?w=${width}${imgHeight}&bg=${color}`}
                  width={width}
                  height={height}
                  loading="lazy"
                  decoding="async"
                />
              </picture>
            </div>
          )}
          {product.fields?.productBadge && (
            <div className={styles.badge}>
              <div
                className={styles['badge-border']}
                style={
                  product.fields.productColor ? { borderColor: product.fields.productColor } : null
                }></div>
              <p className={styles['badge-content']}>{product.fields.productBadge}</p>
            </div>
          )}
        </div>
      </div>
      <div
        className={cx(styles['prod-rating'], {
          [styles.noReviews]: product.fields?.aggregate === 0,
        })}>
        <Rating rating={product.fields.aggregate} className={styles.rating} />
      </div>
      <div className={styles['prod-title']}>
        <h2>
          {brand === 'go-solutions' && product.fields.productLine && (
            <div className={styles['prod-category']}>
              <p>{product.fields.productLine.fields.title} </p>
            </div>
          )}
          {(brand === 'summit' || brand === 'now-fresh' || brand === 'go-solutions') && (
            <div className={styles['prod-category']}>
              <p>{product.fields.titleEyebrow} </p>
            </div>
          )}
          {product.fields.productName}
        </h2>
      </div>
      {showPackaging && (
        <div className={styles['prod-packaging']}>
          <p>{product.fields.packaging}</p>
        </div>
      )}
      <GenericLink
        className={styles.cta}
        internalLink={product}
        ariaLabel={`${t('common.ctas.viewDetailsAbout')} ${product.fields.productName}`}>
        <AccessibleButton type="primary" theme="dark" tabIndex="-1" className={styles.ctaBtn}>
          {t('common.ctas.viewDetails')}
        </AccessibleButton>
      </GenericLink>
    </div>
  );
}
