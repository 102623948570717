import FAQItem from './FAQItem';
import useTranslation from '../../lib/hooks/useTranslation';

import styles from './faq.module.scss';

/**
 * @param {object} [props] component props
 * @param {object} [props.fields] component data
 * @param {string} [props.componentIndex] index value on page
 * @returns {JSX.Element} react component for the FAQ
 */
export default function FAQ({ fields, componentIndex = '0' }) {
  const { headline } = fields;
  const { t } = useTranslation();
  const qAndAs = [];

  // Questions and answers are coming in as question1, question2, etc. And more may get added
  // This allows them to add as many as they want
  for (let i = 1; fields['question' + i] !== undefined && fields['answer' + i] !== undefined; i++)
    qAndAs.push({
      question: fields['question' + i],
      answer: fields['answer' + i],
    });

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <h2 className={styles.headline}>{headline}</h2>
        <dl title={t('common.faq.faqs')}>
          {qAndAs.map((item, i) => {
            return <FAQItem id={`faq-${componentIndex}-item-${i}`} {...item} key={i} />;
          })}
        </dl>
      </div>
    </div>
  );
}
