import axios from 'axios';
import cx from 'classnames';
import { useEffect, useState } from 'react';

/**
 * @param {object} [props] component props
 * @param {string} [props.url] URL to retrieve the remote icon
 * @param {string} [props.className] classname to be used in the icon
 * @returns {JSX.Element} remote icon wrapped in a div
 */
export default function RemoteIcon({ url, className }) {
  const [icon, setIcon] = useState('');
  const getIcon = async (url) => {
    const svg = await axios.get('https:' + url, { headers: { Accept: 'text/svg+xml' } });
    if (svg.headers['content-type'] !== 'image/svg+xml') {
      throw Error('Invalid Icon Format');
    }
    return svg.data;
  };
  useEffect(() => {
    getIcon(url)
      .then((svg) => {
        setIcon(svg);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [url]);
  return <div className={cx(className)} dangerouslySetInnerHTML={{ __html: icon }} />;
}
