import styles from './reviewFilters.module.scss';
import { forwardRef } from 'react';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import { Caret } from '../Icons';
import cx from 'classnames';
import { enableBodyScroll } from 'body-scroll-lock';
import useTranslation from '../../lib/hooks/useTranslation';
import StarRating from './Filters/StarRating';

/**
 *
 * @param {object} props component props
 * @param {Set} props.filters current applied filters
 * @param {Function} props.setFilters set active filters
 * @param {Array} props.reviewDistribution reviews stats from Bv
 * @param {boolean} props.filtersOpen flag if the filters are open
 * @param {Function} props.setFiltersOpen set if the filters are open
 * @param {string} props.brand slug of the brand
 * @param {object} ref Ref for the filter container
 * @returns {JSX.Element} Review Filters element
 */
function ReviewFilters(
  { filters, setFilters, reviewDistribution, filtersOpen, setFiltersOpen, brand },
  ref,
) {
  const { t } = useTranslation();

  return (
    <section ref={ref} className={cx(styles.container, { [styles.filtersOpen]: filtersOpen })}>
      <div className={cx(styles.filtersContainer, { [styles.visible]: filtersOpen })}>
        <div className={styles.interactiveHeadlineContainer}>
          <Caret className={styles.caret} />
          <AccessibleButton
            className={styles.interactiveHeadline}
            ariaLabel={t('review.filters.closeFilters')}
            onClick={() => {
              enableBodyScroll(ref.current);
              setFiltersOpen(false);
            }}>
            {t('review.filters.filterReviews')}
          </AccessibleButton>
          <AccessibleButton
            type="link"
            theme={brand === 'gather' ? 'dark' : null}
            ariaLabel={t('review.filters.clearFilters')}
            onClick={() => {
              setFilters(new Set([]));
            }}
            className={cx(styles.clearCta, { [styles.visible]: filters.size > 0 })}>
            {t('review.filters.clear')}
          </AccessibleButton>
        </div>
        <div className={styles.headlineContainer}>
          <p className={styles.headline}>{t('review.filters.filters')}</p>
          <AccessibleButton
            type="link"
            theme={brand === 'gather' ? 'dark' : null}
            ariaLabel={t('review.filters.clearFilters')}
            onClick={() => {
              setFilters(new Set([]));
            }}
            className={cx(styles.clearCta, { [styles.visible]: filters.size > 0 })}>
            {t('review.filters.clear')}
          </AccessibleButton>
        </div>
        <div className={styles.categoriesContainer}>
          <StarRating
            filtersOpen={filtersOpen}
            filters={filters}
            setFilters={setFilters}
            reviewDistribution={reviewDistribution}
          />
        </div>
        <AccessibleButton
          className={styles.filtersBtn}
          ariaLabel={
            filters?.size > 0
              ? filters?.size === 1
                ? t('review.filters.numFilterApplied')
                : t('review.filters.numFiltersApplied', [filters.size.toString()])
              : t('review.filters.noFilters')
          }
          onClick={() => {
            enableBodyScroll(ref.current);
            setFiltersOpen(false);
          }}
          disabled={filters?.size <= 0}>
          {filters?.size > 0
            ? filters.size === 1
              ? t('review.filters.numFilterApplied')
              : t('review.filters.numFiltersApplied', [filters.size.toString()])
            : t('review.filters.noFilters')}
        </AccessibleButton>
      </div>
    </section>
  );
}

export default forwardRef(ReviewFilters);
