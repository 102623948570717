import styles from './accessibleButton.module.scss';
import * as icons from '../Icons';
import cx from 'classnames';
import GenericLink from '../GenericLink/GenericLink';
import { forwardRef } from 'react';
import { isPDFLink } from '../../lib/utils';

/**
 * component to be used when a button needs to have a different state when tabbed and on hover
 * @param {object} [props] component Props
 * @param {Function} [props.onClick] on click event handler
 * @param {Function} [props.onTouchStart] on touch start handler
 * @param {Function} [props.onKeyDown] on key down handler
 * @param {string} [props.className] classes to be used in component
 * @param {JSX.element} [props.children] children elements
 * @param {string} [props.ariaLabel] aria-label for the button
 * @param {string} [props.type] type of button, either 'primary', 'secondary' or 'link'
 * @param {string} [props.theme] color on hover state in go-solutions, 'dark' or 'light'
 * @param {string} [props.icon] icon to use for styled buttons
 * @param {object} [props.link] parameter to be used when the button is a link
 * @param {object} [props.tabIndex] value to set the tab index of the rendered button or anchor
 * @param {boolean} [props.disabled] disabled value for the button
 * @param {string} [props.ariaExpanded] aria expanded to be used in the button
 * @param {boolean} [props.submit] if true enables the type="submit" attribute in the button
 * @param {boolean} [props.hasDataText] boolean to add children as a data attribute
 * @param {string} [props.target] target to be used in the Generic link
 * @param {string} [props.size] render full size or small
 * @param {object} [ref] component ref
 * @returns {JSX.element} accessible button
 */
function AccessibleButton(
  {
    onClick,
    onTouchStart,
    onKeyDown,
    className,
    children,
    ariaLabel,
    type,
    theme = 'light',
    icon,
    link,
    tabIndex,
    disabled,
    ariaExpanded,
    submit,
    hasDataText = false,
    target = '_blank',
    size = 'full',
    ...rest
  },
  ref,
) {
  const IconToShow = icon ? icons[icon] : false;

  const isLinkPdf =
    link && link.fields && link.fields.linkAddress && isPDFLink(link.fields.linkAddress);

  return !link ? (
    <button
      className={cx(
        styles.button,
        styles[`${type}-${theme}`],
        {
          [styles.styled]: !!type,
          [styles[type]]: !!type,
          [styles.disabled]: disabled,
          [styles.small]: size === 'small',
        },
        className,
      )}
      onClick={onClick}
      onTouchStart={onTouchStart}
      onKeyDown={onKeyDown}
      disabled={disabled}
      aria-expanded={ariaExpanded}
      tabIndex={tabIndex}
      ref={ref}
      type={submit ? 'submit' : null}
      aria-label={ariaLabel}
      {...rest}>
      {!type && (
        <>
          <span className={styles.wrapper} tabIndex="-1" data-text={hasDataText ? children : null}>
            {children} {isLinkPdf ? '(PDF)' : null}
          </span>
          {IconToShow && <IconToShow />}
        </>
      )}
      {!!type && (
        <>
          <p className={cx(styles['styled-content'])} tabIndex={-1}>
            {children} {isLinkPdf ? '(PDF)' : null}
          </p>
          {IconToShow && <IconToShow tabIndex="-1" />}
        </>
      )}
    </button>
  ) : (
    <GenericLink
      className={cx(
        styles.button,
        'p3',
        styles[`${type}-${theme}`],
        {
          [styles.styled]: !!type,
          [styles[type]]: !!type,
          [styles.disabled]: disabled,
        },
        className,
      )}
      externalLink={link?.fields?.linkAddress}
      externalIcon={false}
      ariaLabel={ariaLabel}
      onClick={onClick}
      disabled={disabled}
      target={link?.fields?.jumpLink ? '_self' : target}
      ref={ref}
      AppendedIcon={IconToShow && <IconToShow />}
      internalLink={link?.fields?.internalPage}
      jumpLink={link?.fields?.jumpLink}>
      {children} {isLinkPdf ? '(PDF)' : null}
    </GenericLink>
  );
}

export default forwardRef(AccessibleButton);
