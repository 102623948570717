import { getDomainBasedOnLocale } from './utils';

/**
 * Function that will return product JSON-LD structured data
 * @param {object} [fields] data from contentful
 * @param {number} [aggregate] calculated aggregate review score
 * @returns {object} returns the schema object to be stringified and included in the Head
 */
export function getProductSchema(fields, aggregate) {
  const GTINs = fields.externalIDs.gtin.split(',');
  const schema = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    '@id': `${process.env.NEXT_PUBLIC_PRIMARY_DOMAIN}/${
      fields?.externalIDs?.websiteReviewsId || fields.slug
    }`,
    name: fields.productName,
    image: [
      `https:${fields.productImage.fields.file.url}?w=1600&h=1600&fit=pad&fm=jpg&bg=rgb:ffffff`,
      `https:${fields.productImage.fields.file.url}?w=1600&h=1200&fit=pad&fm=jpg&bg=rgb:ffffff`,
      `https:${fields.productImage.fields.file.url}?w=1600&h=900&fit=pad&fm=jpg&bg=rgb:ffffff`,
    ],
    description: fields.shortDescription,
    brand: {
      '@type': 'Brand',
      '@id': `${process.env.NEXT_PUBLIC_PRIMARY_DOMAIN}/#organization`,
    },
  };

  if (aggregate != 0) {
    schema.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: aggregate,
      reviewCount: fields.bazaarvoiceStats?.TotalReviewCount || 0,
      bestRating: '5',
      worstRating: '1',
    };
  }

  if (GTINs.length > 0) {
    schema.gtin = GTINs;
  }

  return schema;
}

/**
 * Function that will return review JSON-LD structured data
 * @param {object} [fields] data from contentful
 * @returns {object} returns the schema object to be stringified and included on the page
 */
export function getReviewSchema(fields) {
  const schema = {
    '@context': 'https://schema.org/',
    '@id': `${process.env.NEXT_PUBLIC_PRIMARY_DOMAIN}/${fields.ProductId}`,
    review: {
      '@type': 'Review',
      dateCreated: fields.SubmissionTime,
      headline: fields.Title,
      reviewBody: fields.ReviewText,
      reviewRating: {
        '@type': 'Rating',
        ratingValue: fields.rating,
        bestRating: 5,
      },
      author: {
        '@type': 'person',
        name: fields.UserNickname,
      },
    },
  };

  if (fields.Photos.length > 0) {
    const images = fields.Photos.map((photo) => {
      return {
        '@type': 'ImageObject',
        image: photo.Sizes.normal.Url,
        description: 'normal',
        thumbnail: {
          '@type': 'ImageObject',
          name: photo.Sizes.thumbnail.Url,
        },
      };
    });
    schema.review.image = images;
  }

  return schema;
}

/**
 * Function that will return article JSON-LD structured data
 * @param {object} [fields] data from contentful
 * @returns {object} returns the schema object to be stringified and included in the Head
 */
export function getArticleSchema(fields) {
  const schema = {
    '@context': 'https://schema.org/',
    '@type': 'Article',
    headline: fields.title,
    description: fields.shortDescription,
    image: [
      `https:${fields.articleHeroImage.fields.file.url}?w=1600&h=1600`,
      `https:${fields.articleHeroImage.fields.file.url}?w=1600&h=1200`,
      `https:${fields.articleHeroImage.fields.file.url}?w=1600&h=900`,
    ],
    dateModified: fields.updatedAt,
    datePublished: fields.publishDate ?? fields.contentfulPublishDate,
    publisher: {
      '@type': 'Organization',
      '@id': `${process.env.NEXT_PUBLIC_PRIMARY_DOMAIN}/#organization`,
    },
  };

  if (fields.authors) {
    schema.author = fields.authors.map((author) => ({
      '@type': 'Person',
      name: author.fields.name,
    }));
  }

  return schema;
}

/**
 * Function that will return JSON-LD structured data for the homepage
 * @param {object} [fields] data from contentful
 * @param {string} [locale] locale of site
 * @returns {object} returns the schema object to be stringified and included in the Head
 */
export function getHomepageSchema(fields, locale) {
  const localeUrl = `${getDomainBasedOnLocale(locale)}/${locale}`;
  const schema = {
    '@context': 'https://schema.org/',
    '@type': 'WebPage',
    name: fields.brand.fields.name,
    url: localeUrl,
    provider: {
      '@type': 'Organization',
      '@id': `${process.env.NEXT_PUBLIC_PRIMARY_DOMAIN}/#organization`,
      name: fields.brand.fields.name,
      logo: `https:${fields.brand.fields.logo.fields.file.url}`,
      url: process.env.NEXT_PUBLIC_PRIMARY_DOMAIN,
      sameAs: fields.brand.fields.brandLinks,
    },
  };

  return schema;
}
