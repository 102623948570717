import { useRef, useEffect, useContext, useState } from 'react';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import cx from 'classnames';
import cookieCutter from 'cookie-cutter';
import { useRouter } from 'next/router';
import Head from 'next/head';
import 'intersection-observer';

import SiteContext from './AppContext';
import { GatherLogo, GoSolutionsLogo, NowFreshLogo, PetcureanLogo, SummitLogo } from './Icons';
import { useTranslation } from '../lib/hooks';
import Navigation from './Navigation/Navigation';
import Footer from './Footer/Footer';
import Modal from '../components/Modal/Modal';
import RedirectModal from './RedirectModal/RedirectModal';
import CookieBanner from './CookieBanner/CookieBanner';
import styles from './layout.module.scss';
import { getCanonical, getDomainBasedOnLocale } from '../lib/utils';
import isEmpty from 'lodash/isEmpty';
import Script from 'next/script';
import PromoBanner from './PromoBanner/PromoBanner';

const logos = {
  gather: GatherLogo,
  'go-solutions': GoSolutionsLogo,
  'now-fresh': NowFreshLogo,
  petcurean: PetcureanLogo,
  summit: SummitLogo,
};
const sitekey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

/**
 * Component to use as main layout of any template
 * @param {object} [props] component props
 * @param {string} [props.title] Page Title
 * @param {string} [props.locale] locale being used
 * @param {object} [props.localesData] list of available locales
 * @param {string} [props.brandSlug] parameter used to retrieve the brand logo
 * @param {object} [props.seoData] seo content data
 * @param {object} [props.globalData] global data
 * @param {Function} [props.children] children of the layout
 * @param {string} [props.className] class to be added to the layout component
 * @param {boolean} [props.error] flag when using custom 404 page is rendered
 * @returns {JSX.Element} Layout react component
 */
export default function Layout({
  title = 'default',
  locale,
  localesData,
  brandSlug = '',
  seoData,
  globalData,
  children,
  className,
  error = false,
}) {
  clearAllBodyScrollLocks();
  const { userLocale, localeMap } = useContext(SiteContext);
  const router = useRouter();
  const modalEL = useRef();
  const mainEL = useRef();
  const brand = brandSlug.substring(1);
  const Logo = brand === '' ? PetcureanLogo : logos[brand];
  const { t } = useTranslation();
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const [showPromoBanner, setShowPromoBanner] = useState(false);
  const canonical = getCanonical(localeMap, locale);
  const brandEntry = children?.props?.fields?.brand;

  const isNowFreshBrand = brandEntry && brandEntry.fields?.slug === 'now-fresh';

  useEffect(() => {
    const hideRedirectModal = cookieCutter.get('hideRedirectModal');
    if (
      !hideRedirectModal &&
      (router.query.redirectModalLocale || locale.toLowerCase() !== userLocale.toLowerCase())
    ) {
      setShowRedirectModal(true);
    } else {
      setShowRedirectModal(false);
    }
  }, [modalEL, router, locale, userLocale]);

  useEffect(() => {
    const showCookieBanner = cookieCutter.get('cookieBanner');
    if (showCookieBanner !== 'hide') {
      setShowCookieBanner(true);
    } else {
      setShowCookieBanner(false);
    }
  }, [modalEL, router, locale, userLocale]);

  useEffect(() => {
    const showPromoBanner = cookieCutter.get('promoBanner');
    if (showPromoBanner !== 'hide') {
      setShowPromoBanner(true);
    } else {
      setShowPromoBanner(false);
    }
  }, [modalEL, router, locale, userLocale]);

  return (
    <div
      className={cx(className, brand, styles.container, {
        [styles.nowFreshPadding]: isNowFreshBrand,
      })}>
      {sitekey && (
        <Script id="reCaptcha" src={`https://www.google.com/recaptcha/api.js?render=${sitekey}`} />
      )}
      <Head>
        <title>{seoData?.fields?.title || title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {brand && (
          <>
            <link rel="apple-touch-icon" sizes="180x180" href={`/${brand}/apple-touch-icon.png`} />
            <link rel="icon" type="image/png" sizes="32x32" href={`/${brand}/favicon-32x32.png`} />
            <link rel="icon" type="image/png" sizes="16x16" href={`/${brand}/favicon-16x16.png`} />
            <link rel="manifest" href={`/${brand}/site.webmanifest`} />
          </>
        )}
        {seoData?.fields?.title && <meta property="og:title" content={seoData.fields?.title} />}
        {seoData?.fields?.description && (
          <>
            <meta name="description" content={seoData.fields?.description} />
            <meta property="og:description" content={seoData.fields?.description} />
          </>
        )}
        {seoData?.fields?.socialImage && (
          <meta
            property="og:image"
            content={`https:${seoData.fields.socialImage.fields?.file?.url}`}
          />
        )}
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://${process.env.NEXT_PUBLIC_HOST}${router.asPath}`}
        />
        {canonical && (
          <link rel="canonical" href={`https://${process.env.NEXT_PUBLIC_HOST}/${canonical}`} />
        )}
        {!canonical && <link rel="canonical" href={`https://${process.env.NEXT_PUBLIC_HOST}`} />}
        {!isEmpty(localeMap) &&
          localesData &&
          localesData.items.map(
            (locale, index) =>
              localeMap[locale.code] && (
                <link
                  rel="alternate"
                  hrefLang={locale.code}
                  // eslint-disable-next-line max-len
                  href={`${getDomainBasedOnLocale(locale.code)}/${locale.code}/${
                    localeMap[locale.code]
                  }`}
                  key={index}
                />
              ),
          )}
        {isEmpty(localeMap) &&
          !error &&
          localesData &&
          localesData.items.map((locale, index) => (
            <link
              rel="alternate"
              hrefLang={locale.code}
              href={`${getDomainBasedOnLocale(locale.code)}/${locale.code}`}
              key={index}
            />
          ))}
      </Head>
      <a href="#main-content" className={styles.skipBtn}>
        <span>{t('common.navigation.skipNavigation')}</span>
      </a>
      {children?.props?.fields?.brand?.fields?.cookieBannerText && (
        <CookieBanner
          showCookieBanner={showCookieBanner}
          setShowCookieBanner={setShowCookieBanner}
          copyText={children.props.fields.brand.fields.cookieBannerText}
          ctaText={children.props.fields.brand.fields.cookieBannerCta}
        />
      )}
      {brandEntry?.fields?.calloutBannerStatus && brandEntry?.fields?.calloutBanner && (
        <PromoBanner
          showPromoBanner={showPromoBanner}
          setShowPromoBanner={setShowPromoBanner}
          promoText={brandEntry.fields.calloutBanner}
        />
      )}
      {globalData.navigation.items.length > 0 && (
        <Navigation
          Logo={Logo}
          content={globalData.navigation}
          locale={locale}
          localesData={localesData}
        />
      )}
      <main className={styles.main} id="main-content" ref={mainEL}>
        {children}
      </main>
      {userLocale && (
        <Modal
          autoTriggerOnLoad={showRedirectModal}
          fullSize={false}
          theme="light"
          ref={modalEL}
          opener={mainEL}
          className={styles.redirectModal}>
          <RedirectModal dialogEl={modalEL} />
        </Modal>
      )}
      <Footer footerData={globalData?.footer?.items[0]?.fields} siteLocale={locale} />
    </div>
  );
}
