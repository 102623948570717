import { useRef } from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import styles from './styledSelect.module.scss';
import cx from 'classnames';

/* eslint-disable jsx-a11y/no-onchange */

/**
 * StyledSelect component.
 * @param {object} [props] component props
 * @param {string} [props.id] id of dropdown
 * @param {string} [props.name] name of dropdown
 * @param {string} [props.label] label of dropdown
 * @param {JSX} [props.Icon] icon to display in dropdown
 * @param {boolean} [props.isMinimalLayout] use minimal layout design
 * @param {boolean} [props.isSortBtn] use the sort button layout
 * @param {string} [props.value] index of the value to be selected on render
 * @param {string} [props.country] current selected country
 * @param {string} [props.type] type of select element (should be "country" or "region",
 * or undefined for standard dropdowns)
 * @param {Array<object>} [props.advancedOptions] dropdown options with submit value and label data
 * @param {Array<string>} [props.options] dropdown options
 * @param {Function} [props.onFocus] function for the focus event
 * @param {Function} [props.onChange] function for the change event
 * @param {Function} [props.onBlur] function for the blur event
 * @param {string} [props.showError] flag to show the error state in the component
 * @param {boolean} [props.showEmptyOption] flag to show an initial empty option
 * @param {string} [props.errorMsg] Message to display on error
 * @param {string} [props.className] Additional class to attach to the component
 * @param {string} [props.ariaLabel] text for the aria-label
 * @param {string} [props.ariaRequired] flag for the aria-required attribute
 * @returns {JSX.Element} react component
 */
export default function StyledSelect({
  id,
  name,
  label,
  value,
  country,
  type,
  advancedOptions,
  options,
  onFocus,
  onChange,
  onBlur,
  Icon,
  isMinimalLayout = false,
  isSortBtn = false,
  showError,
  showEmptyOption = true,
  errorMsg,
  className,
  ariaLabel,
  ariaRequired,
}) {
  const selectEl = useRef(null);
  const handleCountryRegionEvent = (selectedValue, syntheticEvent) => {
    const e = syntheticEvent.nativeEvent;
    const customEvent = {
      type: e.type,
      target: {
        type: 'select',
        value: e.target.value,
        name: e.target.name,
      },
    };
    if (typeof onChange === 'function') onChange(customEvent);
  };
  const hasAdvancedOptions = advancedOptions?.length > 0;
  const selectedIndex = selectEl?.current?.options?.selectedIndex;
  const displayValue = selectEl?.current?.[selectedIndex]?.text || value;

  return (
    <div
      className={cx(styles.container, className, {
        [styles.error]: showError,
        [styles.minimalLayout]: isMinimalLayout,
        [styles.sortBtn]: isSortBtn,
      })}>
      <div className={cx(styles.wrapper, { selected: value !== '' && value !== undefined })}>
        {label && (
          <label htmlFor={id} className={styles.label}>
            {label}
          </label>
        )}
        <span className={styles.selectedOption}>
          {Icon && <Icon className={styles.icon} width="16px" height="16px" />}
          {displayValue}
        </span>
        {type === 'country' && (
          <CountryDropdown
            id={id}
            name={name}
            value={value}
            priorityOptions={['CA', 'US']}
            onFocus={onFocus}
            onChange={handleCountryRegionEvent}
            onBlur={handleCountryRegionEvent}
            aria-required={ariaRequired}
            aria-label={ariaLabel}
            className={styles.select}
          />
        )}
        {type === 'region' && (
          <RegionDropdown
            id={id}
            name={name}
            value={value}
            country={country}
            onFocus={onFocus}
            onChange={handleCountryRegionEvent}
            onBlur={handleCountryRegionEvent}
            aria-required={ariaRequired}
            aria-label={ariaLabel}
            className={styles.select}
          />
        )}
        {!type && (
          <select
            ref={selectEl}
            id={id}
            name={name}
            value={value}
            onFocus={onFocus}
            onChange={onChange}
            onBlur={onBlur}
            aria-required={ariaRequired}
            aria-label={ariaLabel}
            className={styles.select}>
            {showEmptyOption && <option value=""></option>}
            {hasAdvancedOptions
              ? advancedOptions.map((option, i) => (
                  <option value={option.key} key={i}>
                    {option.value}
                  </option>
                ))
              : options?.map((option, i) => (
                  <option value={option} key={i}>
                    {option}
                  </option>
                ))}
          </select>
        )}
      </div>
      <p className={styles.errorMsg}>{errorMsg}</p>
    </div>
  );
}
/* eslint-enable jsx-a11y/no-onchange */
