import { useTranslation } from '../../lib/hooks';
import { Bag, Car, Facebook, Laptop, Sign, Telephone, Close } from '../Icons';
import styles from './infoCard.module.scss';
import GenericLink from '../GenericLink/GenericLink';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import { External } from '../Icons';
import cx from 'classnames';
import { useEffect, useRef } from 'react';
import { formatDistance } from '../../lib/utils';

const GOOGLE_MAPS_DIRECTIONS_URL = 'https://www.google.com/maps/dir/?api=1&destination=';

/**
 * @param {object} [props] component props
 * @param {boolean} [props.hasMap] flag to enable/disable the map
 * @param {string} [props.storeLocatorName] store locator name
 * @param {string} [props.address1] address 1
 * @param {string} [props.address2] address 2
 * @param {string} [props.city] city
 * @param {string} [props.province] province
 * @param {string} [props.country] country
 * @param {string} [props.postalCode] postal code
 * @param {string} [props.curbsidepickup] Y or null value
 * @param {string} [props.storeLocatorPhone] store locator phone
 * @param {string} [props.url] store url
 * @param {string} [props.fb] store fb url
 * @param {boolean} [props.active] active card
 * @param {boolean} [props.visible] flag for the card visibility
 * @param {object} [props.locationsEl] ref from locations component
 * @param {number} [props.index] index in the InfoCardList
 * @param {string} [props.lat] store latitude
 * @param {string} [props.lng] store longitude
 * @param {number} [props.distance] distance from search
 * @param {Function} [props.setActivePin] state handler for active pin
 * @param {object} [props.infoCardListEl] info card list component ref
 * @param {string} [props.shoponline] Y or null value
 * @param {Function} [props.setActiveView] locations active view state setter
 * @param {string} [props.activeView] locations active view state
 * @param {boolean} [props.displayAll] locations state to check if it is list view
 * @returns {JSX.Element} info card react component
 */
export default function InfoCard({
  hasMap,
  storeLocatorName,
  address1,
  address2,
  city,
  province,
  country,
  postalCode,
  curbsidepickup,
  shoponline,
  storeLocatorPhone,
  url,
  fb,
  active,
  visible,
  locationsEl,
  lat,
  lng,
  distance,
  distanceFormat,
  index,
  setActivePin,
  infoCardListEl,
  activeView,
  setActiveView,
  displayAll,
}) {
  const { t } = useTranslation();
  const cardEl = useRef();

  const closeIconHandler = (e) => {
    e.stopPropagation();
    const pinClicked = new CustomEvent('card-clicked', {
      detail: {
        pinIndex: null,
      },
    });
    locationsEl.current.dispatchEvent(pinClicked);
    setActivePin(null);
  };

  useEffect(() => {
    if (!active || window.innerWidth < 1024) return;
    infoCardListEl.current.scrollTo(0, cardEl.current.offsetTop);
  });

  const cardClickHandler = () => {
    if (hasMap) {
      setActivePin(index);
      const cardClickedEvent = new CustomEvent('card-clicked', {
        detail: {
          activePin: index,
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        },
      });
      if (activeView === 'list') {
        setActiveView('map');
        window.scroll(0, window.scrollY + locationsEl.current.getBoundingClientRect().y - 88);
      }
      locationsEl.current.dispatchEvent(cardClickedEvent);
    }
  };

  const directionsURL = `${GOOGLE_MAPS_DIRECTIONS_URL}${lat},${lng}`;
  const distanceUnit =
    distanceFormat === 'kilometers'
      ? t('common.storeLocator.infoList.km')
      : t('common.storeLocator.infoList.mi');
  const distanceLabel = `${formatDistance(distance, distanceFormat)} ${distanceUnit}`;

  return (
    <AccessibleButton
      ref={cardEl}
      className={cx(styles.container, {
        [styles.active]: active,
        [styles.visible]: visible,
        [styles.grid]: !hasMap,
      })}
      onClick={cardClickHandler}>
      {!displayAll && (
        <AccessibleButton
          ariaLabel={t('common.storeLocator.infoList.closeIcon')}
          onClick={closeIconHandler}
          className={styles.closeIcon}>
          <Close />
        </AccessibleButton>
      )}
      <p className={styles.storeName}>{storeLocatorName}</p>
      <p className={styles.distance}>{`${distanceLabel}`}</p>
      <div className={styles.addressContainer}>
        <p className={styles.address}>
          {`${address1}${city && `, ${city}`}${province && `, ${province}`}${
            address2 && `, ${address2}`
          }${postalCode && `, ${postalCode}`}${country && `, ${country}`}`}
        </p>
        {curbsidepickup === 'Y' && (
          <p className={styles.curbside}>
            <Car />
            <span>{t('common.storeLocator.infoList.curbsidePickup')}</span>
          </p>
        )}
      </div>
      <div className={styles.ctasContainer}>
        {storeLocatorPhone && (
          <div className={styles.cta}>
            <GenericLink
              externalLink={`tel:${storeLocatorPhone}`}
              ariaLabel={`${storeLocatorName} ${storeLocatorPhone}`}
              className={styles.ctaLink}>
              <span className={styles.ctaIcon}>
                <Telephone width={20} height={19} />
              </span>
              <span className={styles.ctaText}>{storeLocatorPhone}</span>
            </GenericLink>
          </div>
        )}
        <div className={styles.cta}>
          <GenericLink
            externalLink={directionsURL}
            ariaLabel={`${storeLocatorName} ${t('common.storeLocator.infoList.directions')}`}
            className={styles.ctaLink}>
            <span className={styles.ctaIcon}>
              <Sign width={20} height={19} />
            </span>
            <span className={styles.ctaText}>
              {t('common.storeLocator.infoList.directions')}
              <External />
            </span>
          </GenericLink>
        </div>
        {url && (
          <div className={styles.cta}>
            <GenericLink
              externalLink={url}
              ariaLabel={`${storeLocatorName} ${
                shoponline === 'Y'
                  ? t('common.storeLocator.infoList.buyOnline')
                  : t('common.storeLocator.infoList.website')
              }
              `}
              className={styles.ctaLink}>
              <span
                className={cx(styles.ctaIcon, {
                  [styles.ctaIconBuy]: shoponline === 'Y',
                })}>
                {shoponline === 'Y' ? (
                  <Bag width={20} height={19} />
                ) : (
                  <Laptop width={20} height={15} />
                )}
              </span>
              <span className={styles.ctaText}>
                {shoponline === 'Y'
                  ? t('common.storeLocator.infoList.buyOnline')
                  : t('common.storeLocator.infoList.website')}
                <External />
              </span>
            </GenericLink>
          </div>
        )}
        {fb && (
          <div className={styles.cta}>
            <GenericLink
              externalLink={fb}
              ariaLabel={`${storeLocatorName} ${t('common.storeLocator.infoList.facebook')}`}
              className={styles.ctaLink}>
              <span className={styles.ctaIcon}>
                <Facebook width={20} height={20} />
              </span>
              <span className={styles.ctaText}>
                {t('common.storeLocator.infoList.facebook')}
                <External />
              </span>
            </GenericLink>
          </div>
        )}
      </div>
    </AccessibleButton>
  );
}
