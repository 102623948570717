import styles from './styledForm.module.scss';

import { useEffect, useContext, useState, useRef, forwardRef } from 'react';
import cx from 'classnames';

import { emailRegEx, encodeAsFormData, toCamelCase } from '../../lib/utils';
import { checkboxGroupDivider } from '../../lib/constants';
import { useTranslation } from '../../lib/hooks';
import SiteContext from '../AppContext';
import { ModalContext } from '../Modal/Modal';
import PageContext from '../PageContext';
import accept from 'attr-accept';
import JSZip from 'jszip';

import StyledLink from '../StyledLink/StyledLink';
import StyledInput from '../StyledInput/StyledInput';
import StyledFileInput from '../StyledFileInput/StyledFileInput';
import StyledFileDragAndDrop from '../StyledFileDragAndDrop/StyledFileDragAndDrop';
import StyledSelect from '../StyledSelect/StyledSelect';
import StyledTextarea from '../StyledTextarea/StyledTextarea';
import StyledCheckboxGroup from '../StyledCheckboxGroup/StyledCheckboxGroup';
import StyledRadioGroup from '../StyledRadioGroup/StyledRadioGroup';
import Agreement from '../Agreement/Agreement';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import SuccessMessage from '../SuccessMessage/SuccessMessage';
import Rate from '../Rate/Rate';
import Callouts from '../Callouts/Callouts';
import Loader from '../Loader/Loader';
import BvMediaUpload from '../BvMediaUpload/BvMediaUpload';
import emojiRegex from 'emoji-regex';
import iovationLoader from '../../lib/reviews/iovationLoader';

const MB_BYTES = 1048576;
// Default to 100MB
const MAX_FILESIZE = 100 * MB_BYTES;
const MAX_FILE_NUM = 5;
const ALLOWED_FILE_TYPES = 'image/jpeg, image/png, video/*';

const formFieldsMap = {
  textInputAny: {
    component: StyledInput,
    type: 'text',
  },
  textInputEmail: {
    component: StyledInput,
    type: 'email',
  },
  fileInput: {
    component: StyledFileInput,
    type: null,
  },
  fileDragAndDrop: {
    component: StyledFileDragAndDrop,
    type: null,
  },
  textArea: {
    component: StyledTextarea,
    type: 'textarea',
  },
  dropdown: {
    component: StyledSelect,
    type: null,
  },
  dropdownCountry: {
    component: StyledSelect,
    type: 'country',
  },
  dropdownRegion: {
    component: StyledSelect,
    type: 'region',
  },
  disclaimerCheckbox: {
    component: Agreement,
    type: null,
  },
  checkboxButtonGroup: {
    component: StyledCheckboxGroup,
    type: null,
  },
  radioButtonGroup: {
    component: StyledRadioGroup,
    type: null,
  },
  productCallouts: {
    component: Callouts,
    type: null,
  },
  starRating: {
    component: Rate,
    type: null,
  },
  bazaarvoiceImageUpload: {
    component: BvMediaUpload,
    type: 'bvImage',
  },
};

const phoneNumThemeMap = {
  gather: 'dark',
  'go-solutions': 'dark',
  'now-fresh': 'light',
  petcurean: 'dark',
  summit: 'dark',
};

const submitBtnTypeMap = {
  gather: 'primary',
  'go-solutions': 'primary',
  'now-fresh': 'primary',
  petcurean: 'secondary',
  summit: 'secondary',
};

/**
 * Creates a new blank state object
 * @param {object} [formFields] Form fields data
 * @returns {object} New blank state object
 */
function createBlankState(formFields = []) {
  return formFields?.reduce((obj, key) => {
    return {
      ...obj,
      [key.fields.name]: {
        fieldType: formFieldsMap[toCamelCase(key.fields?.fieldType)]?.type,
        value: '',
        valid: true,
        required: key.fields.isRequired,
        errorMsg: '',
      },
    };
  }, {});
}

/**
 * Sends the form's data to Netlify after validating it's not spam through Recaptcha v3
 * @param {object} [formData] data to send to the server
 * @param {Function} [setIsFormSent] hook to set the forms success state
 * @param {Function} [setIsFormSubmitted] hook to set the form submitted state
 * @param {Function} [container] reference to the element that contains the form
 * @param {Function} [formRef] ref to the form element
 * @param {boolean} [isInline] flag to style the form as inline (like the newsletter form)
 */
function sendForm(formData, setIsFormSent, setIsFormSubmitted, container, formRef, isInline) {
  const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
  const grecaptcha = window.grecaptcha;

  if (siteKey) {
    // Recaptcha V3 function wrapper: https://developers.google.com/recaptcha/docs/v3
    grecaptcha.ready(function () {
      grecaptcha
        .execute(siteKey, { action: 'submit' })
        .then(function (token) {
          // Send the recapcha token to an endpint on our API
          // for antispam validation before sending the formData to Netlify
          fetch('/api/formValidation', {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
          })
            .then((response) => response.json())
            // Our API answers with a shouldSend flag
            .then(({ shouldSend }) => {
              if (shouldSend) {
                fetch('/forms', {
                  method: 'POST',
                  body: formData,
                }).then(() => {
                  // If it's not spam show the success message
                  setIsFormSent(true);
                  setIsFormSubmitted(false);
                  // If formData includes a truthy newsletter signup push a GTM event
                  if (
                    formData.get('gs-newsletter-accept') ||
                    formData.get('nf-newsletter-accept')
                  ) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({ event: 'newsletter_sign_up' });
                  }
                });
              } else {
                // If it's spam also show the success message
                // although we didn't send the form's data to Netlify
                setIsFormSent(true);
                setIsFormSubmitted(false);
              }
            });
        })
        .catch((error) => {
          console.log(error);
          if (!isInline && container) container.scrollTo(0, formRef.current.offsetTop - 160);
          setIsFormSubmitted(false);
        });
    });
  } else {
    // if we're not using recaptcha
    fetch('/forms', {
      method: 'POST',
      body: formData,
    }).then(() => {
      // If it's not spam show the success message
      setIsFormSent(true);
      setIsFormSubmitted(false);
      // If formData includes a truthy newsletter signup push a GTM event
      if (formData.get('gs-newsletter-accept') || formData.get('nf-newsletter-accept')) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'newsletter_sign_up' });
      }
    });
  }
}

/**
 * @param {object} [props] component props
 * @param {string} [props.id] string to be prefixed in the inputs ids to avoid id collisions
 * @param {string} [props.componentIndex] same as props.id (this is set by the FreeForm template)
 * @param {string} [props.className] additional class for the component
 * @param {object} [props.fields] form data
 * @param {boolean} [props.isInline] flag to style the form as inline (like the newsletter form)
 * @param {Function} [props.setIsFormSent] optional external hook to set the succes state
 * @param {boolean} [props.hidden] sets the form as hidden
 * @param {boolean} [props.netlify] flags the netlify attribute (to allow Netlify parse the form)
 * @param {boolean} [props.isFreeFormComponent] free form flag
 * @param {boolean} [containerRef] Ref for the modal that may contain this component
 * @returns {JSX.Element} Contact Us form element
 */
export function StyledForm(
  {
    id,
    componentIndex,
    className,
    fields,
    isFreeFormComponent = false,
    isInline = false,
    setIsFormSent: setIsFormSentExternal = null,
    hidden = false,
    netlify = true,
  },
  containerRef,
) {
  const {
    formId,
    eyebrow,
    title,
    description,
    nutritionHotlinePhoneNumber,
    formFields,
    ctaLabel,
    successImage,
    successMessageTitle,
    isBazaarvoiceForm,
  } = fields;
  const finalId = id || componentIndex;
  const formRef = useRef(null);
  const successRef = useRef(null);
  const modalContext = useContext(ModalContext);
  const siteContext = useContext(SiteContext);
  const pageContext = useContext(PageContext);
  const { brand, locale } = siteContext;
  const { t } = useTranslation();

  const [formState, setFormState] = useState(createBlankState(formFields));
  const [isFormSent, setIsFormSent] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [fp, setFp] = useState('');
  const [fileUploading, setFileUploading] = useState(false);

  const isFormValid = Object.values(formState)
    .map((el) => el.valid)
    .every((el) => el === true);
  const formStatusMsg = isFormSent
    ? t('common.forms.general.successMsg')
    : isFormValid
      ? ''
      : t('common.forms.general.submitErrorMsg');

  const handleFocus = (e) => {
    // Safari handling of focus and blur for checkboxes and radios is wired and unstandard
    // We have to handle everything with onChange in contrast to the other fields.
    if (e.target.type === 'checkbox' || e.target.type === 'radio') return;

    handleChange(e);
  };

  // For validation, if allowedTypes, maxNumFiles, or maxFileSize set in CMS,
  // use that instead of the constant. Only needed for fileInput type, not drag and drop
  let allowedFileTypes = ALLOWED_FILE_TYPES;
  let maxNumFiles = MAX_FILE_NUM;
  let maxFileSize = MAX_FILESIZE;

  if (formFields) {
    for (let i = 0; i < formFields.length; i++) {
      if (toCamelCase(formFields.fields?.fieldType) === 'fileInput') {
        if (formFields.fields.allowedTypes) {
          allowedFileTypes = formFields.fields.allowedTypes;
        }
        if (formFields.fields.maxFileNumber) {
          maxNumFiles = formFields.fields.maxFileNumber;
        }
        if (formFields.fields.maxFileSize) {
          maxFileSize = formFields.fields.maxFileSize;
        }
      }
    }
  }

  const handleChange = (e) => {
    const newFormState = { ...formState };
    let value;
    let errorMsg = '';
    let valid = true;

    // We handle validation of checkboxes and radios on change instead of on blur
    // to avoid issues with Safari.
    if (e.target.type === 'checkbox') {
      let isDisclaimer = false;

      e.target.classList.forEach((elm) => {
        if (elm.startsWith('agreement')) {
          isDisclaimer = true;
        }
      });

      // There are three types of Form Field types that implement checkboxes
      // Disclaimer checkbox: its value should be true or empty
      // Product callouts and Checkbox button group: its value is a string containing
      // the values of the checked checkboxes concatenated using a custom divider
      if (isDisclaimer) {
        value = e.target.checked === true ? e.target.checked : '';
      } else {
        const prevState = formState[e.target.name].value;

        // If not checked remove the value from the list of values, otherwise add it
        if (e.target.checked === false) {
          value = prevState
            .split(checkboxGroupDivider)
            .filter((elm) => elm !== e.target.value)
            .join(checkboxGroupDivider);
        } else {
          if (prevState === '') {
            value = e.target.value;
          } else {
            value = `${prevState}${checkboxGroupDivider}${e.target.value}`;
          }
        }
      }
      // Handle file check on change since it doesn't blur right away
    } else if (e.target.type === 'file') {
      if (e.target.files?.length) {
        const files = e.target.files;

        if (files.length > maxNumFiles) {
          value = '';
          valid = false;
          errorMsg = t('common.forms.general.tooManyFiles');
        } else {
          for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (!accept(file, allowedFileTypes)) {
              value = '';
              valid = false;
              errorMsg = t('common.forms.general.fileInvalidType');
              break;
            }

            // Currently don't allow greater than 100Mb
            if (file?.size > maxFileSize || file.size === 0) {
              value = '';
              valid = false;
              errorMsg =
                file.size > 0
                  ? t('common.forms.general.fileTooLarge')
                  : t('common.forms.general.fileTooSmall');
              break;
            }
          }
        }

        // Only accept the first file currently, since netlify only supports one file at a time
        if (valid) {
          value = files[0];
        }
      } else {
        value = '';
      }
    } else if (e.target.type === 'dragdrop') {
      if (e.target.hasError) {
        value = '';
        valid = false;
        errorMsg = t(`common.forms.general.${e.target.errorCode}`);
      } else {
        value = e.target.value;
      }
    } else if (e.target.type === 'select' && e.target.name === 'country') {
      // Reset the value of the region field if the country is changed
      if (e.target.value != formState.country.value && formState.region) {
        newFormState.region.value = '';
      }
      value = e.target.value;
    } else if (typeof e.target.value === 'string') {
      // strip emojis
      value = e.target.value.replace(emojiRegex(), '');
    } else {
      value = e.target.value;
    }

    newFormState[e.target.name].value = value;
    newFormState[e.target.name].valid = valid;
    newFormState[e.target.name].errorMsg = errorMsg;
    setFormState(newFormState);
  };

  const handleBlur = (e) => {
    // Safari handling of focus and blur for checkboxes and radios is wired and unstandard
    // We have to handle everything with onChange in contrast to the other fields.
    if (e.target.type === 'checkbox' || e.target.type === 'radio') return;

    const newFormState = { ...formState };

    // min/max length validations
    if (e.target.type === 'text' || e.target.type === 'textarea') {
      if (e.target.maxLength > 0 && e.target.value.length > e.target.maxLength) {
        newFormState[e.target.name].valid = false;
        newFormState[e.target.name].errorMsg = t('common.forms.general.textTooLong', [
          e.target.maxLength,
        ]);
        setFormState(newFormState);
        return;
      } else if (e.target.value.length < e.target.minLength) {
        newFormState[e.target.name].valid = false;
        newFormState[e.target.name].errorMsg = t('common.forms.general.textTooShort', [
          e.target.minLength,
        ]);
        setFormState(newFormState);
        return;
      }
    }

    // If not valid by browser's default check, flag and return
    if (!e.target.checkValidity()) {
      newFormState[e.target.name].valid = false;
      newFormState[e.target.name].errorMsg = t('common.forms.general.fieldErrorMsg');
      setFormState(newFormState);
      return;
    }

    // If email does not have the form anystring@anystring.anystring
    // or is not an empty string, flag and return
    if (e.target.type === 'email' && !(e.target.value === '') && !emailRegEx.test(e.target.value)) {
      newFormState[e.target.name].valid = false;
      newFormState[e.target.name].errorMsg = t('common.forms.general.fieldErrorMsg');
      setFormState(newFormState);
      return;
    }
  };

  const submitSend = (values, hiddenValues, container) => {
    const formBody = encodeAsFormData({ 'form-name': formId, ...values, ...hiddenValues });
    const successHook = setIsFormSentExternal || setIsFormSent;
    sendForm(formBody, successHook, setIsFormSubmitted, container, formRef, isInline);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let errorFound = false;
    const values = {};
    const reviewedState = { ...formState };
    const promiseArray = [];

    // Iterate the form state object and extract any empty or error fields into reviewdStete
    for (const prop in formState) {
      // Iterate only own properties
      if (Object.prototype.hasOwnProperty.call(formState, prop)) {
        values[prop] = formState[prop].value;

        // If the field is not valid or it is a required field but empty
        if (
          formState[prop].valid === false ||
          (formState[prop].value === '' && formState[prop].required === true)
        ) {
          reviewedState[prop].valid = false;
          reviewedState[prop].errorMsg = t('common.forms.general.fieldErrorMsg');
          errorFound = true;
        }

        // If email is not valid
        if (prop === 'email' && !emailRegEx.test(formState[prop].value)) {
          reviewedState[prop].valid = false;
          reviewedState[prop].errorMsg = t('common.forms.general.fieldErrorMsg');
          errorFound = true;
        }

        // If file is not valid
        if (formState[prop].value instanceof File) {
          const size = formState[prop].value.size;

          if (size > MAX_FILESIZE || size === 0) {
            reviewedState[prop].valid = false;
            reviewedState[prop].errorMsg =
              size > 0
                ? t('common.forms.general.fileTooLargeErrorMsg')
                : t('common.forms.general.fileEmptyErrorMsg');
            errorFound = true;
          }
        }
      }
    }

    const container = containerRef?.current || document.body;

    if (errorFound) {
      setFormState(reviewedState);
      if (!isInline && container) container.scrollTo(0, formRef.current.offsetTop - 160);
      return;
    }

    setIsFormSubmitted(true);

    const hiddenValues = {
      hidden_entryId: pageContext.templateEntryId,
      hidden_locale: locale,
      hidden_url: window.location.href,
      hidden_date: new Date(),
      hidden_BvId: pageContext?.BvId,
      hidden_fp: '',
      hidden_BvImgs: '',
    };
    if (isBazaarvoiceForm) {
      hiddenValues.hidden_fp = fp;
    }

    for (const prop in values) {
      // Iterate only own properties
      if (Object.prototype.hasOwnProperty.call(values, prop)) {
        // This portion is to create a zip file of multiple files if they exist
        if (Array.isArray(values[prop]) && values[prop].length) {
          // If this is an array of files and there are more than one, create zip file
          if (values[prop][0] instanceof File) {
            if (values[prop].length > 1) {
              const zip = new JSZip();
              values[prop].forEach((file) => zip.file(file.name, file));

              // Add to a promise array so that we wait until they are all resolved
              promiseArray.push(
                new Promise((resolve) => {
                  // generate the file which is async, so we have to wait until it is done to submit
                  zip.generateAsync({ type: 'uint8array' }).then((data) => {
                    const zippedFiles = new File([data], 'attachments.zip', {
                      lastModified: Date.now(),
                      type: 'application/zip',
                    });

                    values[prop] = zippedFiles;
                    resolve();
                  });
                }),
              );
            } else {
              // If only one file in the array, pass that file
              values[prop] = values[prop][0];
            }
          }

          // extract the file urls of any uploads
          if (values[prop][0] instanceof Object) {
            const urlStrings = values[prop]
              ?.filter((fw) => fw.url && fw.errors?.length < 1)
              ?.map((fw) => fw.url)
              ?.join();

            if (isBazaarvoiceForm) {
              hiddenValues.hidden_BvImgs = urlStrings;
            }
          }
        }
      }
    }

    // If zip files are getting generated, we need to wait until they are all done
    if (promiseArray.length) {
      Promise.all(promiseArray).then(() => {
        submitSend(values, hiddenValues, container);
      });
    } else {
      submitSend(values, hiddenValues, container);
    }
  };

  useEffect(() => {
    if (modalContext?.visible === false) {
      setFormState(createBlankState(formFields));
      setIsFormSent(false);
      setIsFormSubmitted(false);
    }
  }, [modalContext, formFields]);

  useEffect(() => {
    const container = containerRef?.current || window;
    if (isFormSent && !isInline && container)
      container.scrollTo(0, successRef.current.offsetTop - 160);
  }, [isInline, isFormSent, containerRef]);

  useEffect(() => {
    setFormState(createBlankState(formFields));
    setIsFormSent(false);
    setIsFormSubmitted(false);
  }, [formId, formFields]);

  // Bazaarvoice config for device fingerprinting
  // we only want this to run client side
  useEffect(() => {
    if (isBazaarvoiceForm) {
      window.IGLOO = window.IGLOO || {
        enable_rip: true, // Enable Real IP protection.
        enable_flash: false, // Disable flash
        install_flash: false, // Don't ask user to install flash
        loader: {
          version: 'general5', // Non-experimental 5.x updates
        },
      };

      // start the iovation loader
      iovationLoader();

      window.IGLOO.bb_callback = function (blackBoxString, isComplete) {
        if (isComplete) {
          setFp(blackBoxString);
        }
      };
    }
  }, [isBazaarvoiceForm]);

  return (
    <>
      <div
        role="alert"
        aria-relevant="all"
        aria-atomic="true"
        aria-hidden={hidden}
        className={styles.ariaLiveStatus}>
        {formStatusMsg}
      </div>
      {isFormSent ? (
        <div
          ref={successRef}
          aria-hidden={hidden}
          className={cx(styles.container, className, {
            [styles.freeFormComponent]: isFreeFormComponent,
          })}>
          <SuccessMessage
            id={formId}
            className={styles.success}
            image={successImage?.fields?.file?.url}
            message={successMessageTitle}
            showCloseBtn={typeof modalContext !== 'undefined'}
          />
        </div>
      ) : (
        <div
          id={isFreeFormComponent && fields.inPageLinkTarget ? fields.inPageLinkTarget : ''}
          aria-hidden={hidden}
          className={cx(
            {
              [styles.container]: !isInline,
              [styles.freeFormComponent]: isFreeFormComponent,
            },
            className,
          )}>
          <div className={styles.header}>
            {eyebrow && <p className={styles.eyebrow}>{eyebrow}</p>}
            {title && <h2 className={styles.title}>{title}</h2>}
            {description && <p className={styles.instructions}>{description}</p>}
            {nutritionHotlinePhoneNumber && (
              <StyledLink
                tabIndex={hidden ? '-1' : '0'}
                href={`tel:${nutritionHotlinePhoneNumber}`}
                type="p2"
                theme={phoneNumThemeMap[brand]}
                isBold={false}
                className={styles.phonenum}>
                {nutritionHotlinePhoneNumber}
              </StyledLink>
            )}
          </div>
          <form
            ref={formRef}
            name={formId}
            method="POST"
            className={cx(styles.form, { [styles.inlineForm]: isInline })}
            onSubmit={handleSubmit}
            hidden={hidden}
            // We validate forms using checkValidity() and do our own validation,
            // and display our own messages. noValidate only affects interactive validation
            // using reportValidity().
            noValidate={true}
            // https://docs.netlify.com/forms/setup/#html-forms
            netlify={netlify.toString()}
            netlify-honeypot="bot-field">
            {/* this input is to prevent Hydration errors after Netlify parses this form */}
            <input type="hidden" name="form-name" value={formId} />
            <div className={styles.botField}>
              <label>
                Don’t fill this out if you’re human: <input type="text" name="bot-field" />
              </label>
            </div>

            {!isFormValid && <div className={styles.formErrorMsg}>{formStatusMsg}</div>}
            {formFields &&
              formFields.length > 0 &&
              formFields.map(({ fields: fieldData }, index) => {
                const Field = formFieldsMap[toCamelCase(fieldData?.fieldType)]?.component;

                return (
                  Field && (
                    <Field
                      key={index}
                      type={formFieldsMap[toCamelCase(fieldData?.fieldType)]?.type}
                      id={`${finalId}-${formId}-${fieldData?.name}`}
                      name={fieldData?.name}
                      label={fieldData?.fieldLabel}
                      helperText={fieldData?.helperText}
                      value={formState[fieldData?.name].value}
                      fileTypesAllowed={fieldData?.allowedTypes || ALLOWED_FILE_TYPES}
                      allowMultiple={false}
                      maxSize={
                        fieldData?.maxFileSize ? fieldData.maxFileSize * MB_BYTES : MAX_FILESIZE
                      }
                      maxFiles={fieldData?.maxFileNumber || MAX_FILE_NUM}
                      country={formState.country?.value}
                      advancedOptions={fieldData?.advancedOptions}
                      options={fieldData?.options}
                      iconButtons={fieldData?.iconButtons}
                      onFocus={handleFocus}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onInvalid={(e) => e.preventDefault()}
                      showError={formState[fieldData?.name].valid === false}
                      errorMsg={
                        formState[fieldData?.name].errorMsg ||
                        t('common.forms.general.fieldErrorMsg')
                      }
                      className={styles.field}
                      ariaRequired={fieldData?.isRequired}
                      checkboxText={fieldData?.disclaimerText}
                      minLength={fieldData?.fieldMinimumLength}
                      maxLength={fieldData?.fieldMaximumLength}
                      setFileUploading={setFileUploading}
                    />
                  )
                );
              })}
            {/* The value of the hidden fields is populated on submit */}
            <input
              type="hidden"
              id={`${finalId}-${formId}-hidden_url`}
              name="hidden_url"
              value=""
            />
            <input
              type="hidden"
              id={`${finalId}-${formId}-hidden_entryId`}
              name="hidden_entryId"
              value=""
            />
            <input
              type="hidden"
              id={`${finalId}-${formId}-hidden_locale`}
              name="hidden_locale"
              value=""
            />
            <input
              type="hidden"
              id={`${finalId}-${formId}-hidden_date`}
              name="hidden_date"
              value=""
            />
            {isBazaarvoiceForm && (
              <>
                <input
                  type="hidden"
                  id={`${finalId}-${formId}-hidden_BvId`}
                  name="hidden_BvId"
                  value=""
                />
                <input type="hidden" id={`${finalId}-${formId}-fp`} name="hidden_fp" value="" />
                <input
                  type="hidden"
                  id={`${finalId}-${formId}-hidden_BvImgs`}
                  name="hidden_BvImgs"
                  value=""
                />
              </>
            )}
            <AccessibleButton
              type={submitBtnTypeMap[brand]}
              theme="dark"
              tabIndex={hidden ? '-1' : '0'}
              className={{ [styles.submitBtn]: !isInline }}
              disabled={isFormSubmitted || fileUploading}
              submit={true}>
              {isFormSubmitted ? (
                <Loader theme={brand === 'go-solutions' ? 'dark' : 'hover-safe'} />
              ) : (
                ctaLabel
              )}
            </AccessibleButton>
          </form>
        </div>
      )}
    </>
  );
}

export default forwardRef(StyledForm);
