import { useContext } from 'react';
import cx from 'classnames';
import RemoteIcon from '../RemoteIcon/remoteIcon';
import SiteContext from '../AppContext';
import Picture from '../Image/Picture';
import RichText from '../Richtext/Richtext';
import { getImageSize } from '../../lib/imageUtils';

import styles from './PCPHero.module.scss';

/**
 * @param {object} [props] component props
 * @param {string} [props.title] title
 * @param {Array<object>}[props.bulletPoints] bullet points
 * @param {string} [props.eyebrow] eyebrow
 * @param {object} [props.headerImage] image content
 * @param {string} [props.categoryDescription] description
 * @returns {JSX.Element} React component
 */
export default function PCPHero({
  title,
  categoryDescription,
  bulletPoints,
  eyebrow,
  headerImage,
}) {
  const siteContext = useContext(SiteContext);
  const { brand } = siteContext;

  const imgWidth = {
    small: brand === 'go-solutions' ? 247 : 338,
    medium: brand === 'go-solutions' ? 425 : 505,
    large: brand === 'go-solutions' ? 375 : 580,
    xlarge: brand === 'go-solutions' ? 425 : 648,
  };

  const pcpHeroImageSize = headerImage?.fields?.file?.details?.image;
  const pcpHeroImageSizes = {
    xl: getImageSize(imgWidth.xlarge, pcpHeroImageSize),
    lg: getImageSize(imgWidth.large, pcpHeroImageSize),
    md: getImageSize(imgWidth.medium, pcpHeroImageSize),
    sm: getImageSize(imgWidth.small, pcpHeroImageSize),
    fallbackSize: getImageSize(imgWidth.small, pcpHeroImageSize),
  };

  const isRichTextField =
    typeof categoryDescription === 'object' &&
    categoryDescription !== null &&
    categoryDescription.content &&
    Array.isArray(categoryDescription.content);

  return (
    <section className={styles.background}>
      <div className={styles.container}>
        <div className={styles.copyContainer}>
          {eyebrow && <p className={styles.eyebrow}>{eyebrow}</p>}
          <h1 className={cx(categoryDescription ? 'h2' : 'h1', styles.headline)}>{title}</h1>
          {categoryDescription &&
            (isRichTextField ? (
              <RichText content={categoryDescription} className={styles.description} />
            ) : (
              <p className={styles.description}>{categoryDescription}</p>
            ))}
          {bulletPoints && <hr className={styles.separator} />}
          {bulletPoints && (
            <ul className={styles.bullets}>
              {bulletPoints.map((bulletPoint, i) => (
                <li className={styles.bullet} key={i}>
                  <div className={styles.bulletImage}>
                    {bulletPoint?.fields?.image?.fields?.file && (
                      <RemoteIcon url={bulletPoint.fields.image.fields.file.url} />
                    )}
                  </div>
                  <span className={styles.bulletText}>{bulletPoint.fields.primaryText}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className={styles.imgContainer}>
          {headerImage?.fields?.file && (
            <Picture
              className={styles.image}
              url={headerImage.fields.file.url}
              imageSizes={pcpHeroImageSizes}
              quality="70"
              alt={headerImage.fields.description}
            />
          )}
        </div>
      </div>
    </section>
  );
}
