import { useEffect, useState } from 'react';

/**
 * This is a High Order component to be used when the useLayoutEffect hooks is used in
 * a react component
 * @param {JSX.Element} [Component] component using useLayoutEffect Hook
 * @param {string} [placeholder] placeholder text to be used
 * @returns {JSX.Element} component able to use useLayoutEffect
 */
const withLayoutEffect = (Component, placeholder) =>
  function WithLayoutEffect(props) {
    const [showChild, setShowChild] = useState(false);

    // Wait until after client-side hydration to show
    useEffect(() => {
      setShowChild(true);
    }, []);

    if (!showChild) {
      // You can show some kind of placeholder UI here
      return <div>{placeholder}</div>;
    }

    return <Component {...props} />;
  };

export default withLayoutEffect;
