import cx from 'classnames';

import styles from './noResults.module.scss';
import AccessibleButton from '../AccessibleButton/AccessibleButton';

/**
 * @param {object} [props] component props
 * @param {string} [props.noResultsTitle] title for no results
 * @param {string} [props.noResultsMessage] message for no results
 * @param {object} [props.cta] link for no results
 * @param {string} [props.activeView] current active view (either 'list' or 'map')
 * @returns {JSX.Element} react component
 */
export default function NoResults({ noResultsTitle, noResultsMessage, cta, activeView }) {
  return (
    <div className={cx(styles.container, { [styles.static]: activeView === 'list' })}>
      <p className={styles.headline}>{noResultsTitle}</p>
      <p className={styles.subheadline}>{noResultsMessage}</p>
      <AccessibleButton type="primary" link={cta} className={styles.cta}>
        {cta?.fields?.linkText}
      </AccessibleButton>
    </div>
  );
}
