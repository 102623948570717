import { useContext } from 'react';
import cx from 'classnames';
import Image from '../Image/Image';
import AccessibleButton from '../AccessibleButton/AccessibleButton';

import SiteContext from '../AppContext';
import styles from './transitionCta.module.scss';

/**
 * @param {object} [props] component props
 * @param {object} [props.fields] data for the component
 * @param {object} [props.fields.accentImage] accent image
 * @param {object} [props.fields.cta] cta link
 * @param {string} [props.fields.text] title call to action
 * @param {string} [props.fields.componentTheme] theming of component (light or dark mode)
 * @returns {JSX.Element} react component
 */
export default function TransitionCta({
  fields: { accentImage, cta, text, componentTheme = 'light' },
}) {
  const siteContext = useContext(SiteContext);
  const { brand } = siteContext;

  const ctaButtonThemeMap = {
    gather: componentTheme === 'light' ? 'dark' : 'light',
    'go-solutions': 'dark',
    'now-solutions': 'dark',
    petcurean: componentTheme === 'light' ? 'dark' : 'light',
    summit: componentTheme === 'light' ? 'dark' : 'light',
  };

  const accentImageSize = accentImage?.fields?.file?.details?.image || {};
  return (
    <section className={cx(styles.container, styles[`container-${componentTheme}`])}>
      {accentImage?.fields?.file && (
        <div className={styles.backgroundImage}>
          <Image
            url={accentImage.fields.file.url}
            alt={accentImage.fields.description}
            imageSizes={{ defaultSize: [accentImageSize?.width, accentImageSize?.height] }}
            quality="40"
            wrapImage={false}
          />
        </div>
      )}
      <div className={styles.content}>
        {text && <p className={styles.title}>{text}</p>}
        {Array.isArray(cta) && cta.length > 0 && (
          <div className={styles.ctaContainer}>
            {cta.map((component, index) => (
              <AccessibleButton
                key={index}
                type="primary"
                theme={ctaButtonThemeMap[brand]}
                className={styles.btn}
                link={component}>
                {component.fields.linkText}
              </AccessibleButton>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}
