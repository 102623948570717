import { useState, useEffect, useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import cx from 'classnames';

import AccessibleButton from '../AccessibleButton/AccessibleButton';
import GenericLink from '../GenericLink/GenericLink';
import Image from '../Image/Image';
import RichText from '../Richtext/Richtext';
import { getImageSize } from '../../lib/imageUtils';
import SiteContext from '../AppContext';

import styles from './relatedArticles.module.scss';

/**
 * A component that returns one Article
 * @param {object} [props] component props
 * @param {Array} [props.article] Data of the article to render
 * @param {string} [props.articleImage] Article Image Data
 * @param {string} [props.title] Article title
 * @param {string} [props.description] Article description
 * @param {Function} [props.t] translation function from translation hook
 * @returns {JSX.Element} Returns Article react component
 */
export default function Article({ article, articleImage, title, description, t }) {
  const [animIn, setAnimIn] = useState(false);
  const { brand } = useContext(SiteContext);

  const articleImageSize = articleImage?.fields?.file?.details?.image;
  const articleImageSizes = {
    defaultSize: getImageSize(500, articleImageSize),
    fallbackSize: getImageSize(500, articleImageSize),
  };

  const [articleImgRef, inView] = useInView({
    rootMargin: '-100px 0px',
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setAnimIn(true);
    }
  }, [inView]);

  const renderSecondaryTypeButton = brand === 'go-solutions';

  const isRichTextField =
    typeof description === 'object' &&
    description !== null &&
    description.content &&
    Array.isArray(description.content);

  return (
    <GenericLink
      internalLink={article}
      className={styles.wrapper}
      ariaLabel={`${t('common.ctas.learnMoreAbout')} ${title}`}
      ref={articleImgRef}>
      <div className={cx(styles.imgContainer, { [styles.animIn]: animIn })}>
        {articleImage?.fields?.file?.url && (
          <Image
            alt={articleImage?.fields?.description}
            className={styles.articleImg}
            sizes="(max-width: 1023px) 100vw, (min-width: 1024px) 45vw"
            url={articleImage.fields.file.url}
            imageSizes={articleImageSizes}
            quality="60"
          />
        )}
      </div>
      <h3 className={styles['article-title']}>{title}</h3>
      {isRichTextField ? (
        <RichText content={description} className={cx(styles['article-subtitle'], 'p2')} />
      ) : (
        <p className={cx(styles['article-subtitle'], 'p2')}>{description}</p>
      )}
      <AccessibleButton
        tabIndex="-1"
        type={renderSecondaryTypeButton ? 'secondary' : 'primary'}
        theme="dark"
        className={styles['article-btn']}>
        {t('common.ctas.learnMore')}
      </AccessibleButton>
    </GenericLink>
  );
}
