import cx from 'classnames';

import Image from '../Image/Image';
import styles from './pendingResults.module.scss';

/**
 * @param {object} [props] component props
 * @param {string} [props.message] message for the pending results state
 * @param {object} [props.image] image for the pending results state
 * @param {boolean} [props.isDesktopHidden] flag to hide in desktop
 * @param {boolean} [props.isDesktopOnly] flag to show only in desktop
 * @param {string} [props.activeView] 'list' or 'map'
 * @returns {JSX.Element} react component
 */
export default function PendingResults({
  message,
  image,
  isDesktopHidden,
  isDesktopOnly,
  activeView,
}) {
  return (
    <div
      className={cx(styles.container, {
        [styles['desktop-hidden']]: isDesktopHidden,
        [styles['desktop-only']]: isDesktopOnly,
        [styles['map']]: activeView === 'map',
      })}>
      <div>
        {image.fields?.file && (
          <Image
            classNameImage={styles.image}
            wrapImage={false}
            url={image.fields?.file?.url}
            alt={image.fields?.description}
          />
        )}
        <p className={cx('p2', styles.message)}>{message}</p>
      </div>
    </div>
  );
}
