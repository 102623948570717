/* eslint-disable react/display-name */
import RichText from '../../Richtext/Richtext';
import { useContext } from 'react';
import SiteContext from '../../AppContext';
import styles from './blogPost.module.scss';
import BlogAuthors from '../../BlogAuthors/BlogAuthors';
import RelatedArticles from '../../RelatedArticles/RelatedArticles';
import Picture from '../../Image/Picture';
import VideoComponent from '../../VideoComponent/VideoComponent';
import cx from 'classnames';
import Head from 'next/head';
import { getArticleSchema } from '../../../lib/Schema';

/**
 *
 * @param {string} date string to be converted to date
 * @param {string} locale locale code to format date for
 * @returns {string} formatted date string
 */
function formatDate(date, locale) {
  return new Date(date).toLocaleDateString(locale.toLowerCase(), {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
}

/**
 * temporal blog post template
 * @param {object} [props] component props
 * @param {object} [props.fields] data fields from contentful
 * @returns {JSX.element} Blog Post react component template
 */
export default function BlogPost({ fields }) {
  const {
    articleHeroImage,
    heroVideoEmbedCode,
    heroVideoEmbedSource,
    title,
    tagLabels,
    footnoteList,
    authors,
    mainContent,
    publishDate,
    contentfulPublishDate,
  } = fields;
  const { locale } = useContext(SiteContext);
  const dateToShow = publishDate ?? contentfulPublishDate;
  const publishedAtDate = formatDate(dateToShow, locale);
  const hasHeaderVideo = heroVideoEmbedSource && heroVideoEmbedCode;
  const articleSchema = getArticleSchema(fields);
  const blogPostImageSizes = {
    lg: [878, 494],
    md: [652, 367],
    sm: [344, 194],
    fallbackSize: [344, 194],
  };

  return (
    <article className={styles.blogPost}>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(articleSchema) }}
        />
      </Head>
      <header className={styles.header}>
        <div className={styles.background}>
          <div className={styles.wrapper}>
            <p className={styles.date}>{publishedAtDate}</p>
            <h1 className={styles.title}>{title}</h1>
            {tagLabels && (
              <ul className={styles.tags}>
                {tagLabels.map((tag, index) => (
                  <li key={index} className={styles.tag}>
                    {tag}
                  </li>
                ))}
              </ul>
            )}
            <div className={styles.media}>
              {/* All media (images and videos) should have a 16:9 aspect ratio. */}
              {hasHeaderVideo && (
                <div className={styles.video}>
                  <VideoComponent source={heroVideoEmbedSource} code={heroVideoEmbedCode} />
                </div>
              )}
              {!hasHeaderVideo && articleHeroImage?.fields?.file && (
                <Picture
                  url={articleHeroImage.fields.file.url}
                  imageSizes={blogPostImageSizes}
                  quality="50"
                  alt={articleHeroImage?.fields?.description}
                  fit="fill"
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.filler}>
          <div></div>
        </div>
      </header>
      <RichText content={mainContent} className={styles.body} />
      {footnoteList && <RichText content={footnoteList} className={styles.footnotes} />}
      {authors && (
        <BlogAuthors className={cx({ [styles.authorsMargin]: !footnoteList })} authors={authors} />
      )}
      {fields.relatedContent && (
        <div className={styles.related}>
          <RelatedArticles {...fields.relatedContent} />
        </div>
      )}
    </article>
  );
}
