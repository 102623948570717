import styles from './ingredientCard.module.scss';
import SiteContext from '../AppContext';
import { useContext } from 'react';
import Image from '../Image/Image';
import RemoteIcon from '../RemoteIcon/remoteIcon';

import RichText from '../Richtext/Richtext';

/**
 * Ingredient Card component
 * @param {object} [props] component Props
 * @param {string} [props.image] image used in card
 * @param {object} [props.primaryText] name of the ingredient
 * @param {object} [props.secondaryText] description of the ingredient
 * @returns {JSX.Element} react component for the ingredient card
 */
export default function IngredientCard({ image, primaryText, secondaryText }) {
  const siteContext = useContext(SiteContext);
  const { brand } = siteContext;
  const imageWidth = brand === 'go-solutions' ? 48 : 136;
  const imageHeight = brand === 'go-solutions' ? 48 : 144;

  const ingredientCardImageSizes = {
    defaultSize: [imageWidth, imageHeight],
    fallbackSize: [imageWidth, imageHeight],
  };

  return (
    <div className={styles.container}>
      {image?.fields?.file &&
        (brand === 'now-fresh' ? (
          <Image
            classNameImage={styles.image}
            url={image.fields.file.url}
            alt={image.fields.description}
            imageSizes={ingredientCardImageSizes}
            quality="70"
            fit="pad"
            wrapImage={false}
          />
        ) : (
          <RemoteIcon url={image.fields.file.url} className={styles.image} />
        ))}
      <div className={styles.copyContainer}>
        <p className={styles.headline}>{primaryText}</p>
        {secondaryText && <RichText content={secondaryText} className={styles.description} />}
      </div>
    </div>
  );
}
