import Picture from '../Image/Picture';
import RemoteIcon from '../RemoteIcon/remoteIcon';
import { getImageSize } from '../../lib/imageUtils';
import { useContext } from 'react';
import SiteContext from '../AppContext';

import styles from './imageAndBenefits.module.scss';

// Benefits Card
/**
 * @param {object} [props] component props
 * @param {object} [props.fields] component data
 * @param {object} [props.fields.image] top image
 * @param {string} [props.fields.eyebrow] eyebrow
 * @param {string} [props.fields.primaryText] header
 * @returns {JSX.Element} react component for image and benefits
 */
const BenefitWidget = ({ fields: { image, eyebrow, primaryText } }) => {
  return (
    <div className={styles.benefits}>
      <RemoteIcon url={image.fields.file.url} className={styles.benefitsIcon} />
      <div className={styles.textContainer}>
        <p className={styles.benefitsEyebrow}>{eyebrow}</p>
        <p className={styles.benefitsText}>{primaryText}</p>
      </div>
    </div>
  );
};

// default component
/**
 * @param {object} [props] component props
 * @param {object} [props.fields] component data
 * @param {object} [props.fields.topImage] top image
 * @param {string} [props.fields.eyebrow] eyebrow
 * @param {string} [props.fields.header] header
 * @param {string} [props.fields.description] description
 * @param {Array<any>} [props.fields.benefits] benefits
 * @returns {JSX.Element} react component for image and benefits
 */
export default function ImageAndBenefits({
  fields: { topImage, eyebrow, header, description, benefits },
}) {
  const siteContext = useContext(SiteContext);
  const { brand } = siteContext;
  const topImageSizes = {
    xl: [424, 424],
    md: [350, 350],
    sm: [250, 250],
    fallbackSize: getImageSize(350, topImage?.fields?.file?.details?.image),
  };

  const petcureanImageSizes = {
    xl: [640, 640],
    md: [450, 450],
    sm: [350, 350],
    fallbackSize: getImageSize(350, topImage?.fields?.file?.details?.image),
  };

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <Picture
          className={styles.image}
          url={topImage.fields.file.url}
          quality="55"
          imageSizes={brand === 'petcurean' ? petcureanImageSizes : topImageSizes}
          alt={topImage.fields.description}
          loading="lazy"
        />
        <div className={styles.info}>
          <div>
            <p className={styles.eyebrow}>{eyebrow}</p>
            <h2 className={styles.header}>{header}</h2>
          </div>
          <p className={styles.description}>{description}</p>
        </div>
      </div>
      {Array.isArray(benefits) && benefits.length > 0 && (
        <div className={styles.bottom}>
          {benefits.map((component, index) => (
            <BenefitWidget {...component} key={index} />
          ))}
        </div>
      )}
    </div>
  );
}
