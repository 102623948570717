import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from 'react';
import cx from 'classnames';

import styles from './relatedProductsNow.module.scss';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import Picture from '../Image/Picture';
import Rating from '../Rating/Rating';
import GenericLink from '../GenericLink/GenericLink';
import StyledLink from '../StyledLink/StyledLink';
import { getImageSize } from '../../lib/imageUtils';
import { getInternalLinkFromString } from '../../lib/utils.js';
import useTranslation from '../../lib/hooks/useTranslation';

/**
 * @param {object} [props] component props
 * @param {object} [props.fields] related products data
 * @param {object} [props.fields.cta] cta object
 * @param {string} [props.fields.description] description text
 * @param {string} [props.fields.eyebrow] eyebrow text
 * @param {string} [props.fields.title] title text
 * @param {object} [props.fields.products] data for each product
 * @returns {JSX.Element} now fresh related products component
 */
export default function RelatedProductsNow({
  fields: { cta, description, eyebrow, title, products },
}) {
  const { t } = useTranslation();

  const [animIn, setAnimIn] = useState(false);

  const [relatedProductsImgRef, inView] = useInView({
    rootMargin: '-120px 0px',
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setAnimIn(true);
    }
  }, [inView]);

  return (
    <section className={styles.container}>
      <div className={styles.topWrapper}>
        <div>
          {eyebrow && <p className={styles.eyebrow}>{eyebrow}</p>}
          <h2 className={styles.title}>{title}</h2>
        </div>
        {description && <p className={styles.description}>{description}</p>}
        {cta?.fields && (
          <div className={styles['prod-cta']}>
            <AccessibleButton type="primary" theme="dark" link={cta}>
              {cta.fields.linkText}
            </AccessibleButton>
          </div>
        )}
      </div>
      {products?.map((product, index) => {
        const productLink = getInternalLinkFromString(
          product.fields?.slug ? product.fields.slug : '',
        );

        const productImageSize = product?.fields?.productImage?.fields?.file?.details?.image;
        const productImageSizes = {
          sm: getImageSize(898, productImageSize),
          lg: getImageSize(740, productImageSize),
          md: getImageSize(888, productImageSize),
          fallbackSize: getImageSize(898, productImageSize),
        };

        return (
          <GenericLink
            key={index}
            internalLink={productLink}
            className={cx(styles.productWrapper, {
              [styles.rightAlign]: index % 2 !== 0,
            })}
            ariaLabel={`${t('common.ctas.learnMoreAbout')} ${product.fields.productName}`}
            ref={relatedProductsImgRef}>
            <div
              className={cx(styles['img-container'], {
                [styles.animIn]: animIn,
              })}>
              {product.fields.productImage?.fields?.file && (
                <Picture
                  alt={product.fields.productImage.fields.description}
                  className={styles['prod-img']}
                  url={product.fields.productImage.fields.file.url}
                  imageSizes={productImageSizes}
                  quality="100"
                  loading="lazy"
                  fallbackFormat="jpg"
                />
              )}
            </div>
            <div className={styles.infoContainer}>
              <div className={styles['prod-rating']}>
                <Rating rating={product.fields.aggregate} className={styles.rating} />
              </div>
              <div className={styles['prod-eyebrow']}>
                <p>{product.fields.titleEyebrow}</p>
              </div>
              <div className={styles['prod-title']}>
                <h3>{product.fields.productName}</h3>
              </div>
              <div className={styles['prod-btn']}>
                <StyledLink
                  type="p2"
                  theme="dark"
                  className={styles['styled-link']}
                  tabIndex="-1"
                  noHref={true}>
                  {t('common.ctas.viewRecipe')}
                </StyledLink>
              </div>
            </div>
          </GenericLink>
        );
      })}
    </section>
  );
}
