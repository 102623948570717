import cx from 'classnames';
import styles from './productFilter.module.scss';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import Checkbox from '../Checkbox/Checkbox';
import { useRef } from 'react';
import { Caret } from '../Icons';

/**
 * @param {object} [props] component props
 * @param {string} [props.category] Category title
 * @param {number} [props.index] index from parent element
 * @param {boolean} [props.open] this will determine if the category is open
 * @param {object} [props.state] category landing component state
 * @param {Function} [props.dispatch] category landing dispatcher
 * @param {Array<string>} [props.tags] tags from category
 * @param {number} [props.totalSelected] total tags selected
 * @returns {JSX.Element} react component
 */
export default function FilterCategory({
  category,
  tags,
  index,
  open,
  state,
  dispatch,
  totalSelected,
}) {
  const categoryEl = useRef(null);
  const tagHandler = (e, value) => {
    window.scrollTo(0, state.gridElementY - 88);
    if (e.currentTarget.checked) {
      dispatch({ type: 'add-active-filter', filter: value });
    } else {
      dispatch({ type: 'remove-active-filter', filter: value });
    }
  };
  return (
    <div
      ref={categoryEl}
      className={cx(styles.categoryContainer, { [styles.open]: open })}
      style={{
        height: open && categoryEl ? categoryEl.current.scrollHeight : '66px',
      }}>
      <AccessibleButton
        className={styles.categoryTitle}
        ariaExpanded={open}
        onClick={() => {
          if (state.openCategories.has(index)) {
            dispatch({ type: 'close-category', category: index });
          } else {
            dispatch({ type: 'open-category', category: index });
          }
        }}>
        {`${category} ${totalSelected > 0 ? `(${totalSelected})` : ''}`}
        <Caret />
      </AccessibleButton>
      <ul className={styles.filters}>
        {tags.map((tag, key) => (
          <li key={`${key}-${index}`} className={styles.filter}>
            <Checkbox
              value={tag.value}
              checked={state.activeFilters.includes(tag.value)}
              disabled={tag.disabled}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.currentTarget.checked = !e.currentTarget.checked;
                  tagHandler(e, tag.value);
                }
              }}
              onChange={(e) => tagHandler(e, tag.value)}>
              {tag.value}
            </Checkbox>
          </li>
        ))}
      </ul>
    </div>
  );
}
