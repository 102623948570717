import Picture from '../../Image/Picture';
import StyledLink from '../../StyledLink/StyledLink';
import AccessibleButton from '../../AccessibleButton/AccessibleButton';
import styles from './inBlogPromotion.module.scss';
import cx from 'classnames';
import { useContext } from 'react';
import SiteContext from '../../AppContext';

/**
 * @param {object} [props] component props
 * @param {object} [props.image] component image
 * @param {string} [props.eyebrow] eyebrow
 * @param {string} [props.title] title
 * @param {string} [props.copy] copy
 * @param {object} [props.cta] component link
 * @returns {JSX.Element} react component
 */
export default function InBlogPromotion({ image, eyebrow, title, copy, cta }) {
  const siteContext = useContext(SiteContext);
  const { brand } = siteContext;

  // Condition to render situational cta component
  const renderStyledLink = brand === 'go-solutions' || brand === 'now-fresh';

  const inBlogPromotionImageSizes = {
    lg: [355, 355],
    md: [459, 459],
    sm: [320, 320],
    fallbackSize: [320, 320],
  };

  const inBlogPromotionImageFit = {
    gather: null,
    'go-solutions': 'fill',
    'now-fresh': 'fill',
    petcurean: null,
    summit: null,
  };

  const inBlogPromotionCTATheme = {
    gather: 'light',
    'go-solutions': 'dark',
    'now-fresh': 'light',
    petcurean: 'light',
    summit: 'light',
  };

  return (
    <div className={styles.container}>
      {image && (
        <div className={styles.imageContainer}>
          <Picture
            className={styles.image}
            url={image.fields.file.url}
            imageSizes={inBlogPromotionImageSizes}
            quality="80"
            alt={image?.fields?.description}
            fit={inBlogPromotionImageFit[brand]}
          />
        </div>
      )}
      <div
        className={cx(styles.backgroundShadow, { [styles.noImage]: typeof image === 'undefined' })}>
        <div className={cx(styles.background)}>
          <div className={styles.content}>
            <div className={styles.copyContainer}>
              <p className={styles.eyebrow}>{eyebrow}</p>
              <h2 className={styles.headline}>{title}</h2>
              <p className={styles.copy}>{copy}</p>
            </div>
            {(cta.fields?.internalPage || cta.fields?.linkAddress) &&
              (renderStyledLink ? (
                <StyledLink
                  href={
                    cta.fields.internalPage
                      ? cta.fields.internalPage?.fields?.slug
                      : cta.fields.linkAddress
                  }
                  type="primary"
                  className={styles.cta}
                  theme={inBlogPromotionCTATheme[brand]}>
                  {cta.fields.linkText}
                </StyledLink>
              ) : (
                <AccessibleButton link={cta} type="primary" theme="light" className={styles.cta}>
                  {cta.fields.linkText}
                </AccessibleButton>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
