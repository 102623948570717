import { Star } from '../Icons';
import styles from './rating.module.scss';
import cx from 'classnames';
import { roundClosestQuarter } from '../../lib/utils';

/**
 * This component renders a number to a star rating
 * @param {object} [props] component props
 * @param {number} [props.rating] rating to be displayed
 * @param {string} [props.className] extra class for component
 * @returns {JSX.Element} react component
 */
export default function Rating({ rating, className }) {
  const roundedRating = roundClosestQuarter(rating);
  const stars = Math.trunc(roundedRating);
  const decimal = roundedRating % 1;
  const starsArray = [];
  for (let i = 0; i < stars; i++) {
    starsArray.push(1);
  }
  starsArray.push(decimal);
  return (
    <div className={cx(styles.container, className)}>
      {starsArray
        .filter((starSize) => starSize > 0)
        .map((starSize, i) => (
          <span key={i}>
            <Star
              // The star's cropping is managed by the viewBox attribute:
              // https://css-tricks.com/scale-svg/#the-viewbox-attribute
              viewBox={`0 0 ${(starSize * 100 * 33) / 100} 32`}
            />
          </span>
        ))}
    </div>
  );
}
