import { useContext, useRef, useState } from 'react';
import StyledSelect from '../StyledSelect/StyledSelect';
import { GlobeLines } from '../Icons';
import useTranslation from '../../lib/hooks/useTranslation';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { getDomainBasedOnLocale } from '../../lib/utils';

import styles from './regionSelector.module.scss';
import SiteContext from '../AppContext';
import Modal from '../Modal/Modal';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

/**
 * Region Selector component
 * @param {object} [props] component props
 * @param {object} [props.id] dropdown id
 * @param {object} [props.currentLocale] current locale
 * @param {object} [props.locales] locales data object
 * @param {boolean} [props.showContainer] show the dropdown container
 * @param {boolean} [props.desktopOnly] only show on desktop
 * @returns {JSX.Element} Region Selector react component
 */
export default function RegionSelector({
  id = 'region-selector',
  currentLocale,
  desktopOnly = false,
  locales,
  showContainer = true,
}) {
  const { t } = useTranslation();
  const { localeMap } = useContext(SiteContext);
  const modalEl = useRef();
  const regionSelectorEl = useRef();
  const options = [];
  const optionObjects = [];
  let currentLocaleIndex = 0;
  const revertLocale = () => {
    const currentLocaleObject = locales.items.find(
      (locale) => locale.code.toLowerCase() === currentLocale.toLowerCase(),
    );
    setSelectedRegion(currentLocaleObject.name);
  };

  if (locales?.items) {
    for (let i = 0; i < locales.items.length; i++) {
      if (locales.items[i].code.toLowerCase() === currentLocale.toLowerCase()) {
        currentLocaleIndex = i;
      }

      options.push(locales.items[i].name);
      optionObjects.push({
        value: locales.items[i].code,
        key: locales.items[i].name,
      });
    }
  }

  const [selectedRegion, setSelectedRegion] = useState(
    options.length ? options[currentLocaleIndex] : '',
  );

  const [locale, setlocale] = useState('');

  options.sort((a, b) => {
    return a.localeCompare(b);
  });

  const handleChange = (e) => {
    const selectedOption = e.currentTarget?.value;
    const locale = optionObjects.find((option) => option.key === selectedOption);
    const domain = getDomainBasedOnLocale(locale.value);
    if (localeMap[locale.value]) {
      window.location.href = `${domain}/${locale.value}/${localeMap[locale.value]}`;
    } else if (!isEmpty(localeMap)) {
      modalEl.current.showModal();
    } else {
      window.location.href = `${domain}/${locale.value}`;
    }
    setlocale(locale.value);
    setSelectedRegion(selectedOption);
  };

  return (
    <nav
      ref={regionSelectorEl}
      aria-label={`${t('common.navigation.regionSelectorLabel')}`}
      className={cx(styles.container, {
        [styles.showContainer]: showContainer,
        [styles.desktopOnly]: desktopOnly,
      })}>
      {options.length && (
        <div className={styles.contentWrapper}>
          <div className={styles.dropdownWrapper}>
            <StyledSelect
              id={id}
              name={id}
              className={styles.dropdown}
              ariaLabel={`${t('common.navigation.selectYourRegion')}`}
              Icon={GlobeLines}
              isMinimalLayout={true}
              onChange={handleChange}
              options={options}
              showEmptyOption={false}
              value={selectedRegion}
              key={id}
            />
          </div>
        </div>
      )}
      <Modal
        ref={modalEl}
        opener={regionSelectorEl}
        fullSize={false}
        theme="light"
        closeAction={() => {
          revertLocale();
        }}>
        <ConfirmationModal
          action={() => (window.location.href = `${getDomainBasedOnLocale(locale)}/${locale}`)}
          cancelAction={() => {
            revertLocale();
            modalEl.current.close();
          }}
        />
      </Modal>
    </nav>
  );
}
