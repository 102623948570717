import { useContext } from 'react';
import SiteContext from '../AppContext';

import styles from './homeProductsComponent.module.scss';
import HomeProductCategory from './HomeProductCategory';

/**
 * @param {object} [props] component props
 * @param {object} [props.catCategoryImage] cat image object
 * @param {string} [props.catCategoryEyebrow] cat eyebrow text
 * @param {Array<any>} [props.catCategoryList] list of cat categories
 * @param {object} [props.dogCategoryImage] dog image object
 * @param {string} [props.dogCategoryEyebrow] dog eyebrow text
 * @param {Array<any>} [props.dogCategoryList] list of dog categories
 * @param {string} [props.showDogCategoryList] whether or not to show the dog list
 * @param {string} [props.showCatCategoryList] whether or not to show the cat list
 * @returns {JSX.Element} react component for homepage products list
 */
export default function HomeProductList({
  catCategoryImage,
  catCategoryEyebrow,
  catCategoryList,
  dogCategoryImage,
  dogCategoryEyebrow,
  dogCategoryList,
  showCatCategoryList,
  showDogCategoryList,
}) {
  const { brand } = useContext(SiteContext);
  const isNowFresh = brand === 'now-fresh';

  return (
    <section className={styles.container}>
      {showDogCategoryList && (
        <HomeProductCategory
          align={isNowFresh ? 'right' : 'left'}
          image={dogCategoryImage}
          eyebrow={dogCategoryEyebrow}
          productList={dogCategoryList}
        />
      )}
      {showCatCategoryList && (
        <HomeProductCategory
          align={isNowFresh ? 'left' : 'right'}
          image={catCategoryImage}
          eyebrow={catCategoryEyebrow}
          productList={catCategoryList}
        />
      )}
    </section>
  );
}
