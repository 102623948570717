/**
 * @param {object} [props] component props
 * @param {string} [props.text] text
 * @param {string} [props.style] script style
 * @returns {JSX.Element} react component
 */
export default function InBlogStyledText({ text, style }) {
  const textStyles = {
    superscript: <sup>{text}</sup>,
    subscript: <sub>{text}</sub>,
  };

  return <>{textStyles[style]}</>;
}
