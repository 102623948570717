import AccessibleButton from '../AccessibleButton/AccessibleButton';
import { useTranslation } from '../../lib/hooks';
import styles from './reviewCard.module.scss';
import { submitReviewFeedback } from '../../lib/reviews/bazaarvoice';
import { useState, useEffect } from 'react';

/**
 *
 * @param {props} [props] component props
 * @param {object} [props.Helpfulness] helpfulness stats from Bazaarvoice
 * @param {string} [props.reviewId] review id in Bazaarvoice
 * @param {string} [props.brand] brand slug
 * @returns {JSX.Element} Review Feedback react component
 */
export default function ReviewFeedback({
  TotalNegativeFeedbackCount,
  TotalPositiveFeedbackCount,
  reviewId,
  brand,
}) {
  const { t } = useTranslation();
  const [feedbackSubmitted, setFeedbackSubmitted] = useState('');

  const handleVote = (reviewId, vote) => {
    submitReviewFeedback(reviewId, vote);
    setFeedbackSubmitted(vote);
  };

  useEffect(() => {
    const storedValue = JSON.parse(localStorage.getItem('reviewFeedback')) || {};
    if (storedValue[reviewId]) setFeedbackSubmitted(storedValue[reviewId]);
  }, [reviewId]);

  return (
    <div className={styles.helpfulContainer}>
      <p>{t('review.helpfulPrompt')}</p>
      <div className={styles.buttonWrapper}>
        <AccessibleButton
          type="primary"
          theme={brand === 'gather' ? 'dark' : null}
          className={styles.helpfulPositive}
          onClick={() => handleVote(reviewId, 'Positive')}
          disabled={!!feedbackSubmitted}
          icon={feedbackSubmitted === 'Positive' ? 'Thumbup' : null}>
          {t('review.helpfulPositive')} |{' '}
          {feedbackSubmitted === 'Positive'
            ? TotalPositiveFeedbackCount + 1
            : TotalPositiveFeedbackCount}
        </AccessibleButton>
        <AccessibleButton
          type="secondary"
          theme={brand === 'gather' ? 'dark' : null}
          className={styles.helpfulNegative}
          onClick={() => handleVote(reviewId, 'Negative')}
          disabled={!!feedbackSubmitted}
          icon={feedbackSubmitted === 'Negative' ? 'Thumbdown' : null}>
          {t('review.helpfulNegative')} |{' '}
          {feedbackSubmitted === 'Negative'
            ? TotalNegativeFeedbackCount + 1
            : TotalNegativeFeedbackCount}
        </AccessibleButton>
      </div>
    </div>
  );
}
