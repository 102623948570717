export const colors = {
  gather: {
    backgroundCream: 'rgb:fffced',
  },
  goSolutions: {
    backgroundWhite: 'rgb:ffffff',
    backgroundBlack: 'rgb:000000',
  },
  nowFresh: {
    backgroundTan: 'rgb:f6f3df',
  },
  petcurean: {
    backgroundWhite: 'rgb:fbfbfb',
  },
  summit: {
    backgroundWhite: 'rgb:f7efe6',
  },
};

export const checkboxGroupDivider = ',';
