/**
 * Initializes the PriceSpider script tag for embedding widgets, should go in a useEffect.
 * @param {string} locale String of the current locale
 * @returns {Function} A cleanup function to remove the script tag on component unmount.
 */
export function initializePriceSpiderScriptTag(locale) {
  const psAccountNumber = process.env.NEXT_PUBLIC_PRICESPIDER_ACCOUNT_NUMBER;

  // Split locale into language and country, eg: en-us, fr-ca
  const [language, country] = locale.toLowerCase().split('-');

  const script = document.createElement('script');
  script.src = '//cdn.pricespider.com/1/lib/ps-widget.js';
  script.async = true;
  script.defer = true;
  script.setAttribute('ps-account', psAccountNumber);
  script.setAttribute('ps-country', (country || 'US').toUpperCase());
  script.setAttribute('ps-language', language || 'en');

  document.head.appendChild(script);

  return () => {
    // Cleanup script on component unmount
    document.head.removeChild(script);
  };
}
