import ProductGrid from '../../ProductGrid/ProductGrid';
import ProductFilters from '../../ProductGrid/ProductFilters';
import PLPHero from '../../PLPHero/PLPHero';
import { getFiltersFromProducts } from '../../../lib/productUtils';
import styles from './categoryLanding.module.scss';
import { useEffect, useReducer, useRef, useContext, useCallback } from 'react';
import CategoryLandingReducer from './CategoryLandingReducer';
import cx from 'classnames';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import SiteContext from '../../AppContext';
import PCPHero from '../../PCPHero/PCPHero';

const initialState = {
  products: [],
  activeFilters: [],
  filters: [],
  filteredProducts: [],
  enabledFilters: [],
  openCategories: new Set(),
  filterElement: null,
  showCategories: true,
};

/**
 * React component for Category Landing page
 * @param {object} [props] component props
 * @param {object} [props.fields] props coming form contentful
 * @returns {JSX.Element} Returns Category Landing react component
 */
export default function CategoryLanding({ fields }) {
  const [state, dispatch] = useReducer(CategoryLandingReducer, initialState);
  const siteContext = useContext(SiteContext);
  const filtersEl = useRef(null);
  const gridEl = useRef(null);
  const categoryLandingEl = useRef(null);

  const initState = useCallback(() => {
    const availableProducts = fields.products
      ? fields.products.filter(
          (product) =>
            product.fields?.availability === 'cat2' || product.fields?.availability === 'cat3',
        )
      : [];

    clearAllBodyScrollLocks();

    dispatch({
      type: 'init-state',
      products: availableProducts,
      filteredProducts: availableProducts,
      filters: getFiltersFromProducts(availableProducts),
      filtersStuck: filtersEl?.current?.getBoundingClientRect().y <= 88,
      activeFilters: [],
      filtersOpen: false,
      openCategories: new Set(),
      gridElementY:
        window.innerWidth >= 1024
          ? gridEl?.current?.getBoundingClientRect().y + window.scrollY
          : gridEl?.current?.getBoundingClientRect().y + window.scrollY - 70,
      showCategories: true,
    });
  }, [fields.products]);

  useEffect(() => {
    initState();
  }, [initState]);

  useEffect(() => {
    const largeBreakpointSubscription = siteContext.largeBreakpointChange$.subscribe(() => {
      initState();
    });
    const escPressedSubscription = siteContext.escPressed$.subscribe(() => {
      clearAllBodyScrollLocks();
      dispatch({ type: 'close-filters' });
    });
    return () => {
      largeBreakpointSubscription.unsubscribe();
      escPressedSubscription.unsubscribe();
    };
  }, [siteContext, initState]);

  useEffect(() => {
    fromEvent(document, 'click')
      .pipe(filter((e) => e.target === categoryLandingEl.current))
      .subscribe(() => {
        clearAllBodyScrollLocks();
        dispatch({ type: 'close-filters' });
      });

    // this observable is to determine when the sticky section should be fixed on the viewport
    // in short words it will only emit when the scroll position passes or goes back
    // through the sticky section.
    const scrollSubscription = fromEvent(window, 'scroll')
      .pipe(
        map(() => filtersEl && filtersEl?.current?.getBoundingClientRect().y <= 88),
        distinctUntilChanged(),
      )
      .subscribe((afterThreshold) => {
        dispatch({ type: 'set-filters-stuck', filtersStuck: afterThreshold });
      });

    return () => {
      scrollSubscription.unsubscribe();
    };
  }, [dispatch, siteContext]);

  return (
    <section
      className={cx(styles.container, { [styles.filtersOpen]: state.filtersOpen })}
      ref={categoryLandingEl}>
      {fields.headerType ? (
        <PLPHero title={fields.title} headerImage={fields.headerImage} />
      ) : (
        <PCPHero
          title={fields.title}
          categoryDescription={fields.categoryDescription}
          bulletPoints={fields.bulletPoints}
          eyebrow={fields.eyebrow}
          headerImage={fields.headerImage}
        />
      )}
      <div className={styles.content}>
        {fields.showFilters && (
          <ProductFilters
            state={state}
            dispatch={dispatch}
            ref={filtersEl}
            className={styles.filters}
          />
        )}
        <ProductGrid
          products={state.filteredProducts}
          showCategories={fields.showCategories}
          className={cx(styles.products, { [styles.fullWidthGrid]: !fields.showFilters })}
          numFilters={state.activeFilters.length}
          showFilters={fields.showFilters}
          ref={gridEl}
        />
      </div>
    </section>
  );
}
