import useSWRInfinite from 'swr/infinite';
import useSWR from 'swr';
import { sleep } from '../utils';
import * as Sentry from '@sentry/nextjs';

const BV_READ_KEY = process.env.NEXT_PUBLIC_BV_READONLY_KEY || '';
const BV_WRITE_KEY = process.env.NEXT_PUBLIC_BV_WRITE_KEY || '';
const BV_ENVIRONMENT = process.env.NEXT_PUBLIC_BV_ENVIRONMENT || 'stg.api.bazaarvoice.com';
const BV_API_VER = process.env.NEXT_PUBLIC_BV_API_VERSION || '5.4';
const MAX_RETRIES = 5;

/**
 *
 * @param {string} url url to fetch data from
 * @param {number} retries number of times to retry a failed request
 * @returns {JSON} response from api
 */
export async function fetcherFunc(url, retries = MAX_RETRIES) {
  try {
    const res = await fetch(url);
    let error = null;

    if (res.ok) {
      const formattedRes = await res.json();
      if (!formattedRes.HasErrors) {
        return formattedRes;
      }
      error = JSON.stringify(formattedRes.Errors);
    }

    if (retries > 0) {
      const timeToSleep = (Math.floor(Math.random() * 3) + 1) * 1000;
      await sleep(timeToSleep);
      return fetcherFunc(url, retries - 1);
    }

    throw new Error(`Bazaarvoice request failed with status ${res.status} and error ${error}`);
  } catch (error) {
    Sentry.captureException(error);
  }
}

/**
 * Hook to build url and wrap useSWR hook to query Bazaarvoice
 * for reviews on a specific product
 * @param {object} [options] options object
 * @param {string} [options.id] product id
 * @param {Array} [options.filters] filters
 * @param {Array} [options.sort] sort method
 * @param {number} [options.limit] how many reviews to paginate at a time
 * @param {string} [options.locale] the locale for displaying labels, config, product attributes
 * @returns {object}  https://swr.vercel.app/
 */
export function useBazaarvoiceReviews({ id, filters, sort, limit = 3, locale }) {
  // eslint-disable-next-line max-len
  const url = `https://${BV_ENVIRONMENT}/data/reviews.json`;
  const queryStrings = [];
  queryStrings.push(`passKey=${BV_READ_KEY}`);
  queryStrings.push(`apiVersion=${BV_API_VER}`);
  queryStrings.push(`include=Products`);
  queryStrings.push(`stats=Reviews`);
  queryStrings.push(`locale=${formatLocale(locale)}`);
  queryStrings.push(`filter=productId:${id}`);
  if (sort) queryStrings.push(`sort=${sort}`);
  if (filters.size > 0) {
    queryStrings.push(`filter=Rating:eq:${encodeURIComponent([...filters].join())}`);
  }

  const query = url + '?' + queryStrings.join('&');

  const { data, error, mutate, size, setSize, isValidating } = useSWRInfinite(
    (index) => {
      const offset = index * limit;
      return `${query}&limit=${limit}&offset=${offset}`;
    },
    fetcherFunc,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isEmpty = data?.[0]?.['Results']?.length === 0;
  const isReachingEnd = isEmpty || (data && data[data.length - 1]?.['Results']?.length < limit);
  return {
    data,
    error,
    mutate,
    size,
    setSize,
    isValidating,
    isLoadingInitialData,
    isLoadingMore,
    isReachingEnd,
  };
}

/**
 * Hook to build url and wrap useSWR hook to query Bazaarvoice
 * for product statistics
 * @param {string} id product id in Bazaarvoice
 * @returns {object} review statistics from Bazaarvoice
 */
export function useBazaarvoiceStats(id) {
  // eslint-disable-next-line max-len
  const url = `https://${BV_ENVIRONMENT}/data/statistics.json`;
  const queryStrings = [];
  queryStrings.push(`apiVersion=${BV_API_VER}`);
  queryStrings.push(`passKey=${BV_READ_KEY}`);
  queryStrings.push(`filter=productid:${id}`);
  queryStrings.push(`stats=Reviews`);

  const query = url + '?' + queryStrings.join('&');
  const { data, error } = useSWR(query, fetcherFunc);
  return { data, error };
}

/**
 * API call to return review data from Bazaarvoice
 * @param {string} productId product id in Bazaarvoice
 * @param {string} locale the locale for displaying labels, config, product attributes
 * @returns {object} response from Bazaarvoice API
 */
async function getBazaarvoiceData(productId, locale) {
  const url = `https://${BV_ENVIRONMENT}/data/statistics.json`;
  const queryStrings = [];
  queryStrings.push(`apiVersion=${BV_API_VER}`);
  queryStrings.push(`passKey=${BV_READ_KEY}`);
  queryStrings.push(`filter=productid:${productId}`);
  queryStrings.push(`stats=Reviews`);
  queryStrings.push(`locale=${formatLocale(locale)}`);

  const query = url + '?' + queryStrings.join('&');
  const res = await fetcherFunc(query);
  return res?.['Results']?.[0]?.['ProductStatistics']?.['ReviewStatistics'] || null;
}

/**
 * Function to query the relevant Bazaarvoice data based on page template
 * @param {string} template corresponds to entry templates in Contentful
 * @param {object} data page data pulled from Contentful
 * @param {locale} locale locale of page being built
 */
export async function resolveBazaarvoiceQueries(template, data, locale) {
  switch (template) {
    case 'categoryLanding':
      if (data.fields.products) {
        for (let i = 0; i < data.fields.products.length; i += 1) {
          const product = data.fields.products[i];
          const bvId = product.fields.externalIDs?.websiteReviewsId;
          if (bvId) {
            product.fields.bazaarvoiceStats = await getBazaarvoiceData(bvId, locale);
          }
        }
      }
      break;
    case 'product':
      {
        const bvId = data.fields.externalIDs?.websiteReviewsId;
        if (bvId) {
          data.fields.bazaarvoiceStats = await getBazaarvoiceData(bvId, locale);
        }
        if (data.fields.relatedProducts?.fields?.products) {
          for (let i = 0; i < data.fields.relatedProducts.fields.products.length; i += 1) {
            const product = data.fields.relatedProducts.fields.products[i];
            const bvId = product.fields.externalIDs?.websiteReviewsId;
            if (bvId) {
              product.fields.bazaarvoiceStats = await getBazaarvoiceData(bvId, locale);
            }
          }
        }
      }
      break;
    case 'freeFormTemplate':
      if (data.fields.components) {
        for (let i = 0; i < data.fields.components.length; i += 1) {
          const component = data.fields.components[i];
          if (component === 'relatedProducts') {
            for (let j = 0; j < component.fields.products.length; j += 1) {
              const product = component.fields.products[j];
              const bvId = product.fields.externalIDs?.websiteReviewsId;
              if (bvId) {
                product.fields.bazaarvoiceStats = await getBazaarvoiceData(bvId, locale);
              }
            }
          }
        }
      }
      break;
    case 'homePage':
      if (data.fields.promotedDogProducts?.length > 0) {
        for (let i = 0; i < data.fields.promotedDogProducts.length; i += 1) {
          const product = data.fields.promotedDogProducts[i];
          const bvId = product.fields.externalIDs?.websiteReviewsId;
          if (bvId) {
            product.fields.bazaarvoiceStats = await getBazaarvoiceData(bvId, locale);
          }
        }
      }
      if (data.fields.promotedCatProducts?.length > 0) {
        for (let i = 0; i < data.fields.promotedCatProducts.length; i += 1) {
          const product = data.fields.promotedCatProducts[i];
          const bvId = product.fields.externalIDs?.websiteReviewsId;
          if (bvId) {
            product.fields.bazaarvoiceStats = await getBazaarvoiceData(bvId, locale);
          }
        }
      }
  }
}

/**
 * Function to format a local to use with Bazaarvoice
 * @param {string} locale locale code in Contentful format
 * @returns {string} locale code in Bazaarvoice format
 */
function formatLocale(locale) {
  // BV does not have a corresponding 'en' locale so we'll just use 'en_US'
  if (locale === 'en') {
    return 'en_US';
  }
  const splitLocale = locale.split('-');
  return `${splitLocale[0].toLowerCase()}_${splitLocale[1].toUpperCase()}`;
}

/**
 * Upload a file to Bv with progress updates
 * @param {object} [file] image to be uploaded
 * @param {string} [locale] locale of the submission
 * @returns {JSON} response from file upload api
 */
export async function uploadImg(file, locale) {
  // eslint-disable-next-line max-len
  const url = `https://${BV_ENVIRONMENT}/data/uploadphoto.json`;
  const queryStrings = [];
  queryStrings.push(`apiVersion=${BV_API_VER}`);
  queryStrings.push(`passKey=${BV_WRITE_KEY}`);
  queryStrings.push(`contentType=review`);
  queryStrings.push(`locale=${formatLocale(locale)}`);

  const query = url + '?' + queryStrings.join('&');

  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', query);

    xhr.onload = () => {
      const resp = JSON.parse(xhr.responseText);
      res(resp);
    };
    xhr.onerror = (evt) => rej(evt);

    const formData = new FormData();
    formData.append('photo', file);
    xhr.send(formData);
  });
}

/**
 * Record BV Review Feedback
 * We need to save feedback to localstorage to stop a user from
 * voting on the same review multiple times
 * @param {string} [reviewId] the id of the review in Bazaarvoice
 * @param {string} [vote] can be negative or positive feedback
 */
function recordReivewFeedback(reviewId, vote) {
  const localStorageKey = 'reviewFeedback';
  const storedValue = JSON.parse(localStorage.getItem(localStorageKey)) || {};
  const newValue = Object.assign({}, { [reviewId]: vote }, storedValue);
  localStorage.setItem(localStorageKey, JSON.stringify(newValue));
}

/**
 * Submit Review Feedback
 * @param {string} [reviewId] the id of the review in Bazaarvoice
 * @param {string} [vote] can be negative or positive feedback
 */
export function submitReviewFeedback(reviewId, vote) {
  const headers = new Headers();
  headers.append('Content-Type', 'application/x-www-form-urlencoded');

  const urlencoded = new URLSearchParams();
  urlencoded.append('passKey', BV_WRITE_KEY);
  urlencoded.append('apiVersion', BV_API_VER);
  urlencoded.append('contentType', 'review');
  urlencoded.append('contentId', reviewId);
  urlencoded.append('vote', vote);
  urlencoded.append('feedbackType', 'helpfulness');

  const requestOptions = {
    method: 'POST',
    headers: headers,
    body: urlencoded,
    redirect: 'follow',
  };

  fetch(`https://${BV_ENVIRONMENT}/data/submitfeedback.json?`, requestOptions)
    .then((response) => response.json())
    .then(recordReivewFeedback(reviewId, vote))
    .catch((error) => console.log('error', error));
}
