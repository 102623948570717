import AccessibleButton from '../AccessibleButton/AccessibleButton';
import cx from 'classnames';
import Link from 'next/link';
import PrimaryNavLink from './PrimaryNavLink';
import PrimaryNavMenu from './PrimaryNavMenu';
import { useContext, useEffect, useReducer, useRef } from 'react';
import { Close, Hamburger } from '../Icons';
import navReducer from './NavReducer';
import RegionSelector from './RegionSelector';
import primaryNavStyles from './primaryNavMenu.module.scss';
import Image from '../Image/Image';
import { useTranslation } from '../../lib/hooks';
import SiteContext from '../AppContext';
import styles from './navigation.module.scss';

const initialNavState = {
  activeNav: null,
  activeSubNav: null,
  activeSmallSubNav: null,
  mobileNavOpen: false,
  subNavOpen: false,
  animated: true,
  scrollableElement: null,
  navHeight: 0,
};

/**
 * Navigation component
 * @param {object} [props] component props
 * @param {JSX} [props.Logo] brand logo
 * @param {object} [props.content] primary nav content
 * @param {string} [props.locale] the current locale
 * @param {object} [props.localesData] object of available locales
 * @returns {JSX.Element} Navigation react component
 */
export default function Navigation({ Logo, content, locale, localesData }) {
  const [state, dispatch] = useReducer(navReducer, initialNavState);
  const siteContext = useContext(SiteContext);
  const { brand } = siteContext;
  const { t } = useTranslation();
  const navItemsEl = useRef(null);
  const navEl = useRef(null);
  const mobileImageUrl = content.items[0].fields.mobileBackgroundImage
    ? content.items[0].fields.mobileBackgroundImage?.fields?.file?.url
    : '';
  const whereToBuyCta = content.items[0].fields.whereToBuyCta ?? null;
  const whereToBuyThemes = {
    gather: 'light',
    'go-solutions': 'dark',
    'now-fresh': 'dark',
    petcurean: 'light',
    summit: 'light',
  };

  useEffect(() => {
    const escapeSubscription = siteContext.escPressed$.subscribe(() => {
      dispatch({ type: 'reset-state' });
    });

    document.addEventListener('click', (e) => {
      if (e.target === navEl.current) {
        dispatch({ type: 'close-nav' });
      }
    });
    const largeBreakpointSubs = siteContext.largeBreakpointChange$?.subscribe(() => {
      dispatch({ type: 'reset-state' });
    });
    dispatch({ type: 'set-scrollable-element', scrollableElement: navItemsEl.current });

    return () => {
      escapeSubscription.unsubscribe();
      largeBreakpointSubs.unsubscribe();
      dispatch({ type: 'reset-state' });
    };
  }, [siteContext]);

  const showLocalesSelector = localesData && content.items[0]?.fields?.localeSelectorOn;

  const primaryNav = content.items[0].fields.primaryNavigationMenuItems?.map((navItem, i) => {
    return (
      <li className={cx(styles.navItem, { active: i === state.activeNav })} key={i}>
        {navItem.fields.linkText ? (
          <PrimaryNavLink navItem={navItem} />
        ) : (
          <PrimaryNavMenu
            active={i === state.activeNav}
            {...navItem.fields}
            state={state}
            dispatch={dispatch}
            onClick={(e) => {
              const background = e.currentTarget.nextElementSibling;
              const navHeight = background.querySelector(
                `.${primaryNavStyles['subnav-wrapper']}`,
              ).clientHeight;
              if (state.activeNav === i) {
                dispatch({ type: 'close-nav' });
                return;
              }
              dispatch({ type: 'close-sub-nav' });
              dispatch({ type: 'open-nav', navIndex: i, navHeight });
            }}
          />
        )}
      </li>
    );
  });

  const renderHamburgerNav = content.items[0].fields.primaryNavigationMenuItems?.length > 0;
  const russiaHomePage = `/${locale.toLowerCase()}/ofitsial-noyezayavleniye`;
  const isRussiaSite =
    locale.toLowerCase() === 'ru-ru' && (brand === 'go-solutions' || brand === 'now-fresh');
  const homePageRoute = isRussiaSite ? russiaHomePage : `/${locale.toLowerCase()}`;

  return (
    <>
      {showLocalesSelector && (
        <RegionSelector
          id="region-selector-desktop"
          currentLocale={locale}
          locales={localesData}
          desktopOnly={true}
        />
      )}
      <nav
        id="nav"
        aria-label={`${t('common.navigation.primaryNavLabel')}`}
        ref={navEl}
        className={cx(styles.background, styles.navbar, {
          [styles.moveNav]: state.activeSmallSubNav !== null,
          [styles.overlay]: state.activeNav !== null,
        })}>
        <div className={cx(styles.container)}>
          <Link href={homePageRoute} className={styles.logoLink}>
            <span className={styles.logoLinkWrapper} tabIndex={-1}>
              <Logo className={styles.logo} title="Return to homepage" />
            </span>
          </Link>
          <ul
            className={cx(styles.navItemsLarge, {
              'sub-nav-open': state.subNavOpen,
              [styles.navItemsLargeCenter]: !whereToBuyCta,
            })}>
            {primaryNav}
          </ul>
          {whereToBuyCta && (
            <AccessibleButton
              className={cx(styles.buyCta)}
              type={brand === 'go-solutions' ? 'secondary' : 'primary'}
              theme={whereToBuyThemes[brand]}
              link={whereToBuyCta}>
              {t('common.navigation.whereToBuy')}
            </AccessibleButton>
          )}
          {renderHamburgerNav && (
            <AccessibleButton
              className={styles.hamburger}
              ariaLabel="open menu"
              onClick={() => {
                if (navItemsEl.current) {
                  // Set height to innerHeight - nav height (88)
                  navItemsEl.current.style.height = !state.mobileNavOpen
                    ? `${window.innerHeight - 88}px`
                    : '';
                }

                if (state.mobileNavOpen) {
                  dispatch({ type: 'close-mobile-nav' });
                  return;
                }
                dispatch({ type: 'open-mobile-nav' });
              }}>
              {state.mobileNavOpen ? <Close width="24" /> : <Hamburger />}
            </AccessibleButton>
          )}
          <div
            className={cx(styles.navItemsContainer, {
              [styles.navOpen]: state.mobileNavOpen && renderHamburgerNav,
            })}
            role="navigation">
            <ul
              ref={navItemsEl}
              className={cx(styles.navItems, {
                [styles.overflowVisible]: state.activeSmallSubNav !== null,
              })}>
              {primaryNav}
              {showLocalesSelector && (
                <li className={styles.mobileRegionSelector}>
                  <RegionSelector
                    id="region-selector-mobile"
                    currentLocale={locale}
                    locales={localesData}
                    showContainer={false}
                  />
                </li>
              )}
            </ul>
            {mobileImageUrl && (
              <Image
                className={styles.backgroundImage}
                url={mobileImageUrl}
                imageSizes={{ defaultSize: [296, 461], fallbackSize: [296, 461] }}
                alt=""
                quality="50"
              />
            )}
          </div>
        </div>
      </nav>
    </>
  );
}
