import cx from 'classnames';

import styles from './inBlogBulletedList.module.scss';

/**
 * inline component for a bulleted list
 * @param {object} [props] component props
 * @param {string} [props.headline] the title part of the component
 * @param {Array} [props.listItems] the lines of the bulleted list.
 * @param {boolean} [props.numberedList] flag to render as an ordered list
 * @returns {JSX.Element} react component
 */
export default function InBlogBulletedList({ headline, listItems, numberedList }) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={cx('h4', styles.headline)}>{headline}</h2>
        {numberedList ? (
          <ol className={styles.list}>
            {listItems.map((listItem, index) => (
              <li key={index}>
                <span className={styles.item}>{listItem}</span>
              </li>
            ))}
          </ol>
        ) : (
          <ul className={styles.list}>
            {listItems.map((listItem, index) => (
              <li key={index}>
                <span className={styles.item}>{listItem}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
