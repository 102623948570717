import { useEffect, useRef, useState } from 'react';
import { fromEvent, interval } from 'rxjs';
import { debounce, map } from 'rxjs/operators';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import styles from './expandableElement.module.scss';
import cx from 'classnames';
import { useTranslation } from '../../lib/hooks';

/**
 * This component should be used when a view more text needs to be used
 * this component adds the view more that basically wraps it's children into a element that
 * will increase it's height according to the component state
 * @param {object} [props] component props
 * @param {JSX.Element} [props.children] element to be wrapped
 * @param {string} [props.className] classes to be used in the container component
 * @param {boolean} [props.initialize] this property it is used to re initialize the
 * component when needed
 * @param {number} [props.collapsedHeight] height of the component in collapsed state
 * @param {string} [props.viewMoreI18nKey] i18n key to be used in the view more CTA
 * @param {string} [props.viewLessI18nKey] i18n key to be used in the view less CTA
 * @param {boolean} [props.invisible] make content visible hidden when collapsed
 * @param {number} [props.fromViewportWidth] parameter to be used when start collapsing
 * on specific viewport width
 * @param {string} [props.viewMoreAriaLabel] Text to be used for aria label in view more button
 * @param {string} [props.viewLessAriaLabel] Text to be used for aria label in view less nutton
 * @param {string} [props.viewMoreTheme] theme for expandable CTA
 * @returns {JSX.Element} expandable react component
 */
export default function ExpandableElement({
  children,
  className,
  initialize,
  collapsedHeight = 120,
  fromViewportWidth = 9999,
  viewMoreI18nKey = 'table.viewMore',
  viewLessI18nKey = 'table.viewLess',
  viewMoreAriaLabel,
  viewLessAriaLabel,
  invisible = false,
  viewMoreTheme = 'dark',
}) {
  const { t } = useTranslation();
  if (viewMoreAriaLabel === '') {
    viewMoreAriaLabel = t('table.viewMore');
  }
  if (viewLessAriaLabel === '') {
    viewLessAriaLabel = t('table.viewLess');
  }
  const expandableEl = useRef(null);
  const [collapsed, setCollapsed] = useState(false);
  const [showViewAll, setViewAll] = useState(false);
  useEffect(() => {
    const resizeSubscription = fromEvent(window, 'resize')
      .pipe(
        debounce(() => interval(250)),
        map(() => expandableEl.current.scrollHeight),
      )
      .subscribe((scrollHeight) => {
        if (scrollHeight > collapsedHeight && window.innerWidth < fromViewportWidth) {
          setViewAll(true);
        } else {
          setViewAll(false);
          setCollapsed(false);
        }
      });

    return () => {
      resizeSubscription.unsubscribe();
    };
  }, [collapsed, collapsedHeight, fromViewportWidth]);

  useEffect(() => {
    if (
      expandableEl.current &&
      expandableEl.current.scrollHeight > collapsedHeight &&
      window.innerWidth < fromViewportWidth
    ) {
      setCollapsed(true);
      setViewAll(true);
    }
  }, [initialize, collapsedHeight, fromViewportWidth]);

  return (
    <div
      className={cx(styles.container, className, {
        collapsed: collapsed,
      })}>
      <AccessibleButton
        onClick={() => {
          setCollapsed((collapsed) => !collapsed);
        }}
        type="link"
        theme={viewMoreTheme}
        ariaLabel={collapsed ? viewMoreAriaLabel : viewLessAriaLabel}
        icon={collapsed ? 'Expand' : 'Minimize'}
        className={cx(styles.viewAllCta, {
          collapsed,
          [styles.hidden]: !showViewAll,
        })}>
        {collapsed ? t(viewMoreI18nKey) : t(viewLessI18nKey)}
      </AccessibleButton>
      <div
        ref={expandableEl}
        style={{
          maxHeight:
            collapsed && expandableEl?.current?.scrollHeight > collapsedHeight
              ? `${collapsedHeight}px`
              : 'none',
        }}
        className={cx(styles.expandable, {
          [styles.invisible]: invisible && collapsed,
        })}>
        {children}
      </div>
    </div>
  );
}
