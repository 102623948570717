import cx from 'classnames';

import { useTranslation } from '../../lib/hooks';

import AccessibleButton from '../AccessibleButton/AccessibleButton';

import styles from './successMessage.module.scss';

/**
 * @param {object} [props] component props
 * @param {string} [props.id] ID of the form used for tracking
 * @param {string} [props.className] Additional className for the component
 * @param {object} [props.image] Image to display
 * @param {string} [props.message] Title to display
 * @param {boolean} [props.showCloseBtn] Flag to show or hide the close button
 * @returns {JSX.Element} react component
 */
export default function SuccessMessage({ id, className, image, message, showCloseBtn = false }) {
  const { t } = useTranslation();
  const closeText = t('common.ctas.close');

  return (
    <div id={`${id}-success`} className={cx(styles.container, className)}>
      {image && (
        <img
          className={styles.image}
          srcSet={`${image}?h=772 2x,
                   ${image}`}
          alt=""
        />
      )}

      <h3 className={styles.message}>{message}</h3>

      {showCloseBtn && (
        <form method="dialog">
          <AccessibleButton type="primary" theme="dark" submit={true}>
            {closeText}
          </AccessibleButton>
        </form>
      )}
    </div>
  );
}
