import { useTranslation } from '../../lib/hooks';
import Image from '../Image/Image';
import styles from './blogAuthors.module.scss';
import cx from 'classnames';
/**
 * @param {object} [props] component props
 * @param {Array<object>} [props.authors] configured blog authors
 * @param {string} [props.className] extra classes
 * @returns {JSX.Element} react component
 */
export default function BlogAuthors({ authors, className }) {
  const { t } = useTranslation();

  const blogAuthorsImageSizes = {
    defaultSize: [64, 64],
    fallbackSize: [64, 64],
  };

  return (
    <div className={cx(styles.container, className)}>
      <hr className={styles.separator} />
      <p className={`${styles.headline} ${authors.length === 1 ? styles.singleAuthor : ''}`}>
        {authors.length === 1 ? t('authors.author') : t('authors.authors')}
      </p>
      <div
        className={`${styles.authorsContainer} ${authors.length === 1 ? styles.singleAuthor : ''}`}>
        {authors.map((author, index) => (
          <div
            className={`${styles.authorCard} ${authors.length === 1 ? styles.singleAuthor : ''}`}
            key={index}>
            {author.fields?.image?.fields?.file && (
              <Image
                classNameImage={styles.image}
                url={author.fields.image.fields.file.url}
                alt={author.fields.image.fields.description}
                sizes={`(max-width: 1023px) 1x, (min-width: 1024px) 2x`}
                imageSizes={blogAuthorsImageSizes}
                fit="fill"
                roundedCorners="max"
                wrapImage={false}
              />
            )}
            <div className={styles.authorInfoContainer}>
              <p className={styles.name}>{author.fields?.name}</p>
              <p className={styles.title}>{author.fields?.title}</p>
              <p className={styles.bio}>{author.fields?.bio}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
