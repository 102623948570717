import { useRef, useContext } from 'react';
import { useTranslation } from '../../lib/hooks';
import cx from 'classnames';
import InfoCard from './InfoCard';
import NoResults from './NoResults';
import PendingResults from './PendingResults';
import AccessibleButton from '../AccessibleButton/AccessibleButton';

import styles from './infoCardList.module.scss';
import SiteContext from '../AppContext';

/**
 * Component with a list of cards showing location results
 * @param {object} [props] component props
 * @param {boolean} [props.hasMap] flag to enable/disable the map
 * @param {Array<object>} [props.locations] list of locations
 * @param {number} [props.activePin] index from active location
 * @param {boolean} [props.displayAll] flag indicating we should display the entire list
 * @param {object} [props.locationsEl] ref from locations element
 * @param {Function} [props.setActivePin] active pin handler
 * @param {object} [props.userGeolocation] user geo location
 * @param {string} [props.noResultsTitle] title for no results state
 * @param {string} [props.noResultsMessage] message for no results state
 * @param {object} [props.cta] link for no results state
 * @param {boolean} [props.loading] flag indicating if we are fetching results
 * @param {string} [props.locationPendingMessage] message for the pending results state
 * @param {object} [props.locationPendingImage] image for the pending results state
 * @param {string} [props.activeView] current active view (either 'list' or 'map')
 * @param {Function} [props.setActiveView] locations active view state setter
 * @param {string}  props.distanceFormat whether to display distance in km or mi
 * @param {boolean} [props.hasTopBorder] flag indicating to show top border
 * @param {Function} [props.onLoadMore] called on click of load more button
 * @param {boolean} [props.showLoadMore] flag indicating to show the load more button
 * @returns {JSX.Element} Info Card List react component
 */
export default function InfoCardList({
  hasMap,
  locations,
  activePin,
  displayAll,
  locationsEl,
  setActivePin,
  userGeolocation,
  noResultsTitle,
  noResultsMessage,
  cta,
  loading,
  locationPendingMessage,
  locationPendingImage,
  activeView,
  setActiveView,
  distanceFormat,
  hasTopBorder,
  onLoadMore,
  showLoadMore,
}) {
  const infoCardListEl = useRef();
  const { t } = useTranslation();
  const { brand } = useContext(SiteContext);

  const isPSGBrand = brand === 'gather' || brand === 'summit';

  return (
    <>
      {locations.length > 0 && userGeolocation && (
        <div
          className={cx(styles.container, {
            [styles.topBorder]: hasTopBorder,
            [styles.selected]: activePin !== null && !displayAll,
            [styles.grid]: !hasMap,
          })}
          ref={infoCardListEl}>
          <ul
            className={cx(styles.storeList, {
              [styles.grid]: !hasMap,
            })}>
            {locations.map((location, i) => (
              <li key={i} className={styles.card}>
                <InfoCard
                  {...location}
                  hasMap={hasMap}
                  infoCardListEl={infoCardListEl}
                  active={activePin === i}
                  visible={activePin === i || displayAll}
                  setActivePin={setActivePin}
                  locationsEl={locationsEl}
                  activeView={activeView}
                  setActiveView={setActiveView}
                  displayAll={displayAll}
                  index={i}
                  distanceFormat={distanceFormat}
                />
              </li>
            ))}
          </ul>
          {showLoadMore && (
            <div
              className={cx(styles.loadMore, {
                [styles.visibleMobile]: displayAll,
              })}>
              <AccessibleButton
                className={styles.loadMoreBtn}
                onClick={onLoadMore}
                ariaLabel={t('common.storeLocator.infoList.loadMoreStores')}
                type="secondary"
                theme={isPSGBrand ? 'dark' : 'light'}>
                {t('common.storeLocator.infoList.loadMore')}
              </AccessibleButton>
            </div>
          )}
        </div>
      )}

      {!loading && locations.length === 0 && userGeolocation && (
        <NoResults
          noResultsTitle={noResultsTitle}
          noResultsMessage={noResultsMessage}
          cta={cta}
          activeView={activeView}
        />
      )}

      {(!userGeolocation || loading) && (
        <PendingResults
          message={locationPendingMessage}
          image={locationPendingImage}
          isDesktopOnly={true}
        />
      )}
    </>
  );
}
