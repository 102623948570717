import { useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import PhilosophyHero from '../../PhilosophyHero/PhilosophyHero';
import ImageWithText from '../../ImageWithText/ImageWithText';
import Testimonial from '../../Testimonial/Testimonial';
import IngredientsGrid from '../../IngredientsGrid/IngredientsGrid';
import SiteContext from '../../AppContext';
import cx from 'classnames';

import styles from './philosophy.module.scss';
import TransitionCta from '../../TransitionCta/TransitionCta';

/**
 * React component for Philosophy template
 * @param {object} [props] component props
 * @param {object} [props.fields] props coming form contentful
 * @returns {JSX.Element} Returns Philosophy template react component
 */
export default function Philosophy({ fields }) {
  const {
    accentImages,
    benefitIconList,
    headerCTAs,
    headerImage,
    headline,
    imageAndTextBlock1,
    imageAndTextBlock2,
    keyBenefitsCopy,
    keyBenefitsImage,
    keyBenefitsTitle,
    subhead,
    testimonialCallout,
    textAndCta,
  } = fields;

  const heroContent = {
    accentImage: accentImages?.length ? accentImages[0] : null,
    headerImage,
    headline,
    subhead,
    headerCTAs,
  };

  const benefitImageTextContent = {
    fields: {
      secondaryText: keyBenefitsCopy,
      primaryText: keyBenefitsTitle,
      image: keyBenefitsImage,
    },
  };

  const siteContext = useContext(SiteContext);
  const { brand } = siteContext;

  const [animIn, setAnimIn] = useState(false);

  const [benefitIconsRef, inView] = useInView({
    rootMargin: '0px 0px',
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setAnimIn(true);
    }
  }, [inView]);

  return (
    <section className={styles.container}>
      <PhilosophyHero {...heroContent} />
      {imageAndTextBlock1 && (
        <ImageWithText
          {...imageAndTextBlock1}
          align="left"
          fullWidth={brand === 'go-solutions' || brand === 'now-fresh'}
          hasAnimation={true}
          className={styles.philosophyStyles}
        />
      )}

      {imageAndTextBlock2 && (
        <ImageWithText
          {...imageAndTextBlock2}
          align="right"
          fullWidth={brand === 'go-solutions' || brand === 'now-fresh'}
          hasAnimation={true}
          className={styles.philosophyStyles}
        />
      )}

      <div className={styles.benefitsImageText}>
        <ImageWithText
          {...benefitImageTextContent}
          fullWidth={false}
          hasAnimation={true}
          isBenefitsLayout={true}
          className={styles.philosophyStyles}
        />
      </div>

      <div className={cx(styles.benefits, { [styles.animIn]: animIn })} ref={benefitIconsRef}>
        <IngredientsGrid ingredients={benefitIconList} xLargeNoWrap={true} />
      </div>

      {testimonialCallout && <Testimonial {...testimonialCallout} hasAnimation={true} />}

      {textAndCta && <TransitionCta {...textAndCta} />}
    </section>
  );
}
