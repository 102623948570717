import { useContext } from 'react';
import Hero from '../../Hero/Hero';
import HomeProductsComponent from '../../HomeProductsComponent/HomeProductsComponent';
import Testimonial from '../../Testimonial/Testimonial';
import RelatedProducts from '../../RelatedProducts/RelatedProducts';
import RelatedArticles from '../../RelatedArticles/RelatedArticles';
import ImageWithText from '../../ImageWithText/ImageWithText';
import BenefitsAndCallout from '../../BenefitsAndCallout/BenefitsAndCallout';
import Head from 'next/head';
import SiteContext from '../../AppContext';
import { getHomepageSchema } from '../../../lib/Schema';
import PhilosophyHero from '../../PhilosophyHero/PhilosophyHero';
import HeroCarouselSplash from '../../HeroCarouselSplash/HeroCarouselSplash';

import styles from './homepage.module.scss';

/**
 * React component for Homepage template
 * @param {object} [props] component props
 * @param {object} [props.fields] props coming form contentful
 * @returns {JSX.Element} Returns Homepage template react component
 */
export default function Homepage({ fields }) {
  const { locale, brand } = useContext(SiteContext);

  const {
    accentImages,
    bannerCta,
    bannerHeadline,
    bannerImage,
    benefits,
    catCategoryImage,
    catCategoryEyebrow,
    catCategoryList,
    dogCategoryEyebrow,
    dogCategoryImage,
    dogCategoryList,
    headerCTAs,
    headerImage,
    headline,
    promotedCatProducts,
    promotedCatProductsCta,
    promotedCatProductsTitle,
    promotedDogProducts,
    promotedDogProductsCta,
    promotedDogProductsTitle,
    testimonialCallout,
    secondaryValueProposition,
    showCatCategoryList,
    showDogCategoryList,
    subhead,
    valueProposition,
    heroSplash,
  } = fields;

  const heroContent = {
    accentImage: accentImages?.length ? accentImages[0] : null,
    headerImage,
    headline,
    subhead,
    headerCTAs,
  };

  const heroSplashFields = heroSplash?.map(({ fields }) => ({ ...fields }));

  const renderHeroComponent = () => {
    if (heroSplash) {
      return <HeroCarouselSplash fields={heroSplashFields} />;
    }

    switch (brand) {
      case 'gather':
      case 'petcurean':
        return <PhilosophyHero hasVerboseCopy={brand === 'gather'} {...heroContent} />;
      case 'go-solutions':
      case 'now-fresh':
      case 'summit':
        return <Hero {...heroContent} hasMarginBottom={false} />;
      default:
        return null;
    }
  };

  const productListContent = {
    catCategoryImage,
    catCategoryEyebrow,
    catCategoryList,
    dogCategoryImage,
    dogCategoryEyebrow,
    dogCategoryList,
    showCatCategoryList,
    showDogCategoryList,
  };

  const hasCatProducts = fields.promotedCatProducts && fields.promotedCatProducts.length > 0;

  const relatedCatContent = {
    fields: {
      cta: promotedCatProductsCta,
      products: promotedCatProducts,
      title: promotedCatProductsTitle,
    },
  };

  const hasDogProducts = fields.promotedDogProducts && fields.promotedDogProducts.length > 0;

  const relatedDogContent = {
    fields: {
      cta: promotedDogProductsCta,
      products: promotedDogProducts,
      title: promotedDogProductsTitle,
    },
  };

  const homepageSchema = getHomepageSchema(fields, locale);

  return (
    <section className={styles.container}>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(homepageSchema) }}
        />
      </Head>

      {renderHeroComponent()}

      {valueProposition && (
        <div className={styles.valueProposition}>
          <ImageWithText
            {...valueProposition}
            align={brand === 'now-fresh' ? 'right' : 'left'}
            fullWidth={true}
            hasAnimation={true}
          />
        </div>
      )}

      {benefits && (
        <div className={styles.benefits}>
          <BenefitsAndCallout {...benefits} hasAnimation={true} />
        </div>
      )}

      {Array.isArray(secondaryValueProposition) && secondaryValueProposition.length > 0 && (
        <div className={styles.valueProposition}>
          {secondaryValueProposition.map((component, index) => (
            <ImageWithText
              {...component}
              fullWidth={true}
              align={index % 2 ? 'right' : 'left'}
              hasAnimation={true}
              key={index}
            />
          ))}
        </div>
      )}

      {(showDogCategoryList || showCatCategoryList) && (
        <HomeProductsComponent {...productListContent} />
      )}

      {testimonialCallout && (
        <Testimonial
          {...testimonialCallout}
          bannerCta={bannerCta}
          bannerHeadline={bannerHeadline}
          bannerImage={bannerImage}
          hasAnimation={true}
        />
      )}

      {hasDogProducts && (
        <RelatedProducts {...relatedDogContent} productsToShow={3} isGridView={false} />
      )}

      {hasCatProducts && (
        <RelatedProducts {...relatedCatContent} productsToShow={3} isGridView={false} />
      )}

      {fields.promotedContent && <RelatedArticles {...fields.promotedContent} />}
    </section>
  );
}
