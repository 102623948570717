import styles from './headerAndBody.module.scss';

/**
 * @param {object} [props] // component props
 * @param {object} [props.fields] // component fields
 * @param {string} [props.fields.eyebrow] // eyebrow text
 * @param {string} [props.fields.headline] // header text
 * @param {string} [props.fields.copy] // copy text
 * @returns {JSX.Element} react component for header and body
 */
export default function HeaderAndBody({ fields: { eyebrow, headline, copy } }) {
  return (
    <div className={styles.container}>
      {eyebrow && <p className={styles.eyebrow}>{eyebrow}</p>}
      {headline && <h2 className={styles.headline}>{headline}</h2>}
      {copy && <p className={styles.copy}>{copy}</p>}
    </div>
  );
}
