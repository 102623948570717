import { useRef, useState } from 'react';
import Slider from 'react-slick';
import cx from 'classnames';
import { toCamelCase, capitalize } from '../../lib/utils';
import { breakpoints } from '../../lib/imageUtils';
import styles from './heroCarousel.module.scss';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useContext } from 'react';
import SiteContext from '../AppContext';
import GatherPDPHeroImage from './GatherPDPHeroImage';

/**
 * @param {object} [props] component props
 * @param {object} [props.productImage] main image content
 * @param {string} [props.productColor] badge border color
 * @param {object} [props.pdpHeaderImage] Product image for the hero carousel
 * @param {object} [props.pdpHeaderAccentImage] Product image bg for the hero carousel (Gather)
 * @param {string} [props.productBadge] product Badge
 * @param {object} [props.productType] Type of product (eg. bag, small can, large tetrapack)
 * @param {Array<any>} [props.carouselImages] content for carousel images
 * @returns {JSX.Element} hero carousel react component
 */
export default function HeroCarousel({
  productImage,
  productColor,
  pdpHeaderImage,
  pdpHeaderAccentImage,
  productBadge,
  productType,
  carouselImages,
}) {
  const { brand } = useContext(SiteContext);
  const [loaded, setLoaded] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);
  const router = useRouter();
  const prodType = capitalize(toCamelCase(productType));
  const sliderEl = useRef(null);
  const thumbBgColor = brand === 'go-solutions' ? '000000' : 'f6f3df';
  const thumbs = [];

  const productImageUrl = productImage.fields.file.url;
  if (productImage?.fields?.file) {
    thumbs.push({
      url: `${productImageUrl}?w=64&h=64&q=60&fit=fill&bg=rgb:${thumbBgColor}`,
      alt: productImage.fields.description ?? '',
      size: [64, 64],
    });
  }

  const renderGatherPDPHeroImage = brand === 'gather' && pdpHeaderAccentImage?.fields?.file;

  useEffect(() => {
    setLoaded(true);

    const setLoadedToFalse = () => {
      setLoaded(false);
    };
    const setLoadedToTrue = () => {
      setLoaded(true);
    };

    router.events.on('routeChangeStart', setLoadedToFalse);
    router.events.on('routeChangeComplete', setLoadedToTrue);

    return () => {
      router.events.off('routeChangeStart', setLoadedToFalse);
      router.events.off('routeChangeComplete', setLoadedToTrue);
    };
  }, [router]);

  if (carouselImages) {
    carouselImages.forEach((elm) => {
      if (elm.fields?.file) {
        thumbs.push({
          url: `${elm.fields.file.url}?w=64&h=64&fit=fill&q=60`,
          alt: elm.fields.description ?? '',
          size: [64, 64],
        });
      }
    });
  }

  const settings = {
    dots: true,
    arrows: false,
    lazyLoad: 'ondemand',
    className: 'pdp-slick',
    customPaging: function slickPagingLarge(i) {
      return (
        <button
          onFocus={() => {
            sliderEl.current.slickGoTo(i);
          }}>
          <div tabIndex="-1">
            <picture>
              {['avif', 'webp'].map((imgFormat, formatIndex) => (
                <source
                  key={`${formatIndex}`}
                  srcSet={`${thumbs[i].url}&fm=${imgFormat}`}
                  type={`image/${imgFormat}`}
                />
              ))}
              <img
                src={thumbs[i].url}
                alt={thumbs[i].alt ?? ''}
                tabIndex="-1"
                width={thumbs[i].size[0]}
                height={thumbs[i].size[1]}
              />
            </picture>
          </div>
        </button>
      );
    },
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          customPaging: function slickPagingSmall(i) {
            return (
              <button
                onFocus={() => {
                  sliderEl.current.slickGoTo(i);
                }}>
                <div tabIndex="-1"></div>
                {thumbs[i].alt}
              </button>
            );
          },
        },
      },
    ],
  };

  const onImgLoad = () => {
    setImgLoaded(true);
  };

  const carouselImagesSize = [
    { media: breakpoints.sm, size: [800, 800] },
    { media: breakpoints.md, size: [1200, 1200] },
  ];

  return (
    <div className={styles.container}>
      {loaded && (
        <Slider ref={sliderEl} {...settings}>
          <div className={styles['primary-slide']}>
            {renderGatherPDPHeroImage ? (
              <GatherPDPHeroImage
                pdpHeaderAccentImage={pdpHeaderAccentImage}
                pdpHeaderImage={pdpHeaderImage}
                imgLoaded={imgLoaded}
                onImgLoad={onImgLoad}
              />
            ) : (
              pdpHeaderImage?.fields?.file && (
                <picture>
                  {carouselImagesSize.map(({ media, size }, index) =>
                    ['avif', 'webp'].map((imgFormat, formatIndex) => (
                      <source
                        key={`${index}${formatIndex}`}
                        media={media}
                        type={`image/${imgFormat}`}
                        width={size[0]}
                        height={size[1]}
                        srcSet={`
                          ${pdpHeaderImage.fields.file.url}?w=${size[0]}&h=${
                            size[1]
                          }&fit=fill&fm=${imgFormat}, 
                          ${pdpHeaderImage.fields.file.url}?w=${size[0] * 2}&h=${
                            size[1] * 2
                          }&fit=fill&fm=${imgFormat} 2x`}
                      />
                    )),
                  )}
                  <img
                    src={`${pdpHeaderImage.fields.file.url}?w=800&h=800&fit=fill`}
                    width="800"
                    height="800"
                    alt={pdpHeaderImage.fields.description ?? ''}
                    className={cx(styles.primaryImg, { [styles.imgLoaded]: imgLoaded })}
                    onLoad={onImgLoad}
                  />
                </picture>
              )
            )}
            {productBadge && (
              <div className={cx(styles.badge, { [styles[`badge${prodType}`]]: prodType })}>
                <div
                  className={styles.badgeBorder}
                  style={productColor ? { borderColor: productColor } : null}
                />
                <p className={styles['badge-content']}>{productBadge}</p>
              </div>
            )}
          </div>
          {carouselImages &&
            carouselImages.map((item, index) => {
              if (item.fields?.file)
                return (
                  <picture key={`images-${index}`}>
                    {carouselImagesSize.map(({ media, size }, sizesIndex) =>
                      ['avif', 'webp'].map((imgFormat, formatIndex) => (
                        <source
                          key={`images-${sizesIndex}${formatIndex}`}
                          media={media}
                          type={`image/${imgFormat}`}
                          width={size[0]}
                          height={size[1]}
                          srcSet={`
                            ${item.fields.file.url}?w=${size[0]}&h=${
                              size[1]
                            }&fit=fill&fm=${imgFormat}, 
                            ${item.fields.file.url}?w=${size[0] * 2}&h=${
                              size[1] * 2
                            }&fit=fill&fm=${imgFormat} 2x`}
                        />
                      )),
                    )}
                    <img
                      src={`${item.fields.file.url}?w=800&h=800&fit=fill`}
                      alt={item.fields.description ?? ''}
                      width="800"
                      height="800"
                    />
                  </picture>
                );
            })}
        </Slider>
      )}
    </div>
  );
}
