import { useContext } from 'react';
import InstagramEmbed from 'react-instagram-embed';
import VideoComponent from '../../VideoComponent/VideoComponent';
import SiteContext from '../../AppContext';
import Image from '../../Image/Image';
import { colors } from '../../../lib/constants';
import cx from 'classnames';

import styles from './socialEmbed.module.scss';

/**
 * @param {object} [props] component props
 * @returns {JSX.Element} react component for social embed
 */
export default function SocialEmbed(props) {
  // When the component is being embedded directly into a page,
  // the props are being passed in 'fields'
  // When the component is being embedded in a rich text field,
  // they are getting passed in directly to props
  const {
    ingredientAccentImages,
    caption,
    description,
    embedCode,
    embedSource,
    headline,
    igLayout,
  } = props.fields ? props.fields : props;

  const { isInline = false } = props;

  const { instagramClientToken } = useContext(SiteContext);
  const source = embedSource ? embedSource.toLowerCase() : '';

  const renderMedia = (code) => {
    switch (source) {
      case 'instagram':
        return (
          <InstagramEmbed
            url={`https://instagr.am/p/${code}/`}
            clientAccessToken={instagramClientToken}
            hideCaption={true}
            injectScript={true}
          />
        );
      case 'youtube':
      case 'vimeo':
        return <VideoComponent source={source} code={code} />;
      default:
        return <div>Invalid media type</div>;
    }
  };

  return (
    <div
      className={cx(styles.container, styles[`type-${source}`], {
        [styles.inline]: isInline,
        [styles.col2]: igLayout === '2 Column',
      })}>
      {ingredientAccentImages && !isInline && (
        <div className={styles.accentImages}>
          {ingredientAccentImages.map((img, index) => (
            <div className={styles.accent} key={index}>
              {img?.fields?.file && (
                <Image
                  url={img.fields.file.url}
                  alt={img.fields.description}
                  bgColor={colors.nowFresh.backgroundTan}
                  quality="40"
                  width={img?.fields?.file?.details?.image?.width || null}
                  height={img?.fields?.file?.details?.image?.height || null}
                />
              )}
            </div>
          ))}
        </div>
      )}
      {(headline || description) && !isInline && (
        <div className={styles.content}>
          {headline && <h2 className={styles.headline}>{headline}</h2>}
          {description && <p className={styles.description}>{description}</p>}
        </div>
      )}
      {embedSource && embedCode && (
        <div className={styles.mediaContainer}>
          {embedCode.map((code, index) => (
            <div className={styles.mediaElement} key={index}>
              {renderMedia(code)}
            </div>
          ))}
        </div>
      )}
      {caption && !isInline && <div className={styles.caption}>{caption}</div>}
    </div>
  );
}
