import cx from 'classnames';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import { useContext } from 'react';
import Picture from '../Image/Picture';
import { getImageSize } from '../../lib/imageUtils';
import SiteContext from '../AppContext';
import { colors } from '../../lib/constants';
import RichText from '../Richtext/Richtext';

import styles from './heroErrorPage.module.scss';

/**
 * @param {object} [props] component props
 * @param {string} [props.headline] hero headline
 * @param {object} [props.headerImage] hero header image
 * @param {string} [props.subhead] hero subhead
 * @param {string} [props.errorMessage] error message
 * @param {Array<any>} [props.headerCTAs] array of hero ctas
 * @returns {JSX.Element} react component for hero
 */
export default function HeroErrorPage({
  headline,
  headerImage,
  subhead,
  errorMessage,
  headerCTAs,
}) {
  const { brand } = useContext(SiteContext);
  let imageUrl;
  let imageBg;

  if (headerImage?.fields?.file) {
    imageUrl = headerImage.fields.file.url;
    imageBg =
      brand === 'go-solutions'
        ? colors.goSolutions.backgroundBlack
        : colors.goSolutions.backgroundWhite;
  }

  const imageSize = headerImage?.fields?.file?.details?.image;
  const imageSizes = {
    sm: getImageSize(400, imageSize),
    xl: getImageSize(716, imageSize),
    md: getImageSize(500, imageSize),
    lg: getImageSize(500, imageSize),
    fallbackSize: getImageSize(500, imageSize),
  };

  const isRichTextField =
    typeof subhead === 'object' &&
    subhead !== null &&
    subhead.content &&
    Array.isArray(subhead.content);

  return (
    <section className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={cx(styles.bgImage)}>
          {headerImage?.fields?.file && brand !== 'gather' && (
            <Picture
              imageSizes={imageSizes}
              url={imageUrl}
              bgColor={imageBg}
              alt={headerImage.fields.description}
              quality="70"
            />
          )}
        </div>
        <div className={styles.content}>
          {headline && <h1 className={styles.headline}>{headline}</h1>}
          {subhead &&
            (isRichTextField ? (
              <RichText content={subhead} className={styles.subhead} />
            ) : (
              <p className={styles.subhead}>{subhead}</p>
            ))}
          {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
          {headerCTAs && (
            <ul className={styles.ctas}>
              {headerCTAs.map((item, index) => (
                <li className={styles.cta} key={index}>
                  <AccessibleButton type="primary" theme="dark" link={item} key={index}>
                    {item.fields && item.fields.linkText}
                  </AccessibleButton>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </section>
  );
}
