import cx from 'classnames';

import { useTranslation } from '../../lib/hooks';

import { Check } from '../Icons';
import RichText from '../Richtext/Richtext';

import styles from './agreement.module.scss';

/**
 * Agreement component
 * @param {object} [props] component props
 * @param {string} [props.id] input id
 * @param {string} [props.name] input name
 * @param {string} [props.value] input value
 * @param {string} [props.checkboxText] rich text for the checkbox label
 * @param {Function} [props.onFocus] function to execute on focus
 * @param {Function} [props.onChange] function to execute on change
 * @param {Function} [props.onClick] function to execute on click
 * @param {Function} [props.onBlur] function to execute on blur
 * @param {string} [props.showError] function to execute on blur
 * @param {string} [props.className] styles to override container
 * @param {string} [props.ariaRequired] flag for the aria-required attribute
 * @returns {JSX.Element} Agreement react component
 */
export default function Agreement({
  id,
  name,
  value,
  checkboxText,
  onFocus,
  onChange,
  onClick,
  onBlur,
  showError,
  className,
  ariaRequired,
}) {
  const { t } = useTranslation();

  return (
    <fieldset
      className={cx(styles.agree, className, {
        [styles.error]: showError,
      })}>
      <legend className={styles.legend}>{t('common.forms.general.termsLegend')}</legend>
      <div className={styles['agree-checkbox']}>
        <input
          className={styles.checkbox}
          type="checkbox"
          name={name}
          checked={value}
          id={id}
          onFocus={onFocus}
          onChange={onChange}
          onBlur={onBlur}
          onClick={onClick}
          aria-required={ariaRequired}
        />
        <Check />
      </div>

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={styles.label} htmlFor={id}>
        <RichText
          content={checkboxText}
          className={styles['agree-label']}
          internalLinksInNewWindow={true}
        />
      </label>
    </fieldset>
  );
}
