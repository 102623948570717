import { useEffect, useContext } from 'react';
import { initializePriceSpiderScriptTag } from '../../lib/pricespider';

import styles from './whereToBuyWidget.module.scss';
import SiteContext from '../AppContext';

/**
 * @param {object} [props] component props
 * @param {string} [props.sku] product sku
 * @param {string} [props.className] className for styling
 * @returns {JSX.Element} react component
 */
export default function WhereToBuyWidget({ sku, className }) {
  const { brand, locale } = useContext(SiteContext);

  const PriceSpiderBrandConfig = {
    'go-solutions': '656e059d608ce3000e0229a5',
    'now-fresh': '655b913e3bd1d9000e8c83a5',
    summit: '6565f48a15562f000fb5d60d',
  };

  useEffect(() => {
    initializePriceSpiderScriptTag(locale);
  }, [locale]);

  const isPriceSpiderEnabled = locale === 'en-us' || locale === 'en-ca' || locale === 'fr-ca';

  return (
    // change alt text to use translation hook
    <div className={className}>
      {isPriceSpiderEnabled && (
        <button className={styles.ctaButton} alt="Where To Buy">
          {sku && (
            <div
              className="ps-widget"
              data-ps-config={PriceSpiderBrandConfig[brand]}
              data-ps-sku={sku}></div>
          )}
        </button>
      )}
    </div>
  );
}
