import * as components from '../components';
import * as inlineComponents from '../components/Inline';

/**
 * Function that will look for the react component comparing from the entry.sys.contentType.sys.id
 * and the value in the indexLocale.js
 * @param {string} [name] component name, this component will be looked in the indexLocale.js
 * from ../components/indexLocale.js
 * @param {object} [props] props to be passed to the component
 * @returns {JSX.Element|boolean} React component or false when is not found
 */
export function getComponent(name, props) {
  const ComponentRef = components[name];
  if (typeof ComponentRef === 'undefined') {
    console.warn(`Component ${name} not found`);
    return false;
  }
  return <ComponentRef {...props} />;
}

/**
 * gets the component matching the inline type
 * @param {string} name the name of the component
 * @param {object} props data from contentful
 * @returns {JSX.Element|boolean} React component or false when is not found
 */
export function getInlineComponent(name, props) {
  const ComponentRef = inlineComponents[name];
  if (typeof ComponentRef === 'undefined') {
    console.warn(`Component ${name} not found`);
    return false;
  }
  return <ComponentRef {...props} isInline={true} />;
}
