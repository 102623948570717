import { useEffect, useContext } from 'react';
import { initializePriceSpiderScriptTag } from '../../lib/pricespider';
import TabbedHeader from '../TabbedHeader/TabbedHeader';
import styles from './storeLocatorWidget.module.scss';

import SiteContext from '../AppContext';

/**
 * @param {object} [props] component props
 * @param {object} [props.fields] contentful fields
 * @returns {JSX.Element} react component for Store Locator PriceSpider widget
 */
export default function StoreLocatorWidget({ fields }) {
  const { brand, locale } = useContext(SiteContext);

  const PriceSpiderBrandConfig = {
    'go-solutions': '656e05d6608ce3000e0229a6',
    'now-fresh': '655b83b73bd1d9000e8c83a0',
    summit: '6565f4de24e20200240a954e',
  };

  useEffect(() => {
    initializePriceSpiderScriptTag(locale);
  }, [locale]);

  return (
    <div className={styles.container}>
      <TabbedHeader title={fields.title} description={fields.description} isPageHeader />
      <div className={styles.widget}>
        <div className="ps-widget" data-ps-config={PriceSpiderBrandConfig[brand]}></div>
      </div>
    </div>
  );
}
