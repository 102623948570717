import { useEffect, useState, useContext } from 'react';
import FileHeader from './FileHeader';
import { uploadImg } from '../../lib/reviews/bazaarvoice';
import SiteContext from '../AppContext';

/**
 * Single File Upload Component
 * @param {object} [props] component props
 * @param {object} [props.file] the file to be uploaded
 * @param {Function} [props.onDelete] function to delete a file from form submission
 * @param {Function} [props.onUpload] function to handle uploads
 * @returns {JSX.Element} SingleFileUpload
 */
export default function SingleFileUpload({ file, onDelete, onUpload }) {
  const [isLoading, setIsLoading] = useState(true);
  const { locale } = useContext(SiteContext);

  useEffect(() => {
    /**
     * Async Function to await the file upload
     */
    async function upload() {
      const response = await uploadImg(file, locale);
      const url = response?.Photo?.Sizes?.thumbnail?.Url;
      const error = response?.FormErrors?.FieldErrors?.photo?.Message || null;
      onUpload(file, url, error);
      setIsLoading(false);
    }
    upload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <li>
      <FileHeader file={file} onDelete={onDelete} isLoading={isLoading} />
    </li>
  );
}
