import styles from './plpHero.module.scss';
import { useContext } from 'react';
import SiteContext from '../AppContext';
import { colors } from '../../lib/constants';
import { breakpoints } from '../../lib/imageUtils';
import cx from 'classnames';
import { getImageSize } from '../../lib/imageUtils';

/**
 * React component for Category Landing header
 * @param {object} [props] component props
 * @param {string} [props.title] title text to display
 * @param {object} [props.headerImage] image content
 * @param {object} [props.accentImage] freefrom accent image content
 * @param {boolean} [props.hasMarginBottom] hero has a bottom margin
 * @returns {JSX.Element} Returns Category Landing react component
 */
export default function PLPHero({ title, headerImage, hasMarginBottom = false, accentImage }) {
  const { brand } = useContext(SiteContext);
  const imageQuality = 60;
  const imgParams =
    brand === 'go-solutions'
      ? `&bg=${colors.goSolutions.backgroundBlack}&q=${imageQuality}`
      : `&bg=${colors.nowFresh.backgroundTan}&q=${imageQuality}`;

  const accentImageSize = accentImage?.fields?.file?.details?.image || [];
  const [accentImgWidth, accentImgHeight] = accentImage
    ? getImageSize(accentImageSize?.width, accentImageSize)
    : [500, null];

  const imgElement = () => {
    const plpHeroImageSize = headerImage?.fields?.file?.details?.image || [];
    const plpHeroImageURL = headerImage?.fields?.file?.url;
    const plpHeroImageSizes = [
      { media: breakpoints.lg, size: getImageSize(450, plpHeroImageSize) },
      { media: breakpoints.md, size: getImageSize(480, plpHeroImageSize) },
      { media: breakpoints.sm, size: getImageSize(400, plpHeroImageSize) },
    ];

    const [plpHeroImageWidth, plpHeroImageHeight] = headerImage
      ? getImageSize(400, plpHeroImageSize)
      : [400, null];

    return (
      headerImage?.fields?.file && (
        <picture>
          {plpHeroImageSizes.map(({ media, size }, index) =>
            ['avif', 'webp'].map((imgFormat, formatIndex) => (
              <source
                key={`${index}${formatIndex}`}
                media={media}
                type={`image/${imgFormat}`}
                width={size[0]}
                height={size[1]}
                srcSet={
                  `${plpHeroImageURL}?w=${size[0]}&h=${size[1]}&fm=${imgFormat}${imgParams}, ` +
                  `${plpHeroImageURL}?w=${size[0] * 2}&h=${
                    size[1] * 2
                  }&fm=${imgFormat}${imgParams} 2x`
                }
              />
            )),
          )}
          <img
            src={`${plpHeroImageURL}?w=${plpHeroImageWidth}&h=${plpHeroImageHeight}${imgParams}`}
            alt={headerImage.fields.description || ''}
            className={styles['hero-bg']}
            width={plpHeroImageWidth}
            height={plpHeroImageHeight}
          />
        </picture>
      )
    );
  };

  return (
    <div className={cx(styles.header, { [styles.marginBottom]: hasMarginBottom })}>
      <div className={styles['hero-container']}>
        <h1 className={styles['hero-title']}>{title}</h1>
        {brand === 'go-solutions' ? (
          imgElement()
        ) : (
          <div className={styles.heroBgContainer}>{imgElement()}</div>
        )}
        {accentImage?.fields?.file && (
          <div className={styles.accentImage}>
            <img
              src={`${accentImage.fields.file.url}`}
              alt={accentImage.fields.description || ''}
              width={accentImgWidth}
              height={accentImgHeight}
            />
          </div>
        )}
      </div>
    </div>
  );
}
