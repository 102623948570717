import cx from 'classnames';
import styles from '../reviewFilters.module.scss';
import AccessibleButton from '../../AccessibleButton/AccessibleButton';
import Checkbox from '../../Checkbox/Checkbox';
import { useRef, useState } from 'react';
import { Caret } from '../../Icons';
import { useTranslation } from '../../../lib/hooks';

const MAX_STARS = 5;

/**
 *
 * @param {object} reviewDistribution rating distribution from BV
 * @param {number} rating star rating 1-5 of review
 * @returns {number} number of reviews of {rating} value
 */
function reviewsByRatingValue(reviewDistribution, rating) {
  return reviewDistribution?.find((x) => x.RatingValue === rating)?.Count || 0;
}

/**
 *
 * @param {object} props component props
 * @param {boolean} props.filtersOpen flag if filter sidebar is open
 * @param {Set} props.filters active filters
 * @param {Function} props.setFilters state setter for active filters
 * @param {Array} props.reviewDistribution reviews stats from Bv
 * @returns {JSX.Element} Star Rating Component
 */
export default function StarRating({ filtersOpen, filters, setFilters, reviewDistribution }) {
  const categoryEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const stars = [];
  for (let i = MAX_STARS; i > 0; i--) {
    stars.push({
      rating: i,
      reviewCount: reviewsByRatingValue(reviewDistribution, i),
    });
  }

  const handleChange = (e) => {
    if (e.currentTarget.checked) {
      setFilters(new Set(filters).add(e.target.value));
    } else {
      setFilters(new Set([...filters].filter((el) => el != e.target.value)));
    }
  };

  return (
    <div
      ref={categoryEl}
      className={cx(styles.categoryContainer, {
        [styles.open]: isOpen,
        [styles.filtersOpen]: filtersOpen,
      })}
      style={{
        height: isOpen && categoryEl ? categoryEl.current.scrollHeight : '66px',
      }}>
      <AccessibleButton
        className={styles.categoryTitle}
        ariaExpanded={isOpen}
        onClick={() => {
          setIsOpen(!isOpen);
        }}>
        {t('review.filters.rating')}
        <Caret />
      </AccessibleButton>
      <ul className={styles.filters}>
        {stars.map((el, key) => (
          <li key={key} className={styles.filter}>
            <Checkbox
              value={el.rating}
              checked={filters.has(el.rating.toString())}
              disabled={!el.reviewCount}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.currentTarget.checked = !e.currentTarget.checked;
                  handleChange(e);
                }
              }}
              onChange={(e) => handleChange(e)}>
              {el.reviewCount ? (
                <div>
                  {el.rating > 1
                    ? t('review.filters.numStars', [el.rating])
                    : t('review.filters.numStar', [el.rating])}
                  <span className={styles.reviewCount}>
                    {el.reviewCount > 1
                      ? t('review.filters.numReviews', [el.reviewCount])
                      : t('review.filters.numReview', [el.reviewCount])}
                  </span>
                </div>
              ) : el.rating > 1 ? (
                t('review.filters.numStars', [el.rating])
              ) : (
                t('review.filters.numStar', [el.rating])
              )}
            </Checkbox>
          </li>
        ))}
      </ul>
    </div>
  );
}
