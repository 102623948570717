import { getFilteredEntries } from '../../../lib/productUtils';

/**
 * reducer to handle blog landing state
 * @param {object} [state] blog landing state
 * @param {object} [action] action object to be processed
 * @returns {object} new blog landing state
 */
export default function BlogLandingReducer(state, action) {
  switch (action.type) {
    case 'init-state':
      return {
        activeCategory: action.activeCategory,
        currentPage: action.currentPage,
        blogPosts: action.blogPosts,
        filteredBlogPosts: action.filteredBlogPosts,
        categories: action.categories,
      };
    case 'set-active-category':
      return {
        ...state,
        currentPage: 0,
        activeCategory: action.activeCategory,
        filteredBlogPosts:
          state.categories[action.activeCategory] === 'Featured'
            ? state.blogPosts.filter((blogPost) => blogPost.fields.featured)
            : getFilteredEntries(state.blogPosts, [state.categories[action.activeCategory]]),
      };
    case 'set-current-page':
      return {
        ...state,
        currentPage: action.currentPage,
      };
    default:
      throw new Error();
  }
}
