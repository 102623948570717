import AccessibleButton from '../AccessibleButton/AccessibleButton';
import { useTranslation } from '../../lib/hooks';
import styles from './confirmationModal.module.scss';
import { useContext } from 'react';

import SiteContext from '../AppContext';

/**
 * @param {object} [props] component props
 * @param {Function} [props.action] confirm action
 * @param {Function} [props.cancelAction] cancel action
 * @returns {JSX.Element} react component
 */
export default function ConfirmationModal({ action, cancelAction }) {
  const { t } = useTranslation();
  const siteContext = useContext(SiteContext);
  const { brand } = siteContext;

  const btnThemeMap = {
    gather: 'dark',
    'go-solutions': 'light',
    'now-fresh': 'light',
    petcurean: 'dark',
    summit: 'dark',
  };

  return (
    <div className={styles.container}>
      <h4>{t('common.confirmationModal.headline')}</h4>
      <div className={styles.ctasContainer}>
        <AccessibleButton type="primary" theme={btnThemeMap[brand]} onClick={action}>
          {t('common.confirmationModal.yes')}
        </AccessibleButton>
        <AccessibleButton type="secondary" theme={btnThemeMap[brand]} onClick={cancelAction}>
          {t('common.confirmationModal.no')}
        </AccessibleButton>
      </div>
    </div>
  );
}
