import styles from './ingredientTable.module.scss';

/**
 * Ingredient Table component
 * @param {object} [props] component Props
 * @param {string} [props.sectionTitle] Title of the table
 * @param {Array<any>} [props.ingredientQuantities] List of quantities for the table
 * @param {string} [props.finePrint] bottom text
 * @returns {JSX.Element} react component for the ingredient table
 */
export default function IngredientTable({ sectionTitle, ingredientQuantities, finePrint }) {
  return (
    <div className={styles.container}>
      <h5 className={styles.headline}>{sectionTitle}</h5>
      <table className={styles.table}>
        <tbody>
          {ingredientQuantities?.map((item, index) => (
            <tr className={styles.row} key={index}>
              <td className={styles.data}>{item.fields.ingredient.fields.label}</td>
              <td className={styles.data}>{item.fields.quantity}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className={styles.bottomLine}>{finePrint}</p>
    </div>
  );
}
