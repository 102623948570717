// import cookieCutter from 'cookie-cutter';
import { useContext } from 'react';
import cx from 'classnames';
import cookieCutter from 'cookie-cutter';

import SiteContext from '../AppContext';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import RichText from '../Richtext/Richtext';

import styles from './cookieBanner.module.scss';

/**
 * CookieBanner component.
 * @param {object} [props] component props
 * @param {string} [props.className] class to be added to the component
 * @param {string} [props.showCookieBanner] flag to show or hide the banner
 * @param {string} [props.setShowCookieBanner] state setter for showCookieBanner
 * @param {string} [props.copyText] rich text for the cookie banner
 * @param {string} [props.ctaText] text for the CTA
 * @returns {JSX.Element} Layout react component
 */
export default function CookieBanner({
  className,
  showCookieBanner,
  setShowCookieBanner,
  copyText,
  ctaText,
}) {
  const siteContext = useContext(SiteContext);
  const brand = siteContext.brand;
  const handleAccept = () => {
    setShowCookieBanner(false);
    cookieCutter.set('cookieBanner', 'hide');
  };

  return (
    <div
      className={cx(styles.container, className, {
        [styles.visible]: showCookieBanner,
      })}>
      <div className={styles.wrapper}>
        {copyText && <RichText content={copyText} className={styles.text} />}
        {ctaText && (
          <AccessibleButton
            type={brand === 'go-solutions' ? 'secondary' : 'primary'}
            theme="dark"
            className={styles.cta}
            onClick={handleAccept}>
            {ctaText}
          </AccessibleButton>
        )}
      </div>
    </div>
  );
}
