import { useEffect, useRef, useState, useContext } from 'react';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import cx from 'classnames';

import { breakpoints, getImageSize, adjustImageSize } from '../../lib/imageUtils';
import SiteContext from '../AppContext';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import RichText from '../Richtext/Richtext';
import styles from './hero.module.scss';

/**
 * @param {object} [props] component props
 * @param {boolean} [props.hasMarginBottom] hero has a bottom margin
 * @param {string} [props.headline] hero headline
 * @param {object} [props.headerImage] hero header image
 * @param {string} [props.subhead] hero subhead
 * @param {Array<any>} [props.headerCTAs] array of hero ctas
 * @returns {JSX.Element} react component for hero
 */
export default function Hero({
  hasMarginBottom = true,
  headline,
  headerImage,
  subhead,
  headerCTAs,
}) {
  const [animOut, setAnimOut] = useState(false);
  const containerRef = useRef();
  const { brand } = useContext(SiteContext);

  useEffect(() => {
    const scrollSubscription = fromEvent(window, 'scroll')
      .pipe(throttleTime(50))
      .subscribe(() => {
        if (containerRef?.current) {
          const elm = containerRef.current;
          const elmRect = elm.getBoundingClientRect();
          setAnimOut(elmRect.top < -300);
        }
      });

    return () => {
      scrollSubscription.unsubscribe();
    };
  }, []);

  const headerImageSize = headerImage?.fields?.file?.details?.image || [];

  const headerImageSrcSize = [
    { media: breakpoints.xl, size: getImageSize(adjustImageSize(716, brand), headerImageSize) },
    { media: breakpoints.md, size: getImageSize(adjustImageSize(500, brand), headerImageSize) },
    { media: breakpoints.sm, size: getImageSize(adjustImageSize(300, brand, 3), headerImageSize) },
  ];

  const [headerImgWidth, headerImgHeight] = headerImage
    ? getImageSize(adjustImageSize(500, brand), headerImageSize)
    : [adjustImageSize(500, brand), null];

  const isRichTextField =
    typeof subhead === 'object' &&
    subhead !== null &&
    subhead.content &&
    Array.isArray(subhead.content);

  return (
    <div
      className={cx(styles.container, { [styles.marginBottom]: hasMarginBottom })}
      ref={containerRef}>
      <div className={styles.background} />
      <div className={cx(styles.contentWrapper, { [styles.adjustContentToMiddle]: !headerCTAs })}>
        {headline && <h1 className={styles.headline}>{headline}</h1>}
        {subhead &&
          (isRichTextField ? (
            <RichText content={subhead} className={styles.subhead} />
          ) : (
            <p className={styles.subhead}>{subhead}</p>
          ))}
        {headerCTAs && (
          <ul className={styles.ctas}>
            {headerCTAs.map((item, index) => (
              <li className={styles.cta} key={index}>
                <AccessibleButton type="primary" theme="dark" link={item} key={index}>
                  {item.fields && item.fields.linkText}
                </AccessibleButton>
              </li>
            ))}
          </ul>
        )}
      </div>
      {headerImage && (
        <div className={cx(styles.bgImage, { [styles.animOut]: animOut })}>
          <picture>
            {headerImageSrcSize.map(({ media, size }, index) =>
              ['avif', 'webp'].map((imgFormat, formatIndex) => (
                <source
                  key={`${index}${formatIndex}`}
                  media={media}
                  type={`image/${imgFormat}`}
                  srcSet={`${headerImage.fields.file.url}?w=${size[0]}&fm=${imgFormat}, ${
                    headerImage.fields.file.url
                  }?w=${size[0] * 2}&fm=${imgFormat} 2x`}
                />
              )),
            )}
            <img
              src={`${headerImage.fields.file.url}?w=${headerImgWidth}`}
              alt={headerImage.fields.description ?? ''}
              width={headerImgWidth}
              height={headerImgHeight}
            />
          </picture>
        </div>
      )}
    </div>
  );
}
