const algoliasearch = require('algoliasearch/lite');

// eslint-disable-next-line max-len
const client = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_APP,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
);
const index = client.initIndex('stores');

/**
 * Gets stores around a point
 * @param {string} lat latitude of centre point
 * @param {string} lng longitude of centre point
 * @param {string} brand which brand to search for
 * @param {boolean} curbside whether or not the stores have curbside
 * @param {boolean} online whether or not the stores have online shopping
 * @param {string} campaignId a campaign id to filter against (optional)
 * @param {Function} callback callback function with results
 */
export default function getStores(lat, lng, brand, curbside, online, campaignId, callback) {
  let filter = getBrandFilter(brand);

  filter += curbside ? ' AND curbsidepickup:Y' : '';
  filter += online ? ' AND shoponline:Y' : '';

  index
    .search('', {
      aroundLatLng: `${lat}, ${lng}`,
      aroundRadius: 160934, // 100 miles in meters
      filters: filter,
      hitsPerPage: 200,
    })
    .then(({ hits }) => {
      if (campaignId) {
        hits = hits.filter((hit) => {
          return hit.swarm && hit.swarm.length ? hit.swarm.includes(campaignId) : false;
        });
      }

      if (online) {
        hits = hits.filter((hit) => {
          return hit.url != '';
        });
      }

      hits.forEach((store) => {
        store.store_locator_name = store.store_locator_name.replace(/&amp;/g, '&');
      });

      callback(hits);
    });
}
/**
 * Generates an algolia filter based on the product brand
 * @param {string} brand The product brand to filter on
 * @returns {string} The filter based on the brand
 */
function getBrandFilter(brand) {
  const brandFilters = {
    gather: 'product_lines.gather:Y',
    'go-solutions': 'product_lines.go:Y',
    'now-fresh': 'product_lines.now:Y',
    summit: 'product_lines.summit:Y',
  };

  return brandFilters[brand] || 'product_lines.now:Y';
}
