import RemoteIcon from '../RemoteIcon/remoteIcon';

import styles from './iconBulletedList.module.scss';

// list item widget
/**
 * @param {object} [props] // component props
 * @param {object} [props.fields] // component fields
 * @param {string} [props.fields.primaryText] // primaryText
 * @param {object} [props.fields.image] // image
 * @returns {JSX.Element} component for bulleted list
 */
const BulletedList = ({ fields: { primaryText, image } }) => {
  return (
    <div className={styles.listItems}>
      <RemoteIcon url={image.fields.file.url} className={styles.listIcon} />
      <p className={styles.listItemsText}>{primaryText}</p>
    </div>
  );
};

// default component
/**
 * @param {object} [props] // component props
 * @param {object} [props.fields] // component fields
 * @param {string} [props.fields.eyebrow] // eyebrow text
 * @param {string} [props.fields.header] // header text
 * @param {string} [props.fields.description] // description text
 * @param {Array<any>} [props.fields.listItems] // description text
 * @returns {JSX.Element} react component for icon bulleted list
 */
export default function IconBulletedList({ fields: { eyebrow, header, description, listItems } }) {
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <p className={styles.eyebrow}>{eyebrow}</p>
        <h2 className={styles.header}>{header}</h2>
        <p className={styles.description}>{description}</p>
      </div>
      {Array.isArray(listItems) && listItems.length > 0 && (
        <div className={styles.list}>
          {listItems.map((component, index) => (
            <BulletedList {...component} key={index} />
          ))}
        </div>
      )}
    </div>
  );
}
