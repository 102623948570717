import { useRef, useEffect, useState } from 'react';
import { Caret } from '../Icons';
import RichText from '../Richtext/Richtext';
import cx from 'classnames';

import styles from './faqItem.module.scss';

/**
 * @param {object} [props] component props
 * @param {string} [props.id] component id
 * @param {string} [props.question] question 1 text
 * @param {string} [props.answer] answer 1 text
 * @returns {JSX.Element} react component for the FAQ
 */
export default function FAQItem({ id, question, answer }) {
  const [isOpen, setIsOpen] = useState(false);
  const btnRef = useRef();

  const handleKeyDown = (e) => {
    const { keyCode } = e;

    // On enter or space bar, toggle open/closed
    if (keyCode === 13 || keyCode === 32) {
      setIsOpen(!isOpen);
      e.preventDefault();
    }
  };

  useEffect(() => {
    const btn = btnRef?.current;

    if (btn) {
      btn.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      btn.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={cx(styles.container, { [styles.isOpen]: isOpen })} role="presentation">
      <dt className={styles.questionWrapper}>
        <button
          aria-expanded={isOpen}
          className={styles.toggle}
          aria-controls={`${id}-answer`}
          id={`${id}-toggle`}
          onClick={handleClick}
          ref={btnRef}>
          <span className={styles.question}>{question}</span>
          <Caret className={styles.caret} />
        </button>
      </dt>
      <dd
        id={`${id}-answer`}
        role="region"
        hidden={!isOpen}
        aria-labelledby={`${id}-toggle`}
        className={styles.answer}>
        <RichText content={answer} className={styles.answerContent} />
      </dd>
    </div>
  );
}
