import styles from './ingredientDetail.module.scss';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import IngredientTable from '../IngredientTable/IngredientTable';
import { getTextFromIngredients } from './IngredientDetails';
import useTranslation from '../../lib/hooks/useTranslation';
import { Check } from '../Icons';
import { useContext } from 'react';
import SiteContext from '../AppContext';
import ExpandableElement from '../ExpandableElement/ExpandableElement';
import { addExpandableFormat } from '../../lib/utils';
import cx from 'classnames';

/**
 * @param {object} [props] component props
 * @param {Array<object>} [props.cat2Recipe] parsed cat 2 ingredients
 * @param {string} [props.calorieContent] calorie content
 * @param {string} [props.recommendedFor] recommended for text
 * @param {object} [props.pdfLink] pdf link using external link structure
 * @param {Array<any>} [props.guaranteedAnalysis] List to be used in the table
 * @param {string} [props.guaranteedAnalysisFinePrint] bottom table text
 * @param {boolean} [props.active] the ingredients table is active or not
 * @returns {JSX.Element} react component
 */
export default function Cat2Info({
  cat2Recipe,
  calorieContent,
  recommendedFor,
  pdfLink,
  guaranteedAnalysis,
  guaranteedAnalysisFinePrint,
  active,
}) {
  const { t } = useTranslation();
  const { brand } = useContext(SiteContext);
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.copyContainer}>
          {cat2Recipe && (
            <div className={styles.textSection}>
              <h3 className={styles.title}>{t('table.ingredients')}</h3>
              <ExpandableElement
                collapsedHeight={120}
                className={styles.recipe}
                initialize={active}>
                <p
                  className={styles.text}
                  dangerouslySetInnerHTML={{
                    __html: addExpandableFormat(getTextFromIngredients(cat2Recipe), 161),
                  }}
                />
              </ExpandableElement>
              <p className={cx(styles.text, styles.medium)}>{getTextFromIngredients(cat2Recipe)}</p>
            </div>
          )}
          {calorieContent && (
            <div>
              <h3 className={styles.title}>{t('table.calorieContent')}</h3>
              <div className={styles.textSection}>{calorieContent}</div>
            </div>
          )}
          {recommendedFor && (
            <div className={styles.textSection}>
              <h3 className={styles.title}>{t('table.recommendedFor')}</h3>
              <ul className={styles.recommendedList}>
                {recommendedFor.map((item, index) => (
                  <li className={styles.recommended} key={index}>
                    <Check />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        {guaranteedAnalysis && (
          <IngredientTable
            sectionTitle={t('table.guaranteedAnalysis')}
            ingredientQuantities={guaranteedAnalysis}
            finePrint={guaranteedAnalysisFinePrint}
          />
        )}
      </div>
      {pdfLink?.fields?.linkAddress && (
        <AccessibleButton
          icon="Download"
          className={styles.downloadCta}
          type={brand === 'go-solutions' ? 'primary' : 'secondary'}
          theme="dark"
          link={pdfLink}>
          Download Nutrient Profile
        </AccessibleButton>
      )}
    </div>
  );
}
