import { useEffect, useRef, useState, useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import { fromEvent, interval } from 'rxjs';
import { debounce, distinctUntilChanged, map } from 'rxjs/operators';
import { getInternalLinkFromString } from '../../lib/utils';
import useTranslation from '../../lib/hooks/useTranslation';
import SiteContext from '../AppContext';

import GenericLink from '../GenericLink/GenericLink';
import { Arrow, LongThinArrow, Video, ExternalLarge } from '../Icons';
import Pagination from '../Pagination/Pagination';
import Picture from '../Image/Picture';
import { getImageSize } from '../../lib/imageUtils';
import cx from 'classnames';

import styles from './blogGrid.module.scss';

/**
 * @param {object} [props] component props
 * @param {object} [props.articleHeroImage] image content
 * @param {Array} [props.tagLabels] content tags
 * @param {string} [props.title] blog post title
 * @param {string} [props.slug] blog post slug
 * @param {string} [props.externalSlug] blog post slug for external blog posts
 * @param {string} [props.publishDate] publish date for blog posts
 * @param {string} [props.heroVideoEmbedCode] video embed id
 * @param {string} [props.heroVideoEmbedSource] video embed type - youtube or vimeo
 * @param {number} [props.index] index of BlogCard
 * @returns {JSX.Element} react component
 */
function BlogCard({
  articleHeroImage,
  tagLabels,
  title,
  slug,
  externalSlug,
  publishDate,
  heroVideoEmbedCode,
  heroVideoEmbedSource,
  index,
}) {
  const { locale, brand } = useContext(SiteContext);

  const blogGridSize = articleHeroImage?.fields?.file?.details?.image;
  const blogGridImageSizes = {
    xl: getImageSize(375, blogGridSize),
    lg: getImageSize(332, blogGridSize),
    md: getImageSize(423, blogGridSize),
    sm: getImageSize(375, blogGridSize),
    fallbackSize: getImageSize(332, blogGridSize),
  };

  const [animIn, setAnimIn] = useState(false);

  const [blogCardImgRef, inView] = useInView({
    rootMargin: '-100px 0px',
    triggerOnce: true,
  });

  const isExternalBlogPost = !!externalSlug;

  useEffect(() => {
    if (inView) {
      setAnimIn(true);
    }
  }, [inView]);

  const formatDate = (date, locale) => {
    return new Date(date).toLocaleDateString(locale.toLowerCase(), {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
    });
  };
  const publishedAtDate = formatDate(publishDate, locale);

  const ArrowIcon =
    brand === 'now-fresh' ? (
      <LongThinArrow className={styles.arrow} />
    ) : (
      <Arrow className={styles.arrow} />
    );

  return (
    <GenericLink
      internalLink={!isExternalBlogPost ? getInternalLinkFromString(slug) : null}
      externalLink={isExternalBlogPost ? externalSlug : null}
      externalIcon={false}
      className={styles.cardContainer}>
      {articleHeroImage?.fields?.file && (
        <div
          className={cx(styles.imgContainer, {
            [styles.animIn]: animIn,
          })}
          aria-hidden="true"
          ref={blogCardImgRef}>
          <Picture
            url={articleHeroImage.fields.file.url}
            imageSizes={blogGridImageSizes}
            quality="50"
            alt={articleHeroImage.fields.description}
            loading={index > 3 ? 'lazy' : 'eager'}
          />
          {heroVideoEmbedCode && heroVideoEmbedSource && <Video className={styles.videoIcon} />}
        </div>
      )}
      {isExternalBlogPost && <p className={styles.date}>{publishedAtDate}</p>}
      <div
        className={cx(styles.tags, {
          [styles.externalBlogTags]: isExternalBlogPost,
        })}>
        {tagLabels && tagLabels.slice(0, 2).map((label, i) => <span key={i}>{label}</span>)}
      </div>
      <div
        className={cx(styles.cardHeadlineContainer, {
          [styles.alignArrowIcon]: isExternalBlogPost,
        })}>
        <p className={styles.cardHeadline}>{title}</p>
        {isExternalBlogPost ? <ExternalLarge className={styles.externalArrow} /> : ArrowIcon}
      </div>
    </GenericLink>
  );
}

/**
 * @returns {number} number of results according to viewport
 */
function getResultPerPage() {
  const resultsPerViewportWidth = {
    small: 8,
    medium: 10,
    large: 12,
  };
  if (window.innerWidth < 720) {
    return resultsPerViewportWidth.small;
  } else if (window.innerWidth >= 720 && window.innerWidth < 1024) {
    return resultsPerViewportWidth.medium;
  } else {
    return resultsPerViewportWidth.large;
  }
}

/**
 * @param {object} [props] component props
 * @param {Array<object>} [props.blogPosts] blog posts
 * @param {object} [props.state] blog landing state
 * @param {Function} [props.dispatch] blog landing dispatcher
 * @returns {JSX.Element} react component
 */
export default function BlogGrid({ blogPosts, state, dispatch }) {
  const { t } = useTranslation();
  const blogGridEl = useRef();
  const [resultsPerPage, setResultsPerPage] = useState(0);
  const firstResultFromPage = state.currentPage * resultsPerPage;
  const lastResultFromPage = state.currentPage * resultsPerPage + resultsPerPage;

  useEffect(() => {
    setResultsPerPage(getResultPerPage());
    const resizeSubscription = fromEvent(window, 'resize')
      .pipe(
        debounce(() => interval(50)),
        map(() => getResultPerPage()),
        distinctUntilChanged(),
      )
      .subscribe((resultsPerPage) => {
        dispatch({ type: 'set-current-page', currentPage: 0 });
        setResultsPerPage(resultsPerPage);
      });

    return () => {
      resizeSubscription.unsubscribe();
    };
  }, [dispatch]);

  const blogPostsToShow = blogPosts ? blogPosts.slice(firstResultFromPage, lastResultFromPage) : [];

  return (
    <section className={styles.container} ref={blogGridEl}>
      <div className={styles.results}>
        {blogPostsToShow.length > 0 && (
          <span>
            {t('pagination', [
              blogPosts.length > 0 ? firstResultFromPage + 1 : 0,
              lastResultFromPage < blogPosts.length ? lastResultFromPage : blogPosts.length,
              blogPosts.length,
            ])}
          </span>
        )}
      </div>
      {blogPostsToShow.map((blogPost, i) => (
        <BlogCard {...blogPost.fields} index={i} key={i} />
      ))}
      {blogPosts.length > resultsPerPage && (
        <Pagination
          resultPerPage={resultsPerPage}
          className={styles.pagination}
          totalResults={blogPosts.length}
          page={state.currentPage}
          setPage={(callback) => {
            const newPage = typeof callback === 'function' ? callback(state.currentPage) : callback;
            dispatch({ type: 'set-current-page', currentPage: newPage });
          }}
          resultsEl={blogGridEl}
        />
      )}
    </section>
  );
}
