import cx from 'classnames';
import styles from './subNav.module.scss';
import GenericLink from '../GenericLink/GenericLink';
import Picture from '../Image/Picture';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import { useState } from 'react';

/**
 * @param {object} [props] component props
 * @param {string} [props.columnHeader] column header
 * @param {Array} [props.columnLinks] CMS links
 * @param {Function} [props.dispatch] navReducer action dispatcher
 * @param {boolean} [props.active] set column as active for small viewports
 * @returns {JSX.element} react component
 */
function SubNavColumn({ columnHeader, columnLinks, dispatch, active }) {
  return (
    <div className={cx(styles.column, { [styles.activeColumn]: active })}>
      <p className={styles.columnHeader}>{columnHeader}</p>
      <AccessibleButton
        className={styles.interactiveColumnHeader}
        onClick={() => {
          dispatch({ type: 'close-sub-nav-small' });
        }}>
        {columnHeader}
      </AccessibleButton>
      <ul className={styles.links}>
        {columnLinks.map((link, i) => (
          <GenericLink
            internalLink={link?.fields?.internalPage}
            externalLink={link?.fields?.linkAddress}
            className={styles.link}
            key={i}>
            {link?.fields?.linkText}
          </GenericLink>
        ))}
      </ul>
    </div>
  );
}

/**
 * @param {object} [props] component props
 * @param {string} [props.expandedNavigationItemText] header text
 * @param {object} [props.link] if the sub nav is a link, this parameter has the CMS value
 * @param {Array<object>} [props.navigationItems] sub nav links
 * @param {object} [props.backgroundImage] background CMS image object
 * @param {number} [props.index] current item index
 * @param {Function} [props.dispatch] navReducer action dispatcher
 * @returns {JSX.element} react component
 */
export default function SubNav({
  expandedNavigationItemText,
  link,
  navigationItems,
  backgroundImage,
  index,
  dispatch,
}) {
  const [activeColumn, setActiveColumn] = useState();
  return (
    <div className={cx(styles.container, { [styles.fullWidth]: navigationItems?.length === 0 })}>
      {
        <>
          {link ? (
            <GenericLink
              className={cx(styles.linkCategory, {
                [styles.external]: !link.fields.internalPage,
                [styles.fullWidth]: !navigationItems,
              })}
              externalLink={link.fields.linkAddress}
              internalLink={link.fields.internalPage}>
              {link.fields.linkText}
            </GenericLink>
          ) : (
            <span className={cx(styles.categoryHeader)}>{expandedNavigationItemText}</span>
          )}
          {navigationItems?.length > 0 && (
            <ul className={styles.columnHeaderCategories}>
              {navigationItems.map((column, i) => {
                if (!column.fields.title && !column.fields.links) {
                  return;
                }
                return (
                  <li className={styles.columnHeaderCategory} key={i}>
                    <AccessibleButton
                      className={styles.category}
                      onClick={() => {
                        dispatch({ type: 'open-sub-nav-small', activeSubNav: index });
                        setActiveColumn(() => i);
                      }}>
                      {column.fields.title}
                    </AccessibleButton>
                  </li>
                );
              })}
            </ul>
          )}
        </>
      }
      {navigationItems?.length > 0 && (
        <div className={styles.subnavContent}>
          {navigationItems.map((column, i) => {
            if (!column.fields.title && !column.fields.links) {
              return;
            }
            return (
              <SubNavColumn
                key={i}
                columnHeader={column.fields.title}
                columnLinks={column.fields.links}
                dispatch={dispatch}
                interactive={true}
                active={activeColumn === i}
              />
            );
          })}
          {backgroundImage?.fields?.file && (
            <Picture
              className={styles.backgroundImage}
              imageSizes={{
                sm: [178, 271],
                md: [253, 455],
                lg: [253, 455],
                fallbackSize: [253, 455],
              }}
              url={backgroundImage.fields.file.url}
              alt={backgroundImage.fields.description}
              quality="50"
            />
          )}
        </div>
      )}
    </div>
  );
}
