import styles from './reviewCard.module.scss';
import { useTranslation } from '../../lib/hooks';
import Rating from '../Rating/Rating';
import { useContext, useState } from 'react';
import SiteContext from '../AppContext';
import ExpandableElement from '../ExpandableElement/ExpandableElement';
import { addExpandableFormat } from '../../lib/utils';
import { Recommended, ReviewCat, ReviewDog } from '../Icons';
import ReviewCarousel from './ReviewCarousel';
import ReviewFeedback from './ReviewFeedback';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import googleTranslate from '../../lib/googleTranslate';
import Loader from '../Loader/Loader';
import { getReviewSchema } from '../../lib/Schema';
import Head from 'next/head';

/**
 *
 * @param {string} city user supplied city
 * @param {string} country user selected country
 * @returns {string} formatted location string for display
 */
function formatReviewerLocation(city, country) {
  if (city && country) {
    return `${city.Value}, ${country}`;
  } else if (city && !country) {
    return `${city.Value}`;
  } else if (!city && country) {
    return `${country}`;
  } else {
    // no location data
    return '';
  }
}

/**
 * @param {object} [props] component props
 * @param {string} [props.petType] cat/dog product
 * @param {string} [props.UserNickname] reviewer name
 * @param {string} [props.Title] review title
 * @param {string} [props.ReviewText] review body
 * @param {string} [props.ClientResponses] petcurean response
 * @param {number} [props.Rating] review rating
 * @param {string} [props.SubmissionTime] review date
 * @param {object} [props.AdditionalFields] custom fields from bv
 * @param {object} [props.ContextDataValues] custom select fields from bv
 * @param {object} [props.ContentLocale] review locale
 * @param {number} [props.TotalNegativeFeedbackCount] score from bv
 * @param {number} [props.TotalPositiveFeedbackCount] score from bv
 * @param {string} [props.Id] review Id in Bv
 * @param {string} [props.UserLocation] user entered location
 * @param {Array<object>} [props.Photos] review photos
 * @param {boolean} [props.IsRecommended] is the product recommended by reviewer
 * @param {string} [props.ProductId] product id in bv
 * @returns {JSX.Element} react component
 */
export default function ReviewCard({
  petType,
  UserNickname,
  Title,
  ReviewText,
  ClientResponses,
  Rating: rating,
  SubmissionTime,
  AdditionalFields,
  ContextDataValues,
  ContentLocale,
  TotalNegativeFeedbackCount,
  TotalPositiveFeedbackCount,
  Id,
  UserLocation,
  Photos,
  IsRecommended,
  ProductId,
}) {
  const { brand, locale } = useContext(SiteContext);
  const [translatedContent, setTranslatedcontent] = useState('');
  const [showTranslation, setShowTranslation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const formattedDate = new Date(SubmissionTime).toLocaleDateString(locale.toLowerCase());
  const { t } = useTranslation();
  const responseIconMap = {
    gather: '/gather/review-response-icon.png',
    'go-solutions': '/go-solutions/review-response-icon.png',
    'now-fresh': '/now-fresh/review-response-icon.png',
    summit: '/summit/review-response-icon.png',
  };
  const responseIconUrl = responseIconMap[brand];
  const petIcon = petType === 'Cat' ? <ReviewCat /> : <ReviewDog />;
  const hasPetBio =
    AdditionalFields?.PetsName?.Value ||
    AdditionalFields?.PetsBreed?.Value ||
    ContextDataValues?.PetsAge?.ValueLabel;
  const responseBody = ClientResponses?.length > 0 ? ClientResponses[0].Response : '';
  const brandNames = {
    gather: 'Gather',
    'go-solutions': 'GO! SOLUTIONS',
    'now-fresh': 'NOW FRESH',
    petcurean: 'Petcurean',
    summit: 'Summit',
  };
  const reviewLang = ContentLocale.slice(0, 2).toLowerCase();
  const userLang = locale.slice(0, 2).toLowerCase();
  const showTranslationButton = reviewLang !== userLang;
  const reviewTitle = showTranslation ? translatedContent[0].translatedText : Title;
  const reviewBody = showTranslation ? translatedContent[1].translatedText : ReviewText;
  const response = showTranslation ? translatedContent[2].translatedText : responseBody;
  // if a username is rejected in BV it returns null in the response
  UserNickname = UserNickname || 'Anonymous';
  // filter out any malformed image uploads
  Photos = Photos.filter((img) => img?.Sizes?.normal?.Url);
  const reviewSchema = getReviewSchema({
    UserNickname,
    Title,
    ReviewText,
    rating,
    SubmissionTime,
    Photos,
    ProductId,
  });

  /**
   * function to update component on calling GTranslate
   */
  async function handleTranslate() {
    setIsLoading(true);
    if (translatedContent) {
      setShowTranslation(!showTranslation);
    } else {
      const translation = await googleTranslate(userLang, reviewLang, [
        Title,
        ReviewText,
        responseBody,
      ]);
      // if we get a good response
      if (translation?.data?.translations?.length > 0) {
        setTranslatedcontent(translation.data.translations);
        setShowTranslation(true);
      } else {
        console.log('translation service error:', translation);
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(reviewSchema) }}
        />
      </Head>
      <div className={styles.container}>
        <div className={styles.reviewerContainer}>
          <p className={styles.reviewer}>
            {UserNickname}
            <span className={styles.date}>{formattedDate}</span>
          </p>
          <p className={styles.reviewerLocation}>
            {formatReviewerLocation(AdditionalFields.City, UserLocation)}
          </p>
          {ContextDataValues?.TimeSpentFeeding?.ValueLabel && (
            <p className={styles.feedingSince}>
              {t('review.feedingSince')} {ContextDataValues?.TimeSpentFeeding?.ValueLabel}
            </p>
          )}
          {hasPetBio && (
            <div className={styles.petBio}>
              <div className={styles.headline}>
                {petIcon}
                <h4>{t('review.petInfoTitle')}</h4>
              </div>
              {AdditionalFields?.PetsName?.Value && (
                <p>
                  <span>{t('review.petName')}</span> {AdditionalFields.PetsName.Value}
                </p>
              )}
              {AdditionalFields?.PetsBreed?.Value && (
                <p>
                  <span>{t('review.petBreed')}</span> {AdditionalFields.PetsBreed.Value}
                </p>
              )}
              {ContextDataValues?.PetsAge?.ValueLabel && (
                <p>
                  <span>{t('review.petAge')}</span> {ContextDataValues.PetsAge.ValueLabel}
                </p>
              )}
            </div>
          )}
        </div>
        <div className={styles.reviewContainer}>
          <div className={styles.topContainer}>
            <Rating rating={rating} className={styles.rating} />
            <span className={styles.date}>{formattedDate}</span>
          </div>
          <h3 className={styles.reviewTitle}>
            <bdi>{reviewTitle}</bdi>
          </h3>
          <ExpandableElement
            collapsedHeight={52}
            fromViewportWidth={720}
            viewMoreI18nKey={'review.readMore'}
            viewLessI18nKey={'review.lessInformation'}
            viewMoreAriaLabel={t('review.readMoreFrom', [UserNickname])}
            viewMoreTheme={brand === 'gather' ? 'dark' : null}
            className={styles.expandable}>
            <p className={styles.reviewBody}>
              <bdi dangerouslySetInnerHTML={{ __html: addExpandableFormat(reviewBody, 90) }} />
            </p>
          </ExpandableElement>
          <div className={styles.translationControls}>
            {showTranslationButton && !showTranslation && (
              <AccessibleButton
                onClick={handleTranslate}
                disabled={isLoading || showTranslation}
                className={styles.translateBtn}>
                {isLoading ? <Loader /> : t('review.translateWith')}
              </AccessibleButton>
            )}
            {showTranslation && (
              <>
                <a
                  className={styles.googleAttribution}
                  href="http://translate.google.com"
                  target="_blank"
                  rel="noreferrer">
                  <img
                    alt=""
                    src="/google-translated.png"
                    srcSet="/google-translated-1x.png 1x,
                    /google-translated-2x.png 2x"
                  />
                </a>
                <AccessibleButton
                  className={styles.translateBtn}
                  onClick={() => setShowTranslation(!showTranslation)}>
                  {t('review.showOriginal')}
                </AccessibleButton>
              </>
            )}
          </div>
          {Photos.length > 0 && <ReviewCarousel images={Photos} />}
          {hasPetBio && (
            <div className={styles.petBio}>
              <div className={styles.headline}>
                {petIcon}
                <h4>{t('review.petInfoTitle')}</h4>
              </div>
              {AdditionalFields?.PetsName?.Value && (
                <p>
                  <span>{t('review.petName')}</span> {AdditionalFields.PetsName.Value}
                </p>
              )}
              {ContextDataValues?.PetsAge?.ValueLabel && (
                <p>
                  <span>{t('review.petAge')}</span> {ContextDataValues.PetsAge.ValueLabel}
                </p>
              )}
            </div>
          )}
          {IsRecommended && (
            <div className={styles.recommendedContainer}>
              <Recommended />
              <p>{t('review.authorRecommends')}</p>
            </div>
          )}
          <ReviewFeedback
            TotalNegativeFeedbackCount={TotalNegativeFeedbackCount}
            TotalPositiveFeedbackCount={TotalPositiveFeedbackCount}
            reviewId={Id}
            brand={brand}
          />
          {ContextDataValues?.IncentivizedReview?.Value === 'True' && (
            <p className={styles.incentivizedReview}>
              {ContextDataValues.IncentivizedReview.DimensionLabel}
            </p>
          )}
        </div>
      </div>
      {response && (
        <div className={styles.responseContainer}>
          <p className={styles.responseHeader}>{t('review.responseIntro', [brandNames[brand]])}</p>
          <div className={styles.responseWrapper}>
            <img src={responseIconUrl} alt={t('review.portraitRespondent')} />
            <div className={styles.responseBodyWrapper}>
              <p className={styles.responseTitle}>{brandNames[brand]}</p>
              <p className={styles.responseBody}>{response}</p>
              {showTranslation && (
                <a
                  className={styles.googleAttribution}
                  href="http://translate.google.com"
                  target="_blank"
                  rel="noreferrer">
                  <img
                    alt=""
                    src="/google-translated-1x.png"
                    srcSet="/google-translated-1x.png 1x,
                    /google-translated-2x.png 2x"
                  />
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
