import styles from './blogCategories.module.scss';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import cx from 'classnames';
import { useRef } from 'react';

/**
 * @param {object} [props] component props
 * @param {Array<string>} [props.categories] categories
 * @param {object} [props.state] Blog Listing state
 * @param {Function} [props.dispatch] Blog Listing dispatcher
 * @returns {JSX.Element} react component
 */
export default function BlogCategories({ categories, state, dispatch }) {
  const listEl = useRef(null);
  return (
    <section className={styles.container}>
      <ul className={styles.categoryList} ref={listEl}>
        {categories.map((category, i) => (
          <li
            className={cx(styles.categoryContainer, {
              [styles.active]: state.activeCategory === i,
            })}
            key={i}>
            <AccessibleButton
              className={styles.category}
              hasDataText={true}
              onClick={(e) => {
                if (window.innerWidth < 1024) {
                  const marginLeft = parseInt(getComputedStyle(listEl.current).marginLeft);
                  const marginOffset = window.innerWidth > 720 ? 24 : 16;
                  listEl.current.scrollTo(
                    e.currentTarget.parentNode.offsetLeft - marginLeft - marginOffset,
                    0,
                  );
                }
                dispatch({ type: 'set-active-category', activeCategory: i });
              }}>
              {category}
            </AccessibleButton>
          </li>
        ))}
      </ul>
    </section>
  );
}
