import styles from './heroCopy.module.scss';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import { useTranslation } from '../../lib/hooks';
import Rating from '../Rating/Rating';
import StyledLink from '../StyledLink/StyledLink';
import StyledForm from '../StyledForm/StyledForm';
import Modal from '../Modal/Modal';
import { useRef, useContext } from 'react';
import SiteContext from '../AppContext';
import { getInternalPageFromString, getInternalLinkFromString } from '../../lib/utils';
import GenericLink from '../GenericLink/GenericLink';
import { Amazon, Bag, Chewy } from '../Icons';
import WhereToBuyWidget from '../PriceSpiderWidgets/WhereToBuyWidget';

const BUYONLINE_ICONS = {
  Amazon: Amazon,
  Chewy: Chewy,
};

/**
 * @param {object} [props] component props
 * @param {string} [props.brand] product brand
 * @param {string} [props.titleEyebrow] hero eyebrow
 * @param {string} [props.productName] Product name
 * @param {string} [props.shortDescription] Hero description
 * @param {string} [props.packaging] Product presentations
 * @param {Array<any>} [props.tagLabels] product tags
 * @param {string} [props.whereToBuyLink] Link for where to buy CTA
 * @param {object} [props.contactUsForm] Contact us form data
 * @param {number} [props.aggregate] aggregate rating value
 * @param {Array<object>}[props.reviews] reviews from product
 * @param {object}[props.externalIDs] external IDs from product
 * @param {object} [props.bazaarvoiceStats] stats from Bazaarvoice
 * @param {number} [props.totalReviews] total reviews for this product
 * @param {Array<object>} [props.buyOnlineLinks] links to buy online
 * @returns {JSX.Element} hero copy react component
 */
export default function HeroCopy({
  brand,
  titleEyebrow,
  productName,
  shortDescription,
  packaging,
  tagLabels,
  whereToBuyLink,
  contactUsForm,
  aggregate,
  reviews,
  externalIDs,
  bazaarvoiceStats,
  totalReviews,
  buyOnlineLinks,
}) {
  const finalWhereToBuyLink = whereToBuyLink || getInternalPageFromString('store-locator');
  const showContactForm = brand.fields.hasContactForm;

  const { locale } = useContext(SiteContext);
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const openerRef = useRef(null);
  const openModal = () => {
    modalRef.current.showModal();
  };

  const linkTheme = {
    gather: 'dark',
    summit: 'dark',
    'go-solutions': 'light',
    'now-fresh': 'light',
  };

  const btnTheme = {
    gather: 'dark',
    summit: 'dark',
    'go-solutions': null,
    'now-fresh': null,
  };

  // designed as an en-us feature only
  if (locale !== 'en-us') buyOnlineLinks = null;

  const renderRatingContainer =
    (locale === 'zh-cn' && totalReviews === 0) || (locale === 'ru-ru' && totalReviews === 0);

  const isGather = brand.fields.slug === 'gather';
  const noBuyOnlineSection = brand.fields.slug === 'gather' && brand.fields.slug === 'summit';

  const isPriceSpiderEnabled = locale === 'en-us' || locale === 'en-ca' || locale === 'fr-ca';

  return (
    <div className={styles.container}>
      <h1 className={styles.headline}>
        <span className={styles.eyebrow}>{titleEyebrow} </span>
        {productName}
      </h1>
      {!renderRatingContainer && (
        <div className={styles.ratingContainer}>
          <Rating rating={aggregate} className={styles.rating} />
          <StyledLink
            className={styles.reviews}
            type="primary"
            href="#reviews"
            target="_self"
            theme={linkTheme[brand.fields.slug]}>
            {totalReviews === 1 ? t('hero.review') : t('hero.reviews', [totalReviews])}
          </StyledLink>
        </div>
      )}
      <p className={styles.description}>{shortDescription}</p>
      <p className={styles.packages}>{packaging}</p>
      {tagLabels && (
        <ul className={styles.tags}>
          {tagLabels.map((tag, index) => (
            <li className={styles.tag} key={index}>
              {tag}
            </li>
          ))}
        </ul>
      )}
      <div className={styles.buttonsContainer}>
        {(whereToBuyLink && !isPriceSpiderEnabled) || isGather ? (
          <AccessibleButton
            type="primary"
            theme={btnTheme[brand.fields.slug]}
            link={finalWhereToBuyLink}>
            {t('hero.whereToBuy')}
          </AccessibleButton>
        ) : (
          <WhereToBuyWidget sku={externalIDs.sku} />
        )}
        {showContactForm && (
          <AccessibleButton
            ref={openerRef}
            onClick={openModal}
            type="secondary"
            theme={btnTheme[brand.fields.slug]}>
            {t('hero.askAQuestion')}
          </AccessibleButton>
        )}
        {/* This copy of the form outside the modal is important because the original */}
        {/* contactus form is inside a modal. If removed Netlify wont be able to crawl */}
        {/* it during build time and the data wont be registered. */}
        {showContactForm && contactUsForm?.fields && (
          <StyledForm
            fields={contactUsForm?.fields}
            id="heroCopy-hidden"
            hidden={true}
            netlify={true}
            className={styles.staticForm}
          />
        )}
        {showContactForm && (
          <Modal ref={modalRef} opener={openerRef}>
            {/* There's a copy of this form in the FooterTop with the netlify attribute */}
            {/* set to true */}
            {contactUsForm?.fields && (
              <StyledForm
                fields={contactUsForm?.fields}
                id="heroCopy"
                hidden={false}
                netlify={false}
                ref={modalRef}
              />
            )}
          </Modal>
        )}
      </div>
      {noBuyOnlineSection && buyOnlineLinks && buyOnlineLinks.length > 0 && (
        <div className={styles.buyOnlineContainer}>
          <p className={styles.eyebrow}>{t('hero.buyOnline')}</p>
          <ul className={styles.buyOnlineLinkList}>
            {buyOnlineLinks.map((link, i) => {
              const Icon = BUYONLINE_ICONS[link.fields?.linkIcon] || null;
              return (
                <li key={i} className={styles.buyOnlineLinkContainer}>
                  <GenericLink
                    externalLink={link.fields.linkAddress}
                    className={styles.buyOnlineLink}>
                    {Icon && <Icon className={styles.linkIcon} />}
                    {link.fields.linkText}
                  </GenericLink>
                </li>
              );
            })}
            <li className={styles.buyOnlineLinkContainer}>
              <GenericLink
                internalLink={getInternalLinkFromString('store-locator')}
                className={styles.buyOnlineLink}>
                <Bag className={styles.linkIcon} />
                {t('hero.buyOnlineOther')}
              </GenericLink>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
