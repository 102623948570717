import { useTranslation } from '../../lib/hooks';
import { useDropzone } from 'react-dropzone';
import cx from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getAllowedFileTypeObject } from '../../lib/utils';

import styles from '../StyledFileDragAndDrop/styledFileDragAndDrop.module.scss';
import SingleFileUpload from './SingleFileUpload';
import UploadError from './UploadError';

/**
 * Styled file drag and drop component
 * @param {string} [props] component props
 * @param {string} [props.type] type of input
 * @param {string} [props.id] id of input
 * @param {string} [props.name] name of input
 * @param {string} [props.value] value of field
 * @param {string} [props.helperText] helper text
 * @param {string} [props.label] label of input
 * @param {string} [props.maxFiles] max number of files
 * @param {string} [props.maxSize] max file size
 * @param {string} [props.fileTypesAllowed] allowable file types
 * @param {Function} [props.onChange] function for the change event
 * @param {boolean} [props.showError] whether to show error message
 * @param {string} [props.errorMsg] message to display on error
 * @param {string} [props.className] additional class to attach to the component
 * @param {string} [props.ariaRequired] flag for the aria-required attribute
 * @param {string} [props.setFileUploading] method to set the status of the file upload
 * @returns {JSX.Element} StyledFileDragAndDrop react component
 */
export default function BvMediaUpload({
  type,
  id,
  name,
  value,
  helperText,
  label,
  maxFiles,
  maxSize,
  fileTypesAllowed,
  onChange,
  showError,
  errorMsg,
  className,
  ariaRequired,
  setFileUploading,
}) {
  const [files, setFiles] = useState(value);
  const { t } = useTranslation();

  const onDrop = useCallback((accFiles, rejFiles) => {
    const mappedAcc = accFiles.map((file, i) => ({ file, errors: [], id: uuidv4() }));
    const mappedRej = rejFiles.map((r) => ({ ...r, id: uuidv4() }));
    setFiles((curr) => [...curr, ...mappedAcc, ...mappedRej]);
  }, []);

  useEffect(() => {
    let activeUpload = false;
    if (files?.length > 0) {
      const uploaded = files.filter((fw) => fw.url || fw.errors.length > 0);
      activeUpload = uploaded.length !== files.length;
    }

    const fakeEvt = {
      target: {
        type: 'bvMediaUpload',
        name,
        value: files,
      },
    };

    setFileUploading(activeUpload);
    onChange(fakeEvt);
    // including the onChange function as a dep causes render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, name]);

  /**
   *
   * @param {object} file the file object to process
   * @param {string} url image url returned from Bazaarvoice
   * @param {object} error any client facing errors returned from the BV api
   */
  function onUpload(file, url, error) {
    setFiles((curr) => {
      return curr.map((fw) => {
        if (fw.file === file) {
          const newFw = { ...fw, url };
          if (error) newFw.errors.push(error);
          return newFw;
        }
        return fw;
      });
    });
  }

  /**
   *
   * @param {object} file the file object to delete
   */
  function onDelete(file) {
    let newFiles = files.filter((fw) => fw.file !== file);
    if (newFiles.length <= maxFiles) {
      newFiles = newFiles.map((fw) => {
        fw.errors = fw.errors.filter((error) => error.code !== 'too-many-files');
        return fw;
      });
    }
    setFiles(newFiles);
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: getAllowedFileTypeObject(fileTypesAllowed),
    maxSize,
    minSize: 1,
    maxFiles,
    onDrop,
  });
  return (
    <div
      className={cx(styles.container, className, {
        [styles.error]: files.length > maxFiles || showError,
      })}>
      <label className={cx('p2 bold', styles.label)} htmlFor={id}>
        {label}
        {helperText && <span className={styles.helperText}>{helperText}</span>}
      </label>
      <div {...getRootProps()} className={styles.dropzone}>
        <input {...getInputProps()} id={id} name={name} aria-required={ariaRequired} />
        <p>{t('common.forms.general.dragAndDrop')}</p>
      </div>
      {!!files?.length && (
        <aside className={styles.fileList}>
          <p className={styles.fileListTitle}>{t('common.forms.general.files')}</p>
          <ul>
            {files.map((fileWrapper, i) =>
              fileWrapper.errors?.length ? (
                <UploadError
                  file={fileWrapper.file}
                  key={fileWrapper.id}
                  errors={fileWrapper.errors}
                  onDelete={onDelete}
                />
              ) : (
                <SingleFileUpload
                  file={fileWrapper.file}
                  key={fileWrapper.id}
                  onDelete={onDelete}
                  onUpload={onUpload}
                />
              ),
            )}
          </ul>
        </aside>
      )}
      {files.length > maxFiles && (
        <p className={styles.errorMsg}>{t('common.forms.general.fileLimit', [maxFiles])}</p>
      )}
      {showError && <p className={styles.errorMsg}>{errorMsg}</p>}
    </div>
  );
}
