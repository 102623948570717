import cx from 'classnames';
import { useTranslation } from '../../lib/hooks';

import { List, Map } from '../Icons';

import styles from './mapListControls.module.scss';
import { useRef, useState, useEffect, useContext } from 'react';
import Checkbox from '../Checkbox/Checkbox';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import { Caret } from '../Icons';
import SiteContext from '../AppContext';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Component with display controls for the store map and list
 * @param {object} [props] component props
 * @param {boolean} [props.hasMap] flag to enable/disable the map
 * @param {string} [props.activeView] indicates the active view (either "map" or "list")
 * @param {Function} [props.setActiveView] sets the value of active view
 * @param {boolean} [props.showCurbside] checked state of shop curbside filter
 * @param {Function} [props.setShowCurbside] sets the value of curbside pickup filter
 * @param {boolean} [props.showOnline] checked state of shop online filter
 * @param {Function} [props.setShowShopOnline] sets the value of shop Online filter
 * @param {string} [props.purchaseFilterLabel] label for filter group
 * @param {boolean} [props.hasCurbsidePickup] flag to enable/disable curbside filter
 * @param {boolean} [props.hasOnlinePurchase] flag to enabale/disable online filter
 * @returns {JSX.Element} MapListControls react component
 */
export default function MapListControls({
  hasMap,
  activeView,
  setActiveView,
  showCurbside,
  setShowCurbside,
  showOnline,
  setShowShopOnline,
  purchaseFilterLabel,
  hasCurbsidePickup,
  hasOnlinePurchase,
}) {
  const { t } = useTranslation();

  const curbsideLabel = t('common.storeLocator.mapControls.curbsideLabel');
  const shopOnlineLabel = t('common.storeLocator.mapControls.shopOnlineLabel');
  const categoriesContainerEl = useRef(null);
  const containerEl = useRef(null);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const siteContext = useContext(SiteContext);
  const filterCount = containerEl.current?.querySelectorAll(
    'input[type="checkbox"]:checked',
  )?.length;

  useEffect(() => {
    const largeBreakpointSubs = siteContext.largeBreakpointChange$.subscribe(() => {
      clearAllBodyScrollLocks();
      setFiltersOpen(false);
    });
    const escapeSubs = siteContext.escPressed$.subscribe(() => {
      clearAllBodyScrollLocks();
      setFiltersOpen(false);
    });
    const clickSubs = fromEvent(document, 'click')
      .pipe(filter((e) => e.target === containerEl.current))
      .subscribe(() => {
        clearAllBodyScrollLocks();
        setFiltersOpen(false);
      });

    return () => {
      largeBreakpointSubs.unsubscribe();
      escapeSubs.unsubscribe();
      clickSubs.unsubscribe();
    };
  }, [siteContext]);

  return (
    <div
      className={cx(styles.container, {
        [styles.grid]: !hasMap,
        [styles.filtersOpen]: filtersOpen,
      })}
      ref={containerEl}
      style={{
        height: filtersOpen && containerEl.current ? containerEl.current.scrollHeight : '',
      }}>
      {(hasCurbsidePickup || hasOnlinePurchase) && (
        <>
          <div className={styles.headlineContainer}>
            <AccessibleButton
              icon="Expand"
              className={cx(styles.openFiltersBtn, { [styles.open]: filtersOpen })}
              ariaLabel={t('common.storeLocator.filters.openFilters')}
              onClick={() => {
                if (window.innerWidth < 1024) {
                  filtersOpen
                    ? enableBodyScroll(categoriesContainerEl.current)
                    : disableBodyScroll(categoriesContainerEl.current);
                }
                setFiltersOpen(!filtersOpen);
              }}>
              {purchaseFilterLabel + ` ${filterCount > 0 ? `(${filterCount})` : ''}`}
            </AccessibleButton>
            <AccessibleButton
              type="link"
              ariaLabel={t('common.storeLocator.filters.clearFilters')}
              onClick={() => {
                setShowCurbside(false);
                setShowShopOnline(false);
              }}
              className={cx(styles.clearCta, { [styles.visible]: filterCount > 0 })}>
              {t('common.storeLocator.filters.clear')}
            </AccessibleButton>
          </div>
          <div
            className={cx(styles.filtersContainer, { [styles.visible]: filtersOpen })}
            ref={categoriesContainerEl}>
            <div className={styles.interactiveHeadlineContainer}>
              <Caret className={styles.caret} />
              <AccessibleButton
                className={styles.interactiveHeadline}
                ariaLabel={t('common.storeLocator.filters.closeFilters')}
                onClick={() => {
                  enableBodyScroll(categoriesContainerEl.current);
                  setFiltersOpen(false);
                }}>
                {purchaseFilterLabel}
              </AccessibleButton>
              <AccessibleButton
                type="link"
                ariaLabel={t('common.storeLocator.filters.clearFilters')}
                onClick={() => {
                  setShowCurbside(false);
                  setShowShopOnline(false);
                }}
                className={cx(styles.clearCta, { [styles.visible]: filterCount > 0 })}>
                {t('common.storeLocator.filters.clear')}
              </AccessibleButton>
            </div>
            <div className={styles.categoryContainer}>
              <ul className={cx(styles.filters, { [styles.open]: filtersOpen })}>
                {hasCurbsidePickup && (
                  <li className={styles.filter}>
                    <Checkbox
                      value={curbsideLabel}
                      checked={showCurbside}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.currentTarget.checked = !e.currentTarget.checked;
                          setShowCurbside(e.target.checked);
                        }
                      }}
                      onChange={(e) => setShowCurbside(e.target.checked)}>
                      {curbsideLabel}
                    </Checkbox>
                  </li>
                )}

                {hasOnlinePurchase && (
                  <li className={styles.filter}>
                    <Checkbox
                      value={shopOnlineLabel}
                      checked={showOnline}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.currentTarget.checked = !e.currentTarget.checked;
                          setShowShopOnline(e.target.checked);
                        }
                      }}
                      onChange={(e) => setShowShopOnline(e.target.checked)}>
                      {shopOnlineLabel}
                    </Checkbox>
                  </li>
                )}
              </ul>
            </div>
            <AccessibleButton
              className={styles.filtersBtn}
              onClick={() => {
                enableBodyScroll(categoriesContainerEl.current);
                setFiltersOpen(false);
              }}
              disabled={filterCount <= 0}>
              {filterCount > 0
                ? filterCount === 1
                  ? t('common.storeLocator.filters.numFilterApplied')
                  : t('common.storeLocator.filters.numFiltersApplied', [filterCount.toString()])
                : t('common.storeLocator.filters.noFilters')}
            </AccessibleButton>
          </div>
        </>
      )}

      <div className={styles.icons}>
        {hasMap && (
          <button
            className={cx(styles['map-icon-cta'], styles.cta, {
              [styles.active]: activeView === 'map',
            })}
            onClick={() => setActiveView('map')}>
            <Map className={cx(styles['map-icon'])} />
          </button>
        )}

        {hasMap && (
          <button
            className={cx(styles['list-icon-cta'], styles.cta, {
              [styles.active]: activeView === 'list',
            })}
            onClick={() => setActiveView('list')}>
            <List className={cx(styles['list-icon'], { [styles.active]: activeView === 'list' })} />
          </button>
        )}
      </div>
    </div>
  );
}
