import { useState } from 'react';
import cx from 'classnames';
import { useTranslation } from '../../lib/hooks';

import TabbedHeader from '../TabbedHeader/TabbedHeader';
import Locations from '../Locations/Locations';
import TextList from '../TextList/TextList';

import styles from './storeLocatorComponent.module.scss';

/**
 * @param {object} [props] component props
 * @param {object} [props.fields] props coming from contentful
 * @param {boolean} [props.isStoreLocatorPage] is store locator or free form element
 * @returns {JSX.Element} react component for the campaign map
 */
export default function StoreLocatorComponent({ fields, isStoreLocatorPage = false }) {
  const { t } = useTranslation();

  const inStoreText = t('common.storeLocator.tabbedHeader.inStoreText');
  const buyOnlineText = t('common.storeLocator.tabbedHeader.buyOnlineText');

  const [activeTab, setActiveTab] = useState(fields.hasInStore ? inStoreText : buyOnlineText);

  return (
    <section
      className={cx(styles.container, { [styles.inlineComponent]: !isStoreLocatorPage })}
      id={!isStoreLocatorPage && fields.inPageLinkTarget ? fields.inPageLinkTarget : ''}>
      <TabbedHeader
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        inStoreText={inStoreText}
        buyOnlineText={buyOnlineText}
        title={fields.title}
        description={fields.description}
        hasInStore={fields.hasInStore}
        hasOnline={fields.hasOnline}
        hasBackground={isStoreLocatorPage}
        isPageHeader={isStoreLocatorPage}
        retailerLogo={fields.retailerLogo}
      />

      {activeTab === inStoreText && (
        <div
          className={cx(styles['tab-content'], styles['map-tab'], {
            [styles.active]: activeTab === inStoreText,
          })}>
          <Locations
            noResultsMessage={fields.noResultsMessage}
            noResultsTitle={fields.noResultsTitle}
            campaignId={fields.crmCampaignId}
            cta={fields.cta}
            brand={fields.brand.fields}
            hasMap={fields.hasMap}
            mapCentrePoint={{ lat: fields.mapCentrePoint.lat, lng: fields.mapCentrePoint.lon }}
            locationPendingMessage={fields.locationPendingMessage}
            locationPendingImage={fields.locationPendingImage}
            purchaseFilterLabel={fields.purchaseFilterLabel}
            hasCurbsidePickup={fields.hasCurbsidePickup}
            hasOnlinePurchase={fields.hasOnlinePurchase}
            isStoreLocatorPage={isStoreLocatorPage}
            distanceFormat={fields.distanceFormat}
          />
        </div>
      )}

      {activeTab === buyOnlineText && (
        <div
          className={cx(styles['tab-content'], { [styles.active]: activeTab === buyOnlineText })}>
          <TextList disclaimer={fields.buyOnlineDisclaimer} retailerList={fields.onlineRetailers} />
        </div>
      )}
    </section>
  );
}
