import AccessibleButton from '../AccessibleButton/AccessibleButton';
import Picture from '../Image/Picture';
import { getImageSize, adjustImageSize } from '../../lib/imageUtils';
import styles from './philosophyHero.module.scss';
import RichText from '../Richtext/Richtext';
import cx from 'classnames';

import { useContext } from 'react';
import SiteContext from '../AppContext';

/**
 * @param {object} [props] component props
 * @param {string} [props.headline] hero headline
 * @param {string} [props.accentImage] hero accent image
 * @param {object} [props.headerImage] hero header image
 * @param {string} [props.subhead] hero subhead
 * @param {Array<any>} [props.headerCTAs] array of hero ctas
 * @param {boolean} [props.hasVerboseCopy] style prop if too much copy in hero
 * @returns {JSX.Element} react component for philosophy hero
 */
export default function PhilosophyHero({
  accentImage,
  headline,
  headerImage,
  subhead,
  headerCTAs,
  hasVerboseCopy,
}) {
  const { brand } = useContext(SiteContext);

  const headerImageSize = headerImage?.fields?.file?.details?.image;
  const headerImageSizes = {
    sm: getImageSize(adjustImageSize(370, brand), headerImageSize),
    xl: getImageSize(adjustImageSize(648, brand), headerImageSize),
    lg: getImageSize(adjustImageSize(450, brand), headerImageSize),
    md: getImageSize(adjustImageSize(450, brand), headerImageSize),
    fallbackSize: getImageSize(adjustImageSize(450, brand), headerImageSize),
  };

  const accentImageSize = accentImage?.fields?.file?.details?.image || [];
  const [accentImgWidth, accentImgHeight] = accentImage
    ? getImageSize(accentImageSize?.width, accentImageSize)
    : [500, null];

  const ctaButtonThemeMap = {
    gather: 'dark',
    'go-solutions': 'dark',
    'now-solutions': 'dark',
    petcurean: 'light',
    summit: 'light',
  };

  const isRichTextField =
    typeof subhead === 'object' &&
    subhead !== null &&
    subhead.content &&
    Array.isArray(subhead.content);

  return (
    <div className={cx(styles.container, { [styles.hasVerboseCopyContainer]: hasVerboseCopy })}>
      <div className={styles.contentWrapper}>
        {headline && <h1 className={styles.headline}>{headline}</h1>}
        {subhead &&
          (isRichTextField ? (
            <RichText content={subhead} className={styles.subhead} />
          ) : (
            <p className={styles.subhead}>{subhead}</p>
          ))}
        <ul className={cx(styles.ctas, { [styles.noCtas]: !headerCTAs })}>
          {headerCTAs &&
            headerCTAs.map((item, index) => (
              <li className={styles.cta} key={index}>
                <AccessibleButton
                  type="primary"
                  theme={ctaButtonThemeMap[brand]}
                  link={item}
                  key={index}>
                  {item.fields && item.fields.linkText}
                </AccessibleButton>
              </li>
            ))}
        </ul>
      </div>
      {headerImage?.fields?.file && (
        <div className={styles.bgImage}>
          <Picture
            imageSizes={headerImageSizes}
            url={headerImage.fields.file.url}
            alt={headerImage.fields.description}
            loading="eagar"
            quality="70"
          />
        </div>
      )}
      {accentImage?.fields?.file && (
        <div className={styles.accent}>
          <img
            src={`${accentImage.fields.file.url}`}
            alt={accentImage.fields.description || ''}
            width={accentImgWidth}
            height={accentImgHeight}
          />
        </div>
      )}
    </div>
  );
}
