import BlogLandingHero from '../../BlogLandingHero/BlogLandingHero';
import BlogCategories from '../../BlogCategories/BlogCategories';
import styles from './blogLanding.module.scss';
import { useTranslation } from '../../../lib/hooks';
import BlogGrid from '../../BlogGrid/BlogGrid';
import { useReducer, useCallback, useEffect } from 'react';
import BlogLandingReducer from './BlogLandingReducer';

/**
 * This function will create the categories from the retrieved blog posts
 * @param {Array<object>} [blogPosts] retrieved blog posts
 * @param {Array<string>} [presetCategories] categories specified in Contentful
 * @returns {Array<string>} categories from blog posts
 */
function getBlogCategories(blogPosts, presetCategories) {
  if (!blogPosts || blogPosts.length == 0) return [];
  if (presetCategories.length > 0) return presetCategories;
  return blogPosts
    .map((blogPost) => blogPost.fields.filterTags)
    .filter((category) => typeof category !== 'undefined')
    .flat(1)
    .reduce((categories, contentTag) => {
      if (!contentTag.fields) return categories;
      if (categories.includes(contentTag.fields.label)) return categories;
      categories.push(contentTag.fields.label);
      return categories;
    }, []);
}

const initialState = {
  activeCategory: 0,
  filteredBlogPosts: [],
  blogPosts: [],
  categories: [],
  currentPage: 0,
};

/**
 * @param {object} [props] component props
 * @param {object} [props.fields] template content
 * @returns {JSX.Element} react component
 */
export default function BlogLanding({ fields }) {
  const presetCategories = fields.contentTags
    ? fields.contentTags.map((tag) => tag.fields.label)
    : [];
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(BlogLandingReducer, initialState);
  const initState = useCallback(() => {
    const availablePosts = fields.blogPosts
      ? fields.blogPosts.filter((blogPost) => blogPost.fields?.availability === 'active')
      : [];
    const categories = getBlogCategories(availablePosts, presetCategories);

    if (!fields.disableFeaturedCategory) {
      categories.unshift(t('Featured'));
    }

    // Custom logic for filtering the blog posts on initial page load / initial state
    const filteredBlogPosts = availablePosts.filter((blogPost) => {
      const blogPostTags = blogPost.fields.filterTags;
      if (fields.disableFeaturedCategory) {
        const firstCategory = categories[0];
        return (
          blogPostTags &&
          Array.isArray(blogPostTags) &&
          blogPostTags.some((tag) => {
            const tagLabel = tag.fields && tag.fields.label;
            return tagLabel && tagLabel === firstCategory;
          })
        );
      } else {
        return blogPost.fields.featured === true;
      }
    });

    dispatch({
      type: 'init-state',
      activeCategory: 0,
      currentPage: 0,
      blogPosts: availablePosts,
      filteredBlogPosts: fields.showCategories ? filteredBlogPosts : availablePosts,
      categories,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.blogPosts, fields.showCategories, fields.disableFeaturedCategory]);

  useEffect(() => {
    initState();
  }, [fields.slug, initState]);

  return (
    <section key={fields.slug}>
      <div className={styles.heroContainer}>
        <BlogLandingHero {...fields} categories={state.categories} />
        {fields.showCategories && (
          <BlogCategories categories={state.categories} state={state} dispatch={dispatch} />
        )}
      </div>
      {fields.blogPosts && (
        <BlogGrid blogPosts={state.filteredBlogPosts} state={state} dispatch={dispatch} />
      )}
    </section>
  );
}
