/* eslint-disable max-len */
import useTranslation from '../../lib/hooks/useTranslation';
import styles from './feedingGuide.module.scss';
import StyledSelect from '../StyledSelect/StyledSelect';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import Picture from '../Image/Picture';
import StyledForm from '../StyledForm/StyledForm';
import Modal from '../Modal/Modal';
import { getExternalLinkFromString } from '../../lib/utils';
import ExpandableElement from '../ExpandableElement/ExpandableElement';
import { useState, useRef } from 'react';

/**
 * This function generate the arrays to be used in the dropdown from the feeding guide data
 * @param {object} [feedingGuideData] Feeding guide data from contentful
 * @returns {Array<object>} 2 dimension array from number of dropdowns and its data
 */
function prepareDropdownData(feedingGuideData) {
  if (!feedingGuideData?.headers || feedingGuideData?.headers?.length === 0) {
    return [];
  }
  return feedingGuideData.headers?.map((header, i) =>
    feedingGuideData.data
      .map((row) => row[i])
      .reduce((dropdown, value) => {
        if (dropdown.includes(value)) {
          return dropdown;
        }
        dropdown.push(value);
        return dropdown;
      }, []),
  );
}

/**
 * looks for the result in the feeding guide data from given options
 * @param {object} [feedingGuideData] feeding guide data from contentful
 * @param {Array} [options] options to look for
 * @param {Array} [filteredHeaders] table headers with blanks removed
 * @returns {string} result from options
 */
function findFeedingOption(feedingGuideData, options, filteredHeaders) {
  if (options.length < filteredHeaders.length) {
    return '';
  }
  const feedingOption = options.reduce((feeding, option) => {
    feeding = feeding.filter((row) => row.includes(option));
    return feeding;
  }, feedingGuideData.data);

  return feedingOption.flat(1)[feedingGuideData.headers.length];
}

/**
 * Feeding Guide component
 * feedingGuideData.headers contains a list of labels for the select elements.
 * @param {object} [props] component props
 * @param {string} [props.petType] pet option in CMS
 * @param {object} [props.feedingGuideImage] data for side image
 * @param {string} [props.feedingGuideSelectText] dropdowns headline, or if it is a single option,
 * text to be used
 * @param {object} [props.feedingGuideData] data matrix
 * @param {string} [props.feedingGuideFullText] footer text
 * @param {object} [props.bodyScoreChart] PDF File from contentful
 * @param {object} [props.transitionGuideComponent] PDF File from contentful
 * @param {object} [props.contactUsForm] Contact us form data
 * @param {object} [props.brand] brand data from contentful
 * @returns {JSX.Element} react component for the ingredient table
 */
export default function FeedingGuide({
  petType,
  feedingGuideImage,
  feedingGuideSelectText,
  feedingGuideData,
  feedingGuideFullText,
  bodyScoreChart,
  transitionGuideComponent,
  contactUsForm,
  brand,
}) {
  const [options, setOptions] = useState([]);
  const [feedingOption, setFeedingOption] = useState('');
  const [showContactCta, setShowContactCta] = useState(false);
  const { t } = useTranslation();
  const dropdownData = prepareDropdownData(feedingGuideData);
  const filteredHeaders = feedingGuideData?.headers?.filter((header) => header !== '');
  const imgUrl = feedingGuideImage?.fields?.file?.url;
  const chartLink = getExternalLinkFromString(bodyScoreChart?.fields?.file?.url);
  const transitionGuideLink = getExternalLinkFromString(
    transitionGuideComponent?.fields?.file?.url,
  );
  const hasContactForm = brand.fields?.hasContactForm;
  const brandSlug = brand.fields.slug;

  const modalRef = useRef(null);
  const openerRef = useRef(null);
  const openModal = () => {
    modalRef.current.showModal();
  };

  const feedingGuideImageSizes = {
    xl: [648, 648],
    lg: [550, 550],
    md: [648, 648],
    sm: [450, 450],
    fallbackSize: [450, 450],
  };

  const feedGuideImageSizesGather = {
    xl: [760, 536],
    lg: [399, 536],
    md: [656, 536],
    sm: [450, 450],
    fallbackSize: [450, 450],
  };

  const imgSizes = brandSlug === 'gather' ? feedGuideImageSizesGather : feedingGuideImageSizes;

  return (
    <section className={styles.background}>
      <div className={styles.container}>
        {imgUrl ? (
          <Picture
            className={styles.image}
            url={imgUrl}
            imageSizes={imgSizes}
            quality="80"
            alt={feedingGuideImage?.fields?.description}
            fit="fill"
          />
        ) : (
          <div />
        )}
        <div className={styles.copyContainer}>
          <p className={styles.eyebrow}>{t('feedingGuide.eyebrow')}</p>
          <h2 className={styles.headline}>
            {petType === 'Dog' ? t('feedingGuide.dogTitle') : t('feedingGuide.catTitle')}
          </h2>
          <p className={styles.selectHeadline}>{feedingGuideSelectText}</p>
          {feedingGuideData?.headers && (
            <div className={styles.dropdownsContainer}>
              {feedingGuideData.headers.map(
                (header, i) =>
                  header && (
                    <StyledSelect
                      label={header}
                      options={dropdownData[i]}
                      key={i}
                      value={options[i]}
                      id={`feeding-guide-${header}-${i}`}
                      className={styles.dropdown}
                      onChange={(e) => {
                        const selectedOption =
                          e.currentTarget.options[e.currentTarget.selectedIndex].text;
                        setOptions((options) => {
                          const newOptions = [...options];
                          newOptions[i] = selectedOption;
                          if (
                            newOptions.length === filteredHeaders.length &&
                            !options.includes(undefined) &&
                            !newOptions.includes('')
                          ) {
                            const feedingDetail = findFeedingOption(
                              feedingGuideData,
                              newOptions,
                              filteredHeaders,
                            );
                            if (feedingDetail === 'N/A' || feedingDetail === undefined) {
                              setFeedingOption(t('feedingGuide.contactText'));
                              setShowContactCta(hasContactForm);
                            } else {
                              setFeedingOption(t('feedingGuide.petNeeds', [feedingDetail]));
                              setShowContactCta(false);
                            }
                          } else if (newOptions.includes('')) {
                            setFeedingOption('');
                            setShowContactCta(false);
                          }
                          return newOptions;
                        });
                      }}
                    />
                  ),
              )}
            </div>
          )}
          {feedingOption && <p className={styles.feedingOption}>{feedingOption}</p>}
          {showContactCta && (
            <>
              <AccessibleButton
                className={styles.contactBtn}
                type="primary"
                theme="dark"
                ref={openerRef}
                onClick={openModal}>
                {t('common.ctas.contactUs')}
              </AccessibleButton>
              {/* This copy of the form outside the modal is important because the original */}
              {/* contactus form is inside a modal. If removed Netlify wont be able to crawl */}
              {/* it during build time and the data wont be registered. */}
              {contactUsForm?.fields && (
                <StyledForm
                  fields={contactUsForm?.fields}
                  id="feeding-hidden"
                  hidden={true}
                  netlify={true}
                  className={styles.staticForm}
                />
              )}
              <Modal ref={modalRef} opener={openerRef}>
                {contactUsForm?.fields && (
                  <StyledForm
                    fields={contactUsForm.fields}
                    id="feeding"
                    hidden={false}
                    netlify={false}
                    ref={modalRef}
                  />
                )}
              </Modal>
            </>
          )}
          <ExpandableElement
            className={styles.fullTextContainer}
            collapsedHeight={0}
            invisible={true}
            viewMoreTheme={brandSlug === 'summit' ? 'dark' : null}
            viewMoreI18nKey="feedingGuide.moreInfo"
            viewLessI18nKey="feedingGuide.moreInfo">
            <p className={styles.fullText}>{feedingGuideFullText}</p>
          </ExpandableElement>
          <div className={styles.ctaContainer}>
            {bodyScoreChart && (
              <AccessibleButton
                type={brandSlug === 'now-fresh' ? 'primary' : 'secondary'}
                theme={brandSlug === 'gather' ? 'light' : 'dark'}
                link={chartLink}
                className={styles.chartCta}>
                {t('feedingGuide.bodyChartCTALabel')}
              </AccessibleButton>
            )}
            {transitionGuideComponent && (
              <AccessibleButton
                type="secondary"
                theme={brandSlug === 'gather' ? 'light' : 'dark'}
                link={transitionGuideLink}
                className={styles.chartCta}>
                {t('feedingGuide.transitionGuideCTALabel')}
              </AccessibleButton>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
