import { useState } from 'react';
import cx from 'classnames';
import useTranslation from '../../lib/hooks/useTranslation';

import { Star } from '../Icons';

import styles from './rate.module.scss';

/**
 * @param {number} [increment] step between star ratings
 * @param {number} [maxNumberStars] max number of stars in the rating scale
 * @returns {Array} array of stars
 */
function generateStars(increment, maxNumberStars) {
  const stars = [];

  for (let i = 1; i <= maxNumberStars; i++) {
    stars.push(i * increment);
  }

  return stars;
}

/**
 * @param {object} [props] component props
 * @param {string} [props.label] text description
 * @param {string} [props.id] radio group id
 * @param {string} [props.name] radio group name
 * @param {number} [props.increment] step between star ratings
 * @param {number} [props.maxNumberStars] max number of stars in the rating scale
 * @param {string} [props.className] css class override for the component container
 * @param {string} [props.value] selected radio button
 * @param {boolean} [props.showError] flag to show or hide the error message
 * @param {string} [props.errorMsg] the error message text
 * @param {Function} [props.onChange] function to thiger on change of value
 * @param {boolean} [props.ariaRequired] flag to add the aria-required attribute
 * @returns {JSX.Element} react component
 */
export default function Rate({
  label,
  id = 'rate',
  name,
  increment = 1,
  maxNumberStars = 5,
  className,
  value,
  showError,
  errorMsg,
  onChange,
  ariaRequired,
}) {
  const { t } = useTranslation();

  const stars = generateStars(increment, maxNumberStars);
  const [hoverStar, setHoverStar] = useState(null);
  const currentStar = value === '' ? hoverStar : value;

  const starLabel = t('common.rate.starLabel');
  const starsLabel = t('common.rate.starsLabel');

  return (
    <fieldset
      className={cx(styles.container, {
        [className]: className,
        [styles.error]: showError,
      })}>
      <legend className={cx('p2 bold', styles.description)}>{label}</legend>

      {stars.map((star) => (
        <span key={star}>
          <label
            className={cx(styles.star, { [styles.active]: currentStar && currentStar >= star })}
            htmlFor={`${id}-${star}`}
            onFocus={() => {
              setHoverStar(star);
            }}
            onMouseOver={() => setHoverStar(star)}
            onBlur={() => setHoverStar(null)}
            onMouseOut={() => setHoverStar(null)}>
            <Star />
            <input
              className={styles.input}
              type="radio"
              id={`${id}-${star}`}
              key={star}
              name={name}
              value={star}
              onChange={onChange}
              checked={value == star}
              required={ariaRequired}
            />
            <span className={styles.label}>{`${star} ${star === 1 ? starLabel : starsLabel}`}</span>
          </label>
        </span>
      ))}
      <p className={styles.errorMsg}>{errorMsg}</p>
    </fieldset>
  );
}
