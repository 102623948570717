import { useContext, forwardRef } from 'react';
import SiteContext from '../AppContext';
import useTranslation from '../../lib/hooks/useTranslation';
import Product from './Product';
import cx from 'classnames';
import WhereToBuyWidget from '../PriceSpiderWidgets/WhereToBuyWidget';

import styles from './productGrid.module.scss';

/**
 * A component that returns a grid of all the products
 * @param {object} [props] props coming form contentful
 * @param {Array} [props.products] List of products to render
 * @param {number} [props.numFilters] Number of filters applied
 * @param {boolean} [props.showCategories] List of products to render
 * @param {string} [props.className] class for the component
 * @param {boolean} [props.showFilters] filter present
 * @param {object} [ref] ref object
 * @returns {JSX.Element} Returns Product Grid template react component
 */
function ProductGrid({ products, numFilters = 0, showCategories, className, showFilters }, ref) {
  const { brand } = useContext(SiteContext);
  const productMap = {};
  const numResults = products.length;
  const { t } = useTranslation();

  if (showCategories) {
    products.forEach((product) => {
      if (product.fields.productLine) {
        const idKey = product.fields.productLine.sys.id;

        if (!productMap[idKey]) {
          productMap[idKey] = { category: product.fields.productLine, products: [product] };
        } else {
          productMap[idKey].products.push(product);
        }
      }
    });
  }

  return (
    <section className={cx(className, styles.container)} ref={ref}>
      {brand === 'go-solutions' && showCategories && numFilters === 0 ? (
        Object.keys(productMap).map((key, index) => (
          <div key={index} className={cx(styles.category, { [styles.fullWidth]: !showFilters })}>
            {numFilters === 0 && (
              <>
                <h2
                  className={cx(styles.headline, {
                    [styles.fullWidth]: !showFilters,
                  })}>
                  {productMap[key].category.fields.gridHeadline}
                </h2>
                <p
                  className={cx(styles.description, {
                    [styles.fullWidth]: !showFilters,
                  })}>
                  {productMap[key].category.fields.gridDescription}
                </p>
              </>
            )}
            {renderProducts(productMap[key].products, brand, t)}
          </div>
        ))
      ) : (
        <div
          className={cx(styles.category, styles['category-unique'], {
            [styles.fullWidth]: !showFilters,
          })}>
          {numFilters !== 0 && (
            <>
              <p className={cx(styles['filtered-headline'])}>
                {`${
                  numFilters === 1
                    ? t('filters.filterApplied', [numFilters])
                    : t('filters.filtersApplied', [numFilters])
                }`}
              </p>
              <p className={styles['filtered-description']}>
                {`${
                  numResults === 1
                    ? t('filters.result', [numResults])
                    : t('filters.results', [numResults])
                }`}
              </p>
            </>
          )}
          {renderProducts(products, brand, t)}
        </div>
      )}
    </section>
  );
}

/**
 * A component that returns a list of products
 * @param {Array} [products] List of products to render
 * @param {Array} [brand] Current brand
 * @param {Function} [t] translation function from translation hook
 * @returns {JSX.Element} Returns product list react component
 */
function renderProducts(products, brand, t) {
  const sortedProducts = [...products];
  sortedProducts.sort((a, b) => {
    const sortOrderA = a.fields?.sortOrder ? a.fields.sortOrder : 0;
    const sortOrderB = b.fields?.sortOrder ? b.fields.sortOrder : 0;
    return sortOrderB - sortOrderA;
  });

  return (
    <>
      {sortedProducts.map((product, index) => (
        <div className={styles.productContainer} key={index}>
          <Product product={product} brand={brand} t={t} />
          <WhereToBuyWidget sku={product.fields.externalIDs.sku} className={styles.wtbCta} />
        </div>
      ))}
    </>
  );
}

export default forwardRef(ProductGrid);
