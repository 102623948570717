import styles from './ingredientsComponent.module.scss';
import IngredientCard from './IngredientCard';

/**
 * @param {object} [props] component props
 * @param {Array<any>} [props.featuredIngredients] ingredients from CMS
 * @returns {JSX.Element} react component for the ingredients component
 */
export default function IngredientsComponent({ featuredIngredients }) {
  return (
    <section className={styles.container}>
      <ul className={styles.ingredients}>
        {featuredIngredients?.map((ingredient, i) => (
          <li className={styles.ingredient} key={i}>
            <IngredientCard {...ingredient.fields}></IngredientCard>
          </li>
        ))}
      </ul>
    </section>
  );
}
