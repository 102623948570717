import Image from '../../Image/Image';
import styles from './inBlogPullQuote.module.scss';
/**
 * @param {object} [props] component props
 * @param {string} [props.body] body
 * @param {object} [props.authorImage] image content
 * @param {string} [props.name] author name
 * @param {string} [props.attribution] attribution
 * @returns {JSX.Element} react component
 */
export default function InBlogPullQuote({ body, authorImage, name, attribution }) {
  const inBlogPullQuoteImageSizes = {
    defaultSize: [62, 62],
    fallbackSize: [62, 62],
  };

  return (
    <div className={styles.container}>
      <blockquote className={styles.quote}>{body}</blockquote>

      <div className={styles['author-container']}>
        <div className={styles.author}>
          {authorImage?.fields?.file && (
            <Image
              classNameImage={styles.photo}
              url={authorImage.fields.file.url}
              imageSizes={inBlogPullQuoteImageSizes}
              alt={authorImage.fields.description}
              fit="fill"
              wrapImage={false}
            />
          )}

          <address className={styles.address}>
            <span className={styles.name}>{name}</span>

            <span className={styles.attribution}>{attribution}</span>
          </address>
        </div>
      </div>
    </div>
  );
}
