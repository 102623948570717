import styles from './checkbox.module.scss';
import cx from 'classnames';
/**
 * @param {object} [props] Component props
 * @param {JSX.Element} [props.children] children for checkbox component
 * @param {string} [props.value] value for checkbox
 * @param {boolean} [props.disabled] disabled attribute for checkbox
 * @param {Function} [props.onChange] on change handler
 * @param {boolean} [props.checked] checked Attribute
 * @param {Function} [props.onKeyDown] on keydown handler
 * @returns {JSX.Element} checkbox react component
 */
export default function Checkbox({ children, value, disabled, onChange, checked, onKeyDown }) {
  return (
    <label className={cx(styles.container, { [styles.disabled]: disabled })}>
      <input
        type="checkbox"
        className={styles.input}
        value={value}
        onKeyDown={onKeyDown}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
      />
      <span className={styles.wrapper} tabIndex={-1}>
        {children}
      </span>
    </label>
  );
}
