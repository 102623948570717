import styles from './productFilter.module.scss';
import { forwardRef, useRef } from 'react';
import FilterCategory from './FilterCategory';
import { getFiltersFromProducts } from '../../lib/productUtils';
import { useTranslation } from '../../lib/hooks';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import { Caret } from '../Icons';
import cx from 'classnames';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

/**
 * function to determine which tags are disabled
 * @param {Array<string>} [filteredProductTags] tags from products in the grid
 * @param {string} [category] category
 * @param {Array<string>} [tags] tags from list of products
 * @returns {Array<string>} Array from disabled tags
 */
function getDisabledTags(filteredProductTags, category, tags) {
  if (!filteredProductTags[category]) {
    return Array.from(tags);
  }
  return Array.from(tags).filter((tag) => !filteredProductTags[category].has(tag));
}

/**
 * get all the tags in the products to be used in the filters column
 * @param {Array<string>} [filteredProductTags] tags from products in the grid
 * @param {object} [filters] mapped object from categories with tags
 * @param {Array<string>} [activeFilters] current active filters
 * @returns {Array<object>} Mapped array from tags to be used in the filters
 */
function getTagList(filteredProductTags, filters, activeFilters) {
  return Object.keys(filters).map((key) => {
    const disabledTags = getDisabledTags(filteredProductTags, key, filters[key]);
    const tags = Array.from(filters[key]).map((tag) => ({
      value: tag,
      checked: activeFilters.includes(tag),
      disabled: disabledTags.includes(tag),
    }));
    return {
      category: key,
      tags,
      totalSelected: tags.filter((tag) => tag.checked).length,
    };
  });
}

/**
 * A component that returns filters based on the products
 * @param {object} [props] component props
 * @param {object} [props.state] category landing template state
 * @param {Function} [props.dispatch] category landing dispatcher
 * @param {string} [props.className] classname to be used in component
 * @param {object} [ref] use ref variable
 * @returns {JSX.Element} Returns Product Grid template react component
 */
function ProductFilters({ state, dispatch, className }, ref) {
  const filteredProductTags = getFiltersFromProducts(state.filteredProducts);
  const { t } = useTranslation();
  const categoriesContainerEl = useRef(null);
  const tagList = getTagList(filteredProductTags, state.filters, state.activeFilters);
  return (
    <section
      id="productFilters"
      className={cx(styles.container, { [styles.filtersStuck]: state.filtersStuck }, className)}
      ref={ref}>
      <div className={styles.stickySection}>
        <AccessibleButton
          icon="Expand"
          className={styles.openFiltersBtn}
          ariaLabel={t('filters.openFilters')}
          onClick={() => {
            disableBodyScroll(categoriesContainerEl.current);
            dispatch({ type: 'open-filters' });
          }}>
          {t('filters.filters') +
            ` ${state.activeFilters.length > 0 ? `(${state.activeFilters.length})` : ''}`}
        </AccessibleButton>
      </div>
      <div className={cx(styles.filtersContainer, { [styles.visible]: state.filtersOpen })}>
        <div className={styles.interactiveHeadlineContainer}>
          <Caret className={styles.caret} />
          <AccessibleButton
            className={styles.interactiveHeadline}
            ariaLabel={t('filters.closeFilters')}
            onClick={() => {
              enableBodyScroll(categoriesContainerEl.current);
              dispatch({ type: 'close-filters' });
            }}>
            {t('filters.filterRecipes')}
          </AccessibleButton>
          <AccessibleButton
            type="link"
            ariaLabel={t('filters.clearFilters')}
            onClick={() => {
              window.scroll(0, state.gridElementY - 88);
              dispatch({ type: 'clear-filters' });
            }}
            className={cx(styles.clearCta, { [styles.visible]: state?.activeFilters?.length > 0 })}>
            {t('filters.clear')}
          </AccessibleButton>
        </div>
        <div className={styles.headlineContainer}>
          <p className={styles.headline}>{t('filters.filters')}</p>
          <AccessibleButton
            type="link"
            ariaLabel={t('filters.clearFilters')}
            onClick={() => {
              window.scroll(0, state.gridElementY - 88);
              dispatch({ type: 'clear-filters' });
            }}
            className={cx(styles.clearCta, { [styles.visible]: state?.activeFilters?.length > 0 })}>
            {t('filters.clear')}
          </AccessibleButton>
        </div>
        <div className={styles.categoriesContainer} ref={categoriesContainerEl}>
          {tagList.map((filterSet, index) => (
            <FilterCategory
              open={state.openCategories?.has(index)}
              index={index}
              key={index}
              state={state}
              dispatch={dispatch}
              tags={filterSet.tags}
              category={filterSet.category}
              totalSelected={filterSet.totalSelected}
            />
          ))}
        </div>
        <AccessibleButton
          className={styles.filtersBtn}
          ariaLabel={
            state?.activeFilters?.length > 0
              ? state?.activeFilters?.length === 1
                ? t('filters.numFilterApplied')
                : t('filters.numFiltersApplied', [state.activeFilters.length.toString()])
              : t('filters.noFilters')
          }
          onClick={() => {
            enableBodyScroll(categoriesContainerEl.current);
            dispatch({ type: 'close-filters' });
          }}
          disabled={state?.activeFilters?.length <= 0}>
          {state?.activeFilters?.length > 0
            ? state?.activeFilters?.length === 1
              ? t('filters.numFilterApplied')
              : t('filters.numFiltersApplied', [state.activeFilters.length.toString()])
            : t('filters.noFilters')}
        </AccessibleButton>
      </div>
    </section>
  );
}

export default forwardRef(ProductFilters);
