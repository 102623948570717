import cx from 'classnames';
import { useRef, useState, useContext, useEffect } from 'react';

import useTranslation from '../../lib/hooks/useTranslation';

import ReviewCard from './ReviewCard';
import Rating from '../Rating/Rating';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import Modal from '../Modal/Modal';
import StyledForm from '../StyledForm/StyledForm';
import { useBazaarvoiceReviews } from '../../lib/reviews/bazaarvoice';
import ReviewFilters from './ReviewFilters';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import styles from './reviewList.module.scss';
import StyledSelect from '../StyledSelect/StyledSelect';
import SiteContext from '../AppContext';
import { reviewViewOnlyLocales } from '../../siteConfig';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * @param {object} [props] component props
 * @param {number} [props.aggregate] average rating from reviews
 * @param {number} [props.totalReviews] total number of reviews
 * @param {object} [props.writeAReviewForm] Data for the Write a Review form
 * @param {string} [props.externalIDs] Bazaarvoice product Id
 * @param {string} [props.petType] cat/dog product from contentful
 * @returns {JSX.Element} hero copy react component
 */
export default function ReviewList({
  aggregate,
  totalReviews,
  writeAReviewForm,
  externalIDs,
  petType,
}) {
  const { t } = useTranslation();
  const [sort, setSort] = useState('SubmissionTime:desc');
  const [filters, setFilters] = useState(new Set([]));
  const [filtersOpen, setFiltersOpen] = useState(false);
  const reviewsEl = useRef();
  const siteContext = useContext(SiteContext);
  const { brand, locale } = siteContext;
  const bvId = externalIDs?.websiteReviewsId;
  const { data, size, setSize, isLoadingMore, isReachingEnd } = useBazaarvoiceReviews({
    id: bvId,
    sort,
    filters,
    locale: locale,
  });

  const reviewDistribution = data
    ? data[0]?.['Includes']?.['Products']?.[bvId]?.['ReviewStatistics']?.['RatingDistribution']
    : [];
  const reviews = data ? [].concat(...data.map((res) => res.Results)) : [];

  const pagination =
    reviews && (data?.[0]?.['TotalResults'] || isLoadingMore)
      ? t('review.pagination', [reviews?.length || '...', data?.[0]?.['TotalResults'] || '...'])
      : '';

  const showReviews = (reviews && reviews.length > 0) || isLoadingMore;
  const modalRef = useRef(null);
  const openerRef1 = useRef(null);
  const openerRef2 = useRef(null);
  const filtersContainerEl = useRef(null);
  const openModal = (modalRef) => {
    modalRef.current.showModal();
  };

  useEffect(() => {
    const largeBreakpointSubs = siteContext.largeBreakpointChange$.subscribe(() => {
      clearAllBodyScrollLocks();
      setFiltersOpen(false);
    });
    const escapeSubs = siteContext.escPressed$.subscribe(() => {
      clearAllBodyScrollLocks();
      setFiltersOpen(false);
    });
    const clickSubs = fromEvent(document, 'click')
      .pipe(
        filter((e) => {
          return e.target === filtersContainerEl.current;
        }),
      )
      .subscribe(() => {
        clearAllBodyScrollLocks();
        setFiltersOpen(false);
      });

    return () => {
      largeBreakpointSubs.unsubscribe();
      escapeSubs.unsubscribe();
      clickSubs.unsubscribe();
    };
  }, [siteContext]);

  const handleSort = (e) => {
    if (e.target.value !== sort) {
      setSort(e.target.value);
    }
  };

  return (
    <section className={cx(styles.container)} id="reviews" ref={reviewsEl}>
      {showReviews ? (
        <>
          <div className={styles.reviewsHeader}>
            <div className={styles.aggregateContainer}>
              <span className={styles.aggregate}>{aggregate}</span>
              <Rating rating={aggregate} className={styles.aggregateStar} />
              <p className={styles.totalReviews}>{t('review.aggregateSummary', [totalReviews])}</p>
            </div>
            <div className={styles.headlineWrapper}>
              <h2 className={styles.headline}>{t('review.sectionTitle')}</h2>
              {writeAReviewForm?.fields && !reviewViewOnlyLocales.includes(locale) && (
                <AccessibleButton
                  type="primary"
                  icon="Expand"
                  theme={brand === 'gather' ? 'dark' : null}
                  ref={openerRef1}
                  onClick={() => {
                    openModal(modalRef);
                  }}
                  className={styles.cta}>
                  {t('review.writeAReview')}
                </AccessibleButton>
              )}
            </div>
          </div>
          <ReviewFilters
            filters={filters}
            setFilters={setFilters}
            filtersOpen={filtersOpen}
            setFiltersOpen={setFiltersOpen}
            reviewDistribution={reviewDistribution}
            className={styles.filters}
            ref={filtersContainerEl}
            brand={brand}
          />
          <div className={styles.reviewsList}>
            <div className={styles.buttonBar}>
              <AccessibleButton
                icon="Expand"
                className={styles.openFiltersBtn}
                ariaLabel={t('review.filters.openFilters')}
                onClick={() => {
                  disableBodyScroll(filtersContainerEl.current);
                  setFiltersOpen(true);
                }}>
                {filters?.size > 0
                  ? `${t('review.filters.filters')} (${filters.size})`
                  : t('review.filters.filters')}
              </AccessibleButton>
              {pagination && (
                <p className={cx(styles.pagination, styles.paginationTop)}>{pagination}</p>
              )}
              <StyledSelect
                className={styles.sort}
                id="sortRatings"
                isSortBtn={true}
                label={t('review.sortLabel')}
                onChange={handleSort}
                value={sort}
                ariaLabel={t('review.sortReviews')}
                showEmptyOption={false}
                advancedOptions={[
                  { key: 'SubmissionTime:asc', value: t('review.sort.oldest') },
                  { key: 'SubmissionTime:desc', value: t('review.sort.newest') },
                  { key: 'Rating:desc', value: t('review.sort.topRated') },
                  { key: 'Rating:asc', value: t('review.sort.lowRated') },
                  { key: 'TotalPositiveFeedbackCount:desc', value: t('review.sort.helpfulness') },
                ]}
              />
            </div>
            <ul>
              {reviews.map((review, index) => (
                <li className={styles.review} key={index}>
                  <ReviewCard {...review} petType={petType} key={index} />
                </li>
              ))}
            </ul>
            <div className={styles.paginationWrapper}>
              <AccessibleButton
                type={brand === 'gather' ? 'primary' : 'secondary'}
                theme={brand === 'gather' ? 'dark' : null}
                disabled={isLoadingMore || isReachingEnd}
                onClick={() => setSize(size + 3)}>
                {isLoadingMore
                  ? t('review.loadingMore')
                  : isReachingEnd
                    ? t('review.noMore')
                    : t('review.loadMoreCta')}
              </AccessibleButton>
              {pagination && <p className={styles.pagination}>{pagination}</p>}
            </div>
          </div>
        </>
      ) : (
        writeAReviewForm?.fields &&
        !reviewViewOnlyLocales.includes(locale) && (
          <div className={styles.noReviewsContainer}>
            <h2 className={styles.noReviewsHeadline}>{t('review.noReviewTitle')}</h2>
            <p className={styles.noReviewsSubheadline}>{t('review.noReviewSubtitle')}</p>
            <AccessibleButton
              type="primary"
              theme={brand === 'summit' ? 'dark' : null}
              icon="Expand"
              className={styles.cta}
              ref={openerRef2}
              onClick={() => {
                openModal(modalRef);
              }}>
              {t('review.writeAReview')}
            </AccessibleButton>
          </div>
        )
      )}
      {/* This copy of the form outside the modal is important because the original */}
      {/* contactus form is inside a modal. If removed Netlify wont be able to crawl */}
      {/* it during build time and the data wont be registered. */}
      {writeAReviewForm?.fields && !reviewViewOnlyLocales.includes(locale) && (
        <StyledForm
          fields={writeAReviewForm?.fields}
          id="review-hidden"
          hidden={true}
          netlify={true}
          className={styles.staticForm}
        />
      )}
      <Modal ref={modalRef} opener={showReviews ? openerRef1 : openerRef2}>
        {writeAReviewForm?.fields && !reviewViewOnlyLocales.includes(locale) && (
          <StyledForm
            fields={writeAReviewForm.fields}
            id="review1"
            hidden={false}
            netlify={false}
            ref={modalRef}
          />
        )}
      </Modal>
    </section>
  );
}
