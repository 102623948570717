import Picture from '../../Image/Picture';
import { getImageSize } from '../../../lib/imageUtils';
import styles from './inBlogImage.module.scss';

/**
 * @param {object} [props] component props
 * @param {object} [props.image] the image part of the component
 * @param {object} [props.caption] the caption part of the component
 * @returns {JSX.Element} react component
 */
export default function InBlogImage({ image, caption }) {
  const inBlogImageSize = image?.fields?.file?.details?.image;
  const inBlogImageSizes = {
    xl: getImageSize(868, inBlogImageSize),
    lg: getImageSize(774, inBlogImageSize),
    md: getImageSize(656, inBlogImageSize),
    sm: getImageSize(340, inBlogImageSize),
    fallbackSize: getImageSize(340, inBlogImageSize),
  };

  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        {image?.fields?.file && (
          <Picture
            className={styles.image}
            url={image.fields.file.url}
            imageSizes={inBlogImageSizes}
            quality="60"
            alt={image?.fields?.description}
          />
        )}
      </div>

      {caption && (
        <div className={styles.caption}>
          <div className={styles.captionContent}>{caption}</div>
        </div>
      )}
    </div>
  );
}
