import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import styles from './map.module.scss';
import goSolutionsMapStyle from './go-solutions.style.json';
import nowFreshMapStyle from './now-fresh.style.json';
import gatherAndSummitMapStyle from './gatherAndSummit.style.json';
import { Location, Minus, Plus } from '../Icons';

import { useTranslation } from '../../lib/hooks';

const MIN_VISIBLE_PINS = 2;

/**
 * Map component
 * @param {object} [props] component Props
 * @param {object} [props.brand] brand with slug and name
 * @param {boolean} [props.mapReady] flag if the map library has loaded
 * @param {object} [props.mapCentrePoint] lat and long where we are going to center the map
 * @param {number} [props.mapDefaultZoom] zoom level for the map
 * @param {Array} [props.locations] store locations
 * @param {object} [props.userGeolocation] lat and long of the user's location
 * @param {object} [props.locationsEl] location component ref
 * @param {Function} [props.setActivePin] function to change active pin state in locations component
 * @param {Function } [props.detectGeolocation] runs browser geolocation
 * @param {boolean} [props.disableGeolocation] flag indicating that we can't prompt for geolocation
 * @returns {JSX.Element} react component for the map
 */
export default function Map({
  brand,
  mapCentrePoint,
  mapReady,
  mapDefaultZoom = 15,
  locations = [],
  userGeolocation,
  locationsEl,
  setActivePin,
  detectGeolocation,
  disableGeolocation,
}) {
  const mapRef = useRef();
  const centerRef = useRef();
  const zoomRef = useRef();
  const { t } = useTranslation();

  const mapTitle = t('common.storeLocator.map.title', [brand.name]);

  const mapStyles = {
    gather: gatherAndSummitMapStyle,
    'go-solutions': goSolutionsMapStyle,
    'now-fresh': nowFreshMapStyle,
    summit: gatherAndSummitMapStyle,
  };

  const [map, setMap] = useState(null);
  const pins = useRef();
  const userPin = useRef();
  const activePin = useRef();
  const geolocationControlIndex = useRef();

  // Function that removes previously inserted pins from the map
  const clearStores = () => {
    activePin.current = null;

    if (pins.current) {
      pins.current.forEach((locationPin) => {
        locationPin.setMap(null);
      });
    }
    pins.current = [];
  };

  // Check the zoom level by making sure at least the minimum number of pins are visible
  const checkZoomLevel = () => {
    let visiblePins = 0;
    const google = window.google;
    const mapBounds = map.getBounds();

    if (mapBounds) {
      for (let i = 0; i < pins.current.length; i++) {
        if (mapBounds.contains(pins.current[i].getPosition())) {
          visiblePins += 1;
        }

        if (visiblePins > MIN_VISIBLE_PINS) {
          break;
        }
      }
    }

    // There are not enough visible pins and there are pins in the map
    if (visiblePins < MIN_VISIBLE_PINS && pins.current.length) {
      const bounds = new google.maps.LatLngBounds();

      // loop until exhausting available pins or minimum number of pins
      for (let i = 0; i < MIN_VISIBLE_PINS && i < pins.current.length; i++) {
        // Pin locations are sorted by proximity, so we pick the first available
        bounds.extend(pins.current[i].position);
      }

      if (userPin.current) {
        bounds.extend(userPin.current.position);
      }

      map.fitBounds(bounds);
    }
  };

  // Function that inserts the location pins into the map
  const insertStores = () => {
    clearStores();

    const google = window.google;

    locations.forEach((location, index) => {
      const pin = new google.maps.Marker({
        position: {
          lat: parseFloat(location.lat),
          lng: parseFloat(location.lng),
        },
        map: map,
        icon: `/${brand.slug}/map-pin.svg`,
      });

      pin.addListener('click', () => {
        let pinClickedEvent;
        if (activePin.current === pin) {
          // Reset the previous active pin styles
          togglePin(activePin.current, false);
          activePin.current = null;
          pinClickedEvent = new CustomEvent('pin-clicked', { detail: { pinIndex: null } });
        } else {
          // Make the clicked pin the current active pin
          togglePin(activePin.current, false);
          togglePin(pin, true);
          pinClickedEvent = new CustomEvent('pin-clicked', { detail: { pinIndex: index } });

          map.setCenter({
            lat: activePin.current.position.lat(),
            lng: activePin.current.position.lng(),
          });

          // on mobile the store info overlays the map when selected
          // covering the newly active pin, so we pan upwards
          if (window.innerWidth < 1024) {
            map.panBy(0, 115);
          }
        }
        locationsEl.current.dispatchEvent(pinClickedEvent);
      });
      pins.current.push(pin);
    });

    // Check Zoom Level
    checkZoomLevel();
  };

  /**
   * Toggles the pin icon from active to inactive and viceversa
   * @param {object} pin google maps marker
   * @param {boolean} makeActive flag indicating if we are making the pin active
   * @returns {void}
   */
  const togglePin = (pin, makeActive) => {
    if (!pin) return;
    if (makeActive) {
      pin.setIcon(`/${brand.slug}/map-pin-active.svg`);
      activePin.current = pin;
    } else {
      pin.setIcon(`/${brand.slug}/map-pin.svg`);
    }
  };

  // Increases the zoom level by 1
  const zoomInHandler = () => {
    const zoomLevel = map.getZoom();

    if (zoomLevel < 21) {
      map.setZoom(zoomLevel + 1);
    }
  };

  // Decreases the zoom level by 1
  const zoomOutHandler = () => {
    const zoomLevel = map.getZoom();

    if (zoomLevel > 0) {
      map.setZoom(zoomLevel - 1);
    }
  };

  // Function that creates the google map
  const createMap = () => {
    const google = window.google;
    const mapElement = mapRef.current;

    if (google && google.maps) {
      setMap(
        new google.maps.Map(mapElement, {
          center: userGeolocation ?? mapCentrePoint,
          styles: mapStyles[brand.slug],
          zoom: mapDefaultZoom,
          clickableIcons: false,
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: false,
        }),
      );
    }
  };

  // Function that initializes the google maps view
  const initMap = () => {
    // Insert Custom Controls
    const google = window.google;
    const mapElement = mapRef.current;

    const zoomControl = zoomRef.current;
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControl);

    map.addListener('click', () => {
      if (window.innerWidth < 1024) {
        setActivePin(null);
        togglePin(activePin.current, false);
      }
    });

    // Insert Pin with the user location
    if (userGeolocation) {
      userPin.current = new google.maps.Marker({
        position: userGeolocation,
        map: map,
        icon: `/${brand.slug}/map-centre-pin.svg`,
      });
    }

    locationsEl.current.addEventListener('card-clicked', (e) => {
      if (activePin.current === e.detail.activePin) {
        // Reset the previous active pin styles
        togglePin(activePin.current, false);
      } else {
        togglePin(activePin.current, false);
        togglePin(pins.current[e.detail.activePin], true);
      }
      if (e.detail.lng && e.detail.lng) {
        map.setCenter({ lat: e.detail.lat, lng: e.detail.lng });

        // on mobile the store info overlays the map when selected
        // covering the newly active pin, so we pan upwards
        if (window.innerWidth < 1024) {
          map.panBy(0, 115);
        }
      }
    });

    // Add a11y title and aria-label to iframe which is dynamically created
    // When the map is idle we know for sure the iframe was already created
    google.maps.event.addListenerOnce(map, 'idle', () => {
      mapElement.querySelector('iframe').setAttribute('title', mapTitle);
      mapElement.querySelector('iframe').setAttribute('aria-label', mapTitle);
    });
  };

  useEffect(() => {
    createMap();
  }, [mapReady]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (map) {
      initMap();
    }
  }, [map]); // eslint-disable-line react-hooks/exhaustive-deps

  // When store locations are updated we redraw the stores pins
  useEffect(() => {
    if (map) {
      insertStores();
    }
  }, [map, locations]); // eslint-disable-line react-hooks/exhaustive-deps

  // When the user location is updated we redraw the user map pin
  useEffect(() => {
    if (!map || !userGeolocation) {
      return;
    }

    const google = window.google;

    if (userPin.current) {
      userPin.current.setMap(null);
    }

    userPin.current = new google.maps.Marker({
      position: userGeolocation,
      map: map,
      icon: `/${brand.slug}/map-centre-pin.svg`,
    });

    map.setCenter(userGeolocation);
  }, [brand, map, userGeolocation]);

  useEffect(() => {
    const google = window.google;

    // null check in case the map isn't ready and hasn't displayed the custom controls
    if (!map?.controls[google.maps.ControlPosition.RIGHT_BOTTOM]?.length) {
      return;
    }

    if (disableGeolocation && geolocationControlIndex?.current !== undefined) {
      // Remove geolocation control from map
      map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].removeAt(
        geolocationControlIndex.current,
      );
    } else if (!disableGeolocation) {
      const centerControl = centerRef.current;
      geolocationControlIndex.current =
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].length;

      map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(centerControl);
    }
  }, [map, disableGeolocation]);

  return (
    <>
      <div id="map" ref={mapRef} className={styles.map} />

      <button
        className={cx(styles['map-button'], styles['location-button'])}
        ref={centerRef}
        onClick={detectGeolocation}>
        <Location />
      </button>

      <div className={cx(styles['map-button'], styles.zoom)} ref={zoomRef}>
        <button
          className={styles['zoom-in']}
          aria-label={t('common.storeLocator.mapControls.zoomInLabel')}
          onClick={zoomInHandler}>
          <Plus />
        </button>

        <button
          className={styles['zoom-out']}
          aria-label={t('common.storeLocator.mapControls.zoomInLabel')}
          onClick={zoomOutHandler}>
          <Minus />
        </button>
      </div>
    </>
  );
}
