import { useEffect, useState, useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import cx from 'classnames';

import SiteContext from '../AppContext';
import GenericLink from '../GenericLink/GenericLink';
import Picture from '../Image/Picture';
import { getImageSize } from '../../lib/imageUtils';
import { Arrow, LongerThinArrow } from '../Icons';
import styles from './homeProductCategory.module.scss';

/**
 * @param {object} [props] component props
 * @param {string} [props.align] category alignment - left or right
 * @param {string} [props.eyebrow] eyebrow text
 * @param {object} [props.image] image object
 * @param {Array<any>} [props.productList] list of categories
 * @returns {JSX.Element} react component for homepage product category
 */
export default function HomeProductCategory({ align, eyebrow, image, productList }) {
  const [animIn, setAnimIn] = useState(false);
  const { brand } = useContext(SiteContext);

  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setAnimIn(true);
    }
  }, [inView]);

  const categoryImageSize = image?.fields?.file?.details?.image || null;
  const categoryImageSizes = {
    lg: getImageSize(500, categoryImageSize),
    md: getImageSize(400, categoryImageSize),
    sm: getImageSize(400, categoryImageSize),
  };

  return (
    <div
      className={cx(styles.category, {
        [styles.animIn]: animIn,
        [styles.alignRight]: align === 'right',
      })}
      ref={containerRef}>
      <div className={styles.categoryImage}>
        {image?.fields?.file && (
          <Picture
            url={image.fields.file.url}
            alt={image.fields.description}
            imageSizes={categoryImageSizes}
            quality="60"
            loading="lazy"
          />
        )}
      </div>
      <div className={styles.categoryContent}>
        {eyebrow && <h2 className={styles.categoryEyebrow}>{eyebrow}</h2>}
        <ul className={styles.productList}>
          {productList &&
            productList.map((item, index) => {
              return item.fields ? (
                <li key={index} className={styles.product}>
                  <GenericLink
                    internalLink={item.fields.internalPage}
                    AppendedIcon={brand === 'now-fresh' ? <LongerThinArrow /> : <Arrow />}
                    className={styles.productLink}>
                    <bdi className={styles.productTitle}>{item.fields.linkText}</bdi>
                    {item.fields.linkDescription && (
                      <span className={styles.productDesc}>{item.fields.linkDescription}</span>
                    )}
                  </GenericLink>
                </li>
              ) : null;
            })}
        </ul>
      </div>
    </div>
  );
}
