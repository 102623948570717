import styles from './pagination.module.scss';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import { Caret, LongerThinArrow } from '../Icons';
import cx from 'classnames';
import { useTranslation } from '../../lib/hooks';
import { useCallback, useLayoutEffect, useRef, useContext } from 'react';
import SiteContext from '../AppContext';
import { withLayoutEffect } from '../HOCS';

/**
 * @param {number} [page] current page
 * @param {number} [maxReviews] total number of reviews
 * @param {number} [resultPerPage] results per page
 * @returns {Array<number>} pages to be shown
 */
function createPages(page, maxReviews, resultPerPage) {
  const totalPages = Math.ceil(maxReviews / resultPerPage);
  const pagesArray = [];
  for (let i = 0; i < totalPages; i++) {
    pagesArray.push(i);
  }
  if (pagesArray.length < 2) {
    return pagesArray;
  } else {
    return pagesArray.filter((currentPage) => {
      if (page === 0) {
        return page === currentPage || page + 1 === currentPage || page + 2 === currentPage;
      } else if (page * resultPerPage + resultPerPage >= maxReviews) {
        return page - 2 === currentPage || page - 1 === currentPage || page === currentPage;
      }
      return page - 1 === currentPage || page === currentPage || page + 1 === currentPage;
    });
  }
}

/**
 * @param {object} [props] component props
 * @param {string} [props.className] class to be used in pagination component
 * @param {number} [props.page] current page on totalResults
 * @param {Function} [props.setPage] page setter
 * @param {number} [props.totalResults] total number of results
 * @param {number} [props.resultPerPage] results to be shown per page
 * @param {JSX.Element} [props.resultsEl] pages container
 * @returns {JSX.Element} react component
 */
function Pagination({ className, page, setPage, totalResults, resultPerPage, resultsEl }) {
  const { brand } = useContext(SiteContext);
  const pages = createPages(page, totalResults, resultPerPage);
  const paginationEl = useRef(null);
  const { t } = useTranslation();
  const scrollToTopResults = useCallback(
    () => window.scrollTo(0, window.scrollY + resultsEl.current.getBoundingClientRect().y - 88),
    [resultsEl],
  );
  useLayoutEffect(() => {
    const paginationTop = paginationEl.current.getBoundingClientRect().y;
    const isPaginationInViewport = paginationTop > 0 && paginationTop <= window.innerHeight;
    if (isPaginationInViewport) {
      scrollToTopResults();
    }
  }, [page, resultsEl, paginationEl, scrollToTopResults]);

  const renderIcon = (brand) => {
    return brand === 'now-fresh' ? <LongerThinArrow /> : <Caret />;
  };

  return (
    <ul className={cx(styles.pages, className)} ref={paginationEl}>
      <li>
        <AccessibleButton
          className={cx(styles.previous, { [styles.invisible]: page === 0 })}
          ariaLabel={t('review.previousPage')}
          onClick={() => {
            scrollToTopResults();
            setPage((page) => page - 1);
          }}>
          {renderIcon(brand)}
        </AccessibleButton>
      </li>
      {pages.map((pageValue, i) => (
        <li
          key={i}
          className={cx(styles.pageIndicator, { [styles.activeIndicator]: page === pageValue })}>
          <AccessibleButton
            ariaLabel={t('review.pageNumber', [(pageValue + 1).toString()])}
            onClick={() => {
              scrollToTopResults();
              setPage(pageValue);
            }}>
            {pageValue + 1}
          </AccessibleButton>
        </li>
      ))}
      <li>
        <AccessibleButton
          className={cx(styles.next, {
            [styles.invisible]: page * resultPerPage + resultPerPage >= totalResults,
          })}
          ariaLabel={t('review.nextPage')}
          onClick={() => {
            scrollToTopResults();
            setPage((page) => page + 1);
          }}>
          {renderIcon(brand)}
        </AccessibleButton>
      </li>
    </ul>
  );
}

export default withLayoutEffect(Pagination);
