import AccessibleButton from '../AccessibleButton/AccessibleButton';
import GenericLink from '../GenericLink/GenericLink';
import { Arrow } from '../Icons';
import styles from './linkList.module.scss';

/**
 * @param {object} [props] component props
 * @param {object} [props.fields] component data
 * @param {string} [props.fields.headline] headline
 * @param {string} [props.fields.description] description
 * @param {Array<any>} [props.fields.cta] array of ctas
 * @param {Array<any>} [props.fields.links] array of links
 * @returns {JSX.Element} react component for image and benefits
 */
export default function LinkBundle({ fields: { headline, description, cta, links } }) {
  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <h2 className={styles.headline}>{headline}</h2>
        <p className={styles.description}>{description}</p>
        {Array.isArray(cta) && cta.length > 0 && (
          <div className={styles.ctaContainer}>
            {cta.map((component, index) => (
              <AccessibleButton
                key={index}
                type="primary"
                theme="dark"
                className={styles.cta}
                link={component}>
                {component.fields.linkText}
              </AccessibleButton>
            ))}
          </div>
        )}
        <ul>
          {links &&
            links.map((item, index) => {
              return item.fields ? (
                <li key={index} className={styles.linkContainer}>
                  <GenericLink
                    internalLink={item.fields.internalPage}
                    externalLink={item.fields.linkAddress}
                    externalIcon={false}
                    AppendedIcon={<Arrow />}
                    className={styles.linkElements}>
                    <bdi className={styles.linkTitle}>{item.fields.linkText}</bdi>
                    {item.fields.linkDescription && (
                      <span className={styles.linkDescription}>{item.fields.linkDescription}</span>
                    )}
                  </GenericLink>
                </li>
              ) : null;
            })}
        </ul>
      </div>
    </div>
  );
}
