import { breakpoints } from '../../lib/imageUtils';
import styles from './heroCarousel.module.scss';
import cx from 'classnames';

/**
 * @param {object} [props] component props
 * @param {object} [props.pdpHeaderImage] Product image for the hero carousel
 * @param {object} [props.pdpHeaderAccentImage] Product image bg for the hero carousel (Gather)
 * @param {Function} [props.onImgLoad] function to set state
 * @param {boolean} [props.imgLoaded] flag if image is loaded
 * @returns {JSX.Element} Gather hero image and bg
 */
export default function GatherPDPHeroImage({
  pdpHeaderAccentImage,
  pdpHeaderImage,
  onImgLoad,
  imgLoaded,
}) {
  const carouselBgImagesSize = [
    { media: breakpoints.sm, size: [800, 800] },
    { media: breakpoints.md, size: [1200, 1200] },
  ];
  const carouselImageSize = [
    { media: breakpoints.sm, size: [250, 396] },
    { media: breakpoints.md, size: [368, 592] },
    { media: breakpoints.lg, size: [433, 696] },
  ];

  return (
    <div className={styles.gatherSlideWrapper}>
      <div className={styles.background}>
        <picture>
          {carouselBgImagesSize.map(({ media, size }, index) =>
            ['avif', 'webp'].map((imgFormat, formatIndex) => (
              <source
                key={`${index}${formatIndex}`}
                media={media}
                type={`image/${imgFormat}`}
                width={size[0]}
                height={size[1]}
                srcSet={`
                    ${pdpHeaderAccentImage.fields.file.url}?w=${size[0]}&h=${
                      size[1]
                    }&fit=fill&fm=${imgFormat}, 
                    ${pdpHeaderAccentImage.fields.file.url}?w=${size[0] * 2}&h=${
                      size[1] * 2
                    }&fit=fill&fm=${imgFormat} 2x`}
              />
            )),
          )}
          <img
            src={`${pdpHeaderAccentImage.fields.file.url}?w=800&h=800&fit=fill`}
            width="800"
            height="800"
            alt={''}
            className={cx(styles.primaryBg, { [styles.imgLoaded]: imgLoaded })}
            onLoad={onImgLoad}
          />
        </picture>
      </div>

      <picture className={styles.product}>
        {carouselImageSize.map(({ media, size }, index) =>
          ['avif', 'webp'].map((imgFormat, formatIndex) => (
            <source
              key={`${index}${formatIndex}`}
              media={media}
              type={`image/${imgFormat}`}
              width={size[0]}
              height={size[1]}
              srcSet={`
                ${pdpHeaderImage.fields.file.url}?w=${size[0]}&h=${
                  size[1]
                }&fit=fill&fm=${imgFormat}, 
                ${pdpHeaderImage.fields.file.url}?w=${size[0] * 2}&h=${
                  size[1] * 2
                }&fit=fill&fm=${imgFormat} 2x`}
            />
          )),
        )}
        <img
          src={`${pdpHeaderImage.fields.file.url}?w=332&h=526&fit=fill`}
          width="332"
          height="526"
          alt={pdpHeaderImage.fields.description ?? ''}
          className={cx(styles.primaryImg, { [styles.imgLoaded]: imgLoaded })}
          onLoad={onImgLoad}
        />
      </picture>
    </div>
  );
}
