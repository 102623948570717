import { useState } from 'react';
import { useTranslation } from '../../lib/hooks';
import cx from 'classnames';

import styles from './styledFileInput.module.scss';

/**
 * Styled file input component
 * @param {string} [props] component props
 * @param {string} [props.id] id of input
 * @param {string} [props.name] name of input
 * @param {string} [props.allowMultiple] allow multiple files to be selected
 * @param {string} [props.label] label of input
 * @param {string} [props.helperText] helper text
 * @param {string} [props.fileTypesAllowed] allowable file types
 * @param {Function} [props.onFocus] function for the focus event
 * @param {Function} [props.onChange] function for the change event
 * @param {Function} [props.onInvalid] function for the invalid event
 * @param {Function} [props.onBlur] function for the blur event
 * @param {boolean} [props.showError] whether to show error message
 * @param {string} [props.errorMsg] message to display on error
 * @param {string} [props.className] additional class to attach to the component
 * @param {string} [props.ariaRequired] flag for the aria-required attribute
 * @returns {JSX.Element} StyledFileInput react component
 */
export default function FileInput({
  id,
  name,
  allowMultiple = false,
  label,
  helperText,
  fileTypesAllowed,
  onFocus,
  onChange,
  onInvalid,
  onBlur,
  showError,
  errorMsg,
  className,
  ariaRequired,
}) {
  const [fileDetails, setFileDetails] = useState();
  const { t } = useTranslation();

  // Has support for accepting multiple files, but netlify only currently accepts one at a time
  const onFileChange = (e) => {
    if (e.target.files?.length) {
      const fileList = [...e.target.files].map((file, i) => {
        const { name, size } = file;
        const fileBytes = size >= 1048576 ? 'MB' : 'KB';
        const fileSize = size >= 1048576 ? (size / 1048576).toFixed(2) : (size / 1024).toFixed(0);
        return (
          <span key={i} className={styles.fileInfo}>
            <span className={styles.fileName}>{name}</span>
            <span className={styles.fileSize}>
              {fileSize}
              {fileBytes}
            </span>
          </span>
        );
      });

      setFileDetails(fileList);

      if (typeof onChange === 'function') {
        onChange(e);
      }
    }
  };

  return (
    <div
      className={cx(styles.container, className, {
        [styles.error]: showError,
      })}>
      <div className={styles.wrapper}>
        <input
          type="file"
          className={styles.input}
          id={id}
          name={name}
          multiple={allowMultiple}
          onFocus={onFocus}
          onChange={onFileChange}
          onBlur={onBlur}
          onInvalid={onInvalid}
          aria-required={ariaRequired}
          accept={fileTypesAllowed}
        />
        <label className={styles.label} htmlFor={id}>
          <span className={cx('p2 bold', styles.labelText)}>
            {label}
            {helperText && <span className={styles.helperText}>{helperText}</span>}
          </span>
          <span className={styles.fileWrapper}>
            <span className={styles.button}>
              <span>{t('common.forms.general.selectFile')}</span>
            </span>
            <span className={styles.fileDetails}>{fileDetails}</span>
          </span>
        </label>
      </div>
      <p className={styles.errorMsg}>{errorMsg}</p>
    </div>
  );
}
