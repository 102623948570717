import { useState, useRef, useEffect } from 'react';
import cx from 'classnames';

import { useTranslation } from '../../lib/hooks';

import { Search } from '../Icons';
import styles from './locationInput.module.scss';

let geocoder;
let autocomplete;

/**
 * Component to enter or detect location
 * @param {object} [props] component Props
 * @param {boolean} [props.hasMap] flag to enable/disable the map
 * @param {Function} [props.setUserGeolocation] sets the userGeolocation lat and long state
 * @param {boolean} [props.mapReady] flag if the map has loaded
 * @returns {JSX.Element} LocationInput react component
 */
export default function LocationInput({ hasMap, setUserGeolocation, mapReady }) {
  const [isFocused, setIsFocused] = useState();
  const [locationSearches, setLocationSearches] = useState({});
  const { t } = useTranslation();
  const inputEl = useRef(null);

  const labelText = t('common.storeLocator.inputLocation.label');
  const submitLabel = t('common.storeLocator.inputLocation.submit');

  useEffect(() => {
    const input = inputEl.current;

    // we can't setup autocomplete if the map isn't loaded
    if (!mapReady) {
      return;
    }

    const google = window.google;

    // init autocomplete service
    if (!autocomplete) {
      const options = {
        fields: ['formatted_address'],
        componentRestrictions: { country: ['us', 'ca'] },
      };
      autocomplete = new google.maps.places.Autocomplete(input, options);
    }

    // this event will also fire if a user hits "enter" while the input is focused
    autocomplete.addListener('place_changed', useAutocomplete);

    return () => {
      google.maps.event.clearInstanceListeners(autocomplete);
      autocomplete = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapReady]);

  const getAddressLatLng = (address) => {
    if (address in locationSearches) {
      setUserGeolocation(locationSearches[address]);
    }

    if (!geocoder) {
      const google = window.google;
      geocoder = new google.maps.Geocoder();
    }

    geocoder.geocode({ address: address }, function (results, status) {
      if (status == 'OK' && results.length) {
        const geolocationResult = results[0].geometry.location;
        const geolocationObj = {
          lat: geolocationResult.lat(),
          lng: geolocationResult.lng(),
        };

        setUserGeolocation(geolocationObj);
        setLocationSearches({ ...locationSearches, [address]: geolocationObj });
      } else {
        setUserGeolocation(null);
      }
    });
  };

  const useAutocomplete = () => {
    const place = autocomplete.getPlace();
    // if the user has not selected a suggestion the api will return
    // the current input field value as "name"
    const searchInput = place?.formatted_address || place.name;
    getAddressLatLng(searchInput, setUserGeolocation);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (inputEl.current.value.length > 0) {
      getAddressLatLng(inputEl.current.value, setUserGeolocation);
      // Blur to dismiss virtual keyboards
      document.activeElement.blur();
    }
  };

  return (
    // We need to set action for IOS to display the correct UI for the input type search
    <form
      className={cx(styles.container, {
        [styles.grid]: !hasMap,
      })}
      onSubmit={(e) => e.preventDefault()}
      action="">
      <div className={styles['form-field']}>
        <div className={styles['input-container']}>
          <input
            className={styles.input}
            id="locationInput"
            name="locationInput"
            placeholder={labelText}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            type="search"
            autoComplete="off"
            ref={inputEl}
          />
          <label className={styles.label} htmlFor="locationInput">
            {labelText}
          </label>
        </div>

        <button
          className={cx(styles.submit, { [styles['submit-focused']]: isFocused })}
          type="submit"
          onClick={submitHandler}
          aria-label={submitLabel}>
          <Search />
        </button>
      </div>
    </form>
  );
}
