import styles from './typography.module.scss';
import cx from 'classnames';

/**
 * React component for Typography template
 * @param {object} [props] component props
 * @param {string} [props.text] text to be used to showcase the fonts
 * @returns {JSX.Element} Returns Homepage template react component
 */
export default function Typography({ text }) {
  return (
    <section className={styles.background}>
      <div className={cx(styles.container, 'grid-container')}>
        <p className={styles.identifier}>H1</p>
        <h1 className={cx(styles.showcase, 'h1')}>{text}</h1>
        <p className={styles.identifier}>H2</p>
        <h2 className={cx(styles.showcase, 'h2')}>{text}</h2>
        <p className={styles.identifier}>H3</p>
        <h3 className={cx(styles.showcase, 'h3')}>{text}</h3>
        <p className={styles.identifier}>H4</p>
        <h4 className={cx(styles.showcase, 'h4')}>{text}</h4>
        <p className={styles.identifier}>H5</p>
        <h5 className={cx(styles.showcase, 'h5')}>{text}</h5>
        <p className={styles.identifier}>P1</p>
        <p className={cx(styles.showcase, 'p1')}>
          {text}
          <b className="bold">{text}</b>
        </p>
        <p className={styles.identifier}>P2</p>
        <p className={cx(styles.showcase, 'p2')}>
          {text}
          <b className="bold">{text}</b>
        </p>
        <p className={styles.identifier}>P3</p>
        <p className={cx(styles.showcase, 'p3')}>
          {text}
          <b className="bold">{text}</b>
        </p>
        <p className={styles.identifier}>Eyebrow</p>
        <p className={cx(styles.showcase)}>
          <span className="eyebrow">{text}</span>
          <span className="eyebrow-box"> {text}</span>
        </p>
      </div>
    </section>
  );
}
