import { urlParams, breakpoints } from '../../lib/imageUtils';
import Source from './Source';

/**
 * @param {object} [props] component props
 * @param {string} [props.alt] image alt property
 * @param {string} [props.bgColor] image background color property
 * @param {string} [props.className] image class name
 * @param {string} [props.fit] image object-fit property
 * @param {object} [props.imageSizes] image sizes for all 4 breakpoints and fallback
 * (sm, md, lg, xl, fallbackSize)
 * @param {string} [props.quality] image quality property
 * @param {string} [props.url] image url
 * @param {string} [props.sizes] image sizes property
 * @param {Function} [props.onLoad] image onLoad function
 * @param {string} [props.roundedCorners] image crop rounded corners & circle/elipsis property
 * @param {string} [props.loading] enable loading lazy attribute
 * @param {string} [props.fallbackFormat] Format for fallback image
 * @returns {JSX.Element} react component for the Picture
 */
export default function Picture({
  className,
  url,
  imageSizes,
  fit,
  bgColor,
  quality,
  alt = '',
  sizes,
  roundedCorners,
  loading = 'lazy',
  fallbackFormat,
  onLoad = () => {},
}) {
  const { fallbackSize, ...baseSizes } = imageSizes;
  const viewportSizes = Object.keys(baseSizes);
  const imgFormats = ['avif', 'webp'];
  const rounded = roundedCorners;

  // Build urls for src and srcset attributes
  const getUrlParams = (size, imgFormat, pxDensity) =>
    urlParams(url, size, fit, bgColor, quality, imgFormat, rounded, pxDensity);

  const sourceTags =
    viewportSizes.length > 0
      ? // Handle multiple viewport sources
        viewportSizes.map((size, index) =>
          imgFormats.map((imgFormat, formatIndex) => (
            <Source
              srcUrl={getUrlParams(baseSizes[size], imgFormat, 1)}
              srcUrl2x={getUrlParams(baseSizes[size], imgFormat, 2)}
              media={breakpoints[size]}
              size={baseSizes[size]}
              format={imgFormat}
              key={`${index}${formatIndex}`}
            />
          )),
        )
      : // Handle fallback source with no media query
        imgFormats.map((imgFormat, index) => (
          <Source
            srcUrl={urlParams(url, [], fit, bgColor, quality, imgFormat, rounded)}
            srcUrl2x={urlParams(url, [], fit, bgColor, quality, imgFormat, rounded, 2)}
            format={imgFormat}
            key={index}
          />
        ));

  return (
    <picture className={className}>
      {sourceTags}

      <img
        alt={alt}
        src={urlParams(url, fallbackSize, fit, bgColor, null, fallbackFormat, rounded)}
        sizes={sizes}
        loading={loading}
        decoding="async"
        width={fallbackSize ? fallbackSize[0] || null : null}
        height={fallbackSize ? fallbackSize[1] || null : null}
        onLoad={() => onLoad(true)}
      />
    </picture>
  );
}
