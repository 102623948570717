import { useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import cx from 'classnames';

import AccessibleButton from '../AccessibleButton/AccessibleButton';
import Picture from '../Image/Picture';
import { getImageSize } from '../../lib/imageUtils';
import TestimonialBanner from './TestimonialBanner';
import SiteContext from '../AppContext';

import styles from './testimonial.module.scss';

/**
 * @param {object} [props] component props
 * @param {boolean} [props.hasAnimation] Has animation in or not
 * @param {boolean} [props.hasBackground] Has background color or not
 * @param {object} [props.fields] component data
 * @param {object} [props.fields.image] Image Content
 * @param {string} [props.fields.eyebrow] component eyebrow
 * @param {string} [props.fields.title] component title
 * @param {string} [props.fields.copy] component copy
 * @param {string} [props.fields.author] name of the author
 * @param {string} [props.fields.authorTitle] title of the author
 * @param {object} [props.fields.cta] link content
 * @param {object} [props.bannerImage] banner image content
 * @param {string} [props.bannerHeadline] banner headline
 * @param {object} [props.bannerCta] banner link content
 * @returns {JSX.Element} react component
 */
export default function Testimonial({
  hasAnimation,
  hasBackground = true,
  fields: { image, eyebrow, title, copy, author, authorTitle, cta },
  bannerImage,
  bannerHeadline,
  bannerCta,
}) {
  const [animIn, setAnimIn] = useState(false);
  const { brand } = useContext(SiteContext);

  const [ref, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView && hasAnimation) {
      setAnimIn(true);
    }
  }, [inView, hasAnimation]);

  const hasBanner = bannerImage || bannerHeadline || bannerCta;

  const smallTestimonialImageSizes = {
    xl: [424, 424],
    md: [350, 350],
    sm: [250, 250],
    fallbackSize: getImageSize(350, image?.fields?.file?.details?.image),
  };

  const largeTestimonialImageSizes = {
    xl: [1000, 1000],
    lg: [700, 700],
    md: [900, 900],
    sm: [600, 600],
    fallbackSize: getImageSize(900, image?.fields?.file?.details?.image),
  };

  const nowFreshTestimonialImageSizes = {
    xl: [1700, 1700],
    lg: [980, 980],
    md: [1260, 1260],
    sm: [840, 840],
    fallbackSize: getImageSize(1260, image?.fields?.file?.details?.image),
  };

  const testimonialImageSizesMap = {
    gather: largeTestimonialImageSizes,
    'go-solutions': smallTestimonialImageSizes,
    'now-fresh': nowFreshTestimonialImageSizes,
    petcurean: largeTestimonialImageSizes,
    summit: smallTestimonialImageSizes,
  };

  const testimonialImageSizes = testimonialImageSizesMap[brand] || smallTestimonialImageSizes;

  return (
    <section
      className={cx(styles.container, {
        [styles.animIn]: animIn,
        [styles.hasAnimation]: hasAnimation,
        [styles.hasBanner]: hasBanner,
        [styles.hasBackground]: hasBackground,
        [styles.hasNoImage]: !image,
      })}
      ref={ref}>
      <div className={styles.content}>
        {image?.fields?.file && (
          <Picture
            className={styles.image}
            url={image.fields.file.url}
            imageSizes={testimonialImageSizes}
            quality="80"
            alt={image.fields.description}
            loading="lazy"
            fit={brand === 'summit' ? 'fill' : null}
          />
        )}
        <div className={styles.copyContainer}>
          {eyebrow && <p className={cx(styles.eyebrow)}>{eyebrow}</p>}
          <h2 className={styles.headline}>{title}</h2>
          <div className={styles.description}>{copy}</div>
          <div className={styles.authorAndCtaContainer}>
            {(author || authorTitle) && (
              <div className={styles.authorWrapper}>
                {author && <div className={styles.author}>{author}</div>}
                {authorTitle && <div className={styles.authorTitle}>{authorTitle}</div>}
              </div>
            )}
            {Array.isArray(cta) && cta.length > 0 && (
              <div className={styles.ctaContainer}>
                {cta.map((component, index) => (
                  <AccessibleButton
                    key={index}
                    className={styles.cta}
                    theme={brand === 'summit' ? 'light' : hasBackground ? 'dark' : 'light'}
                    type={brand !== 'go-solutions' ? 'primary' : 'secondary'}
                    link={component}>
                    {component.fields.linkText}
                  </AccessibleButton>
                ))}
              </div>
            )}
          </div>
        </div>
        {hasBanner && (
          <div className={styles.banner}>
            <TestimonialBanner
              bannerImage={bannerImage}
              bannerHeadline={bannerHeadline}
              bannerCta={bannerCta}
              hasAnimation={hasAnimation}
            />
          </div>
        )}
      </div>
    </section>
  );
}
