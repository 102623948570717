import { useContext } from 'react';
import { breakpoints, getImageSize, adjustImageSize } from '../../lib/imageUtils';
import SiteContext from '../AppContext';
import styles from './heroCarouselSplash.module.scss';
import cx from 'classnames';

/**
 *
 * @param {object} [props] component props
 * @param {object} [props.headerImage] media image or video object
 * @param {boolean} [props.isMobile] media image or video object
 * @param {boolean} [props.hideOnMobile] hide media image or video object on mobile
 * @returns {JSX.Element} react component for background image or video
 */
export default function HeroCarouselMediaRenderer({ headerImage, isMobile, hideOnMobile }) {
  const { brand } = useContext(SiteContext);
  const headerImageSize = headerImage?.fields?.file?.details?.image || [];

  const headerImageSrcSize = [
    { media: breakpoints.xl, size: getImageSize(adjustImageSize(716, brand), headerImageSize) },
    { media: breakpoints.md, size: getImageSize(adjustImageSize(500, brand), headerImageSize) },
    { media: breakpoints.sm, size: getImageSize(adjustImageSize(300, brand), headerImageSize) },
  ];

  const [headerImgWidth, headerImgHeight] = headerImage
    ? getImageSize(adjustImageSize(500, brand), headerImageSize)
    : [adjustImageSize(500, brand), null];

  const isVideoAsset = headerImage && headerImage.fields.file.contentType.includes('video');

  return (
    <div
      className={cx(styles.bgImage, {
        [styles.bgImageMobile]: isMobile,
        [styles.bgImageHideOnMobile]: hideOnMobile,
      })}>
      {isVideoAsset ? (
        <video className={styles.videoBackground} autoPlay loop muted playsInline>
          <source src={headerImage.fields.file.url} type="video/mp4" />
          <source src={headerImage.fields.file.url} type="video/webm" />
          <source src={headerImage.fields.file.url} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <picture>
          {headerImageSrcSize.map(({ media, size }, index) =>
            ['avif', 'webp'].map((imgFormat, formatIndex) => (
              <source
                key={`${index}${formatIndex}`}
                media={media}
                type={`image/${imgFormat}`}
                srcSet={`${headerImage.fields.file.url}?w=${size[0]}&fm=${imgFormat}, ${
                  headerImage.fields.file.url
                }?w=${size[0] * 2}&fm=${imgFormat} 2x`}
              />
            )),
          )}
          <img
            src={`${headerImage.fields.file.url}?w=${headerImgWidth}`}
            alt={headerImage.fields.description ?? ''}
            width={headerImgWidth}
            height={headerImgHeight}
          />
        </picture>
      )}
    </div>
  );
}
