import styles from './styledInput.module.scss';
import cx from 'classnames';

/**
 * Styled input component
 * @param {string} [props] component props
 * @param {string} [props.type] type of input
 * @param {string} [props.id] id of input
 * @param {string} [props.name] name of input
 * @param {string} [props.label] label of input
 * @param {string} [props.value] value of input
 * @param {string} [props.placeholder] placeholder of input
 * @param {Function} [props.onFocus] function for the focus event
 * @param {Function} [props.onChange] function for the change event
 * @param {Function} [props.onInvalid] function for the invalid event
 * @param {Function} [props.onBlur] function for the blur event
 * @param {string} [props.errorMsg] message to display on error
 * @param {string} [props.className] additional class to attach to the component
 * @param {string} [props.ariaRequired] flag for the aria-required attribute
 * @param {number} [props.minLength] Minimum length required for validation
 * @param {number} [props.maxLength] Maximum length required for validation
 * @returns {JSX.Element} StyledInput react component
 */
export default function Input({
  type,
  id,
  name,
  label,
  value,
  onFocus,
  onChange,
  onInvalid,
  onBlur,
  showError,
  errorMsg,
  className,
  ariaRequired,
  minLength,
  maxLength,
}) {
  const lengthValidation =
    maxLength && value.length > 0 ? (
      <span className={styles.maxLength}>{`${value.length}/${maxLength}`}</span>
    ) : (
      ''
    );
  return (
    <div
      className={cx(styles.container, className, {
        [styles.error]: showError,
      })}>
      <div className={styles.wrapper}>
        <input
          type={type}
          className={cx(styles.input, styles[`input-${type}`])}
          id={id}
          name={name}
          value={value}
          placeholder={label}
          onFocus={onFocus}
          onChange={onChange}
          onBlur={onBlur}
          onInvalid={onInvalid}
          aria-required={ariaRequired}
          minLength={minLength}
          maxLength={maxLength}
        />
        <label className={styles.label} htmlFor={id}>
          {label}
          {lengthValidation}
        </label>
      </div>
      <p className={styles.errorMsg}>{errorMsg}</p>
    </div>
  );
}
