const API_KEY = process.env.NEXT_PUBLIC_MAPS_API_KEY;

/**
 *
 * @param {string} targetLang language code we want the returned translation in
 * @param {string} sourceLang language code of original string to be translated
 * @param {Array} textArr the text we want to translate
 * @returns {JSON} json response from google translate api
 */
export default async function googleTranslate(targetLang, sourceLang, textArr) {
  let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}&format=text`;
  url += `&source=${sourceLang}`;
  url += `&target=${targetLang}`;
  textArr.forEach((text) => {
    url += `&q=${encodeURI(text)}`;
  });

  const response = await fetch(url);
  const translatedText = await response.json();
  return translatedText;
}
