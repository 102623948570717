import { useRef } from 'react';
import styles from './styledTextarea.module.scss';
import cx from 'classnames';

/**
 * Styled text area component
 * @param {string} [props] component props
 * @param {string} [props.type] type of input
 * @param {string} [props.id] id of input
 * @param {string} [props.name] name of input
 * @param {string} [props.label] label of input
 * @param {string} [props.value] value for the input
 * @param {string} [props.placeholder] placeholder of input
 * @param {Function} [props.onFocus] function for the focus event
 * @param {Function} [props.onChange] function for the change event
 * @param {Function} [props.onBlur] function for the blur event
 * @param {string} [props.errorMsg] Message to display on error
 * @param {string} [props.className] Additional class to attach to the component
 * @param {string} [props.ariaRequired] flag for the aria-required attribute
 * @param {number} [props.minLength] Minimum length required for validation
 * @param {number} [props.maxLength] Maximum length required for validation
 * @returns {JSX.Element} StyledTextarea react component
 */
export default function Input({
  type,
  id,
  name,
  label,
  value,
  onFocus,
  onChange,
  onBlur,
  showError,
  errorMsg,
  className,
  ariaRequired,
  minLength,
  maxLength,
}) {
  const labelRef = useRef(null);
  const textareaRef = useRef(null);
  const lengthValidation =
    maxLength && value.length > 0 ? (
      <span className={styles.maxLength}>{`${value.length}/${maxLength}`}</span>
    ) : minLength && value.length > 0 ? (
      <span className={styles.maxLength}>{value.length}</span>
    ) : (
      ''
    );

  // Handle dinamically the text area padding based on the height of the label
  // for the edge case of long questions as labels
  const handleFocus = (e) => {
    const elmBounds = labelRef.current.getBoundingClientRect();

    textareaRef.current.style.paddingTop = `${elmBounds.height + labelRef.current.offsetTop}px`;
    if (typeof onFocus === 'function') onFocus(e);
  };

  return (
    <div
      className={cx(styles.container, className, {
        [styles.error]: showError,
      })}>
      <div className={styles.wrapper}>
        <textarea
          ref={textareaRef}
          type={type}
          className={cx(styles.textarea, styles[`input-${type}`])}
          id={id}
          name={name}
          value={value}
          placeholder={label}
          onFocus={handleFocus}
          onChange={onChange}
          onBlur={onBlur}
          aria-required={ariaRequired}
          minLength={minLength}
          maxLength={maxLength}></textarea>
        <label className={styles.label} htmlFor={id} ref={labelRef}>
          {label}
          {lengthValidation}
        </label>
      </div>
      <p className={styles.errorMsg}>{errorMsg}</p>
    </div>
  );
}
