import styles from './footerTop.module.scss';
import StyledLink from '../StyledLink/StyledLink';
import Modal from '../Modal/Modal';
import StyledForm from '../StyledForm/StyledForm';
import AccessibleButton from '../AccessibleButton/AccessibleButton';
import Picture from '../Image/Picture';
import SiteContext from '../AppContext';
import { useTranslation } from '../../lib/hooks';
import { useContext, useRef, useState } from 'react';
import cx from 'classnames';
import { getImageSize } from '../../lib/imageUtils';

/**
 * FooterTop component
 * @param {object} [props] component props
 * @param {string} [props.nutritionHotlineTitle] title of the nutition hotline section
 * @param {string} [props.nutritionHotlinePhoneNumber] phone number of the nutition hotline section
 * @param {string} [props.showNutritionHotlinePhoneNumber] Show or not the nutrition hotline number
 * @param {string} [props.nutritionHotlineBody] description of the nutition hotline section
 * @param {string} [props.footerHeroImage] decorative pet image
 * @param {string} [props.newsletterHeader] title of the newsletter section
 * @param {string} [props.newsletterBody] description of the newsletter section
 * @param {string} [props.contactUsForm] form triggered by the contact us button
 * @param {string} [props.newsletterForm] inline form of the newsletter
 * @param {boolean} [props.reducedVersion] if true the reduced version of the footer should be shown
 * @param {object} [props.nutritionHotlineEmailLink] fields for the nutrition Hotline Email
 * @param {string}  [props.nutritionEmailTitle] nutrition Email Title
 * @param {boolean} [props.showNutritionEmail] if true should show nutrition Email Title
 * @returns {JSX.Element} FooterTop react component
 */
export default function FooterTop({
  nutritionHotlineTitle,
  nutritionHotlinePhoneNumber,
  showNutritionHotlinePhoneNumber,
  nutritionHotlineBody,
  footerHeroImage,
  newsletterHeader,
  newsletterBody,
  contactUsForm,
  newsletterForm,
  reducedVersion,
  nutritionEmailTitle,
  nutritionHotlineEmailLink,
  showNutritionEmail,
}) {
  const bgImgSrc = footerHeroImage?.fields?.file?.url;
  const bgImgAlt = footerHeroImage?.fields?.description || '';
  const siteContext = useContext(SiteContext);
  const brand = siteContext.brand;
  const { t } = useTranslation();
  const [isNewsletterSent, setIsNewsletterSent] = useState(false);

  const modalRef = useRef(null);
  const openerRef = useRef(null);
  const openModal = () => {
    modalRef.current.showModal();
  };

  const renderNewsLetter = () => {
    if (isNewsletterSent) {
      return (
        <div id="footer-newsletter-success" className={styles.newsletterSuccess}>
          {newsletterForm?.fields?.successImage?.fields?.file?.url && (
            <img
              className={styles.successImage}
              src={newsletterForm.fields.successImage.fields.file.url}
              alt=""
            />
          )}
          <h3 className={styles.successTitle}>{newsletterForm?.fields?.successMessageTitle}</h3>
          <p className="p2">{newsletterForm?.fields?.successMessage}</p>
        </div>
      );
    }
    return (
      <div className={styles.newsletterSection}>
        <h2 className={styles['section-title']}>{newsletterHeader}</h2>
        <p className="p2">{newsletterBody}</p>
        {newsletterForm?.fields && (
          <StyledForm
            fields={newsletterForm?.fields}
            id="footer"
            setIsFormSent={setIsNewsletterSent}
            isInline="true"
            hidden={false}
            netlify={true}
          />
        )}
      </div>
    );
  };

  const footerImageSize = footerHeroImage?.fields?.file?.details?.image || null;
  const imageSizes = reducedVersion
    ? {
        lg: getImageSize(592, footerImageSize),
        md:
          brand === 'go-solutions'
            ? getImageSize(500, footerImageSize)
            : getImageSize(296, footerImageSize),
        sm:
          brand === 'go-solutions'
            ? getImageSize(296, footerImageSize)
            : getImageSize(180, footerImageSize),
        fallbackSize: getImageSize(592, footerImageSize),
      }
    : {
        lg: getImageSize(296, footerImageSize),
        md:
          brand === 'go-solutions'
            ? getImageSize(500, footerImageSize)
            : getImageSize(296, footerImageSize),
        sm:
          brand === 'go-solutions'
            ? getImageSize(296, footerImageSize)
            : getImageSize(180, footerImageSize),
        fallbackSize: getImageSize(296, footerImageSize),
      };
  const hotlineThemeMap = {
    gather: 'dark',
    'go-solutions': 'dark',
    'now-fresh': 'light',
    petcurean: 'light',
    summit: 'dark',
  };
  const hotlineTheme = hotlineThemeMap[brand];

  return (
    <div className={styles.top}>
      {bgImgSrc && (
        <div
          className={cx(styles['bgimg-section'], {
            [styles.bgImgSectionReducedVersion]: reducedVersion,
          })}>
          <Picture
            className={styles['bgimg']}
            imageSizes={imageSizes}
            url={bgImgSrc}
            alt={bgImgAlt}
            quality="80"
          />
        </div>
      )}
      <div
        className={cx(styles['hotline-section'], {
          [styles.hotlineSectionReducedVersion]: reducedVersion,
        })}>
        {/* Possible technical debt for this image below if we decide 
        to add similar images in hotline section to other websites instead of
        only gather */}
        {brand === 'gather' && (
          // eslint-disable-next-line jsx-a11y/alt-text
          <img src="/gather/footer_cat.jpg" className={styles.gatherHotlineImage} />
        )}
        <h2 className={styles['section-title']}>{nutritionHotlineTitle}</h2>
        {showNutritionHotlinePhoneNumber && (
          <StyledLink
            href={`tel:${nutritionHotlinePhoneNumber?.replace(/\D/g, '')}`}
            type="p1"
            theme={hotlineTheme}
            isBold={false}
            className={styles.phonenum}>
            {nutritionHotlinePhoneNumber}
          </StyledLink>
        )}
        {nutritionHotlineBody && <p className="p2">{nutritionHotlineBody}</p>}
        {showNutritionEmail && (
          <>
            <h3 className={styles['section-title']}>{nutritionEmailTitle}</h3>
            <StyledLink
              href={nutritionHotlineEmailLink.fields.linkAddress}
              type="p1"
              theme={brand === 'go-solutions' ? 'dark' : 'light'}
              isBold={false}
              className={styles.phonenum}>
              {nutritionHotlineEmailLink.fields.linkText}
            </StyledLink>
          </>
        )}
        {!reducedVersion && (
          <AccessibleButton
            className={styles.contactBtn}
            type="primary"
            theme="dark"
            ref={openerRef}
            onClick={openModal}>
            {t('common.ctas.contactUs')}
          </AccessibleButton>
        )}
        {/* This copy of the form outside the modal is important because the original */}
        {/* contactus form is inside a modal. If removed Netlify wont be able to crawl */}
        {/* it during build time and the data wont be registered. */}
        {contactUsForm?.fields && (
          <StyledForm
            fields={contactUsForm?.fields}
            id="footer-hidden"
            hidden={true}
            netlify={true}
            className={styles.staticForm}
          />
        )}
        <Modal ref={modalRef} opener={openerRef}>
          {contactUsForm?.fields && (
            <StyledForm
              fields={contactUsForm.fields}
              id="footer"
              hidden={false}
              netlify={false}
              ref={modalRef}
            />
          )}
        </Modal>
      </div>
      {!reducedVersion && renderNewsLetter()}
    </div>
  );
}
