import { useEffect, useState, useContext } from 'react';
import { useInView } from 'react-intersection-observer';
import Picture from '../Image/Picture';
import { getImageSize, adjustImageSize } from '../../lib/imageUtils';
import cx from 'classnames';

import styles from './testimonialBanner.module.scss';
import SiteContext from '../AppContext';
import StyledLink from '../StyledLink/StyledLink';

/**
 * @param {object} [props] component props
 * @param {object} [props.bannerImage] banner image content
 * @param {string} [props.bannerHeadline] banner headline
 * @param {object} [props.bannerCta] banner link content
 * @param {boolean} [props.hasAnimation] Has animation in or not
 * @returns {JSX.Element} react component
 */
export default function TestimonialBanner({
  bannerImage,
  bannerHeadline,
  bannerCta,
  hasAnimation,
}) {
  const { brand } = useContext(SiteContext);
  const [animIn, setAnimIn] = useState(false);

  const [ref, inView] = useInView({
    rootMargin: '-50px 0px',
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView && hasAnimation) {
      setAnimIn(true);
    }
  }, [inView, hasAnimation]);

  const testimonialBannerImageSize = bannerImage?.fields?.file?.details?.image;
  const testimonialBannerImageSizes = {
    md: getImageSize(200, testimonialBannerImageSize),
    sm: getImageSize(adjustImageSize(120, brand, 3), testimonialBannerImageSize),
    fallbackSize: getImageSize(120, testimonialBannerImageSize),
  };

  return (
    <div
      className={cx(styles.container, {
        [styles.animIn]: animIn,
        [styles.hasAnimation]: hasAnimation,
      })}
      ref={ref}>
      {bannerImage?.fields?.file && (
        <div className={styles.image}>
          <Picture
            url={bannerImage.fields.file.url}
            imageSizes={testimonialBannerImageSizes}
            quality="45"
            alt={bannerImage.fields.description}
          />
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.headline}>{bannerHeadline}</div>
        {bannerCta?.fields && (
          <StyledLink
            type="p2"
            theme="dark"
            className={styles.cta}
            href={
              bannerCta.fields.internalPage?.fields
                ? bannerCta.fields.internalPage.fields.slug
                : bannerCta.fields.linkAddress
            }>
            {bannerCta.fields.linkText}
          </StyledLink>
        )}
      </div>
    </div>
  );
}
