import { useTranslation } from '../../lib/hooks';
import { getAllowedFileTypeObject } from '../../lib/utils';
import { useDropzone } from 'react-dropzone';
import camelCase from 'lodash/camelCase';
import cx from 'classnames';

import styles from './styledFileDragAndDrop.module.scss';

/**
 * Get string of file name and file size
 * @param {object} [file] file object
 * @returns {string} file name and size
 */
function getFileDetails(file) {
  const { name, size } = file;
  const fileBytes = size >= 1048576 ? 'MB' : 'KB';
  const fileSize = size >= 1048576 ? (size / 1048576).toFixed(2) : (size / 1024).toFixed(0);

  return `${name} - ${fileSize}${fileBytes}`;
}

/**
 * Styled file drag and drop component
 * @param {string} [props] component props
 * @param {string} [props.id] id of input
 * @param {string} [props.name] name of input
 * @param {string} [props.helperText] helper text
 * @param {string} [props.label] label of input
 * @param {string} [props.maxFiles] max number of files
 * @param {string} [props.maxSize] max file size
 * @param {string} [props.fileTypesAllowed] allowable file types
 * @param {Function} [props.onChange] function for the change event
 * @param {boolean} [props.showError] whether to show error message
 * @param {string} [props.errorMsg] message to display on error
 * @param {string} [props.className] additional class to attach to the component
 * @param {string} [props.ariaRequired] flag for the aria-required attribute
 * @returns {JSX.Element} StyledFileDragAndDrop react component
 */
export default function FileDragAndDrop({
  id,
  name,
  helperText,
  label,
  maxFiles,
  maxSize,
  fileTypesAllowed, // todo
  onChange,
  showError,
  errorMsg,
  className,
  ariaRequired,
}) {
  const { t } = useTranslation();
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: getAllowedFileTypeObject(fileTypesAllowed),
    maxSize,
    minSize: 1,
    maxFiles,
    onDrop: (acceptedFiles, fileRejections) => {
      if (typeof onChange === 'function') {
        let value;

        if (!fileRejections?.length) {
          value = acceptedFiles;
        }

        const fakeEvt = {
          target: {
            type: 'dragdrop',
            name,
            value,
            hasError: fileRejections?.length,
            errorCode:
              fileRejections?.length > 0 && fileRejections[0]?.errors?.length
                ? camelCase(fileRejections[0].errors[0].code)
                : '',
          },
        };
        onChange(fakeEvt);
      }
    },
  });

  const filesAccepted = acceptedFiles.map((file, i) => {
    return (
      <li key={`accepted-${i}`} className={styles.fileAccepted}>
        {getFileDetails(file)}
      </li>
    );
  });

  const filesRejected = fileRejections.map(({ file, errors }, i) => {
    return (
      <li key={`rejected-${i}`} className={styles.fileRejected}>
        {getFileDetails(file)}
      </li>
    );
  });

  return (
    <div
      className={cx(styles.container, className, {
        [styles.error]: showError,
      })}>
      <label className={cx('p2 bold', styles.label)} htmlFor={id}>
        {label}
        {helperText && <span className={styles.helperText}>{helperText}</span>}
      </label>
      <div {...getRootProps()} className={styles.dropzone}>
        <input {...getInputProps()} id={id} name={name} aria-required={ariaRequired} />
        <p>{t('common.forms.general.dragAndDrop')}</p>
      </div>
      {!!(filesAccepted?.length || filesRejected?.length) && (
        <aside className={styles.fileList}>
          <p className={styles.fileListTitle}>{t('common.forms.general.files')}</p>
          <ul>
            {filesAccepted}
            {filesRejected}
          </ul>
        </aside>
      )}
      <p className={styles.errorMsg}>{errorMsg}</p>
    </div>
  );
}
