import {
  useContext,
  createContext,
  useState,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import AccessibleButton from '../AccessibleButton/AccessibleButton';
import { Close } from '../Icons';
import SiteContext from '../AppContext';
import { useTranslation } from '../../lib/hooks';

import styles from './modal.module.scss';

export const ModalContext = createContext();

/**
 * @param {object} [props] component props
 * @param {JSX.Element} [props.children] component children
 * @param {JSX.Element} [props.opener] ref from the element opening the modal
 * @param {string} [props.className] classname prop
 * @param {boolean} [props.fullSize] flag set the modal full size of the window
 * @param {boolean} [props.theme] 'dark' or 'light' background for Go Solutions
 * @param {boolean} [props.autoTriggerOnLoad] boolean to display modal on load
 * @param {Function} [props.closeAction] extra action when the close icon es clicked
 * @param {object} [ref] Component ref
 * @returns {JSX.Element} React component
 */
function Modal(
  {
    children,
    opener,
    className,
    fullSize = true,
    theme = 'dark',
    autoTriggerOnLoad,
    closeAction = () => {},
    ...props
  },
  ref,
) {
  const { t } = useTranslation();
  const siteContext = useContext(SiteContext);
  const contentEL = useRef();
  const { brand } = siteContext;
  // We need this to ensure the portal is created only when the target container
  // of the modal exists (even after navigation between templates)
  const [mounted, setMounted] = useState(false);
  const [visible, setVisible] = useState(false);

  const detectDialogState = useCallback(
    ([openMutation]) => {
      if (openMutation.oldValue === null) {
        disableBodyScroll(ref.current, {
          reserveScrollBarGap: true,
        });
        setVisible(true);
      } else {
        enableBodyScroll(ref.current);
        setVisible(false);
        opener.current.focus();
      }
    },
    [opener, ref],
  );

  const clickHandler = useCallback(
    (e) => {
      if (e.currentTarget === e.target) {
        ref.current.close();
      }
    },
    [ref],
  );

  useEffect(() => {
    setMounted(true);
    const modalEL = ref.current;
    let dialogObserver;

    if (modalEL) {
      if (typeof HTMLDialogElement !== 'function') {
        import('dialog-polyfill')
          .then(({ default: { registerDialog } }) => {
            return registerDialog(modalEL);
          })
          .then(() => {
            if (autoTriggerOnLoad && !modalEL.open) {
              modalEL.showModal();
            }
          });
      }

      dialogObserver = new MutationObserver(detectDialogState);
      dialogObserver.observe(modalEL, {
        attributeFilter: ['open'],
        attributeOldValue: true,
      });

      modalEL.addEventListener('click', clickHandler);
    }
    if (modalEL && autoTriggerOnLoad && typeof HTMLDialogElement === 'function' && !modalEL.open) {
      modalEL.showModal();
    }

    return () => {
      if (dialogObserver) {
        dialogObserver.disconnect();
        modalEL.removeEventListener('click', clickHandler);
      }
    };
  }, [ref, mounted, detectDialogState, clickHandler, autoTriggerOnLoad]);

  const restoreFocus = () => {
    closeAction();
    opener.current.focus();
  };

  // Firefox doesn't support the ::backdrop pseudo element so here the <dialog> elemnt
  // works as the backdrop, and the '.wrapper' element is the modal itself.
  return mounted
    ? createPortal(
        <dialog
          aria-modal="true"
          ref={ref}
          className={cx(styles.modal, className, {
            [styles.fullsize]: fullSize,
          })}
          onClose={restoreFocus}
          {...props}>
          <div
            className={cx(styles.wrapper, styles[theme], {
              [styles.fullsize]: fullSize,
            })}>
            <ModalContext.Provider value={{ visible: visible }}>
              <form className={styles.form} method="dialog">
                <AccessibleButton
                  className={styles.close}
                  ariaLabel={t('common.redirectModal.closeModal')}
                  submit={true}>
                  <Close />
                </AccessibleButton>
              </form>
              <div className={styles.content} ref={contentEL}>
                {children}
              </div>
            </ModalContext.Provider>
          </div>
        </dialog>,
        document.querySelector(`.${brand}`),
      )
    : null;
}

export default forwardRef(Modal);
