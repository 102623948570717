import GenericLink from '../GenericLink/GenericLink';
import Picture from '../Image/Picture';
import { getImageSize } from '../../lib/imageUtils';

import styles from './linkedImageGrid.module.scss';

/**
 *
 * @param {object} [props] component props
 * @param {object} [props.fields] component field props
 * @param {object} [props.fields.linkedImages] images with links
 * @returns {JSX.Element} react component
 */
export default function LinkedImageGrid({ fields: { linkedImages } }) {
  return (
    <div className={styles.container}>
      {linkedImages.map((linkedImage, i) => (
        <GenericLink
          className={styles.link}
          externalLink={linkedImage.fields.link}
          externalIcon={false}
          key={i}>
          <Picture
            className={styles.linkedImage}
            url={linkedImage.fields.image.fields.file.url}
            quality={'70'}
            imageSizes={{
              fallbackSize: getImageSize(200, linkedImage.fields.image.fields.file.details.image),
            }}
            alt={linkedImage.fields.image.fields.description}
          />
        </GenericLink>
      ))}
    </div>
  );
}
