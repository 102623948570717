import { getLocalizationProps } from './LanguageProvider';

/**
 * @param {string} locale current locale
 * @param {string} petType type of pet: dog or cat
 * @returns {object} filter category object
 */
export function getPetTypeTags(locale, petType) {
  const translations = getLocalizationProps(locale, 'categoryLandingSyntheticTags').translations;
  const filterTitle = getLocalizationProps(locale, 'categoryLanding').translations.filters
    .filterCategories.petType;
  const sortOrder = petType === 'Dog' ? 2001 : 2000;
  const label = petType === 'Dog' ? translations.dog : translations.cat;

  return {
    fields: {
      filterCategory: {
        fields: {
          title: filterTitle,
        },
      },
      label: label,
      sortOrder: sortOrder,
    },
  };
}
