import styles from './benefitsNow.module.scss';
import RemoteIcon from '../RemoteIcon/remoteIcon';

/**
 * @param {object} [props] component props
 * @param {string} [props.title] component title
 * @param {string} [props.description] component description
 * @param {Array<any>} [props.iconsWithText] icons content
 * @returns {JSX.Element} benefits now fresh component
 */
export default function BenefitsNow({ title, description, iconsWithText }) {
  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.copyWrapper}>
          <div className={styles.copy}>
            <h2 className={styles.headline}>{title}</h2>
            <p className={styles.description}>{description}</p>
            {iconsWithText && (
              <ul className={styles.benefits}>
                {iconsWithText
                  .filter((iconText) => iconText.fields)
                  .map((iconText, i) => (
                    <li className={styles.benefit} key={i}>
                      <RemoteIcon
                        url={`${iconText.fields.image.fields.file.url}?w=75&h=75&fit=pad`}
                        className={styles.benefitImage}
                      />
                      <p className={styles.benefitDescription}>{iconText.fields.primaryText}</p>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
