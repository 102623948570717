import { useRef, useState } from 'react';
import Slider from 'react-slick';
import Modal from '../Modal/Modal';
import styles from './reviewCarousel.module.scss';
import { useTranslation } from '../../lib/hooks';

/**
 *
 * @param {object} [props] component props
 * @param {object} [props.images] ugc images from bazaarvoicee
 * @returns {JSX.Element} review card react component
 */
export default function ReviewCarousel({ images }) {
  const { t } = useTranslation();
  const [thumbSlider, setThumbSlider] = useState();
  const [lightboxSlider, setLightboxSlider] = useState();
  const modalEl = useRef(null);
  const containerRef = useRef(null);

  // slider settings
  const settings = {
    arrows: true,
    lazyLoad: 'ondemand',
    className: 'review-slick',
    centerMode: true,
    centerPadding: 0,
    asNavFor: lightboxSlider,
    draggable: false,
  };

  const lightboxSettings = {
    arrows: true,
    lazyLoad: 'ondemand',
    className: 'lightbox-slick',
    centerMode: true,
    centerPadding: 0,
    asNavFor: thumbSlider,
    draggable: false,
  };

  const openLightbox = () => {
    modalEl.current.showModal();
  };

  return (
    <div className={styles.container} ref={containerRef}>
      <Slider ref={(ref) => setThumbSlider(ref)} {...settings}>
        {images.map((img, i) => (
          <div key={i}>
            <button
              className={styles.slide}
              onClick={openLightbox}
              aria-label={t('review.viewLargeImage')}>
              <img loading="lazy" className={styles.thumbnail} src={img.Sizes.normal.Url} alt="" />
            </button>
          </div>
        ))}
      </Slider>

      <Modal className={styles.lightbox} ref={modalEl} opener={containerRef} fullSize={false}>
        <Slider ref={(ref) => setLightboxSlider(ref)} {...lightboxSettings}>
          {images.map((img, i) => (
            <div key={i}>
              <img loading="lazy" src={img.Sizes.large.Url} alt="" />
            </div>
          ))}
        </Slider>
      </Modal>
    </div>
  );
}
