import { useEffect, useState } from 'react';
import cx from 'classnames';

import Image from '../Image/Image';
import AccessibleButton from '../AccessibleButton/AccessibleButton';

import styles from './tabbedHeader.module.scss';

/**
 * Component with a header and switchable tabbed content
 * @param {object} [props] component Props
 * @param {number} [props.activeTab] current tab index
 * @param {Function} [props.setActiveTab] function that sets the current tab index
 * @param {string} [props.inStoreText] text for the in-store tab
 * @param {string} [props.buyOnlineText] text for the buy online tab
 * @param {string} [props.title] title in the header
 * @param {string} [props.description] description copy of the header
 * @param {boolean} [props.hasInStore] flag indicating if the in store tab should display
 * @param {boolean} [props.hasOnline] flag indicating if the online tab should display
 * @param {boolean} [props.hasBackground] flag indicating if it should show background
 * @param {boolean} [props.isPageHeader] flag indicating if it is a page title
 * @param {object} [props.retailerLogo] optional retailer logo image
 * @returns {JSX.Element} Tabbed Header react component
 */
export default function TabbedHeader({
  activeTab,
  setActiveTab,
  inStoreText,
  buyOnlineText,
  title,
  description,
  hasInStore,
  hasOnline,
  hasBackground = true,
  isPageHeader,
  retailerLogo,
}) {
  const [tabHeaders, setTabHeaders] = useState([]);

  useEffect(() => {
    const newTabHeaders = [];

    if (hasInStore) {
      newTabHeaders.push(inStoreText);
    }

    if (hasOnline) {
      newTabHeaders.push(buyOnlineText);
    }

    setTabHeaders(newTabHeaders);
  }, [buyOnlineText, inStoreText, hasInStore, hasOnline]);

  return (
    <div className={cx(styles.container, { [styles.hasBackground]: hasBackground })}>
      {retailerLogo?.fields?.file && (
        <div className={styles.logo}>
          <Image
            url={retailerLogo.fields.file.url}
            alt={retailerLogo.fields.description}
            quality="60"
          />
        </div>
      )}

      {isPageHeader ? (
        <h1 className={cx('h3', styles.title)}>{title}</h1>
      ) : (
        <h2 className={cx('h3', styles.title)}>{title}</h2>
      )}

      <p className={cx('p3', styles.description)}>{description}</p>

      {tabHeaders.length > 1 && (
        <ul className={styles['tab-header']}>
          {tabHeaders.map((tab, i) => (
            <li className={cx(styles['tab-item'], { [styles.active]: tab === activeTab })} key={i}>
              <AccessibleButton
                className={styles['tab-button']}
                onClick={() => setActiveTab(tab)}
                onTouchStart={() => setActiveTab(tab)}>
                {tab}
              </AccessibleButton>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
