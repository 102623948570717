import React, { useContext } from 'react';
import { getComponent } from '../../../lib/ComponentFactory';
import Hero from '../../Hero/Hero';
import PLPHero from '../../PLPHero/PLPHero';
import PCPHero from '../../PCPHero/PCPHero';
import PhilosophyHero from '../../PhilosophyHero/PhilosophyHero';
import HeroErrorPage from '../../HeroErrorPage/HeroErrorPage';
import HeroSimple from '../../HeroSimple/HeroSimple';
import SiteContext from '../../AppContext';
import { getHomepageSchema } from '../../../lib/Schema';
import Head from 'next/head';

import styles from './freeForm.module.scss';

/**
 * React component for Free Form template
 * @param {object} [props] component props
 * @param {object} [props.fields] props coming form contentful
 * @returns {JSX.Element} Returns Philosophy template react component
 */
export default function FreeForm({ fields }) {
  const {
    components,
    ctas,
    description,
    headerImage,
    headline,
    ingredientAccentImage,
    errorMessage,
    errorPage,
    heroStyle,
    bulletPoints,
  } = fields;

  const heroContent = {
    accentImage: ingredientAccentImage,
    headerImage,
    headline,
    subhead: description,
    headerCTAs: ctas,
    errorMessage,
    errorPage,
    bulletPoints,
  };

  const getComponentElement = (comp) => {
    if (comp.sys?.contentType?.sys?.id) {
      return getComponent(comp.sys.contentType.sys.id, comp);
    } else {
      return null;
    }
  };

  const { locale, brand } = useContext(SiteContext);
  const homepageSchema = getHomepageSchema(fields, locale);

  // Render logic for error hero components
  const errorHeroComponentMap = {
    summit: Hero,
  };

  const ErrorHeroComponent = errorHeroComponentMap[brand] ?? HeroErrorPage;

  // Render logic for simple hero components
  const plpHeroComponent = (
    <PLPHero
      title={heroContent.headline}
      headerImage={heroContent.headerImage}
      hasMarginBottom={true}
      accentImage={heroContent.accentImage}
    />
  );

  const pcpHeroComponent = (
    <PCPHero
      title={heroContent.headline}
      categoryDescription={heroContent.subhead}
      bulletPoints={heroContent.bulletPoints}
      headerImage={heroContent.headerImage}
    />
  );

  const simpleHeroComponent = (
    <HeroSimple
      title={heroContent.headline}
      headerImage={heroContent.headerImage}
      accentImage={heroContent.accentImage}
    />
  );

  const simpleHeroComponentMap = {
    gather: simpleHeroComponent,
    'go-solutions': plpHeroComponent,
    'now-fresh': plpHeroComponent,
    petcurean: simpleHeroComponent,
    summit: simpleHeroComponent,
  };

  const renderFancyPhilosophyHero = brand === 'gather' || brand === 'petcurean';
  const fancyHeroComponent = renderFancyPhilosophyHero ? (
    <PhilosophyHero {...heroContent} />
  ) : (
    <Hero {...heroContent} />
  );

  const gatherFancyHeroOverwrite = brand === 'gather' && heroContent?.bulletPoints?.length > 0;

  const RenderSimpleHero = simpleHeroComponentMap[brand] ?? plpHeroComponent;
  const RenderFancyHero = gatherFancyHeroOverwrite ? pcpHeroComponent : fancyHeroComponent;

  return (
    <div className={styles.container}>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(homepageSchema) }}
        />
      </Head>
      {errorPage ? (
        <ErrorHeroComponent {...heroContent} />
      ) : heroStyle === 'simple' ? (
        RenderSimpleHero
      ) : (
        RenderFancyHero
      )}

      {components &&
        components.map((comp, index) => {
          // Add a component index in case we need to modify ids
          // for components that appear more than once
          comp.componentIndex = index;
          comp.isFreeFormComponent = true;
          const cfId = comp?.sys?.id;
          return (
            <React.Fragment key={cfId ? `${index}-${cfId}` : index}>
              {getComponentElement(comp)}
            </React.Fragment>
          );
        })}
    </div>
  );
}
