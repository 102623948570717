import cx from 'classnames';

import styles from './styledRadioGroup.module.scss';

/**
 * @param {object} [props] component props
 * @param {string} [props.id] radio group id
 * @param {string} [props.name] radio group name
 * @param {number} [props.label] radio group question
 * @param {string} [props.value] current active values
 * @param {Function} [props.onChange] radio on change handler
 * @param {boolean} [props.showError] flag to show or hide the error message
 * @param {string} [props.errorMsg] the error message text
 * @param {Array<object>} [props.advancedOptions] Options with submit value and label data
 * @param {Array} [props.options] Options to show
 * @param {string} [props.className] css class override for the component container
 * @param {boolean} [props.ariaRequired] flag to add the aria-required attribute
 * @returns {JSX.Element} react component
 */
export default function StyledRadioGroup({
  id,
  name,
  label,
  value,
  advancedOptions,
  options,
  onChange,
  showError,
  errorMsg,
  className,
  ariaRequired,
}) {
  // We will prioritize advanced options over options
  const hasAdvancedOptions = advancedOptions?.length > 0;

  return (
    <fieldset
      className={cx(styles.container, {
        [className]: className,
        [styles.error]: showError,
      })}>
      <legend className={cx('p2 bold', styles.description)}>{label}</legend>
      {hasAdvancedOptions
        ? advancedOptions.map((option, i) => {
            return (
              <label key={i} className={styles.option} id={`label-${id}-${i}`}>
                <div className={styles.inputWrapper}>
                  <input
                    className={styles.input}
                    id={`${id}-${i}`}
                    aria-labelledby={`label-${id}-${i}`}
                    name={name}
                    value={option.key}
                    type="radio"
                    checked={value === option.key}
                    onChange={onChange}
                    required={ariaRequired}
                  />
                </div>
                <span className={cx('p2', styles.label)}>{option.value}</span>
              </label>
            );
          })
        : options?.map((option, i) => {
            return (
              <label key={i} className={styles.option} id={`label-${id}-${i}`}>
                <div className={styles.inputWrapper}>
                  <input
                    className={styles.input}
                    aria-labelledby={`label-${id}-${i}`}
                    id={`${id}-${i}`}
                    name={name}
                    value={option}
                    type="radio"
                    checked={value === option}
                    onChange={onChange}
                    required={ariaRequired}
                  />
                </div>
                <span className={cx('p2', styles.label)}>{option}</span>
              </label>
            );
          })}
      <p className={styles.errorMsg}>{errorMsg}</p>
    </fieldset>
  );
}
