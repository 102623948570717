import { useContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import SiteContext from '../AppContext';
import { colors } from '../../lib/constants';
import cx from 'classnames';

import useTranslation from '../../lib/hooks/useTranslation';
import Article from './Article';
import Image from '../Image/Image';
import { getImageSize } from '../../lib/imageUtils';

import styles from './relatedArticles.module.scss';

/**
 * Formats related 'article' data based on content type
 * @param {object} article article object returned from Contentful
 * @returns {object} related articles fields
 */
function getFieldsByContentType(article) {
  const contentType = article.sys.contentType.sys.id;
  const { fields } = article;

  switch (contentType) {
    case 'blogPost':
      return {
        articleImage: fields.articleHeroImage,
        title: fields.title,
        description: fields.shortDescription,
      };
    case 'blogLanding':
      return {
        articleImage: fields.pageThumbnail,
        title: fields.title,
        description: fields.description,
      };
    case 'freeFormTemplate':
      return {
        articleImage: fields.pageThumbnail || fields.headerImage,
        title: fields.headline,
        description: fields.description,
      };
    default:
      return {};
  }
}

/**
 * @param {object} [props] component props
 * @param {object} [props.fields] related articles data
 * @param {string} [props.fields.eyebrow] eyebrow text
 * @param {string} [props.fields.sectionTitle] title text
 * @param {object} [props.fields.articles] data for each article
 * @param {Array<object>} [props.fields.accentImages] accent images data
 * @returns {JSX.Element} hero copy react component
 */
export default function RelatedArticles({
  fields: { eyebrow, sectionTitle, articles, accentImages },
}) {
  const { brand } = useContext(SiteContext);
  const { t } = useTranslation();
  const [firstAccent, secondAccent] = brand === 'now-fresh' && accentImages ? accentImages : [];
  const [animInFirstAccent, setAnimInFirstAccent] = useState(false);
  const [animInSecondAccent, setAnimInSecondAccent] = useState(false);

  const firstAccentImageSize = firstAccent?.fields?.file?.details?.image;
  const firstAccentImageSizes = {
    defaultSize: getImageSize(593, firstAccentImageSize),
    fallbackSize: getImageSize(593, firstAccentImageSize),
  };

  const secondAccentImageSize = secondAccent?.fields?.file?.details?.image;
  const secondAccentImageSizes = {
    defaultSize: getImageSize(593, secondAccentImageSize),
    fallbackSize: getImageSize(593, secondAccentImageSize),
  };

  const [firstAccentRef, firstAccentInView] = useInView({
    rootMargin: '-100px 0px',
    triggerOnce: true,
  });

  const [secondAccentRef, secondAccentInView] = useInView({
    rootMargin: '-100px 0px',
    triggerOnce: true,
  });

  useEffect(() => {
    if (firstAccentInView) {
      setAnimInFirstAccent(true);
    }
  }, [firstAccentInView]);

  useEffect(() => {
    if (secondAccentInView) {
      setAnimInSecondAccent(true);
    }
  }, [secondAccentInView]);

  return (
    <section className={styles.container}>
      <p className={styles.eyebrow}>{eyebrow}</p>
      <h2 className={styles.title}>{sectionTitle}</h2>
      {articles
        ?.filter(
          (article) =>
            typeof article.fields !== 'undefined' && article.fields?.availability === 'active',
        )
        .map((article, index) => {
          const { articleImage, title, description } = getFieldsByContentType(article);

          return (
            <Article
              key={index}
              article={article}
              articleImage={articleImage}
              title={title}
              description={description}
              t={t}
            />
          );
        })}
      {firstAccent?.fields?.file && (
        <div
          className={cx(styles.accent, styles.firstAccent, { [styles.animIn]: animInFirstAccent })}
          ref={firstAccentRef}>
          <Image
            alt={firstAccent?.fields?.description}
            sizes="(max-width: 1023px) 100vw, (min-width: 1024px) 45vw"
            url={firstAccent.fields.file.url}
            imageSizes={firstAccentImageSizes}
            bgColor={colors.nowFresh.backgroundTan}
            quality="60"
          />
        </div>
      )}
      {secondAccent?.fields?.file && (
        <div
          className={cx(styles.accent, styles.secondAccent, {
            [styles.animIn]: animInSecondAccent,
          })}
          ref={secondAccentRef}>
          <Image
            alt={secondAccent?.fields?.description}
            sizes="(max-width: 1023px) 100vw, (min-width: 1024px) 45vw"
            url={secondAccent.fields.file.url}
            imageSizes={secondAccentImageSizes}
            bgColor={colors.nowFresh.backgroundTan}
            quality="60"
          />
        </div>
      )}
    </section>
  );
}
